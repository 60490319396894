import { either, isEmpty, isNil, pathOr, pipe } from 'ramda';
import * as yup from 'yup';

export const isMissingCompany = (data, t) => {
  return {
    type: 'Company',
    isInvalid: pipe(
      pathOr({}, ['hubspot', 'company', 'properties', 'name']),
      either(isEmpty, isNil)
    )(data),
    validationMessage: t('hubspot-error-missing-company', {
      dealname: data?.hubspot?.deal?.properties?.dealname,
    }),
  };
};

const getIdxFromString = str => +str.match(/\d/g).join('');

export const getValidationSchema = ({ t }) =>
  yup.object().shape({
    company: yup
      .object()
      .required(t('error-missing-company', 'Company is a required field')),

    request_type: yup
      .object()
      .required(
        t('error-missing-request-type', 'Request type is a required field')
      ),

    // Validations when Truck type is Dry van
    commodity: yup.object().required(t('validation-commodity-required')),

    // Validations when Truck type is Reefer
    temperature: yup.number().when('truck_type', {
      is: 'reefer',
      then: yup
        .number()
        .typeError(t('validation-temperature-number'))
        .required(t('validation-temperature-required')),
    }),

    // Validations when Truck type is Flatbed
    length: yup.number().when('truck_type', {
      is: 'flatbed',
      then: yup
        .number()
        .typeError(t('validation-length-number'))
        .required(t('validation-length-required')),
    }),
    width: yup.number().when('truck_type', {
      is: 'flatbed',
      then: yup
        .number()
        .typeError(t('validation-width-number'))
        .required(t('validation-width-required')),
    }),

    height: yup.number().when('truck_type', {
      is: 'flatbed',
      then: yup
        .number()
        .typeError(t('validation-height-number'))
        .required(t('validation-height-required')),
    }),

    routes: yup.array().of(
      yup
        .object()
        .shape({
          origin: yup.object().shape({}),
          destination: yup.object().shape({}),
          loads_per_month: yup
            .number()
            .integer()
            .positive()
            .min(1)
            .label(t('validation-loads-per-month'))
            .typeError(),
        })
        .test(
          'empty-route',
          'Origin or Destination is empty',
          function (route) {
            const path = this.path;
            const pathIdx = getIdxFromString(path);
            // Don't valdiate last item (should be always empty)
            if (
              isEmpty(route.origin) &&
              isEmpty(route.destination) &&
              isNil(route.loads_per_month) &&
              this.parent.length - 1 === pathIdx &&
              this.parent.length - 1 !== 0
            ) {
              return true;
            }
            if (isEmpty(route.origin)) {
              throw this.createError({
                path: `${path}.origin`,
                message: t('empty-route-origin'),
              });
            }
            if (isEmpty(route.destination)) {
              throw this.createError({
                path: `${path}.destination`,
                message: t('empty-route-destination'),
              });
            }
            if (
              isNil(route.loads_per_month) ||
              isEmpty(route.loads_per_month) ||
              isNaN(route.loads_per_month)
            ) {
              throw this.createError({
                path: `${path}.loads_per_month`,
                message: t('validation-loads-per-month-required'),
              });
            }
            return true;
          }
        )
    ),
  });

export const getCommodityModalValidation = ({ t }) =>
  yup.object().shape({
    category: yup.string().required(t('validation-category-required')),
    name: yup.string().required(t('validation-item-description-required')),
    weight: yup
      .number()
      .integer()
      .positive()
      .typeError(t('validation-weight-number'))
      .required(t('validation-weight-required')),
    valuePerLoad: yup.number().when('toggleShipmentValue', {
      is: true,
      then: yup
        .number()
        .integer()
        .positive()
        .min(100001)
        .label(t('value-per-load'))
        .required(),
    }),
  });

export default [isMissingCompany];
