import React from 'react';

// UI & UX
import { Heading } from '@nuvocargo/nuvo-styleguide';

export default function QuoteaRequestHeading({ title, sub }) {
  return (
    <>
      <Heading
        variant="h3"
        component="div"
        styles={{ root: { marginBottom: '32px' } }}>
        {title}
      </Heading>

      <Heading
        component="div"
        variant="h6"
        styles={{ root: { marginBottom: '48px' } }}>
        {sub}
      </Heading>
    </>
  );
}
