import React from 'react';
import styled from 'styled-components';
import { CTAButton } from '@nuvocargo/nuvo-styleguide';

export default function ErrorPage({ onClick }) {
  return (
    <>
      <Container>
        <Main>
          <div style={{ marginTop: '60px', textAlign: 'center' }}>
            <StyledHeader>We're sorry — something's gone wrong.</StyledHeader>
          </div>
          <div style={{ marginTop: '60px', textAlign: 'center' }}>
            Our team has been notified, but click below to fill out a report
          </div>
          <div
            style={{
              marginTop: '60px',
              display: 'flex',
              justifyContent: 'center',
            }}>
            <CTAButton onClick={onClick}>fill report</CTAButton>
          </div>
        </Main>
      </Container>
    </>
  );
}

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 100px 100%;
  grid-template-areas: 'Header' 'Main';
  @media only screen and (max-device-width: 768px) {
  }
`;

const StyledHeader = styled.span`
  color: ${({ theme }) => theme.colors.green};
  font-family: 'value-sans-regular', sans-serif;
  font-size: 48px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.13;
  letter-spacing: normal;
  padding-top: 30px;
  text-align: center;
`;

const Main = styled.div`
  grid-area: Main;
  margin: 0 auto;
  width: 445px;
  ${({ theme }) => `
  @media only screen and ${theme.device.tablet} {
    width: 100%;
  }
  `}
`;
