import styled from 'styled-components';
import { Form } from 'formik';

export const INPUT_STYLE = {
  root: {
    width: '100%',
  },
  wrapper: {
    width: '100%',
  },
};

export const NARROW_INPUT_STYLE = {
  ...INPUT_STYLE,
  root: {
    width: '332px',
  },
  wrapper: {
    width: '332px',
  },
};

export const FormWrapper = styled(Form)`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
`;

export const FlexGroup = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  margin-bottom: 24px;

  &:last-child {
    margin-bottom: 40px;
    background-color: brown;
  }
`;

export const Subsection = styled(FlexGroup)`
  padding-bottom: 5px;
  border-bottom: ${({ theme }) => theme.outlines.default.border};
`;
