import { Heading } from '@nuvocargo/nuvo-styleguide';
import { useTranslation } from 'react-i18next';
import { Stack } from 'components/generic/kit';
import { ShipmentCard } from './styles';
import LegRow from './LegRow';
import CarrierContent from './CarrierContent';
import LocationContent from './LocationContent';

const ShipmentLeg = ({
  caat,
  carrierName,
  destination,
  driverName,
  legNumber,
  origin,
  scac,
  trailerNumber,
  trailerPlateNumber,
  trailerPlateState,
  truckNumber,
  truckPlateNumber,
  truckPlateState,
}) => {
  const { t } = useTranslation();

  return (
    <Stack>
      <Heading
        variant="h6"
        color="green"
        styles={{
          root: {
            letterSpacing: 'normal',
            lineHeight: '24px',
            marginBottom: '16px',
          },
        }}>
        Leg {legNumber}
      </Heading>

      <ShipmentCard>
        <LegRow
          name={t('shipment-details-route-carrier')}
          rightComponent={
            <CarrierContent
              caat={caat}
              carrierName={carrierName}
              driverName={driverName}
              scac={scac}
              trailerNumber={trailerNumber}
              trailerPlateNumber={trailerPlateNumber}
              trailerPlateState={trailerPlateState}
              truckNumber={truckNumber}
              truckPlateNumber={truckPlateNumber}
              truckPlateState={truckPlateState}
            />
          }
          paddingBottom="12px"
          marginBottom="34px"
          marginTop="24px"
          hasSeparator
        />

        <LegRow
          name={t('shipment-details-route-origin')}
          rightComponent={<LocationContent {...origin} />}
          marginBottom="42px"
        />

        <LegRow
          name={t('shipment-details-route-destination')}
          rightComponent={<LocationContent {...destination} />}
          marginBottom="32px"
        />
      </ShipmentCard>
    </Stack>
  );
};

export default ShipmentLeg;
