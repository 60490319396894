import { useState } from 'react';

/**
 * An useState hook equivalent which preserves data to local storage.
 */
export const useLocalStorageState = (localStorageKey, initialValue) => {
  const maybeExistingValue = localStorage.getItem(localStorageKey);
  let init = maybeExistingValue ? JSON.parse(maybeExistingValue) : initialValue;

  const [currentValue, setCurrentValue] = useState(init);

  const lsSetCurrentValue = nextValue => {
    localStorage.setItem(localStorageKey, JSON.stringify(nextValue));
    setCurrentValue(nextValue);
  };

  return [currentValue, lsSetCurrentValue];
};
