export const STRAPS = 'straps';
export const LOGISTIC_BARS = 'logistic_bars';
export const LOAD_LOCKS = 'load_locks';
export const FUMIGATION = 'fumigation';
export const FOOD_GRADE = 'food_grade';
export const MODERN_UNITS = 'modern_units';
export const ODORLESS = 'odorless';
export const HUMIDITY_CONTROLLED = 'humidity_controlled';
export const LIGHTPROOF = 'lightproof';
export const BOTTLED_SEAL = 'bottle_seal';

export const FASTENING_EQUIPMENT_TEXT = {
  [STRAPS]: 'fastening-equipment-extra-straps',
  [LOGISTIC_BARS]: 'fastening-equipment-logistic-bars',
  logisticBars: 'fastening-equipment-logistic-bars',
  [LOAD_LOCKS]: 'fastening-equipment-load-locks',
  loadLocks: 'fastening-equipment-load-locks',
};
export const FASTENING_EQUIPMENT_QUANTITY = {
  [STRAPS]: 'extraStrapsCount',
  [LOGISTIC_BARS]: 'extraLogisticBarsCount',
  [LOAD_LOCKS]: 'extraLoadLocksCount',
};
export const SAFETY_REQUIREMENTS_TEXT = {
  [BOTTLED_SEAL]: 'safety-requirement-bottled-seal',
};
export const TRAILER_SPECIFICATIONS_TEXT = {
  [FOOD_GRADE]: 'trailer-specification-food-grade',
  foodGrade: 'trailer-specification-food-grade',
  [MODERN_UNITS]: 'trailer-specification-modern-units',
  modernUnits: 'trailer-specification-modern-units',
  odorless: 'trailer-specification-odorless',
  [HUMIDITY_CONTROLLED]: 'trailer-specification-humidity-controlled',
  humidityControlled: 'trailer-specification-humidity-controlled',
  lightproof: 'trailer-specification-lightproof',
};
export const FUMIGATION_TEXT = 'trailer-specification-fumigation';
export const TRAILER_SPECIFICATION_SNAKED_KEYS = {
  foodGrade: FOOD_GRADE,
  modernUnits: MODERN_UNITS,
  odorless: ODORLESS,
  humidityControlled: HUMIDITY_CONTROLLED,
  lightproof: LIGHTPROOF,
};
export const TRAILER_SPECIFICATIONS_CAMMEL_KEYS = {
  [FUMIGATION]: 'fumigation',
  [FOOD_GRADE]: 'foodGrade',
  [MODERN_UNITS]: 'modernUnits',
  [ODORLESS]: 'odorless',
  [HUMIDITY_CONTROLLED]: 'humidityControlled',
  [LIGHTPROOF]: 'lightproof',
};

export const OLD_REQUIREMENTS = [STRAPS, FUMIGATION];
export const SAFETY_REQUIREMENTS = [BOTTLED_SEAL];
export const FASTENING_EQUIPMENTS = [STRAPS, LOGISTIC_BARS, LOAD_LOCKS];
export const TRAILER_SPECIFICATIONS = Object.keys(
  TRAILER_SPECIFICATIONS_CAMMEL_KEYS
);
