import { TextCell } from '@nuvocargo/nuvo-styleguide';
import { StatusFilledCell } from '@nuvocargo/nuvo-styleguide/Table/components/Cell';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { formatDateToNow } from 'core/utils/date-fns';
import { REQUEST_PRICE_STATUS_ENUM } from 'core/utils/routeUtils';

export const getPricedStateByStatus = status => {
  switch (status) {
    case REQUEST_PRICE_STATUS_ENUM.REQUESTED:
      return {
        text: 'pricing-requests-status-requested',
        status: 'warning',
      };
    case REQUEST_PRICE_STATUS_ENUM.PRICED:
      return {
        text: 'pricing-requests-status-complete',
        status: 'success',
      };
    case REQUEST_PRICE_STATUS_ENUM.REJECTED:
      return {
        text: 'pricing-requests-status-rejected',
        status: 'error',
      };
    case REQUEST_PRICE_STATUS_ENUM.EXPIRED:
      return {
        text: 'pricing-requests-status-expired',
        status: 'info',
      };
    case REQUEST_PRICE_STATUS_ENUM.IN_PROGRESS:
      return {
        text: 'pricing-requests-status-in-progress',
        status: 'info',
      };
    case REQUEST_PRICE_STATUS_ENUM.UNDETERMINED:
    default:
      return {
        text: 'pricing-requests-status-undetermined',
        status: 'info',
      };
  }
};

const useCreateColumns = ({ companies, assignees }) => {
  const { t } = useTranslation();
  const DEFAULT_LOADING_OPTION = [{ value: null, label: t('loading') }];

  const columns = React.useMemo(
    () => [
      {
        Header: t('general-request-id-text-label'),
        accessor: 'requestIdentifier',
        Cell: ({ cell: { value } }) => (
          <TextCell textColor="green" fontType="eyebrow" text={value} />
        ),
        autoFilter: {
          type: 'search',
          placeholder: t('general-request-id-text-label'),
          name: 'requestIdentifier',
        },
      },
      {
        Header: t('general-company-text-label'),
        accessor: 'company.name',
        autoFilter: {
          type: 'filter',
          label: t('general-company-text-label'),
          options: companies || DEFAULT_LOADING_OPTION,
          virtualized: true,
        },
        Cell: ({ cell: { value } }) => <TextCell text={value} />,
      },
      {
        Header: t('general-assigned-to-text-label'),
        accessor: 'pricingAssignee.name',
        Cell: ({ cell: { value } }) => (
          <TextCell text={value || t('general-not-assigned-text-label')} />
        ),
        autoFilter: {
          type: 'filter',
          label: t('general-assigned-to-text-label'),
          options: assignees || DEFAULT_LOADING_OPTION,
        },
      },
      {
        Header: t('general-number-routes-text-label'),
        accessor: 'numberOfRoutes',
        maxWidth: 180,
        width: 180,
      },
      {
        Header: t('general-created-on-text-label'),
        accessor: 'updatedAt',
        Cell: ({ cell: { value } }) => (
          <TextCell text={formatDateToNow(value)} />
        ),
      },
      {
        Header: t('general-status-text-label'),
        accessor: 'priceState',
        Cell: ({ cell: { value } }) => {
          const { text, status } = getPricedStateByStatus(value.toUpperCase());
          return <StatusFilledCell text={t(text)} status={status} />;
        },
        maxWidth: 240,
        width: 240,
        autoFilter: {
          type: 'filter',
          label: t('general-status-text-label'),
          options: [
            {
              label: t('pricing-requests-status-requested'),
              value: 'requested',
            },
            {
              label: t('pricing-requests-status-complete'),
              value: 'priced',
            },
            {
              label: t('pricing-requests-status-rejected'),
              value: 'rejected',
            },
            {
              label: t('pricing-requests-status-expired'),
              value: 'expired',
            },
            {
              label: t('pricing-requests-status-undetermined'),
              value: 'undetermined',
            },
            {
              label: t('pricing-requests-status-in-progress'),
              value: 'in_progress',
            },
          ],
        },
      },
    ],
    [companies, assignees]
  );
  return columns;
};

export default useCreateColumns;
