// React & Friends
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Form, Formik } from 'formik';

// Utils
import { useTranslation } from 'react-i18next';
import { anyRejected } from 'core/utils/routeUtils';
import { trackClick } from 'core/utils/trackClick';

import * as paths from 'routes/paths';

// UI & UX
import {
  CTAButton,
  Eyebrow,
  Grid,
  GridColumn,
  Heading,
} from '@nuvocargo/nuvo-styleguide';
import { Stack } from 'components/generic/kit';

// Feature & local components
import {
  Commodities,
  FormError,
  FormHeading,
  NotesField as Notes,
  RouteFields,
  SelectEquipment,
  CompaniesSelectField,
  AdditionalServicesCheckboxes as AdditionalServices,
  RequestTypeAndCurrencyFields,
} from '../../components/generic/form';

// Data & validations
import { getInitialValues } from './data';
import { getValidationSchema } from './validations';

// Hooks 123
import { useAccessorials } from 'core/api/accessorials';
import { useCreateRouteMutation } from '../../core/hooks';

export default function NewRoute() {
  const { t } = useTranslation();
  const [results, setResults] = useState();
  const history = useHistory();
  const {
    mutate: createRoutes,
    isLoading,
    isError,
    error,
  } = useCreateRouteMutation();

  const { data: accessorials = [] } = useAccessorials();

  const removeFromResults = route => {
    setResults({ ...results, [route.id]: null });
  };

  return (
    <Grid
      styles={{
        root: {
          marginTop: '60px',
          paddingBottom: '100px',
        },
      }}>
      <GridColumn startMd={0} md={3}>
        <Stack>
          <Eyebrow text={''} color="grey" />
          <Heading variant="h6">{''}</Heading>
        </Stack>
      </GridColumn>
      <GridColumn
        startMd={4}
        md={6}
        styles={{ root: { width: '656px', margin: '0 auto' } }}>
        <Formik
          initialValues={getInitialValues(t)}
          validationSchema={getValidationSchema({ t })}
          onSubmit={values => {
            createRoutes(
              { values, prevResults: results },
              {
                onSuccess: data => {
                  setResults(data);

                  if (anyRejected(data)) return;

                  history.push(
                    `${paths.app.routes}?page=1&companyId=${values.company.id}`
                  );
                },
              }
            );
            trackClick('Create Route Form Submitted');
          }}>
          {({ values, setFieldValue, submitForm }) => (
            <Form>
              <FormHeading title="New Route" />

              <Heading
                variant="h5b"
                style={{ marginBottom: '27px', marginTop: '28px' }}>
                {t('select-company', 'Select Company')}
              </Heading>

              <CompaniesSelectField
                onChange={company => {
                  setFieldValue('commodity', '');
                  trackClick('New Route Company Selected', {
                    company_id: company.id,
                  });
                }}
              />

              <Heading variant="h5b" style={{ marginBottom: '27px' }}>
                {t('select-request-type', 'Select Request Type')}
              </Heading>

              <RequestTypeAndCurrencyFields />

              <Heading
                variant="h5b"
                style={{ marginBottom: '27px', marginTop: '28px' }}>
                {t('select-additional-services', 'Select Additional Services')}
              </Heading>

              <AdditionalServices />

              {/**
               * This will render Route & Commodity Special Requirements once
               * the accessorial query is completed.
               * There are a couple of things to improve here:
               *   - Both SelectEquipment & Commodities components are expecting
               *     to always have all the Accessorials available; if one is
               *     removed from the response, the app will throw an error
               *   - As this is another request, which is async, I think it'd be
               *     appropriate to have a "loading" state.
               */}
              {accessorials?.length > 0 && (
                <>
                  <SelectEquipment
                    value={values.truck_type}
                    accessorials={accessorials}
                  />

                  <Commodities
                    companyId={values.company?.id}
                    accessorials={accessorials}
                  />
                </>
              )}

              <RouteFields
                results={results}
                removeFromResults={removeFromResults}
              />

              <Notes />

              <FormError isError={isError} error={error} />

              <CTAButton
                styles={{
                  primary: {
                    marginTop: '48px',
                    width: '230px',
                    height: '64px',
                  },
                  disabledStyle: { marginTop: '48px' },
                }}
                type="button"
                onClick={submitForm}
                disabled={isLoading}>
                Submit
              </CTAButton>
            </Form>
          )}
        </Formik>
      </GridColumn>
    </Grid>
  );
}
