import React from 'react';

// Utils
import { map } from 'lodash';

// UI & UX
import { Stack } from 'components/generic/kit';
import { ErrorMessage } from 'components/generic/form';

export default function QuoteRequestError({ isError, error }) {
  if (!isError) return null;

  const displayErrors = () => {
    if (!error.data) {
      return '';
    }

    if (!error.data?.errors) {
      return '';
    }

    let errorMessages = null;
    if (Array.isArray(error.data?.errors)) {
      errorMessages = error.data.errors.map(err => err);
    } else if (typeof error.data?.errors === 'object') {
      errorMessages = map(error.data.errors, (v, k) => {
        if (!Array.isArray(v)) {
          return `${k} ${v}`;
        }

        return `${k}: ${v.join(', ')}`;
      });
    }

    return errorMessages.map(m => <li key={m}>{m}</li>);
  };

  return (
    <Stack>
      <ErrorMessage backgroundColor="salmon">
        <ul>
          {displayErrors() || (
            <li>
              {
                'Some errors occurred while processing the request, please check above for more details or try again.'
              }
            </li>
          )}
        </ul>
      </ErrorMessage>
    </Stack>
  );
}
