import styled from 'styled-components';
import { Form } from 'formik';

export const FormWrapper = styled(Form)`
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 100%;
`;
