import { TextCell } from '@nuvocargo/nuvo-styleguide/InputTable/components/Cell';
import { formatMoney, ROUTE_SERVICEABLE_ENUM } from 'core/utils';
import { graphQLCountryToFlagString } from '../../../track-and-trace/utils';
import { generatePath, useParams, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { app } from 'routes/paths';
import { useMemo } from 'react';

export const createPublishedDetailsColumns = () => {
  const { t } = useTranslation();
  let columns = useMemo(
    () => [
      {
        Header: t('general-origin-text-label'),
        accessor: 'origin',
        Cell: ({ value, ...rest }) => (
          <TextCell
            fontColor="green"
            text={`${value.zipCode.parents.city}, ${value.zipCode.name}, ${value.zipCode.parents.state}`}
            leftIcon={{
              name: graphQLCountryToFlagString(value.zipCode.parents.country),
            }}
            {...rest}
          />
        ),
      },
      {
        Header: t('general-destination-text-label'),
        accessor: 'destination',
        Cell: ({ value, ...rest }) => (
          <TextCell
            fontColor="green"
            text={`${value.zipCode.parents.city}, ${value.zipCode.name}, ${value.zipCode.parents.state}`}
            leftIcon={{
              name: graphQLCountryToFlagString(value.zipCode.parents.country),
            }}
            {...rest}
          />
        ),
      },
      {
        Header: t('general-time-in-transit-text-label'),
        accessor: 'timeInTransit',
        alignment: 'center',
        width: '180px',
        Cell: ({ value, row, ...rest }) => {
          const {
            serviceable: { value: serviceableValue },
          } = row;
          const isServiceable =
            serviceableValue === ROUTE_SERVICEABLE_ENUM.SERVICEABLE;
          return (
            <TextCell
              fontColor="steel"
              text={
                isServiceable
                  ? `${value.lower || 0} - ${value.upper || 0} ${t(
                      'days-text-label'
                    )}`
                  : '--'
              }
              {...rest}
            />
          );
        },
      },
      {
        Header: t('general-route-price-text-label'),
        accessor: 'priceCents',
        alignment: 'right',
        width: '180px',
        Cell: ({ value, row, ...rest }) => {
          const {
            serviceable: { value: serviceableValue },
          } = row;
          const isServiceable =
            serviceableValue === ROUTE_SERVICEABLE_ENUM.SERVICEABLE;
          return (
            <TextCell
              fontColor="blue"
              text={
                isServiceable
                  ? formatMoney(parseFloat(value / 100), 'usd')
                  : t('pricing-not-serviceable-text-label')
              }
              {...rest}
            />
          );
        },
      },
    ],
    [t]
  );
  return columns;
};

export const usePublishedDetailsTable = () => {
  const history = useHistory();
  const { id: requestId } = useParams();
  const columns = createPublishedDetailsColumns();

  const goTo = () => {
    const route = generatePath(app.pricingRequestsServiceability, {
      id: requestId,
    });
    history.push(route);
  };

  return {
    columns,
    goTo,
  };
};
