import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  DropdownCell,
  TextCell,
} from '@nuvocargo/nuvo-styleguide/InputTable/components/Cell';
import { useMutation } from 'react-query';
import { assignRepToPricingRequestMutation } from 'core/api';
import { graphQLCountryToFlagString } from '../../../track-and-trace/utils';
import { ROUTE_SERVICEABLE_ENUM } from 'core/utils/routeUtils';

const createServiceAbilityColumns = () => {
  const { t } = useTranslation();
  const columns = useMemo(
    () => [
      {
        Header: t('general-origin-text-label'),
        accessor: 'origin',
        Cell: ({ value, ...rest }) => {
          return (
            <TextCell
              fontColor="green"
              text={`${value.zipCode.parents.city}, ${value.zipCode.name}, ${value.zipCode.parents.state}`}
              leftIcon={{
                name: graphQLCountryToFlagString(value.zipCode.parents.country),
              }}
              {...rest}
            />
          );
        },
      },
      {
        Header: t('general-destination-text-label'),
        accessor: 'destination',
        Cell: ({ value, ...rest }) => {
          return (
            <TextCell
              fontColor="green"
              text={`${value.zipCode.parents.city}, ${value.zipCode.name}, ${value.zipCode.parents.state}`}
              leftIcon={{
                name: graphQLCountryToFlagString(value.zipCode.parents.country),
              }}
              {...rest}
            />
          );
        },
      },
      {
        Header: t('general-serviceable-text-label'),
        accessor: 'serviceable',
        Cell: value => {
          return (
            <DropdownCell
              {...value}
              options={[
                {
                  value: ROUTE_SERVICEABLE_ENUM.SERVICEABLE,
                  label: t('serviceable-text-label'),
                },
                {
                  value: ROUTE_SERVICEABLE_ENUM.NOT_SERVICEABLE,
                  label: t('non-serviceable-text-label'),
                },
              ]}
            />
          );
        },
      },
    ],
    []
  );
  return columns;
};

const UseServiceability = () => {
  const mutateUpdateRoute = useMutation(
    ['assignRepToPricingRequest'],
    ({ requestIdentifier }) =>
      assignRepToPricingRequestMutation({ requestIdentifier }),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  const columns = createServiceAbilityColumns();
  return {
    columns,
    mutateUpdateRoute,
  };
};

export default UseServiceability;
