import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { logo } from 'assets/images';

export default function LinkSent() {
  const { t } = useTranslation();

  return (
    <>
      <Container>
        <Wrapper>
          <Logo src={logo} />
          <Headline>{t('link-sent-header')}</Headline>
          <Row>
            <Link to="/auth/link" className="db tc dark-green">
              {t('link-not-received')}
            </Link>
          </Row>
        </Wrapper>
      </Container>
    </>
  );
}

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
  grid-auto-rows: 300px;
  grid-template-areas:
    '. column .'
    '. column .';
  background-color: ${props => props.theme.colors.sand};
  @media only screen and (max-device-width: 768px) {
  }
`;

const Wrapper = styled.div`
  align-self: center;
  grid-area: column;
  justify-self: center;
  width: 60%;
  @media only screen and (max-device-width: 768px) {
    width: 100%;
  }
`;

const Logo = styled.img`
  display: block;
  margin: 0 auto;
  width: 200px;
`;

const Row = styled.div`
  margin: 20px 0;
`;

const Headline = styled.h3`
  color: ${props => props.theme.colors.steel};
  font-size: 1em;
  font-weight: 400;
  margin: 20px 0 30px 0;
  text-align: center;
`;
