import { v4 as uuidv4 } from 'uuid';
import {
  buildSpecialRequirementsOptions,
  buildYesNoDropdownOptions,
} from 'core/utils';

export const createRequestQuoteServiceTypeOptions = t => [
  { label: t('quote-request-transload-label'), value: 'transload' },
  { label: t('quote-request-direct-label'), value: 'direct' },
];

const extraStrapsSize = 9;

// This function will be implemented to let user select 1-8 extra straps in the _Accessorials_ input table
export const buildStrapsOptions = t =>
  [...Array(extraStrapsSize)].map((strap, index) => ({
    label: `${index} ${t('quote-request-extra-strap')}`,
    value: index,
  }));

export const getInitialValues = t => ({
  // NOTE: I think is wrong to assume this two values, might be
  // incorrect on this one, double check.
  // request_type: { label: 'Contracted', value: 'contracted' },
  // currency: { label: 'USD', value: 'usd' },
  request_type: '',
  service_type: createRequestQuoteServiceTypeOptions(t)[0],
  border_crossing: buildYesNoDropdownOptions(t)[0],
  currency: '',
  truck_type: 'dryvan',
  email: '',
  fuel_pricing: false,
  // Dry Van fields
  special_requirements_dryvan: [],
  additional_services: [],
  notes: '',
  routes: [
    {
      origin: {},
      destination: {},
      loads_per_month: '',
      id: uuidv4(),
    },
  ],
  // Reefer fields
  special_requirements_reefer: [],
  temperature: '',
  // Flatbed requirements
  special_requirements_flatbed: [],
  length: '',
  width: '',
  height: '',
  commodity: '',
  equipment_options: buildSpecialRequirementsOptions(t),
});
