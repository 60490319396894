import * as flag from '@nuvocargo/nuvo-styleguide/Icons/Flags';
import styled from 'styled-components';
import { Stack } from 'components/generic/kit';
import SmallDetails from '../SmallDetails';
import { graphQLCountryToFlagString } from 'screens/track-and-trace/utils';

export default function SmallRoute({ origin, destination }) {
  const originCountryFlag = flag[graphQLCountryToFlagString(origin?.country)];

  return (
    <SmallDetails
      top={
        <Stack axis="horizontal" center>
          {origin?.city}
          {originCountryFlag && (
            <Img src={originCountryFlag} alt={origin?.name} />
          )}
        </Stack>
      }
      down={`to ${destination?.city}`}
    />
  );
}

const Img = styled.img`
  margin-left: 15px;
`;
