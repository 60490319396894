import React from 'react';
import { CTAButton } from '@nuvocargo/nuvo-styleguide';
import { FooterWrapper } from './styles';

const FooterButtons = ({
  handlePrimaryButtonClick,
  handleSecondaryButtonClick,
  isPrimaryButtonDisabled,
  isSecondaryButtonDisabled,
  isSubmit,
  primaryButtonText,
  secondaryButtonText,
}) => {
  return (
    <FooterWrapper>
      <CTAButton
        colorType="secondary"
        onClick={handleSecondaryButtonClick}
        type="button"
        disabled={isSecondaryButtonDisabled}>
        {secondaryButtonText}
      </CTAButton>

      <CTAButton
        onClick={handlePrimaryButtonClick}
        type={isSubmit ? 'submit' : 'button'}
        disabled={isPrimaryButtonDisabled}>
        {primaryButtonText}
      </CTAButton>
    </FooterWrapper>
  );
};

export default FooterButtons;
