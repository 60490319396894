import { stringify } from 'qs';
import { useHistory } from 'react-router';
import {
  useQueryParamsState,
  usePageQueryParam,
  useQueryParams,
} from 'core/hooks';
import {
  createGraphQLCursorParams,
  calculateTotalPagesFromGraphQL,
} from './utils';
import { useGetCustomsShipments } from 'core/api/customsRequest';
import { isEqual } from 'lodash';

const PER_PAGE_COUNT = 20;

export const useShipments = () => {
  const [state = 'ACTIVE', setState] = useQueryParamsState('state');
  const [fileName = '', setFileName] = useQueryParamsState('fileName');
  const [page] = usePageQueryParam([state, fileName]);

  const compareDates = (dateA, dateB) => {
    if (dateA > dateB) {
      return 1;
    } else if (dateA < dateB) {
      return -1;
    } else {
      return 0;
    }
  };

  const borderArrivalTime = shipment => {
    const arrivalEvent = shipment.shipmentEvents.edges.find(
      ({ node }) => node.type === 'ARRIVED_TO_BORDER'
    ).node;
    return arrivalEvent.actualDatetime || arrivalEvent.estimatedDatetime;
  };

  const paperworkComplete = shipment => {
    const attachments = shipment.attachments.map(
      attachment => attachment.category
    );
    const requiredDocs = [
      'bill_of_lading',
      'commercial_invoice',
      'packing_list',
    ];
    return isEqual(attachments, requiredDocs);
  };

  const { data, isLoading } = useGetCustomsShipments(
    {
      ...createGraphQLCursorParams(page - 1, PER_PAGE_COUNT),
      id: !fileName ? undefined : fileName,
      state,
    },
    {
      select: data => ({
        shipments: data.shipments.nodes
          .filter(({ closeToBorder }) => closeToBorder)
          .map(shipment => ({
            ...shipment,
            company: {
              ...shipment?.company,
              name: shipment.company?.legalName,
            },
            paperworkComplete: paperworkComplete(shipment),
          }))
          .sort((shipmentA, shipmentB) =>
            compareDates(
              borderArrivalTime(shipmentA),
              borderArrivalTime(shipmentB)
            )
          ),
        totalPages: calculateTotalPagesFromGraphQL(
          data.shipments.totalCount,
          PER_PAGE_COUNT
        ),
      }),
    }
  );

  return {
    fileName,
    isLoading,
    page,
    setFileName,
    setState,
    shipments: data?.shipments ?? [],
    state,
    totalPages: data?.totalPages ?? 1,
  };
};

export const useShipmentsSidebar = () => {
  const { shipmentId: urlShipmentId, ...qp } = useQueryParams();
  const history = useHistory();
  const updateShipmentsSidebar = ({
    shipmentId = urlShipmentId,
    remove = false,
  }) => {
    let update = {};

    if (!remove) {
      update = {
        shipmentId,
      };
    }

    history.push({
      search: stringify(
        {
          ...qp,
          ...update,
        },
        { encode: false }
      ),
    });
  };

  return {
    shipmentId: urlShipmentId,
    updateShipmentsSidebar,
    qp,
  };
};
