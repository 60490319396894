import { useState } from 'react';
import { CTAButton, Heading, Label, MdIcon } from '@nuvocargo/nuvo-styleguide';
import { Checkbox } from '@nuvocargo/nuvo-styleguide/forms/native';
import { useTranslation } from 'react-i18next';
import { Wrapper, DocumentRow } from './styles';
import { formatShortDateTime, formatDateToNow } from 'core/utils/date-fns';
import { useRequestCustomsDocuments } from 'core/api/customsRequest';
import { useQueryParams } from 'core/hooks';
import { toast } from 'react-toastify';
import { Text } from '@nuvocargo/nuvo-styleguide';

export const Documents = ({ documents, documentsRequestedAt }) => {
  const { t } = useTranslation();
  const { shipmentId } = useQueryParams();

  const [docsRequestedAt, setDocsRequestedAt] = useState(documentsRequestedAt);

  const onSuccessCallback = shipment => {
    setDocsRequestedAt(shipment.documentsRequestedAt);
    toast.success(t('documents-requested', { shipmentId }));
  };

  const { isLoading, mutate: requestDocuments } =
    useRequestCustomsDocuments(onSuccessCallback);

  const customsRelatedDocuments = [
    'commercial_invoice',
    'packing_list',
    'bill_of_lading',
  ];

  const missingDocs = customsRelatedDocuments.filter(
    category => !documents.find(doc => doc.category === category)
  );

  const [requestedCategories, setRequestedCategories] = useState(missingDocs);

  const handleCheckboxChange = e => {
    const { value, checked } = e.target;

    if (checked) {
      setRequestedCategories(prev => [...prev, value]);
    } else {
      setRequestedCategories(prev =>
        prev.filter(category => category !== value)
      );
    }
  };

  const handleDocumentRequestClick = () =>
    requestDocuments({
      id: shipmentId,
      requestedDocuments: requestedCategories,
    });

  return (
    <Wrapper className="mb-5">
      <Heading
        variant="h6"
        color="green"
        styles={{ root: { marginBottom: '16px' } }}>
        {t('documents')}
      </Heading>
      {customsRelatedDocuments.map(category => {
        const doc = documents.find(doc => doc.category === category);
        return (
          <DocumentRow key={category}>
            <MdIcon name="MdOutlineInsertDriveFile" size="24px" color="steel" />
            <div
              className={
                doc ? 'document-row-text' : 'document-row-text field-error'
              }>
              <Text color={doc ? 'darkGrey' : 'red'}>
                {t(`doc_${category}`)}
              </Text>
              {(doc?.document?.fileName || doc?.name) && (
                <span>{doc.document.fileName || doc.name}</span>
              )}
            </div>
            <div className="document-row-date">
              {doc?.document?.createdAt
                ? formatShortDateTime(doc.document.createdAt)
                : ''}
            </div>
            {doc ? (
              <button
                className="document-row-download mr-5"
                onClick={() => {
                  window.open(doc.document.url);
                }}>
                {t('download')}
              </button>
            ) : (
              <>
                <Label
                  color="darkGrey"
                  htmlFor={`requestDocument[${category}]`}>
                  {t('request-document')}
                </Label>
                <Checkbox
                  styles={{
                    root: {
                      marginRight: '20px',
                    },
                  }}
                  type="checkbox"
                  name="requestDocument"
                  id={`requestDocument[${category}]`}
                  onChange={handleCheckboxChange}
                  checked={requestedCategories.indexOf(category) > -1}
                  value={category}
                />
              </>
            )}
          </DocumentRow>
        );
      })}
      <div className="mt-10 flex justify-between">
        <span>
          {docsRequestedAt
            ? t('documents-last-requested', {
                time: formatDateToNow(docsRequestedAt),
              })
            : ''}
        </span>
        <CTAButton
          type="button"
          mode="light"
          colorType="primary"
          disabled={isLoading || requestedCategories.length === 0}
          onClick={handleDocumentRequestClick}>
          {t('request-documents')}
        </CTAButton>
      </div>
    </Wrapper>
  );
};
