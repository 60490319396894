import React from 'react';
import { Formik, Form } from 'formik';
import {
  Grid,
  GridColumn,
  CTAButton,
  Heading,
  Icon,
  Text,
} from '@nuvocargo/nuvo-styleguide';
import { UnauthenticatedLayout as Layout } from 'components/common/layout';
import { useTranslation } from 'react-i18next';
import { TextField } from '@nuvocargo/nuvo-styleguide/forms/formik';
import { ErrorMessage } from 'components/generic/form';
import styled, { css } from 'styled-components';
import { initialValues, validationSchema } from './data';
import { openBanner } from 'core/hooks/useBanner';
import { useHistory } from 'react-router-dom';
import { useForgotPassword } from './hooks';

export default function ForgotPassword() {
  const { t } = useTranslation();
  const history = useHistory();
  const { sendEmail, error, isLoading, invalidTokenError } = useForgotPassword({
    onSuccess: () =>
      // TODO: Fix this banners do not work
      openBanner({
        key: 'forgot-success',
        children: (
          <div style={{ display: 'flex' }}>
            <Icon name="check" color="white" />
            <Heading
              variant="h6"
              styles={{ root: { marginLeft: '10px' } }}
              color="white">
              {t('password-forgot-email-sent')}
            </Heading>
          </div>
        ),
        timeout: 3000,
        onTimeoutEnd: () => history.push('/'),
      }),
  });

  return (
    <Layout>
      <Grid
        styles={{
          root: {
            alignItems: 'center',
            gridTemplateRows: '100vh',
          },
        }}>
        <GridColumn startMd={5} md={4} sm={4}>
          <Header>
            <Heading variant="h3" color="green">
              {t(`forgot-password-title${invalidTokenError}`)}
            </Heading>
            <Text color="steel">
              {t(`forgot-password-subtitle${invalidTokenError}`)}
            </Text>
          </Header>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema(t)}
            onSubmit={sendEmail}>
            <Form>
              <Row>
                <TextField
                  label={t('email')}
                  type="email"
                  name="email"
                  placeholder={t('placeholder-email')}
                />
              </Row>
              {error?.data?.error && (
                <Row>
                  <ErrorMessage backgroundColor="taupe">
                    {error.data.error}
                  </ErrorMessage>
                </Row>
              )}
              <Row alignCenter>
                <CTAButton type="submit" disabled={isLoading}>
                  {t(`forgot-password-btn${invalidTokenError}`)}
                </CTAButton>
              </Row>
            </Form>
          </Formik>
        </GridColumn>
      </Grid>
    </Layout>
  );
}

const Header = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-bottom: 20px;
  & > * + * {
    margin-top: 16px;
  }
`;

const Row = styled.div`
  margin: ${({ verticalSpacing }) =>
      verticalSpacing ? verticalSpacing : '40px'}
    0;
  ${({ alignCenter }) =>
    alignCenter &&
    css`
      display: flex;
      justify-content: center;
    `}
`;
