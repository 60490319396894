import { useQuery } from 'react-query';

import { gql } from 'graphql-request';
import { client } from 'core/utils/axios.config';

import { GET_USERS_QUERY } from 'core/graphql/queries/users';

const GET_ASSIGNEES_QUERY = gql`
  query getAssignees($internalRole: String) {
    users(internalRole: $internalRole) {
      nodes {
        id
        name
      }
    }
  }
`;

export const getAssignees = () =>
  client
    .gql({
      query: GET_ASSIGNEES_QUERY,
      variables: {
        internalRole: 'pricing_representative',
      },
    })
    .then(r => r?.data?.data?.users.nodes ?? []);

export const useAssignees = queryOptions =>
  useQuery('assignees', () => getAssignees(), queryOptions);

export const getUsersRequest = ({ after, first, id, internalRole }) =>
  client
    .gql({
      query: GET_USERS_QUERY,
      variables: {
        after,
        first,
        id,
        internalRole,
      },
    })
    .then(r => r?.data?.data ?? {});

// Get users for track and trace filter dropdown
export const useGetUsers = ({ after, first, id, internalRole }, queryOptions) =>
  useQuery(
    ['users', after, first, id, internalRole],
    () =>
      getUsersRequest({
        after,
        first,
        id,
        internalRole,
      }),
    queryOptions
  );
