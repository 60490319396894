import { useTranslation } from 'react-i18next';
import { Text } from '@nuvocargo/nuvo-styleguide';
import { TextField } from '@nuvocargo/nuvo-styleguide/forms/formik';

const Confirm = () => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col">
      <Text color="green" size="large" fontWeight="bold">
        {t('commodity-modal-confirm-title')}
      </Text>
      <Text color="steel" className="mt-2">
        {t('commodity-modal-confirm-description')}
      </Text>

      <div className="mt-6">
        <TextField
          name="confirm"
          label={t('commodity-modal-confirm-input-label')}
          placeholder={t('commodity-modal-confirm-input-placeholder')}
        />
      </div>
    </div>
  );
};

export default Confirm;
