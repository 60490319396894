import React, { createContext } from 'react';
import { useAuth } from 'core/hooks/useAuth';

export const Context = createContext();

export const Provider = ({ children }) => {
  const authValues = useAuth();

  return <Context.Provider value={authValues}>{children}</Context.Provider>;
};

export function useUser() {
  const context = React.useContext(Context);
  if (context === undefined) {
    throw new Error(`useUser must be used within a UserProvider`);
  }
  return context;
}
