export function usePagination({ page, setPage, totalPages }) {
  const nextPage = () => {
    if (isNaN(page) || page === totalPages) return;
    setPage(page + 1);
  };
  const previousPage = () => {
    const pageIsFirstOrLess = !(page > 1);
    if (isNaN(page) || pageIsFirstOrLess) return;
    setPage(page - 1);
  };

  return {
    gotoPage: setPage,
    nextPage,
    previousPage,
  };
}
