import React from 'react';
import { Text, Heading } from '@nuvocargo/nuvo-styleguide';
import { useTranslation } from 'react-i18next';

const Header = ({ isOrigin, city, state, children }) => {
  const { t } = useTranslation();

  return (
    <div style={{ width: '100%', marginBottom: '48px' }}>
      <Text color="steel">
        {isOrigin ? t('origin') : t('destination')} • {city}, {state}
      </Text>
      <Heading
        color="darkGrey"
        variant="h4"
        styles={{
          root: {
            marginTop: '16px',
          },
        }}>
        {children}
      </Heading>
    </div>
  );
};

export default Header;
