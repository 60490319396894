import Header from './Header';
import Details from './Details';

export default function HeadContent({ shipment }) {
  return (
    <>
      <Header name={shipment.fileName} reference={shipment.customerReference} />
      <Details shipment={shipment} />
    </>
  );
}
