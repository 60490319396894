// React & Friends
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

// Utils
import * as paths from 'routes/paths';
import { useTranslation } from 'react-i18next';
import useCreateColumns from './useCreateColumns';

// UX & UI
import { CTAButton } from '@nuvocargo/nuvo-styleguide';

// Local Components
import { TableWithFilters } from 'components/common/app';

// Hooks
import { useRoutes } from './hooks';

export default function Routes() {
  const { t } = useTranslation();
  const {
    companies,
    filters,
    isLoading,
    onFilterChange,
    page,
    setPage,
    qp,
    routes,
    selected,
    setLocationState,
    totalPages,
  } = useRoutes({ t });

  return (
    <TableWithFilters
      columns={useCreateColumns({
        companies,
        page,
        qp,
        t,
      })}
      data={routes}
      filters={filters}
      isLoading={isLoading}
      onFilterChange={onFilterChange}
      page={page}
      setPage={setPage}
      selected={selected}
      statusOnChange={setLocationState}
      tableTitle={t('routes-table-title')}
      totalPages={totalPages}
      rightComponent={
        <CTAButton
          to={{
            pathname: paths.app.newRoute,
          }}
          size="small"
          as={RouterLink}
          shape="block"
          isLink>
          {t('create-new-route-button', 'Create Route')}
        </CTAButton>
      }
      /**
       * This will override styles to look like a secondary button
       * instead of a primary one. This is a hotfix until the
       * SelectBox component provides a better way to achieve it.
       */
      filtersStylesOverride={{
        buttonClosedAndSelected: {
          backgroundColor: 'transparent !important',
          border: '1px solid rgba(64, 64, 64, 0.15) !important',
          color: 'rgb(48, 116, 96) !important',
          'svg:last-of-type': {
            fill: 'rgb(48, 116, 96)',
          },
          '&:hover': {
            backgroundColor: 'rgba(64, 64, 64, 0.15) !important',
            border: '1px solid rgba(64, 64, 64, 0.15) !important',
          },
        },
      }}
    />
  );
}
