import * as flag from '@nuvocargo/nuvo-styleguide/Icons/Flags';
import { Text } from '@nuvocargo/nuvo-styleguide';
import styled from 'styled-components';
import { Stack, SmallDetails } from 'components/generic/kit';

export default function Location({ location }) {
  const locationCountryFlag = flag[location?.country];
  return (
    <SmallDetails
      top={<Text>{location?.name}</Text>}
      down={
        <Stack axis="horizontal" center>
          {location?.city}
          {locationCountryFlag && (
            <Img src={locationCountryFlag} alt={location?.country} />
          )}
        </Stack>
      }
    />
  );
}

const Img = styled.img`
  margin-left: 8px;
  max-height: 12px;
`;
