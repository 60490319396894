import { GridColumn } from '@nuvocargo/nuvo-styleguide';
import React from 'react';
import FiltersSection from './FiltersSection';
import Table from './Table';

export const TableWithFilters = ({
  columns,
  filters,
  filtersStylesOverride,
  isLoading,
  onFilterChange,
  page,
  searchId,
  searchOnChange,
  searchPlaceholder,
  searchValue,
  selected,
  data,
  tableTitle,
  totalPages,
  rightComponent,
}) => {
  return (
    <GridColumn md={12} sm={4} styles={{ root: { width: '-moz-available' } }}>
      <FiltersSection
        filters={filters}
        filterStylesOverride={filtersStylesOverride}
        onFilterChange={onFilterChange}
        searchId={searchId}
        searchOnChange={searchOnChange}
        searchPlaceholder={searchPlaceholder}
        searchValue={searchValue}
        selected={selected}
        tableTitle={tableTitle}
        rightComponent={rightComponent}
      />

      <Table
        columns={columns}
        data={data}
        isLoading={isLoading}
        page={page}
        totalPages={totalPages}
      />
    </GridColumn>
  );
};

export { default as Table } from './Table';
export * from './styles';
export { default as usePagination } from './usePagination';
