import { useMemo } from 'react';
import { Label } from '@nuvocargo/nuvo-styleguide';
import { Stack } from 'components/generic/kit';
import { useTranslation } from 'react-i18next';

const CarrierContent = ({
  caat,
  carrierName,
  driverName,
  scac,
  trailerNumber,
  trailerPlateNumber,
  trailerPlateState,
  truckNumber,
  truckPlateNumber,
  truckPlateState,
}) => {
  const { t } = useTranslation();

  const carrierData = useMemo(
    () =>
      [
        `${t('route-driver-name')}: ${driverName ?? t('unknown')}`,
        `${t('route-trailer-number')}: ${trailerNumber ?? t('unknown')}`,
        `${t('route-trailer-plate-number')}: ${
          trailerPlateNumber ?? t('unknown')
        }`,
        `${t('route-trailer-plate-state')}: ${
          trailerPlateState ?? t('unknown')
        }`,
        `${t('route-truck-plate-number')}: ${truckPlateNumber ?? t('unknown')}`,
        `${t('route-truck-plate-state')}: ${truckPlateState ?? t('unknown')}`,
        `${t('route-truck-number')}: ${truckNumber ?? t('unknown')}`,
        `${t('route-scac')}: ${scac ?? t('unknown')}`,
        `${t('route-caat')}: ${caat ?? t('unknown')}`,
      ].join(' / '),
    [
      caat,
      driverName,
      scac,
      trailerNumber,
      trailerPlateNumber,
      trailerPlateState,
      trailerPlateState,
      truckNumber,
      truckPlateNumber,
    ]
  );

  return (
    <Stack>
      <Label color="darkGrey">
        {carrierName ?? t('route-carrier-name-unknown')}
      </Label>
      <Label color="steel">
        {carrierName ? carrierData : t('route-details-missing')}
      </Label>
    </Stack>
  );
};
export default CarrierContent;
