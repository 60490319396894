import { CTAButton, Text, Heading } from '@nuvocargo/nuvo-styleguide';
import Iconography from '@nuvocargo/nuvo-styleguide/Icons/Iconography';
import { Card } from 'components/common/app';
import { useTranslation } from 'react-i18next';
import { Stack } from 'components/generic/kit';

const Error = ({ error, callToActionText, onCallToActionClick }) => {
  const { t } = useTranslation();
  return (
    <Card>
      <Stack center>
        <div style={{ marginTop: '83px', marginBottom: '18px' }}>
          <Iconography
            name="insurance"
            styles={{
              width: '96px',
              height: '96px',
            }}
          />
        </div>

        <Heading
          color="green"
          style={{
            marginBottom: '14px',
            maxWidth: '430px',
            textAlign: 'center',
          }}>
          {t('something-went-wrong-shipping-error')}
        </Heading>

        <Text
          color="steel"
          style={{
            marginBottom: '36px',
            maxWidth: '430px',
            textAlign: 'center',
          }}>
          {error}
        </Text>

        {callToActionText && (
          <CTAButton
            onClick={onCallToActionClick}
            style={{
              marginBottom: '66px',
            }}>
            {callToActionText}
          </CTAButton>
        )}
      </Stack>
    </Card>
  );
};

export default Error;
