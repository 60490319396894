import React from 'react';
import { motion } from 'framer-motion';
import Iconography from '@nuvocargo/nuvo-styleguide/Icons/Iconography';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Heading, Text } from '@nuvocargo/nuvo-styleguide';
import CopSuccessButtons from './cop';
import { useQueryParams } from 'core/hooks';

export default function Success() {
  const { t } = useTranslation();
  const { uuid } = useQueryParams();

  return (
    <SuccessWrapper data-testid="signup-success">
      <motion.div
        initial={{
          opacity: 0,
          x: -200,
        }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 1.2, ease: [0.25, 1, 0.5, 1] }}>
        <Iconography name="truck" classname="truck" />
      </motion.div>
      <Heading component="h1" variant="h3" color="green">
        {t('success-title')}
      </Heading>
      <p>
        <Text>{t('success-subtitle')}</Text>
      </p>
      {uuid && <CopSuccessButtons />}
    </SuccessWrapper>
  );
}

const SuccessWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 500px;
  height: 100vh;
  margin: 0 auto;
  max-width: 584px;
  text-align: center;
  padding-left: 1rem;
  padding-right: 1rem;
  h1 {
    font-weight: normal;
  }
  p {
    max-width: 415px;
  }
  svg {
    width: 150px;
    height: 70px;
  }
  ${({ theme }) =>
    css`
      @media only screen and (max-width: ${theme.mediaSizes.sm}) {
        svg {
          height: 50px;
        }
      }
    `}
`;
