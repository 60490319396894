import React from 'react';
import { motion } from 'framer-motion';
const parentVariants = {
  hide: {},
  show: {
    transition: {
      staggerChildren: 0.07,
    },
  },
};

const childVariants = {
  hide: { y: 20, opacity: 0 },
  show: { y: 0, opacity: 1 },
};

const Stagger = ({ children, ...props }) => (
  <motion.div
    variants={parentVariants}
    initial="hide"
    animate="show"
    {...props}>
    {children}
  </motion.div>
);
const StaggerChild = ({ children, ...props }) => (
  <motion.div
    variants={childVariants}
    transition={{ ease: 'easeInOut', duration: 0.4 }}
    {...props}>
    {children}
  </motion.div>
);
Stagger.Child = StaggerChild;

export default Stagger;
