import React, { useState } from 'react';
import { Text, Heading, Link } from '@nuvocargo/nuvo-styleguide';
import { Stack } from 'components/generic/kit';
import { useTranslation } from 'react-i18next';

const LocationInformation = ({
  isOrigin,
  name,
  address,
  contactName,
  contactTelephone,
  showToggle,
}) => {
  const [showExtraInfo, setShowExtraInfo] = useState(!showToggle);
  const { t } = useTranslation();
  return (
    <>
      <Text color="steel" style={{ marginBottom: '4px' }}>
        {isOrigin ? t('origin') : t('destination')}
      </Text>

      <Heading
        color="darkGrey"
        variant="h5"
        styles={{
          root: {
            marginBottom: '10px',
          },
        }}>
        {name}
      </Heading>

      <Text color="steel" style={{ marginBottom: showToggle ? '16px' : '0px' }}>
        {address}
      </Text>

      {showToggle && (
        <Link
          as="span"
          onClick={() => setShowExtraInfo(!showExtraInfo)}
          styles={{
            root: {
              marginBottom: showExtraInfo ? '16px' : 'inherit',
            },
          }}>
          {showExtraInfo ? t('less-details') : t('more-details')}
        </Link>
      )}

      {(!showToggle || showExtraInfo) && (
        <Stack
          axis="horizontal"
          styles={{
            root: {
              marginTop: !showToggle && showExtraInfo ? '32px' : '0px',
            },
          }}>
          <LabelAndText
            label={t('route-add-responsible-label')}
            text={contactName}
            styles={{
              root: {
                marginRight: '32px',
              },
            }}
          />

          <LabelAndText
            label={t('route-add-phone-label')}
            text={contactTelephone}
          />
        </Stack>
      )}
    </>
  );
};

const LabelAndText = ({ label, text, styles }) => {
  if (!text) return null;
  return (
    <Stack styles={styles}>
      <Text color="steel" style={{ marginBottom: '4px' }}>
        {label}
      </Text>
      <Text>{text}</Text>
    </Stack>
  );
};

export default LocationInformation;
