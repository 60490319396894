import { flexLoadingStyle } from 'styles';
import { Flex, Spinner, Stack } from 'components/generic/kit';
import HeadContent from './_components/headContent';
import { useUser, useFeatures } from 'context';
import { isCustomerSuccess } from 'core/utils/roles';
import Configuration from './configuration';
import { Overview } from './overview';
import ShipmentRoute from './route';
import { useShipmentByID } from 'core/api';
import Error from './Error';
import {
  extractDocumentsFromShipment,
  shipmentDetailsViewTypes,
} from '../utils';

export default ShipmentDetails;
export function ShipmentDetails({
  shipmentId,
  shipmentDetailsView,
  closeShipmentDetailsView,
}) {
  const { currentRole } = useUser();
  const { routesIsEnabled } = useFeatures();

  const {
    isLoading,
    data: shipment,
    error,
  } = useShipmentByID(shipmentId, {
    select: shipment => {
      if (!shipment) {
        return null;
      }

      const shipmentDetails = {
        ...shipment,

        company: {
          ...shipment.company,
          name: shipment.company?.legalName,
        },
        fileName: shipment.folioNumber,
        origin: {
          ...shipment.origin,
          postalCode: shipment.origin?.zipCode.name,
          city: shipment.origin.zipCode.parents.city,
          state: shipment.origin.zipCode.parents.state,
          country: shipment.origin.zipCode.parents.country,
        },
        destination: {
          ...shipment.destination,
          postalCode: shipment.destination?.zipCode.name,
          city: shipment.destination.zipCode.parents.city,
          state: shipment.destination.zipCode.parents.state,
          country: shipment.destination.zipCode.parents.country,
        },
        legs: shipment.legs.map(leg => ({
          ...leg,
          origin: {
            ...leg.origin,
            contact: leg.origin?.contactName,
            phone: leg.origin?.contactTelephone,
            postalCode: leg.origin?.zipCode.name,
            city: leg.origin.zipCode.parents.city,
            state: leg.origin.zipCode.parents.state,
            country: leg.origin.zipCode.parents.country,
          },
          destination: {
            ...leg.destination,
            contact: leg.destination?.contactName,
            phone: leg.destination?.contactTelephone,
            postalCode: leg.destination?.zipCode.name,
            city: leg.destination.zipCode.parents.city,
            state: leg.destination.zipCode.parents.state,
            country: leg.destination.zipCode.parents.country,
          },
        })),
        currentShipmentEventId: shipment.currentShipmentEvent.id,
        shipmentEvents: shipment.shipmentEvents.nodes,
        documents: extractDocumentsFromShipment(shipment),
      };

      return {
        ...shipmentDetails,
      };
    },
  });

  // Loading STATE
  if (isLoading) {
    return (
      <Flex styles={flexLoadingStyle}>
        <Spinner />
      </Flex>
    );
  }

  // Error STATE
  if (error) {
    return <Error error={error} />;
  }

  // NotFound STATE
  if (!shipment) {
    return <Error error={`Can't find shipment with ID: ${shipmentId}`} />;
  }

  return (
    <Stack style={{ margin: '40px 36px' }}>
      <HeadContent shipment={shipment} />
      {routesIsEnabled &&
        shipmentDetailsView === shipmentDetailsViewTypes.ROUTE && (
          <ShipmentRoute route={shipment.legs} />
        )}
      {isCustomerSuccess(currentRole) &&
        shipmentDetailsView === shipmentDetailsViewTypes.CONFIGURATION && (
          <Configuration
            shipmentId={shipmentId}
            shipment={shipment}
            closeShipmentDetailsView={closeShipmentDetailsView}
          />
        )}
      {shipmentDetailsView === shipmentDetailsViewTypes.OVERVIEW && (
        <Overview shipment={shipment} />
      )}
    </Stack>
  );
}
