import { stringify } from 'qs';
import { app } from 'routes/paths';

// In Milliseconds
export const ONE_HOUR = 1000 * 60 * 60;
export const EN = 'en';
export const ES = 'es';

export const shipmentDetailsViewTypes = {
  OVERVIEW: 'overview',
  ROUTE: 'route',
  CONFIGURATION: 'configuration',
  EXCEPTION: 'exception',
};

export const ShipmentEventIssuesTypeEnum = {
  accident: 'accident',
  carrier_refused: 'carrier_refused',
  holiday_closure: 'holiday_closure',
  customs_down: 'customs_down',
  customs_traffic: 'customs_traffic',
  mechanical_breakdown: 'mechanical_breakdown',
  production_delay: 'production_delay',
  road_closed: 'road_closed',
  location_not_found: 'location_not_found',
  other: 'other',
};

export const getIssueKeyFromReason = (reason, t, tOptions = {}) => {
  return {
    accident: t('car-accident', tOptions),
    carrier_refused: t('carrier-bounced-service', tOptions),
    holiday_closure: t('closed-per-holiday', tOptions),
    customs_down: t('customs-system-down', tOptions),
    customs_traffic: t('customs-traffic', tOptions),
    mechanical_breakdown: t('mechanical-breakdown', tOptions),
    production_delay: t('production-delay', tOptions),
    road_closed: t('road-closed', tOptions),
    location_not_found: t('unable-to-find-location', tOptions),
    other: t('other', tOptions),
  }[reason];
};

export const calculateShipmentStatusColumnValue = ({
  state,
  progressStatus,
}) => {
  if (state === 'COMPLETED') return 'COMPLETED';
  if (state === 'REQUESTED') return 'PENDING';
  return progressStatus;
};

export const extractFilterQueryParameter = maybeFilterJSONString =>
  maybeFilterJSONString ? JSON.parse(maybeFilterJSONString) : {};

export const buildShipmentDetailsLink = (queryParams, viewName, args = {}) => {
  const searchHistory = {
    ...queryParams,
    ...args,
    shipmentDetailsView: viewName,
  };

  return {
    pathname: app.trackAndTrace,
    search: stringify(searchHistory, { encode: false }),
  };
};

/**
 * Builds a customer-facing url for Shipment details
 * @param { number } shipmentId
 */
export const buildCustomerShipmentDetailsLink = shipmentId => {
  const host = process.env.REACT_APP_CUSTOMER_APP_URL;
  return `${host}/shipments/${shipmentId}`;
};

/**
 * Calculates offset based on page size and a given page
 * @param { number } page
 * @param { number } pageSize
 * @returns { string } Base64 string holding an offset
 */
export const pageAndPageSizeToGraphQLOffset = (page = 0, pageSize = 0) => {
  const offset = page * pageSize;
  return window.btoa(offset).replace(/=/gi, '');
};

export const createGraphQLCursorParams = (page = 0, pageSize = 0) => ({
  first: pageSize,
  after: pageAndPageSizeToGraphQLOffset(page, pageSize),
});

export const calculateTotalPagesFromGraphQL = (totalCount = 1, pageSize = 1) =>
  Math.ceil((totalCount ?? 1) / pageSize);

export const graphQLCountryToFlagString = maybeCountry => {
  switch (maybeCountry) {
    case 'us': {
      return 'usa';
    }

    case 'mx': {
      return 'mexico';
    }

    case 'ca': {
      return 'canada';
    }

    default: {
      return '';
    }
  }
};

const validAttachment = record => (record?.url ? true : false);

const extractDocument = (
  array,
  propName,
  categoryPrefix = '',
  initValue = []
) =>
  array.reduce((acc, rec) => {
    if (validAttachment(rec[propName])) {
      acc.push({
        category: categoryPrefix ? `${categoryPrefix}_${propName}` : propName,
        name: rec[propName].fileName,
        createdAt: rec[propName].createdAt,
        document: {
          ...rec[propName],
        },
      });
    }
    return acc;
  }, initValue);

const objectDocument = (doc, category) => ({
  category,
  name: doc.fileName,
  createdAt: doc.createdAt,
  document: {
    ...doc,
  },
});

const mapDocument = (documents, category) =>
  documents.map(doc => objectDocument(doc, category));

export const extractDocumentsFromShipment = shipment => {
  const {
    attachments = [],
    legs = [],
    customerInvoice,
    documents,
    customerInvoiceXml,
  } = shipment;
  const cartaPortes =
    legs.filter(leg => leg.cartaPorte).map(leg => leg.cartaPorte) || [];
  const receivableLineItems =
    legs
      .filter(leg => leg.receivableLineItems?.edges.length > 0)
      .map(leg => leg.receivableLineItems)
      .flatMap(receivables => receivables.edges) || [];
  const receivableInvoices =
    receivableLineItems.map(receivableLineItem => receivableLineItem.node) ||
    [];

  const dodas = extractDocument(legs, 'doda');
  const pitas = extractDocument(legs, 'pita');
  const manifests = extractDocument(legs, 'manifest');
  const pods = extractDocument(legs, 'pod');
  const cartaPortesXml = extractDocument(
    cartaPortes,
    'xmlDocument',
    'cartaporte'
  );
  const cartaPortesFromCarrier = extractDocument(
    cartaPortes,
    'fromClient',
    'cartaporte'
  );
  const cartaPortesFromClient = extractDocument(
    cartaPortes,
    'fromCarrier',
    'cartaporte'
  );
  const invoices = extractDocument(receivableInvoices, 'invoice', 'receivable');
  const invoicesXml = extractDocument(
    receivableInvoices,
    'invoiceXml',
    'receivable'
  );
  const otherDocuments = mapDocument(documents || [], 'uncategorized');
  const customerInvoices = customerInvoice
    ? [objectDocument(customerInvoice, 'customer_invoice')]
    : [];
  const customerInvoicesXml = customerInvoiceXml
    ? [objectDocument(customerInvoice, 'customer_invoice_xml')]
    : [];

  return [
    ...attachments,
    ...dodas,
    ...pitas,
    ...manifests,
    ...pods,
    ...cartaPortesFromClient,
    ...cartaPortesXml,
    ...cartaPortesFromCarrier,
    ...invoices,
    ...invoicesXml,
    ...customerInvoices,
    ...customerInvoicesXml,
    ...otherDocuments,
  ];
};
