import React from 'react';

export const openBanner = ({ key, children, timeout, onTimeoutEnd }) => {
  if (window && document) {
    let event = new CustomEvent('open-banner', {
      detail: { key, children, timeout },
    });
    window.dispatchEvent(event);

    if (onTimeoutEnd) {
      const timeoutId = setTimeout(() => {
        onTimeoutEnd();
        clearTimeout(timeoutId);
      }, timeout + 600);
    }
  }
};

export const closeBanner = key => {
  if (window && document) {
    let event = new CustomEvent('close-banner', {
      detail: { key },
    });
    window.dispatchEvent(event);
  }
};

export const useBannerEvents = () => {
  const timeoutsRef = React.useRef({});

  // banner {children, key, timeout}
  const [bannersState, setBannersState] = React.useState([]);

  const _openBanner = e => {
    setBannersState(prev => prev.concat([e.detail]));
  };
  const _closeBanner = e => {
    setBannersState(prev => prev.filter(item => item.key !== e.detail.key));
  };

  useEvent('open-banner', _openBanner, [bannersState]);

  useEvent('close-banner', _closeBanner, [bannersState]);

  React.useEffect(() => {
    bannersState.forEach(({ key, timeout }) => {
      if (timeout && !timeoutsRef.current[key]) {
        timeoutsRef.current[key] = setTimeout(() => {
          setBannersState(prev => prev.filter(item => item.key !== key));
          timeoutsRef.current[`${key}-done`] = true;
        }, timeout);
      }
    });

    return () => {
      bannersState.forEach(({ key }) => {
        if (`${key}-done` in timeoutsRef.current) {
          clearTimeout(key);
          delete timeoutsRef.current[key];
          delete timeoutsRef.current[`${key}-done`];
        }
      });
    };
  }, [bannersState]);
  return {
    bannersState,
    setBannersState,
  };
};

const useEvent = (key, handler, deps) => {
  const handlerCallBack = React.useCallback(handler);
  React.useEffect(() => {
    if (window && document) {
      window.addEventListener(key, handlerCallBack);
      return () => window.removeEventListener(key, handlerCallBack);
    }
  }, deps);
};
