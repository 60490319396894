import * as yup from 'yup';
import { transformRouteLocationToPlace } from '../hooks';
import { format, set } from 'date-fns';

export const APPOINTMENT_TYPES = {
  EXACT_TIME: 'exact_time',
  FIFO: 'fifo',
  WINDOW: 'window',
};

const envNumberCopies = process.env.REACT_APP_MAX_NUMBER_OF_SHIPMENT_COPIES;
const maximumNumberOfShipments = parseInt(envNumberCopies, 10);

/* istanbul ignore if */
if (isNaN(maximumNumberOfShipments) || !maximumNumberOfShipments) {
  throw new Error(
    `Environment variable REACT_APP_MAX_NUMBER_OF_SHIPMENT_COPIES must be set to a valid number. parseInt(${envNumberCopies}) gave "${maximumNumberOfShipments}" instead.`
  );
}

export const createValidationSchema = t =>
  yup.object().shape({
    clientResponsibleForBorderCrossing: yup
      .object()
      .when('legsCrossMexicanBorder', {
        is: true,
        then: yup.object().required(t('shipment-builder-required-error')),
      }),
    readyForPickup: yup
      .string()
      .nullable()
      .required(t('shipment-builder-required-error')),
    typeOfLoadUnloadAtDestination: yup
      .object()
      .required(t('shipment-builder-required-error')),
    lowerBoundForDropoffWindowAt: yup
      .string()
      .nullable()
      .when('appointmentTypeAtDestination', {
        is: appointmentTypeAtDestination =>
          appointmentTypeAtDestination?.value === APPOINTMENT_TYPES.WINDOW,
        then: yup
          .string()
          .nullable()
          .required(t('shipment-builder-required-error')),
      }),
    typeOfLoadUnloadAtOrigin: yup
      .object()
      .required(t('shipment-builder-required-error')),
    requestType: yup.object().required(t('shipment-builder-required-error')),
    appointmentTypeAtDestination: yup
      .object()
      .required(t('shipment-builder-required-error')),
    appointmentTypeAtOrigin: yup
      .object()
      .required(t('shipment-builder-required-error')),
    upperBoundForPickupWindowAt: yup
      .string()
      .nullable()
      .when('appointmentTypeAtOrigin', {
        is: appointmentTypeAtOrigin =>
          appointmentTypeAtOrigin?.value === APPOINTMENT_TYPES.WINDOW,
        then: yup
          .string()
          .nullable()
          .required(t('shipment-builder-required-error'))
          .test(
            'must-be-after-readyForPickup',
            t('upper-bound-must-be-after-ready-for-pickup-error'),
            (upperBoundForPickupWindowAt, testContext) => {
              const readyForPickup = testContext.parent.readyForPickup;

              // Both dates must exist
              if (!upperBoundForPickupWindowAt || !readyForPickup) {
                return false;
              }

              // upperBoundForPickupWindowAt must be after readyForPickup
              return (
                new Date(upperBoundForPickupWindowAt).getTime() >
                new Date(readyForPickup).getTime()
              );
            }
          ),
      }),
    upperBoundForDropoffWindowAt: yup
      .string()
      .nullable()
      .when('appointmentTypeAtDestination', {
        is: appointmentTypeAtDestination =>
          appointmentTypeAtDestination?.value === APPOINTMENT_TYPES.WINDOW,
        then: yup
          .string()
          .nullable()
          .required(t('shipment-builder-required-error'))
          .test(
            'must-be-after-lowerBoundForDropoffWindowAt',
            t(
              'upper-bound-must-be-after-lower-bound-for-dropoff-window-at-error'
            ),
            (upperBoundForDropoffWindowAt, testContext) => {
              const lowerBoundForDropoffWindowAt =
                testContext.parent.lowerBoundForDropoffWindowAt;

              // Both dates must exist
              if (
                !upperBoundForDropoffWindowAt ||
                !lowerBoundForDropoffWindowAt
              ) {
                return false;
              }

              // upperBoundForDropoffWindowAt must be after lowerBoundForDropoffWindowAt
              return (
                new Date(upperBoundForDropoffWindowAt).getTime() >
                new Date(lowerBoundForDropoffWindowAt).getTime()
              );
            }
          ),
      }),
    numberOfCopies: yup
      .number()
      .nullable()
      .min(2, t('shipment-builder-number-of-copies-gte-two'))
      .max(
        maximumNumberOfShipments,
        t('shipment-builder-number-of-copies-max', {
          max: maximumNumberOfShipments,
        })
      )
      .integer(t('shipment-builder-number-of-copies-integer')),
    isAppointmentTypeAtOriginFifo: yup
      .object()
      .nullable()
      .when('appointmentTypeAtOrigin', {
        is: appointmentTypeAtOrigin =>
          appointmentTypeAtOrigin?.value === APPOINTMENT_TYPES.WINDOW,
        then: yup.object().required(t('shipment-builder-required-error')),
      }),
    isAppointmentTypeAtDestinationFifo: yup
      .object()
      .nullable()
      .when('appointmentTypeAtDestination', {
        is: appointmentTypeAtDestination =>
          appointmentTypeAtDestination?.value === APPOINTMENT_TYPES.WINDOW,
        then: yup.object().required(t('shipment-builder-required-error')),
      }),
  });

export const createClientResponsibleForBorderCrossingOptions = t => [
  {
    label: t('yes-nuvocargo-manages-border-crossing'),
    value: false,
  },
  {
    label: t('no-client-manages-border-crossing'),
    value: true,
  },
];

export const createRequiresPitaOptions = t => [
  {
    label: t('no-requires-no-pita'),
    value: false,
  },
  {
    label: t('yes-requires-pita'),
    value: true,
  },
];

export const createRequestTypeOptions = t => [
  {
    label: t('request-type-spot'),
    value: 'spot',
  },
  {
    label: t('request-type-committed'),
    value: 'committed',
  },
];

export const createAppointmentTypeOptions = t => [
  {
    label: t('appointment-type-exact-time'),
    value: APPOINTMENT_TYPES.EXACT_TIME,
  },
  {
    label: t('appointment-type-window'),
    value: APPOINTMENT_TYPES.WINDOW,
  },
];

export const createTrialPeriodOptions = t => [
  {
    label: t('trial-period-yes'),
    value: true,
  },
  {
    label: t('trial-period-no'),
    value: false,
  },
];

export const createTypeOfLoadUnloadOptions = t => [
  {
    label: t('load-unload-live-type'),
    value: 'live',
  },
  {
    label: t('load-unload-drop-type'),
    value: 'drop',
  },
];

export const createAdditionalServicesOptions = t => [
  { label: t('customs'), value: 'withCustoms' },
  { label: t('cross-Border-insurance'), value: 'withCrossBorderInsurance' },
  { label: t('financing'), value: 'withFinancing' },
];

export const createIsFifoOptions = t => [
  {
    label: t('no'),
    value: false,
  },
  {
    label: t('yes'),
    value: true,
  },
];

export const ADDITIONAL_SERVICES = {
  CUSTOMS: 'customs',
  CROSS_BORDER_INSURANCE: 'cross_border_insurance',
  FINANCE: 'financing',
};

export const createInitialValues = ({
  origin,
  appointmentTypeAtDestination,
  isAppointmentTypeAtOriginFifo,
  isAppointmentTypeAtDestinationFifo,
  readyForPickup,
  lowerBoundForDropoffWindowAt,
  upperBoundForPickupWindowAt,
  upperBoundForDropoffWindowAt,
  appointmentTypeAtOrigin,
  clientResponsibleForBorderCrossing,
  loadInstructions,
  representativeId,
  requestType,
  requiresPita,
  typeOfLoadUnloadAtDestination,
  typeOfLoadUnloadAtOrigin,
  legsCrossMexicanBorder,
  withCustoms,
  withCrossBorderInsurance,
  withFinancing,
  trialPeriod,
  destination,
}) => ({
  origin,
  destination,
  appointmentTypeAtDestination,
  appointmentTypeAtOrigin,
  isAppointmentTypeAtOriginFifo,
  isAppointmentTypeAtDestinationFifo,
  clientResponsibleForBorderCrossing,
  customerReference: '',
  invoiceToCompanyId: null,
  loadInstructions,
  numberOfCopies: '',
  readyForPickup,
  lowerBoundForDropoffWindowAt,
  representativeId,
  requestType,
  requiresPita,
  state: 'active',
  status: 'accepted',
  typeOfLoadUnloadAtDestination,
  typeOfLoadUnloadAtOrigin,
  upperBoundForPickupWindowAt,
  upperBoundForDropoffWindowAt,
  reuseAppointmentInfoAtOrigin: false,
  reuseAppointmentInfoAtDestination: false,
  legsCrossMexicanBorder,
  withCustoms,
  withCrossBorderInsurance,
  withFinancing,
  trialPeriod,
});

const getAppointmentTypeAt = (appointmentType, isFifo) => {
  if (appointmentType === APPOINTMENT_TYPES.WINDOW && isFifo) {
    return APPOINTMENT_TYPES.FIFO;
  }
  return appointmentType;
};

export const transformFormToRequestBody = ({
  // eslint-disable-next-line no-unused-vars
  company,
  appointmentTypeAtDestination,
  appointmentTypeAtOrigin,
  clientResponsibleForBorderCrossing,
  invoiceToCompanyId,
  legsCrossMexicanBorder,
  requestType,
  requiresPita,
  route,
  typeOfLoadUnloadAtDestination,
  typeOfLoadUnloadAtOrigin,
  upperBoundForPickupWindowAt,
  trialPeriod,
  lowerBoundForDropoffWindowAt,
  upperBoundForDropoffWindowAt,
  isAppointmentTypeAtDestinationFifo,
  isAppointmentTypeAtOriginFifo,
  ...rest
}) => {
  // When the Appointment at Destination is Exact Time, we must not set a date
  if (appointmentTypeAtDestination.value === APPOINTMENT_TYPES.EXACT_TIME) {
    lowerBoundForDropoffWindowAt = null;
    upperBoundForDropoffWindowAt = null;
  }

  return {
    appointmentTypeAtDestination: getAppointmentTypeAt(
      appointmentTypeAtDestination.value,
      isAppointmentTypeAtDestinationFifo?.value
    ),
    appointmentTypeAtOrigin: getAppointmentTypeAt(
      appointmentTypeAtOrigin.value,
      isAppointmentTypeAtOriginFifo?.value
    ),
    // When legs don't cross Mexican border, we default to false
    clientResponsibleForBorderCrossing: legsCrossMexicanBorder
      ? clientResponsibleForBorderCrossing.value
      : false,
    invoiceToDifferentCompanyId: invoiceToCompanyId?.value ?? null,
    requestType: requestType.value,
    // When legs don't cross Mexican border, we default to false
    requiresPita: legsCrossMexicanBorder ? requiresPita.value : false,
    routeId: route.id,
    typeOfLoadUnloadAtDestination: typeOfLoadUnloadAtDestination.value,
    typeOfLoadUnloadAtOrigin: typeOfLoadUnloadAtOrigin.value,
    // Only window appointment types have an upper bound for pickup window value
    upperBoundForPickupWindowAt:
      appointmentTypeAtOrigin?.value === APPOINTMENT_TYPES.WINDOW
        ? upperBoundForPickupWindowAt
        : undefined,
    lowerBoundForDropoffWindowAt:
      appointmentTypeAtDestination?.value === APPOINTMENT_TYPES.WINDOW
        ? lowerBoundForDropoffWindowAt
        : undefined,
    upperBoundForDropoffWindowAt:
      appointmentTypeAtDestination?.value === APPOINTMENT_TYPES.WINDOW
        ? upperBoundForDropoffWindowAt
        : undefined,
    trialPeriod: trialPeriod.value,
    ...rest,
  };
};

// We want te clear all the non-geolocation data from a Route for when we pass
// it to a modal
export const routeToCreateLocation = (location, companyId) => ({
  ...transformRouteLocationToPlace(location),
  companyId,
  addressLine1: '',
  addressLine2: '',
  contactName: '',
  contactTelephone: '',
  id: null,
  name: '',
});

export function getAppointmentInfoAtLocation(location) {
  if (!location) {
    return {};
  }

  let appointmentType =
    location.facilityProcedure?.appointmentType?.toLowerCase();

  // If the route doesn't have a value, must be true by default
  let fifo = true;

  if (appointmentType === APPOINTMENT_TYPES.WINDOW) {
    fifo = false;
  }

  if (appointmentType === APPOINTMENT_TYPES.FIFO) {
    appointmentType = APPOINTMENT_TYPES.WINDOW;
  }

  let lowerBoundWindow = null;

  if (location.facilityProcedure?.startTime) {
    const [hour, minutes] = location.facilityProcedure.startTime.split(':');
    lowerBoundWindow = set(new Date(), {
      hours: +hour,
      minutes: +minutes,
      seconds: 0,
      milliseconds: 0,
    });
  }

  let upperBoundWindow = null;

  if (
    appointmentType === APPOINTMENT_TYPES.WINDOW &&
    location.facilityProcedure?.endTime
  ) {
    const [hour, minutes] = location.facilityProcedure.endTime.split(':');
    upperBoundWindow = set(new Date(), {
      hours: +hour,
      minutes: +minutes,
      seconds: 0,
      milliseconds: 0,
    });
  }

  return {
    appointmentType,
    fifo,
    loadingScheme:
      location.facilityProcedure?.loadingScheme?.toLowerCase() || null,
    lowerBoundWindow: lowerBoundWindow ? new Date(lowerBoundWindow) : null,
    upperBoundWindow: upperBoundWindow ? new Date(upperBoundWindow) : null,
  };
}

export function formatISOWithTimezone(timestamp) {
  if (!timestamp) {
    return null;
  }

  /**
   * Date must be formated in ISO with timezone (i.e: 2013-07-02T09:00:00-7:00)
   * because the hour fraction of the string (i.e: "09:00") is used for Facility
   * Procedure's startTime/endTime.
   * toISOString method from the Date object formats it as GMT+0, which will add
   * the difference of hours in the timezone to the hour fraction and will set
   * wrong values for startTime/endTime in the Facility.
   */
  const ISOFormat = "yyyy-MM-dd'T'HH:mm:ssxxx";

  return format(set(timestamp, { milliseconds: 0 }), ISOFormat);
}
