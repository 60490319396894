import { client } from 'core/utils/axios.config';

export const fetchRoutes = (companyId = '') =>
  client
    .get('edge/routes.json', {
      params: {
        company_id: companyId,
      },
    })
    .then(response => response.data);

export const createRoute = (payload, extra) =>
  client.post(`edge/routes.json`, payload).then(response => ({
    ...response.data,
    ...extra,
  }));
