import { formatDateToNow } from 'core/utils/date-fns';
import { useTranslation } from 'react-i18next';
import {
  isFumigationAccessorial,
  isStrapsAccessorial,
} from 'core/utils/commodityUtils';

export const UseRequestDetails = ({ data, requestId }) => {
  const { t } = useTranslation();
  const capitalizeWord = str => {
    if (str) {
      str = str.toLowerCase();
      str = str.replace(/[_]/g, ' ');
      return str.charAt(0).toUpperCase() + str.slice(1);
    }
    return '';
  };

  const setTranslationText = str => {
    if (str) {
      return str.toLowerCase().replace(/[_]/g, '-');
    }
    return '';
  };

  const setNumbers = (num, isCents) => {
    if (num) {
      if (isCents) {
        return (num / 100).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      }
      return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }
    return 0;
  };

  const commodities = {
    ...data?.commodities?.nodes[0],
  };

  const details = {
    requestId,
    name: t(commodities?.name || 'unknown'),
    createdAt: data?.createdAt ? formatDateToNow(data.createdAt) : '',
    truckType: t(data?.truckType || 'unknown'),
    company: capitalizeWord(data.company?.name),
    serviceType: t(data?.serviceType?.toLowerCase() || 'unknown'),
    weight: `${setNumbers(commodities.weight, false)} KG`,
    currency: data?.currency,
    conversionRate: data?.currency === 'USD' ? '-' : `${data?.conversionRate}`,
    businessType: capitalizeWord(data.company?.businessType),
    category: data.commodities?.nodes?.[0]?.category
      ? t(
          `commodity-${setTranslationText(
            data?.commodities?.nodes?.[0]?.category
          )}`
        )
      : t('unknown'),
    value: `$${setNumbers(commodities.valuePerLoadCents, true)} USD`,
    handleBorderCrossing: data.handleBorderCrossing
      ? t('handle-border-crossing-yes')
      : t('handle-border-crossing-no'),
    requestedEmail: data.requester?.email,
  };

  const requirements =
    data.shipmentRequirements?.map(accessorial =>
      capitalizeWord(accessorial)
    ) || [];

  const getCommoditiesTags =
    commodities?.accessorials?.map(accesorial => {
      if (isStrapsAccessorial(accesorial, 'slug')) {
        return `${commodities.extraStrapsCount} ${t(
          'quote-request-extra-strap'
        )}`;
      }
      if (isFumigationAccessorial(accesorial, 'slug')) {
        return t('route-requirement-fumigation');
      }
      return accesorial.name;
    }) || [];

  const accessorials = [...new Set([...requirements, ...getCommoditiesTags])];

  const additionalNotes = data.notes?.join(',') || [];

  return {
    details,
    accessorials,
    additionalNotes,
  };
};
