import React from 'react';
import { useField } from 'formik';
import { Text } from '@nuvocargo/nuvo-styleguide';
import styled, { css } from 'styled-components';
import Iconography from '@nuvocargo/nuvo-styleguide/Icons/Iconography';

export default function QuoteRadio({ label, icon, ...props }) {
  const [field] = useField(props);
  return (
    <Wrapper>
      <Placeholder isSelected={field.value === props.value}>
        <SvgWrapper>
          <Iconography name={icon} />
        </SvgWrapper>
        <Text
          size="large"
          color="forest"
          styles={{
            large: {
              lineHeight: 1.33,
            },
          }}>
          {label}
        </Text>
      </Placeholder>
      <Radio type="radio" {...field} {...props} />
    </Wrapper>
  );
}
const Wrapper = styled.div`
  position: relative;
`;

const SvgWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100px;
  width: 100px;

  svg {
    width: 100%;
    height: unset;
  }

  ${({ theme }) =>
    css`
      @media only screen and (max-width: ${theme.mediaSizes.tablet}) {
        width: 80px;
        height: 80px;
      }
    `}
`;

const Radio = styled.input`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0%;
  left: 0%;
  opacity: 0;
  &:hover {
    cursor: pointer;
  }
`;

const Placeholder = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 203px;
  height: 192px;
  padding-top: 48px;
  padding-bottom: 37px;
  ${({ theme }) => theme.outlines.default}
  border-radius: 8px;
  svg {
    width: 101px;
    height: 50px;
  }
  ${({ isSelected }) =>
    isSelected &&
    css`
      border: solid 1px ${({ theme }) => theme.colors.forest};
    `}
  & > * + * {
    margin-top: 32px;
  }

  ${({ theme }) =>
    css`
      @media only screen and (max-width: ${theme.mediaSizes.tablet}) {
        width: 155px;
        height: 168px;
      }
    `}
`;
