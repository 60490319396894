import React from 'react';
import {
  CTAButton,
  Eyebrow,
  Grid,
  GridColumn,
  Heading,
  Icon,
  Text,
} from '@nuvocargo/nuvo-styleguide';
import { Box, Flag, Flex, Modal, Stack } from 'components/generic/kit';
import { useFormikContext } from 'formik';
import * as R from 'ramda';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import { useHandleFileDrop, useRoutes } from './hooks';

const CSVModal = (props, ref) => {
  const {
    routesKeys,
    places,
    getRoute,
    parseFile,
    totalCreated,
    isAnyRowLoading,
    resetState,
  } = useRoutes();
  const { modalState, closeModal } = useHandleFileDrop({
    ref,
    onFileDrop: parseFile,
  });

  const resetStateAndCloseModal = () => {
    resetState();
    closeModal();
  };

  const { setFieldValue, values } = useFormikContext();

  const placeWithLabel = ({ value: place }) => {
    const countryIcons = {
      mx: 'mexico',
      us: 'usa',
      ca: 'canada',
    };

    return {
      ...place,
      icon: { name: countryIcons[place.country] },
      label: `${place.name}, ${place.parents?.city}, ${place.parents?.state}`,
    };
  };

  const handleSubmit = () => {
    const routesState = routesKeys
      .map(key => getRoute(key))
      .filter(
        route =>
          !R.isEmpty(route.origin.value) && !R.isEmpty(route.destination.value)
      )
      .map(route => ({
        origin: placeWithLabel(route.origin),
        destination: placeWithLabel(route.destination),
        loads_per_month: parseInt(route.loads_per_month, 10),
        id: `${route.origin.value.id}-${
          route.destination.value.id
        }-${uuidv4()}`,
      }));
    setFieldValue('routes', routesState.concat(values.routes));
    resetStateAndCloseModal();
  };

  return (
    <>
      <DropZone show={modalState === 'hint'} />

      <Modal
        isOpen={modalState === 'show'}
        onClose={resetStateAndCloseModal}
        containerStyle={{
          display: 'flex',
          flexDirection: 'column',
          width: '90%',
          maxWidth: '1300px',
          maxHeight: '80vh',
          padding: '0px ',
          overflowY: 'hidden',
        }}>
        <Box styles={{ root: { padding: '20px' } }}>
          <Heading variant="h6" color="steel">
            CSV Import
          </Heading>
        </Box>
        <Devider />
        <Box
          styles={{
            root: {
              flexGrow: 1,
              maxHeight: '100%',
              overflowY: 'scroll',
            },
          }}>
          <Grid
            styles={{
              root: { padding: '40px 0px', margin: '0px', maxWidth: '100%' },
            }}>
            <GridColumn startMd={2} md={12}>
              {isAnyRowLoading ? (
                <Heading variant="h6">Loading City Data</Heading>
              ) : totalCreated && totalCreated < routesKeys.length ? (
                <Heading variant="h6" color={'red'}>
                  It looks like the file you uploaded is missing information or
                  is formatted incorrectly.
                </Heading>
              ) : (
                <Heading variant="h6">
                  {totalCreated} records will be created
                </Heading>
              )}
            </GridColumn>
            <GridColumn
              startMd={2}
              md={4}
              styles={{ root: { marginTop: '32px', marginBottom: '15px' } }}>
              <Eyebrow text="Origin" size="small" color="steel" />
            </GridColumn>
            <GridColumn
              startMd={6}
              md={4}
              styles={{ root: { marginTop: '32px', marginBottom: '15px' } }}>
              <Eyebrow text="Destination" size="small" color="steel" />
            </GridColumn>
            <GridColumn
              startMd={10}
              md={2}
              styles={{ root: { marginTop: '32px', marginBottom: '15px' } }}>
              <Eyebrow text="Loads Per Month" size="small" color="steel" />
            </GridColumn>
            {modalState !== 'hide' &&
              !R.isEmpty(places) &&
              routesKeys.map((routeKey, i) => {
                const {
                  origin: { preview: originPreview },
                  destination: { preview: destinationPreview },
                  loads_per_month,
                } = getRoute(routeKey);
                return (
                  <React.Fragment key={i}>
                    <GridColumn
                      key={i}
                      startMd={2}
                      md={10}
                      styles={{
                        root: {
                          width: '100%',
                          height: '1px',
                          backgroundColor: '#f3f1ef',
                        },
                      }}
                    />
                    <GridColumn startMd={2} md={4}>
                      <Flex
                        styles={{
                          root: {
                            justifyContent: 'space-between',
                            padding: '18px 24px 15px 0px',
                          },
                        }}>
                        {originPreview.error ? (
                          <Text> - </Text>
                        ) : (
                          <>
                            <Stack axis="horizontal" space="10px" center>
                              <Flag icon={originPreview.country} />
                              <CityWrapper isLoading={originPreview.isLoading}>
                                {originPreview.city}
                              </CityWrapper>
                            </Stack>
                            <Text>{originPreview.postCode}</Text>
                          </>
                        )}
                        <Icon name="arrowForward" color="darkGrey" />
                      </Flex>
                    </GridColumn>

                    <GridColumn startMd={6} md={4}>
                      <Flex
                        styles={{
                          root: {
                            justifyContent: 'space-between',
                            padding: '18px 0px 15px',
                          },
                        }}>
                        {destinationPreview.error ? (
                          <Text> - </Text>
                        ) : (
                          <>
                            <Stack axis="horizontal" space="10px" center>
                              <Flag icon={destinationPreview.country} />
                              <CityWrapper
                                isLoading={destinationPreview.isLoading}>
                                {destinationPreview.city}
                              </CityWrapper>
                            </Stack>
                            <Text
                              styles={{ regular: { marginRight: '112px' } }}>
                              {destinationPreview.postCode}
                            </Text>
                          </>
                        )}
                      </Flex>
                    </GridColumn>

                    <GridColumn startMd={10} md={2}>
                      <Flex
                        styles={{
                          root: {
                            justifyContent: 'space-between',
                            padding: '18px 0px 15px',
                          },
                        }}>
                        {R.isNil(loads_per_month) ? (
                          <Text> - </Text>
                        ) : (
                          <Text styles={{ regular: { marginRight: '112px' } }}>
                            {loads_per_month}
                          </Text>
                        )}
                      </Flex>
                    </GridColumn>
                  </React.Fragment>
                );
              })}
          </Grid>
        </Box>
        <Flex
          styles={{
            root: {
              bottom: '0%',
              width: '100%',
              borderTop: '1px solid #f3f1ef',
              alignItems: 'center',
              justifyContent: 'flex-end',
              padding: '16px 24px',
            },
          }}>
          <Stack axis="horizontal" space="16px">
            <CTAButton
              type="button"
              styles={{
                primary: {
                  width: '112px',
                  backgroundColor: '#f3f1ef',
                  color: '#404040',
                },
              }}
              onClick={resetStateAndCloseModal}>
              Cancel
            </CTAButton>
            <CTAButton
              type="button"
              styles={{ primary: { width: '112px' } }}
              onClick={handleSubmit}
              disabled={isAnyRowLoading}>
              Import
            </CTAButton>
          </Stack>
        </Flex>
      </Modal>
    </>
  );
};

const CityWrapper = ({ children, isLoading }) => {
  return (
    <Box
      styles={{
        root: ({ theme }) =>
          isLoading && {
            height: 16,
            width: 70,
            backgroundColor: theme.colors.cream,
          },
      }}>
      {!isLoading && <Text>{children}</Text>}
    </Box>
  );
};

const DropZone = styled.div(({ show }) => ({
  display: show ? 'block' : 'none',
  backgroundColor: 'rgba(0,0,0,0.1)',
  width: '100%',
  height: '100vh',
  position: 'fixed',
  top: 0,
  left: 0,
  zIndex: 9999999,
}));

const Devider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #f3f1ef;
`;

export default React.forwardRef(CSVModal);
