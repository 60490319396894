import React from 'react';
import { Text } from '@nuvocargo/nuvo-styleguide';
import { Dropdown } from '@nuvocargo/nuvo-styleguide/forms/formik';
import { useTranslation } from 'react-i18next';
import { TimeSelector } from 'components/generic/form/formik/TimeSelector';
import { buildReceivingDaysOptions } from 'core/utils/route-facility';

const BusinessHours = () => {
  const { t } = useTranslation();
  const receivingDaysOptions = buildReceivingDaysOptions({ t });

  return (
    <div className="flex flex-col gap-6">
      <div className="flex w-full flex-row gap-8 border-b border-solid border-nuvo-gray-dark/10 pb-1 ">
        <Text fontWeight="bold" color="green" size="large">
          {t('facility-form-business-hours-title')}
        </Text>
      </div>

      <div className="flex w-full flex-row gap-8">
        <div className="flex-1">
          <TimeSelector
            label={t(`facility-form-business-hours-opens-at-label`)}
            placeholder={t(
              'facility-form-business-hours-opens-at-label-placeholder'
            )}
            buttonPlaceholder={t(
              'facility-form-business-hours-opens-at-label-placeholder'
            )}
            name="opensAt"
          />
        </div>
        <div className="flex-1">
          <TimeSelector
            label={t('facility-form-business-hours-closes-at-label')}
            placeholder={t(
              'facility-form-business-hours-closes-at-label-placeholder'
            )}
            buttonPlaceholder={t(
              'facility-form-business-hours-closes-at-label-placeholder'
            )}
            name="closesAt"
          />
        </div>
      </div>
      {/* Receiving days */}
      <div className="w-full" data-testid="receiving-days">
        <Dropdown
          label={t('facility-form-pickup-receiving-days-label')}
          placeholder={t('facility-form-pickup-receiving-days-placeholder')}
          name="receivingDays"
          options={receivingDaysOptions}
        />
      </div>
    </div>
  );
};

export default BusinessHours;
