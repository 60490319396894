import { Stack } from 'components/generic/kit';
import { Text, Icon } from '@nuvocargo/nuvo-styleguide';
import { useTranslation } from 'react-i18next';

const Banner = () => {
  const { t } = useTranslation();

  return (
    <Stack axis="horizontal" center>
      <Icon name="check" color="white" />
      <Text color="white" style={{ marginLeft: '12px' }}>
        {t('shipment-event-changes-saved')}
      </Text>
    </Stack>
  );
};
Banner.displayName = 'Banner';
export default Banner;
