import { useMemo } from 'react';

const createStateOptions = t =>
  useMemo(
    () => [
      {
        value: 'inactive',
        label: t('general-inactive-text-label'),
        id: 'routes-inactive-filter',
      },
      {
        value: 'active',
        label: t('general-active-text-label'),
        id: 'routes-active-filter',
      },
    ],
    [t]
  );

export default createStateOptions;
