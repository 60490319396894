import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from '@nuvocargo/nuvo-styleguide';
import InputTable from '@nuvocargo/nuvo-styleguide/InputTable/formik';
import {
  DropdownCell,
  TextCell,
} from '@nuvocargo/nuvo-styleguide/InputTable/components/Cell';

import { centsToDollars, formatMoney } from 'core/utils';

const FasteningEquipment = ({ fasteningEquipments }) => {
  const { t } = useTranslation();

  const columns = useMemo(
    () => [
      {
        Header: t('quote-request-equipment-option-type-header'),
        accessor: 'type', // accessor is the "key" in the data
        type: 'text',
      },
      {
        Header: t('quote-request-equipment-option-cost-header'),
        accessor: 'cost',
        Cell: ({ row, ...rest }) => {
          const text = formatMoney(centsToDollars(row.cost));

          return <TextCell text={text} fontColor="steel" {...rest} />;
        },
      },
      {
        Header: t('quote-request-equipment-option-required-header'),
        accessor: 'required',
        Cell: ({ row, ...rest }) => {
          const isStraps = row.type.indexOf('Straps') >= 0;
          const options = Array.from(Array(8)).map((_, i) => ({
            label: isStraps ? `${i} Extra Straps` : i,
            value: i,
          }));

          return <DropdownCell options={options} {...rest} />;
        },
      },
    ],
    [t]
  );

  return (
    <div>
      <Text color="green" size="large" fontWeight="bold">
        {t('commodity-modal-fastening-equipment')}
      </Text>
      <div className="mt-6">
        <InputTable
          hideActions
          name="fasteningEquipments"
          columnIdentifier="type"
          data={fasteningEquipments}
          columns={columns}
        />
      </div>
    </div>
  );
};

export default FasteningEquipment;
