import Sidebar from './Sidebar';
import ShipmentDetails from './ShipmentDetails';
import { shipmentDetailsViewTypes } from '../utils';

const ShipmentDetailsScreen = ({
  shipmentId,
  shipmentDetailsView,
  closeShipmentDetailsView,
}) => {
  if (
    !shipmentId ||
    !shipmentDetailsView ||
    shipmentDetailsView === shipmentDetailsViewTypes.EXCEPTION
  ) {
    return null;
  }

  return (
    <Sidebar
      shipmentId={shipmentId}
      shipmentDetailsView={shipmentDetailsView}
      closeShipmentDetailsView={closeShipmentDetailsView}>
      <ShipmentDetails
        shipmentId={shipmentId}
        shipmentDetailsView={shipmentDetailsView}
        closeShipmentDetailsView={closeShipmentDetailsView}
      />
    </Sidebar>
  );
};

export default ShipmentDetailsScreen;
