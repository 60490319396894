import { useClipboard } from '@mantine/hooks';
import { Menu } from '@nuvocargo/nuvo-styleguide';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { EN, ES } from 'screens/track-and-trace/utils';
import { buildCopyEventTemplateKey } from '../../data';

/**
 * @param { Object } props
 * @param { Object } props.event
 * @param { Object } props.issue
 * @param { {[key: string]: string } } props.templates
 * @param { 'issue' | 'event' | 'completed' } props.type
 * @see #buildCopyEventTemplateKey for template 'hash' key format
 */
export function CopyAction(props) {
  const { event, issue, templates, type } = props;

  const clipboard = useClipboard({ timeout: 1200 });
  const { t } = useTranslation();

  const translatedLabels = useMemo(() => getTranslatedLabelOptions(t), [t]);

  const onMenuOptionClickHandler = useCallback(
    lang => {
      const templateKey = buildCopyEventTemplateKey({
        event,
        issue,
        lang,
        type,
      });
      clipboard.copy(templates[templateKey]);
    },
    [clipboard, event, issue, templates, type]
  );

  const options = useMemo(
    () => [
      { text: t('english'), onClick: () => onMenuOptionClickHandler(EN) },
      { text: t('spanish'), onClick: () => onMenuOptionClickHandler(ES) },
    ],
    [onMenuOptionClickHandler, t]
  );

  const label = (() => {
    const prefix = `${clipboard.copied ? 'copied' : 'copy'}`;
    const suffix = type === 'issue' ? type : 'event';
    return translatedLabels[`${prefix}-${suffix}`];
  })();

  return (
    <Menu
      icon={clipboard.copied ? 'check' : null}
      options={options}
      styles={{ dropdown: { minWidth: '100%' } }}
      text={label}
    />
  );
}

function getTranslatedLabelOptions(t) {
  return {
    'copy-issue': t('copy-issue'),
    'copied-issue': t('copied-issue'),
    'copy-event': t('copy-event'),
    'copied-event': t('copied-event'),
  };
}
