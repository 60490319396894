import i18n from 'i18next';
import { differenceInDays, format, formatDistanceToNowStrict } from 'date-fns';
import { enUS, es } from 'date-fns/locale';

/**
 * This is an attempt to standardize date rendering across our applications.
 * If you need to display a date in a user-frieldy way, please use exported
 * methods below.
 * If for some reason you need a different format, please reconsider it,
 * unless it is extrictly necessary to do so.
 * If you need to do any other date manipulation (difference, additions, etc),
 * use methods exported from the date-fns package itself. This wrapper is just
 * for displaying purposes.
 */

const Locales = {
  es,
  en: enUS,
};

/** Common Date formats for Nuvocargo */
const DateFormats = {
  ISO: 'yyyy-MM-dd', // 2022-10-24
  ISO_WITH_TIME: 'yyyy-MM-dd HH:mm:ss', // 2022-10-24 09:58:12
  SHORT: 'ccc, MMM dd, yyyy', // Mon, Oct 24, 2022
  SHORT_WITH_TIME: 'ccc, MMM dd, yyyy h:mmaaa', // Wed, Oct 24, 2022 9:58am
};

/** Common Time formats for Nuvocargo */
const TimeFormats = {
  AMPM: 'h:mmaaa', // 9:58am
};

/**
 * i.e: '2022-10-24'
 * @param { string | Date } date
 */
export function formatISODate(date) {
  return formatTimestamp({
    format: DateFormats.ISO,
    timestamp: date,
  });
}

/**
 * i.e: '2022-10-24 10:15:32'
 * @param { string | Date } date
 */
export function formatISODateTime(date) {
  return formatTimestamp({
    format: DateFormats.ISO_WITH_TIME,
    timestamp: date,
  });
}

/**
 * i.e: 'Mon, Oct 24, 2022'
 * @param { string | Date } date
 * @param { 'en' | 'es' } [language] If not provided, will default to user preferences
 */
export function formatShortDate(date, language) {
  return formatTimestamp({
    language,
    format: DateFormats.SHORT,
    timestamp: date,
  });
}

/**
 * i.e: 'Mon, Oct 24, 2022 - 4:30'
 * @param { string | Date } date
 * @param { 'en' | 'es' } [language] If not provided, will default to user preferences
 */
export function formatShortDateTime(date, language) {
  return formatTimestamp({
    language,
    format: DateFormats.SHORT_WITH_TIME,
    timestamp: date,
  });
}

/**
 * i.e: 13 seconds ago
 * @param { string | Date } date
 * @param { 'en' | 'es' } [language] If not provided, will default to user preferences
 */
export function formatDateToNow(date, language) {
  return formatDistanceToNowStrict(new Date(date), {
    addSuffix: true,
    locale: getLocaleFromLanguage(language),
  });
}

/**
 * i.e: '11:32am'
 * @param { 'string' | Date } date
 * @param { 'en' | 'es' } [language] If not provided, will default to user preferences
 * @returns
 */
export function formatTime(date, language) {
  return formatTimestamp({
    language,
    format: TimeFormats.AMPM,
    timestamp: date,
  });
}

/**
 * @param { 'string' | Date } date
 * @param { 'string' | Date } [comparedToDate] If not provided, will be compared to now
 */
export function diffInDays(date, comparedToDate = new Date()) {
  return differenceInDays(new Date(date), new Date(comparedToDate));
}

// ### Private Functions ###

/**
 * date-fns format wrapper to standardize date formatting
 * @param { Object } params
 * @param { string | Date } params.timestamp
 * @param { 'en' | 'es' } [params.language]
 * @param { string } [params.format]
 */
function formatTimestamp({
  timestamp,
  format: dateFormat = DateFormats.SHORT_WITH_TIME,
  language = getUserLanguage(),
}) {
  return format(new Date(timestamp), dateFormat, {
    locale: getLocaleFromLanguage(language),
  });
}

/**
 * Converts a string language into a date-fns Locale
 * @param { 'en' | 'es' } language
 * @returns { import("date-fns").Locale }
 */
function getLocaleFromLanguage(language = 'en') {
  return Locales[language] ?? enUS;
}

/**
 * Reads i18n for user language preferences
 */
function getUserLanguage() {
  // Cover any variation of Spanish: es-MX, es-US, es-AR, etc.
  if (i18n.language?.startsWith('es')) {
    return 'es';
  }

  return 'en';
}
