import { Eyebrow, Text } from '@nuvocargo/nuvo-styleguide';
import { SmallDetails as TableCell } from 'components/generic/kit';
import { toTitleCase } from 'core/utils';
import { isNil } from 'ramda';
import { useTranslation } from 'react-i18next';

export const CarrierColumnHeader = () => {
  const { t } = useTranslation();
  return (
    <Eyebrow
      text={t('general-carrier-rep-text-label')}
      size="small"
      color="grey"
    />
  );
};

const trailerNumberDisplay = (trailerNumber, t) =>
  isNil(trailerNumber)
    ? t('pending-trailer-text')
    : `${toTitleCase(t('trailer'))}#${trailerNumber}`;

export const carrierRepDisplay = carrierRep =>
  carrierRep?.firstName && carrierRep?.lastName
    ? `${carrierRep.firstName} ${carrierRep.lastName}`
    : '';

export const shouldCarrierDisplayNotAssigned = carrierRep => {
  const noCarrierRep = isNil(carrierRep);
  const namesExist = carrierRep?.firstName && carrierRep?.lastName;

  return noCarrierRep || !namesExist;
};

export const CarrierColumnContent = ({ cell }) => {
  const { t } = useTranslation();
  const carrierRep = cell.value?.assignee;
  return (
    <TableCell
      top={
        shouldCarrierDisplayNotAssigned(carrierRep) ? (
          <Text>{t('general-not-assigned-text-label')}</Text>
        ) : (
          carrierRepDisplay(carrierRep)
        )
      }
      down={trailerNumberDisplay(cell.value?.trailerNumber, t)}
    />
  );
};
