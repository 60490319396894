import { Text } from '@nuvocargo/nuvo-styleguide';
import { theme } from 'styles';

export default function PaperworkCol({ cell }) {
  if (cell.value) {
    return <Text color={theme.colors.forest}>Completed</Text>;
  } else {
    return <Text color={theme.colors.red}>Incomplete</Text>;
  }
}
