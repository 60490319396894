import React, { useEffect } from 'react';

// Formik
import { useFormikContext } from 'formik';

// Utils
import {
  isGreaterThanYear,
  CURRENCY_AVAILABITY_BY_YEAR_CREATION,
  REQUEST_TYPE_OPTIONS,
} from 'core/utils/routeUtils';

// UI & UX
import { Stack } from 'components/generic/kit';
import { Dropdown } from '@nuvocargo/nuvo-styleguide/forms/formik';
import { useTranslation } from 'react-i18next';
import { createRequestQuoteServiceTypeOptions } from '../../../screens/quote-request/data';
import { buildYesNoDropdownOptions } from 'core/utils';

export default function RequestTypeAndCurrencyFields() {
  const { setFieldValue, values } = useFormikContext();
  const { t } = useTranslation();

  useEffect(() => {
    if (
      values.company &&
      isGreaterThanYear(
        values.company.createdAt,
        CURRENCY_AVAILABITY_BY_YEAR_CREATION
      )
    ) {
      setFieldValue('currency', { label: 'USD', value: 'usd' });
    }
  }, [values.company, setFieldValue]);

  return (
    <>
      <Stack axis="horizontal" space="25px">
        <Dropdown
          name="request_type"
          label="What type of rate is this?"
          styles={{ root: { width: '100%' } }}
          options={REQUEST_TYPE_OPTIONS}
        />
        <Dropdown
          disabled={isGreaterThanYear(
            values.company.createdAt,
            CURRENCY_AVAILABITY_BY_YEAR_CREATION
          )}
          value={values.currency}
          name="currency"
          label="Currency"
          styles={{ root: { width: '100%' } }}
          options={[
            { label: 'USD', value: 'usd' },
            { label: 'MXN', value: 'mxn' },
          ]}
        />
      </Stack>
      <Stack
        axis="horizontal"
        space="25px"
        styles={{ root: { marginTop: '24px' } }}>
        <Dropdown
          name="service_type"
          label="Service type"
          styles={{ root: { width: '100%' } }}
          options={createRequestQuoteServiceTypeOptions(t)}
        />
        <Dropdown
          name="border_crossing"
          label="Include Border Crossing?"
          styles={{ root: { width: '100%' } }}
          options={buildYesNoDropdownOptions(t)}
        />
      </Stack>
    </>
  );
}
