import { Text } from '@nuvocargo/nuvo-styleguide';
import Iconography from '@nuvocargo/nuvo-styleguide/Icons/Iconography';
import { motion } from 'framer-motion';
import React from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';

export default function NoResults() {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <motion.div
        animate={{
          rotate: [-5, 2, 0],
          transition: {
            delay: 0.3,
            repeat: Infinity,
            duration: 0.1,
          },
        }}>
        <Iconography name="insurance" />
      </motion.div>
      <p>
        <Text color="forest">{t('no-results-found')}</Text>
      </p>
      <p>
        <Text color="steel">{t('generic-not-found-text')}</Text>
      </p>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  height: 50vh;
  max-width: 584px;
  text-align: center;
  padding-left: 1rem;
  padding-right: 1rem;
  h1 {
    font-weight: normal;
  }
  p {
    max-width: 415px;
  }
  svg {
    width: 150px;
    height: 70px;
  }
  ${({ theme }) =>
    css`
      @media only screen and (max-width: ${theme.mediaSizes.sm}) {
        svg {
          height: 50px;
        }
      }
    `}
`;
