import {
  ShipmentEventHistory,
  Link as NuvoLink,
} from '@nuvocargo/nuvo-styleguide';
import { useTranslation } from 'react-i18next';
import { Card } from 'components/common/app';
import { formatShortDateTime } from 'core/utils/date-fns';
import { isCustomerSuccess, isTrackAndTrace } from 'core/utils';
import { useFeatures, useUser } from 'context';
import { CopyAction } from '../CopyAction';
import EventDateTimeInput from '../eventDateTimeInput';
import {
  buildShipmentDetailsLink,
  calculateShipmentStatusColumnValue,
  EN,
  getIssueKeyFromReason,
} from 'screens/track-and-trace/utils';
import { Link } from 'react-router-dom';
import { theme } from 'styles';
import { useShipmentsSidebar } from 'screens/track-and-trace/hooks';
import { useCopyEventTemplateOptions } from './hooks';

export function EventDetails({ shipment }) {
  const { shipmentEventsIssuesEnabled, csAddMessageEnabled } = useFeatures();
  const { currentRole } = useUser();
  const { qp } = useShipmentsSidebar();
  const {
    i18n: { language },
    t,
  } = useTranslation();

  const shipmentStatus = calculateShipmentStatusColumnValue({
    progressStatus: shipment.progressStatus,
    state: shipment.state,
  });
  const isShipmentCompleted = shipmentStatus === 'COMPLETED';

  const { templates } = useCopyEventTemplateOptions({
    shipment,
    upcomingEvent: !isShipmentCompleted ? shipment.currentShipmentEvent : null,
  });

  const completedEvents = (shipment.shipmentEvents || [])
    .filter(({ actualDatetime }) => Boolean(actualDatetime))
    .reverse();

  const formattedCompletedEvents = completedEvents.map(event => ({
    details: `${t('confirmed')} • ${formatShortDateTime(event.actualDatetime)}`,
    icon: {
      name: 'check',
      color: 'white',
      size: 'medium',
    },
    id: event.id,
    issues: formatEventIssues({
      t,
      language,
      issues: shipmentEventsIssuesEnabled
        ? event.shipmentEventIssues ?? []
        : [],
    }),
    name: t(`shipment-configuration-event-name-${event.type}`),
    type: 'Occurred',
  }));

  const currentEvent = shipment.currentShipmentEvent;
  const formattedUpcomingEvent = {
    details: t('shipment-event-upcoming-date'),
    id: currentEvent.id,
    issues: formatEventIssues({
      t,
      language,
      issues: shipmentEventsIssuesEnabled
        ? currentEvent?.shipmentEventIssues ?? []
        : [],
    }),
    name: t(`shipment-configuration-event-name-${currentEvent.type}`),
    type: 'Upcoming',
  };

  const formattedOutstandingEvent = isShipmentCompleted
    ? {
        details: formatShortDateTime(currentEvent.actualDatetime),
        description: t('shipment-configuration-event-completed-description'),
        icon: { name: 'check', color: 'white' },
        id: 'completed',
        name: t('shipment-configuration-event-completed-title'),
        type: 'Occurred',
      }
    : null;

  return (
    <section
      data-testid="shipment-events"
      /** @TODO Remove once parent container has a single row-gap */
      style={{ display: 'flex', flexDirection: 'column', rowGap: '32px' }}>
      {/** Upcoming Event */}
      {!isShipmentCompleted && (
        <Card noBorder>
          <ShipmentEventHistory
            dataTestId="shipment-events-upcoming"
            EventBody={() =>
              isTrackAndTrace(currentRole) ? (
                <EventDateTimeInput
                  currentEvent={currentEvent}
                  prevEventDateTime={completedEvents[0]?.actualDatetime}
                  shipmentEventIssues={currentEvent.shipmentEventIssues}
                  shipment={shipment}
                />
              ) : null
            }
            events={[formattedUpcomingEvent]}
            hideTimeline
            IssueAddon={
              isCustomerSuccess(currentRole) || isTrackAndTrace(currentRole)
                ? ({ issue }) => (
                    <>
                      {csAddMessageEnabled && isCustomerSuccess(currentRole) ? (
                        <NuvoLink
                          to={buildShipmentDetailsLink(qp, 'exception', {
                            shipmentId: shipment.id,
                            exceptionId: issue.id,
                          })}
                          as={Link}>
                          {t('shipment-event-issue-add-message')}
                        </NuvoLink>
                      ) : null}

                      {isTrackAndTrace(currentRole) && (
                        <Link
                          to={buildShipmentDetailsLink(qp, 'exception', {
                            shipmentId: shipment.id,
                            exceptionId: issue.id,
                          })}
                          style={{
                            fontWeight: '200',
                            color: `${theme.colors.forest}`,
                            lineHeight: '150%',
                          }}>
                          {t('edit-text-label')}
                        </Link>
                      )}
                    </>
                  )
                : null
            }
            styles={{ root: { borderRadius: '8px' } }}
            title={t('upcoming-event')}
          />
        </Card>
      )}

      {/** Completed events */}
      {formattedCompletedEvents.length > 0 && (
        <Card noBorder>
          <ShipmentEventHistory
            dataTestId="shipment-events-completed"
            EventAddon={
              isCustomerSuccess(currentRole)
                ? ({ event }) => (
                    <CopyAction
                      event={event}
                      type="event"
                      templates={templates}
                    />
                  )
                : null
            }
            events={formattedCompletedEvents}
            OutstandingAddon={
              isCustomerSuccess(currentRole)
                ? ({ event }) => (
                    <CopyAction
                      event={event}
                      type="completed"
                      templates={templates}
                    />
                  )
                : null
            }
            outstandingEvent={formattedOutstandingEvent}
            styles={{ root: { borderRadius: '8px' } }}
            subtitle={t('shipment-event-subtitle')}
            title={t('completed-events')}
          />
        </Card>
      )}
    </section>
  );
}

export function formatEventIssues({ issues, language, t }) {
  return issues.map(issue => {
    const {
      createdAt,
      id,
      noteEnglish,
      noteSpanish,
      reason,
      internalNote,
      reporter,
      resolvedAt,
      resolver,
    } = issue;

    const issueTimestamp = formatShortDateTime(createdAt);
    const responseTimestamp = Boolean(resolver)
      ? formatShortDateTime(resolvedAt)
      : '';

    return {
      description: internalNote,
      details: t('shipment-event-issue-report-details', {
        name: reporter.name,
        timestamp: issueTimestamp,
      }),
      icon: {
        color: 'steel',
        name: 'errorOutline',
        size: 'small',
      },
      id: String(id),
      name: getIssueKeyFromReason(reason, t),
      ...(Boolean(resolver) && {
        response: {
          description: language === EN ? noteEnglish : noteSpanish,
          details: t('shipment-event-issue-response-details', {
            name: resolver.name,
            timestamp: responseTimestamp,
          }),
          icon: {
            color: 'steel',
            name: 'subdirectoryArrowRight',
            size: 'small',
          },
          name: t('shipment-event-issue-response-name'),
        },
      }),
      type: 'Exception',
    };
  });
}
