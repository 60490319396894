import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useRouteDetailsRequest } from 'core/api/routeRequest';
import { REQUEST_PRICE_STATUS_ENUM } from 'core/utils/routeUtils';
import { camel2humanize, formatMoneyWithCurrency } from 'core/utils';
import { countryShorthandToString } from './utils';

export const useRouteDetails = ({ userLanguage, t }) => {
  const { id } = useParams();
  const [updateModal, setUpdateModal] = useState();
  const {
    data: route,
    isLoading,
    error,
  } = useRouteDetailsRequest(
    {
      id,
    },
    {
      select: createTransformRouteResponse(
        userLanguage === 'en' ? 'en-US' : 'es-MX',
        t
      ),
    }
  );

  return {
    error,
    isLoading,
    route,
    setUpdateModal,
    updateModal,
  };
};

const getValueOrEmptyString = value =>
  typeof value === 'string' || typeof value === 'number' ? value : '';

export const transformRouteLocationToPlace = ({
  addressLine1,
  addressLine2,
  contactName,
  contactTelephone,
  geolocationId,
  id,
  name,
  zipCode,
}) => ({
  addressLine1: getValueOrEmptyString(addressLine1),
  addressLine2: getValueOrEmptyString(addressLine2),
  city: getValueOrEmptyString(zipCode?.parents?.city),
  contactName: getValueOrEmptyString(contactName),
  contactTelephone: getValueOrEmptyString(contactTelephone),
  country: getValueOrEmptyString(
    countryShorthandToString(zipCode?.parents?.country)
  ),
  geolocationId: getValueOrEmptyString(geolocationId),
  id,
  latitude: getValueOrEmptyString(zipCode?.latitude),
  longitude: getValueOrEmptyString(zipCode?.longitude),
  name: getValueOrEmptyString(name),
  state: getValueOrEmptyString(zipCode?.parents?.state),
  zipCode: getValueOrEmptyString(zipCode?.name),
});

export const createTransformRouteResponse = (locale, t) => route => {
  const commodity = route.commodities.edges?.[0].node ?? {};
  const commodityRequirements = commodity?.requirements?.map(t =>
    camel2humanize(t)
  );
  const shipmentRequirements = route.shipmentRequirements?.map(t =>
    camel2humanize(t)
  );
  const specialHandling = [
    ...new Set([...commodityRequirements, ...shipmentRequirements]),
  ]?.filter(k => k);

  return {
    id: `RT-${route.id}`,
    company: route.company,
    details: {
      isPriced:
        route.priceState.toUpperCase() === REQUEST_PRICE_STATUS_ENUM.PRICED,
      price:
        route.priceState.toUpperCase() === REQUEST_PRICE_STATUS_ENUM.REQUESTED
          ? t('requested')
          : formatMoneyWithCurrency(
              route.convertedPriceCents / 100,
              route.currency
            ),
      equipment: t(route.truckType ?? 'route-equipment-type-not-found'),
      truckType: route.truckType,
      service: t(route.serviceType ?? 'route-service-not-found'),
      teamDriver: route.shipmentRequirements?.includes('team_driver')
        ? t('route-team-driver-required')
        : t('route-team-driver-not-required'),
      commodityName: commodity.name ?? t('route-commodity-name-unknown'),
      commodityType: t(
        `commodity-${commodity.category.split`_`.join`-`}` ??
          'route-commodity-type-not-found'
      ),
      weight: commodity.weight
        ? new Intl.NumberFormat(locale, {
            style: 'unit',
            unit: 'kilogram',
          }).format(commodity.weight)
        : t('route-weight-unknown'),
      valuePerLoad: commodity?.valuePerLoadCents
        ? formatMoneyWithCurrency(
            parseFloat(commodity?.valuePerLoadCents / 100),
            'USD' // commodity value is always displayed in USD
          )
        : t('route-price-unknown'),
      handling: specialHandling,
      temperature:
        typeof route.temperature === 'number'
          ? `${route.temperature} °F`
          : t('route-temperature-unknown'),
      measurements: {
        width: route.width,
        length: route.length,
        height: route.height,
      },
      handleBorderCrossing: route.handleBorderCrossing
        ? t('handle-border-crossing-yes')
        : t('handle-border-crossing-no'),
    },
    origin: transformRouteLocationToPlace(route.origin),
    destination: transformRouteLocationToPlace(route.destination),
    notes: route.notes
      ?.filter(n => !!n)
      .map(message => ({
        message,
        id: message,
        name: route.requester?.displayName ?? t('unknown-user'),
        createdAt: route.createdAt ?? t('unknown-note-created-date'),
      })),
    state: route.state.toUpperCase(),
    timeInTransit: route.timeInTransit,
  };
};
