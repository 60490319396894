import { client } from 'core/utils/axios.config';

export const fetchQuoteRequest = uuid =>
  client.get(`internal/quote-request/${uuid}`).then(response => response.data);

// Note: This API endpoint is weird, it's structured as a PATCH/UPDATE
// but uses POST and it's treated as a create...?
export const createQuoteRequest = ({ uuid, payload }) =>
  client
    .post(`internal/quote-request/${uuid}`, payload)
    .then(response => response.data);

export const createCommodity = payload =>
  client.post(`v0/commodities`, payload);
