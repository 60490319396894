import { Heading, Icon, Text } from '@nuvocargo/nuvo-styleguide';
import { Flex, Spinner } from 'components/generic/kit';
import { useShipmentByID } from 'core/api';
import { useQueryParams } from 'core/hooks';
import { useTranslation } from 'react-i18next';
import { flexLoadingStyle } from 'styles';

import Error from '../Error';
import { findShipmentEventById } from '../utils';
import Event from './components/Event';
import IssueForm from './components/IssueForm';
import { isUpdateAction } from './components/IssueForm/data';
import { Card } from 'components/common/app';
import { isCustomerSuccess } from 'core/utils';
import { useUser } from 'context';
import { formatEventIssues } from '../overview/components/eventDetails/EventDetails';

const IssueCard = ({ shipmentId }) => {
  const { t } = useTranslation();
  const { currentRole } = useUser();
  const { exceptionId } = useQueryParams();
  const { isLoading, data, error } = useShipmentByID(shipmentId, {
    select: shipment => {
      const currentShipmentEvent = findShipmentEventById(
        shipment.shipmentEvents.nodes,
        shipment.currentShipmentEvent.id
      );

      const shipmentEventIssue =
        currentShipmentEvent?.shipmentEventIssues?.find(
          shipmentEventIssue => shipmentEventIssue.id === Number(exceptionId)
        );

      return {
        currentShipmentEvent,
        shipmentEventIssue,
        shipmentEvents: shipment.shipmentEvents.nodes,
      };
    },
  });

  if (isLoading) {
    return (
      <Flex styles={flexLoadingStyle}>
        <Spinner />
      </Flex>
    );
  }

  if (error) {
    return <Error error={error} />;
  }

  if (!data) {
    return (
      <Error error={`Can't find shipment issue with ID: ${exceptionId}`} />
    );
  }

  const issues = formatEventIssues({
    issues: data?.shipmentEventIssue ? [data.shipmentEventIssue] : [],
    t,
  });

  return (
    <div
      className="flex flex-col bg-nuvo-cream p-8 "
      data-testid="shipment-event-issue-screen">
      <Heading
        variant="h4"
        color="green"
        styles={{
          root: { fontSize: '30px', lineHeight: '36px', marginBottom: '32px' },
        }}>
        {t('action-exception', {
          action: isUpdateAction(exceptionId)
            ? t('update-action-exception')
            : t('add-action-exception'),
        })}
      </Heading>
      <Card style={{ padding: 32 }}>
        <Event shipmentEventType={data.currentShipmentEvent.type} />
        {isCustomerSuccess(currentRole) && (
          <div
            className=" flex flex-col gap-8"
            data-testid="shipment-event-issue-card">
            {issues.map(eventIssue => (
              <div
                key={eventIssue.id}
                className="flex flex-col gap-1 bg-nuvo-gray/5 p-6">
                <div className="flex items-center gap-1">
                  <Icon name="errorOutline" styles={eventIssue.icon} />
                  <Text className="mt-1" fontWeight="600">
                    {eventIssue.name}
                  </Text>
                </div>

                <Text>{eventIssue.details}</Text>
                <Text color="steel">{eventIssue.description}</Text>
              </div>
            ))}
          </div>
        )}
        <IssueForm
          shipmentEventId={data.currentShipmentEvent.id}
          shipmentId={shipmentId}
          exceptionId={exceptionId}
          shipmentEventIssue={data.shipmentEventIssue}
          shipmentEvents={data.shipmentEvents}
        />
      </Card>
    </div>
  );
};

export default IssueCard;
