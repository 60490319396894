import { customs } from 'routes/paths';
import { stringify } from 'qs';

// In Milliseconds
export const ONE_HOUR = 1000 * 60 * 60;

export const extractFilterQueryParameter = maybeFilterJSONString =>
  maybeFilterJSONString ? JSON.parse(maybeFilterJSONString) : {};

export const buildShipmentDetailsLink = (qp, viewName) => ({
  pathname: customs.main,
  search: stringify(
    {
      ...qp,
      shipmentDetailsView: viewName,
    },
    { encode: false }
  ),
});

/**
 * Calculates offset based on page size and a given page
 * @param { number } page
 * @param { number } pageSize
 * @returns { string } Base64 string holding an offset
 */
export const pageAndPageSizeToGraphQLOffset = (page = 0, pageSize = 0) => {
  const offset = page * pageSize;
  return window.btoa(offset).replace(/=/gi, '');
};

export const createGraphQLCursorParams = (page = 0, pageSize = 0) => ({
  first: pageSize,
  after: pageAndPageSizeToGraphQLOffset(page, pageSize),
});

export const calculateTotalPagesFromGraphQL = (totalCount = 1, pageSize = 1) =>
  Math.ceil((totalCount ?? 1) / pageSize);

export const graphQLCountryToFlagString = maybeCountry => {
  switch (maybeCountry) {
    case 'us': {
      return 'usa';
    }

    case 'mx': {
      return 'mexico';
    }

    case 'ca': {
      return 'canada';
    }

    default: {
      return '';
    }
  }
};

export const calculateShipmentStatusColumnValue = ({
  currentTime = Date.now(),
  estimatedDatetime,
  status,
}) => {
  if (status === 'COMPLETED') return 'completed';
  if (status === 'REQUESTED' || !estimatedDatetime) return 'pending';

  const estimatedTime = new Date(estimatedDatetime).getTime();
  const timeDelta = estimatedTime - currentTime;

  if (timeDelta <= ONE_HOUR) {
    return 'at-risk';
  } else if (timeDelta <= ONE_HOUR * 2) {
    return 'close';
  }
  return 'on-time';
};
