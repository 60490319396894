import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  TRAILER_SPECIFICATIONS,
  TRAILER_SPECIFICATIONS_CAMMEL_KEYS,
  SAFETY_REQUIREMENTS,
  SAFETY_REQUIREMENTS_TEXT,
  FASTENING_EQUIPMENTS,
  FASTENING_EQUIPMENT_TEXT,
  FASTENING_EQUIPMENT_QUANTITY,
  STRAPS,
  BOTTLED_SEAL,
} from '../constants';

export default function useParseAccessorialValues(
  commodity = { accessorials: [] },
  accessorials = []
) {
  const { t } = useTranslation();
  const trailerSpecificationInitialValues = useMemo(
    () =>
      accessorials
        .filter(
          accessorial => TRAILER_SPECIFICATIONS.indexOf(accessorial.slug) >= 0
        )
        .reduce((trailerSpecifications, { slug }) => {
          if (commodity?.accessorials?.indexOf(slug) >= 0) {
            return {
              ...trailerSpecifications,
              [TRAILER_SPECIFICATIONS_CAMMEL_KEYS[slug]]: true,
            };
          } else {
            return {
              ...trailerSpecifications,
              [TRAILER_SPECIFICATIONS_CAMMEL_KEYS[slug]]: false,
            };
          }
        }, {}),
    [commodity, accessorials]
  );
  const safetyRequirementOptions = useMemo(
    () =>
      accessorials
        .filter(accessorial => {
          return SAFETY_REQUIREMENTS.indexOf(accessorial.slug) >= 0;
        })
        .map(accessorial => {
          const type = t(SAFETY_REQUIREMENTS_TEXT[accessorial.slug]);
          const cost =
            accessorial.schemaCost === 'FIXED'
              ? accessorial.schemaArgs[0]
              : accessorial.schemaArgs[1];
          const isRequired = commodity.accessorials.indexOf(BOTTLED_SEAL) >= 0;
          const required = {
            label: isRequired ? 'Yes' : 'No',
            value: isRequired,
          };

          return { id: accessorial.slug, type, cost, required };
        }),
    [commodity, accessorials, t]
  );
  const fasteningEquipmentOptions = useMemo(
    () =>
      accessorials
        .filter(accessorial => {
          return FASTENING_EQUIPMENTS.indexOf(accessorial.slug) >= 0;
        })
        .map(accessorial => {
          const type = t(FASTENING_EQUIPMENT_TEXT[accessorial.slug]);
          const cost =
            accessorial.schemaCost === 'FIXED'
              ? accessorial.schemaArgs[0]
              : accessorial.schemaArgs[1];
          const quantity =
            commodity[FASTENING_EQUIPMENT_QUANTITY[accessorial.slug]] || 0;
          const required = {
            label:
              accessorial.slug === STRAPS
                ? `${quantity} Extra Straps`
                : quantity,
            value: quantity,
          };

          return { id: accessorial.slug, type, cost, required };
        }),
    [commodity, accessorials, t]
  );

  return {
    trailerSpecificationInitialValues,
    safetyRequirementOptions,
    fasteningEquipmentOptions,
  };
}
