import Sidebar from './Sidebar';
import ShipmentDetails from './ShipmentDetails';

const ShipmentDetailsScreen = ({ shipmentId, closeShipmentDetailsView }) => {
  if (!shipmentId) {
    return null;
  }

  return (
    <Sidebar
      shipmentId={shipmentId}
      closeShipmentDetailsView={closeShipmentDetailsView}>
      <ShipmentDetails
        shipmentId={shipmentId}
        closeShipmentDetailsView={closeShipmentDetailsView}
      />
    </Sidebar>
  );
};

export default ShipmentDetailsScreen;
