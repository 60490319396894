import Text from '@nuvocargo/nuvo-styleguide/Text';
import { isEmpty } from 'lodash';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

export default function Status({ slug }) {
  const { t } = useTranslation();
  const statuses = {
    COMPLETED: {
      name: 'status-completed',
      dotColor: '',
      color: 'darkGrey',
    },
    DELIVERED: {
      name: 'status-delivered',
      dotColor: '',
      color: 'darkGrey',
    },
    ARRIVED: {
      name: 'status-arrived',
      dotColor: '',
      color: 'darkGrey',
    },
    INCIDENT_REPORTED: {
      name: 'status-incident-reported',
      dotColor: '',
      color: 'darkGrey',
    },
    OPEN_INCIDENT: {
      name: 'status-open-incident',
      dotColor: '',
      color: 'darkGrey',
    },
    IN_PROGRESS: {
      name: 'status-in-progress',
      dotColor: '',
      color: 'darkGrey',
    },
    PAST_DUE: {
      name: 'status-past-due',
      dotColor: '',
      color: 'darkGrey',
    },
    SCHEDULED: {
      name: 'status-scheduled',
      dotColor: '',
      color: 'darkGrey',
    },
    PENDING: {
      name: 'status-pending',
      dotColor: '',
      color: 'darkGrey',
    },
  };
  const status = statuses?.[slug];
  return (
    <Text
      size={status ? 'large' : 'regular'}
      color={status?.color ?? 'darkGrey'}
      style={{ whiteSpace: 'nowrap' }}>
      {!isEmpty(status?.dotColor) && <Dot color={status?.dotColor}></Dot>}
      {t(`${status?.name ?? 'status-not-defined'}`)}
    </Text>
  );
}

const Dot = styled.span`
  width: 8px;
  display: inline-block;
  border-radius: 12px;
  height: 12px;
  margin-right: 8px;
  background-color: ${props => props.color};
`;
