/* eslint-disable react/display-name */
import { useMemo, useState } from 'react';
import { theme } from 'styles';
import { Text, Eyebrow, CTAButton, Label } from '@nuvocargo/nuvo-styleguide';
import { Checkbox } from '@nuvocargo/nuvo-styleguide/forms/native';
import { useTranslation } from 'react-i18next';
import { useTable } from 'react-table';
import { useEventsForm } from './hooks';
import {
  TableStyles,
  TableHeader,
  TableCell,
  Footer,
  EstimatedWrapper,
  ShipmentEventTypeDetails,
} from './styles';
import EstimatedDatetimeCalendar from './EstimatedDatetimeCalendar';
import Header from './Header';
import { isEstimatedDatetimeRequired } from './utils';
import { saveButtonMessage } from '../utils';
import { useFeatures } from 'context';

const EventsForm = ({
  shipment: { shipmentEvents: events },
  shipmentId,
  closeShipmentDetailsView,
}) => {
  const { t } = useTranslation();
  const { newEstimatedEventsActive, isNotificationsConfigHidden } =
    useFeatures();
  const [saveButtonStatus, setSaveButtonStatus] = useState('default');

  const requiredEvents = useMemo(
    () =>
      events.filter(event =>
        newEstimatedEventsActive
          ? isEstimatedDatetimeRequired(event.type)
          : true
      ),
    [events, newEstimatedEventsActive]
  );

  const {
    canSubmit,
    errors,
    eventsValues,
    isSubmitting,
    submitEvents,
    updateAllCheckboxes,
    updateEvent,
    handleCopy,
    handlePaste,
    hasCopiedEstimatedDatesRecently,
    hasPastedEstimatedDatesRecently,
    globalCheckboxIsDisabled = false,
    globalCheckboxState = false,
  } = useEventsForm(
    requiredEvents,
    shipmentId,
    closeShipmentDetailsView,
    newEstimatedEventsActive,
    isNotificationsConfigHidden
  );

  const submitNewEstimatedEvents = () => {
    submitEvents(true, setSaveButtonStatus);
  };

  const handleOnClickSubmit = () => {
    newEstimatedEventsActive ? submitNewEstimatedEvents() : submitEvents(false);
  };

  const EstimatedCalendar = ({ row, id }) => {
    return (
      <TableCell isFirstRow={!row.index} style={{ marginBottom: '24px' }}>
        <EstimatedDatetimeCalendar
          id={id}
          eventsValues={eventsValues}
          updateEvent={updateEvent}
          error={errors?.[id]}
          isSubmitting={isSubmitting}
        />
      </TableCell>
    );
  };

  const columns = useMemo(() => {
    const cols = [
      {
        Header: () => {
          return (
            <TableHeader>
              <Eyebrow
                text={t('shipment-configuration-event-name-table-column')}
                size="small"
                color="steel"
              />
            </TableHeader>
          );
        },
        accessor: 'type',
        Cell: ({ row, cell }) => {
          const {
            original: { actualDatetime },
          } = row;

          return (
            <TableCell
              isFirstRow={!row.index}
              style={{ marginRight: '30px', marginBottom: '24px' }}>
              <ShipmentEventTypeDetails>
                <Text
                  size="regular"
                  styles={{ regular: { fontWeight: '500' } }}>
                  {t(
                    `shipment-configuration-event-name-${cell.value.toLowerCase()}`
                  )}
                </Text>
                <Label color="steel">
                  {!!actualDatetime ? t('event-completed') : ''}
                </Label>
              </ShipmentEventTypeDetails>
            </TableCell>
          );
        },
      },
      {
        Header: () => {
          return (
            <TableHeader>
              <Eyebrow
                text={t('shipment-configuration-event-esimated-time-column')}
                size="small"
                color="steel"
              />
            </TableHeader>
          );
        },
        accessor: 'estimatedDatetime',
        Cell: ({ cell, row }) => {
          const id = cell.row.original.id;
          const {
            values: { type },
          } = row;
          if (!newEstimatedEventsActive) {
            return <EstimatedCalendar row={row} id={id} />;
          } else {
            return isEstimatedDatetimeRequired(type) ? (
              <EstimatedCalendar row={row} id={id} />
            ) : null;
          }
        },
      },
    ];

    if (!isNotificationsConfigHidden) {
      cols.push({
        Header: () => {
          return (
            <TableHeader alignRight style={{ margin: '0 24px 0 24px' }}>
              <Eyebrow
                text={t('shipment-configuration-notify-table-column')}
                size="small"
                color="steel"
                styles={{
                  small: {
                    paddingRight: '12px',
                  },
                }}
              />

              <Checkbox
                disabled={globalCheckboxIsDisabled || isSubmitting}
                onChange={() => {
                  updateAllCheckboxes(!globalCheckboxState);
                }}
                checked={globalCheckboxState}
                size="medium"
              />
            </TableHeader>
          );
        },
        accessor: 'notifyCustomer',
        Cell: ({ cell, row }) => {
          const id = cell.row.original.id;
          return (
            <TableCell
              alignRight
              isFirstRow={!row.index}
              style={{ margin: '0 24px 24px 24px' }}>
              <Checkbox
                checked={eventsValues[id].notifyCustomer}
                disabled={isSubmitting || !!eventsValues[id].actualDatetime}
                size="medium"
                onChange={e => {
                  updateEvent(id, e.target.checked, 'notifyCustomer');
                }}
              />
            </TableCell>
          );
        },
      });
    }

    return cols;
  }, [requiredEvents, eventsValues, isSubmitting]);
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data: requiredEvents });

  return (
    <EstimatedWrapper>
      <Header
        handleCopy={handleCopy}
        handlePaste={handlePaste}
        isSubmitting={isSubmitting}
        hasCopiedEstimatedDatesRecently={hasCopiedEstimatedDatesRecently}
        hasPastedEstimatedDatesRecently={hasPastedEstimatedDatesRecently}
      />
      <TableStyles style={{ marginTop: '4px' }}>
        <table {...getTableProps()}>
          <thead>
            {headerGroups.map(({ getHeaderGroupProps, headers }) => (
              <tr {...getHeaderGroupProps()} key="events-header-row">
                {headers.map(({ getHeaderProps, id, render }) => (
                  <th {...getHeaderProps()} key={`events-header-th-${id}`}>
                    {render('Header')}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row, index) => {
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps()}
                  key={`events-body-row-${row.id}`}
                  style={{
                    gap: '24px',
                    borderBottom:
                      index < rows.length - 1
                        ? `1px solid ${theme.colors.darkCream}`
                        : '',
                  }}>
                  {row.cells.map(({ getCellProps, row, column, render }) => (
                    <td
                      {...getCellProps()}
                      key={`events-body-cell-${row.original.id}-${column.id}`}>
                      {render('Cell')}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </TableStyles>

      <Footer>
        <Label
          color="steel"
          size="medium"
          styles={{ medium: { fontSize: '16px' } }}>
          {t('configuration-changes-label')}
        </Label>

        <CTAButton
          id="configuration-view-save-button"
          disabled={
            !!errors || !canSubmit || isSubmitting || globalCheckboxIsDisabled
          }
          onClick={handleOnClickSubmit}
          styles={{
            primary: {
              width: '167px',
            },
          }}>
          {saveButtonMessage(saveButtonStatus, isSubmitting, t)}
        </CTAButton>
      </Footer>
    </EstimatedWrapper>
  );
};
export default EventsForm;
