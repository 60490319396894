import { useTranslation } from 'react-i18next';
import { Heading, Label, Text } from '@nuvocargo/nuvo-styleguide';

import { formatPrices, whenRouteIs } from 'core/utils/routeUtils';
import RequestPrice from './RequestPrice';

export const PriceSection = ({
  lastUpdatedAt,
  data,
  addErrorToast,
  setToasts,
}) => {
  const { t } = useTranslation();
  const { status, company } = data;

  const hidePriceSection = status.is.unserviceable;
  const shouldShowLastUpdate = !status.is.requested;
  const isCompanyInVariableFuelProgram = company.variableFuel;
  const prices = formatPrices(data);

  const priceText = whenRouteIs(data, {
    requested: t('price-in-progress'),
    expired: t('price-expired'),
    fallback: prices.routePrice,
  });
  const priceColor = whenRouteIs(data, {
    expired: 'steel',
    fallback: 'blue',
  });

  if (hidePriceSection) {
    return <></>;
  }

  return (
    <>
      <div data-testId="price" className="mb-12">
        <div className="mb-4 flex items-center justify-between border-b border-b border-nuvo-gray-dark/10 pb-1">
          <Text as="p" color="green" size="large" fontWeight="bold">
            {t('route-price')}
          </Text>

          {shouldShowLastUpdate && lastUpdatedAt && (
            <Text
              data-testid="last-updated-at"
              as="p"
              color="steel"
              size="extraSmall">
              {t('last-updated')} {lastUpdatedAt}
            </Text>
          )}
        </div>
        <Heading variant="h4" color={priceColor}>
          {priceText}
        </Heading>
        {status.is.expired && (
          <div className="mt-3">
            <RequestPrice addErrorToast={addErrorToast} setToasts={setToasts} />
          </div>
        )}
        {status.is.ready || status.is.missingDetails ? (
          isCompanyInVariableFuelProgram && (
            <>
              <div className="my-6 divide-y divide-nuvo-gray/10 ">
                <div className="flex flex-row justify-between pb-1">
                  <Text as="p" color="steel">
                    {t('route-base-price')}
                  </Text>

                  <Text data-testid="base-cost" as="p" color="darkGrey">
                    {prices.baseCost}
                  </Text>
                </div>

                <div className="flex flex-row justify-between pt-1">
                  <Text as="p" color="steel">
                    {t('route-fuel-price')}
                  </Text>

                  <Text data-testid="fuel-cost" as="p" color="darkGrey">
                    {prices.fuelCost}
                  </Text>
                </div>
              </div>

              {/*this will be hidden by now but in the next sprint we're gonna need it again*/}
              <div className="hidden rounded bg-nuvo-cream-dark p-4">
                <Text as="p" color="darkGrey" size="small">
                  {t('route-fuel-text')}
                </Text>
              </div>
            </>
          )
        ) : (
          <Label color="steel">
            {whenRouteIs(data, {
              requested: t('price-in-progress-text'),
              // this will be skipped by now but in the next sprint we're gonna need it again
              // expired: t('price-expired-text'),
            })}
          </Label>
        )}
      </div>
    </>
  );
};
