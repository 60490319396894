export const requestDetailsStyles = {
  root: {
    borderLeft: '1px solid rgba(40, 40, 40, 0.1)',
    height: 'calc(100vh - 64px)',
    padding: '56px 0 0 24px',
  },
  heading: {
    borderBottom: '1px solid rgba(40, 40, 40, 0.1)',
    paddingBottom: 8,
  },
  tile: { gridColumn: 'span 1' },
  details: { display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: 24 },
  additionalNotesTile: {
    marginTop: 8,
    display: 'flex',
    flexDirection: 'column',
  },
};
