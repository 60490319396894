import { useLocation, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { track } from '@amplitude/analytics-browser';
import { startCase } from 'lodash';

export const usePageTracking = () => {
  const location = useLocation();

  useEffect(() => {
    const pathSections = location.pathname.slice(1).split('/');
    const path = startCase(
      pathSections.filter(section => !section.match(/[\d]+/)?.length)
    );
    const resourceIds = pathSections.filter(
      section => section.match(/[\d]+/)?.length
    );
    let suffix = resourceIds.length > 0 ? 'Detail' : 'List';
    if (path.includes(suffix) || path.includes('New')) suffix = null;

    track([`Page Visited`, path, suffix].filter(x => x).join(' '), {
      app: 'Internal',
      page: location.pathname,
      resource: resourceIds[0],
      url: window.location.href,
      params: location.search?.slice(1),
    });
  }, [location]);
};
