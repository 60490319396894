import React from 'react';
import { useFormikContext } from 'formik';

// UI & UX
import { CTAButton, Heading } from '@nuvocargo/nuvo-styleguide';

// Feature & local components
import CommodityRadioGroup from './CommodityRadioGroup';
import AddCommodityModal from '../AddCommodityModal';

// Theme Kit
import { Flex, Stack } from 'components/generic/kit';
import { trackClick } from 'core/utils/trackClick';

// Hooks
import {
  useCommodities,
  useModal,
  useCreateCommodityMutation,
} from 'core/hooks';

export default function Commodities({
  companyId,
  onCreateSuccess,
  accessorials,
}) {
  const { setFieldValue } = useFormikContext();
  const { commodities } = useCommodities({ companyId });
  const { isLoading, isError } = useCreateCommodityMutation({ companyId });
  const { isModalOpen, closeModal, openModal } = useModal();
  const openCommodityModal = () => {
    openModal();
    trackClick('New Comodity Modal Opened');
  };

  return (
    <>
      <Stack space="24px" styles={{ root: { marginTop: '87px' } }}>
        <Flex
          styles={{
            root: {
              alignItems: 'center',
              justifyContent: 'space-between',
            },
          }}>
          <Heading variant="h5b">Select Commodity</Heading>
          <CTAButton
            type="button"
            mode="light"
            colorType="secondary"
            disabled={isLoading}
            styles={{ secondary: { width: '92px' } }}
            onClick={openCommodityModal}>
            {isLoading ? 'Adding...' : 'Add'}
          </CTAButton>
        </Flex>
        <CommodityRadioGroup
          openModal={openCommodityModal}
          commodities={commodities}
        />
      </Stack>

      <AddCommodityModal
        companyId={companyId}
        isLoading={isLoading}
        isOpen={isModalOpen}
        isError={isError}
        onClose={closeModal}
        onSuccess={(data, values, context) => {
          setFieldValue('commodity', data);
          onCreateSuccess && onCreateSuccess(data, values, context);
          closeModal();
        }}
        accessorials={accessorials}
      />
    </>
  );
}
