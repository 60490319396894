import { Heading, Label } from '@nuvocargo/nuvo-styleguide';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
`;

export default function Header({ name, reference }) {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <Heading variant="h4" color="green">
        {name}
      </Heading>
      <Label color="steel" size="medium">{`${t('customer-reference')} • ${
        reference ? reference : t('no-customer-reference-found')
      }`}</Label>
    </Wrapper>
  );
}
