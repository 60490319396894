import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Container = styled(Wrapper)`
  margin-bottom: 40px;
`;

export const DocumentRow = styled.div`
  display: flex;
  align-items: center;
  padding: 16px 0;
  gap: 16px;
  min-height: 85px;
  box-sizing: border-box;

  :not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.steel};
  }

  &:hover {
    background-color: rgba(64, 64, 64, 0.05);
  }

  > svg {
    color: ${({ theme }) => theme.colors.steel};
    margin-left: 8px;
  }

  .document-row-text {
    font-family: 'value-sans-regular';
    font-weight: 400;
    font-size: 16px;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-items: center;
    gap: 4px;
    line-height: 150%;

    > :first-child {
      font-weight: 500;
      font-family: 'value-sans-medium';
    }
  }

  .document-row-date {
    font-family: 'value-sans-regular';
    font-weight: 400;
    font-size: 14px;
    color: ${({ theme }) => theme.colors.steel};
  }

  .document-row-download {
    font-family: 'value-sans-medium';
    font-weight: 500;
    font-size: 16px;
    color: ${({ theme }) => theme.colors.forest};
    cursor: pointer;
  }
`;
