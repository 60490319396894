import React from 'react';
import { Formik, Form } from 'formik';
import { useTranslation } from 'react-i18next';
import {
  CTAButton,
  ErrorMessage,
  Label,
  Text,
} from '@nuvocargo/nuvo-styleguide';
import {
  Checkbox,
  Dropdown,
  Textarea,
} from '@nuvocargo/nuvo-styleguide/forms/formik';
import Calendar from '@nuvocargo/nuvo-styleguide/Calendar';
import { useHistory } from 'react-router-dom';

import {
  createShipmentEventIssue,
  exceptionReasons,
  getInitValues,
  isUpdateAction,
  MAX_LENGTH,
  updateShipmentEventIssue,
  getValidationSchema,
} from './data';
import {
  useCreateShipmentEventIssue,
  useUpdateShipmentEventIssueById,
} from 'core/api/shipmentEventIssuesRequest';
import { buildShipmentDetailsLink } from 'screens/track-and-trace/utils';
import { isCustomerSuccess, isTrackAndTrace } from 'core/utils';
import { useUser } from 'context';
import { isEstimatedDatetimeRequired } from 'screens/track-and-trace/shipment-details/configuration/utils';
import { useUpdateShipmentEventByQueryParam } from 'core/api';
import { Spinner } from 'components/generic/kit';
import { trackClick } from 'core/utils/trackClick';

/**
 * Will look for the nearest previous estimated datetime to use it
 * as minDate for the current Calendar component
 */
const getMinDate = (events, currentEvent) => {
  let prevEventIndex = events.findIndex(e => e.id === currentEvent.id) - 1;

  do {
    if (events[prevEventIndex]?.estimatedDatetime) {
      return new Date(events[prevEventIndex].estimatedDatetime);
    }

    prevEventIndex = prevEventIndex - 1;
  } while (prevEventIndex > 0);

  // If there isn't a nearest estimated datetime, use current date as fallback
  return new Date();
};

const IssueForm = ({
  shipmentEventId,
  shipmentId,
  exceptionId,
  shipmentEventIssue,
  shipmentEvents,
}) => {
  const { currentRole } = useUser();
  const history = useHistory();
  const { t } = useTranslation();
  const etaEventsArray = shipmentEvents.filter(({ type }) =>
    isEstimatedDatetimeRequired(type)
  );

  const etaEvents = etaEventsArray.reduce((acc, event) => {
    acc[event.type] = event;
    return acc;
  }, {});

  const createShipmentEventMutation = useCreateShipmentEventIssue({
    onSuccess: () => {
      history.push(
        buildShipmentDetailsLink({}, 'overview', {
          shipmentId,
        })
      );
    },
  });

  const updateShipmentEventMutation = useUpdateShipmentEventIssueById({
    onSuccess: () => {
      history.push(
        buildShipmentDetailsLink({}, 'overview', {
          shipmentId,
        })
      );
    },
  });

  const updateShipmentEventByQueryParam = useUpdateShipmentEventByQueryParam({
    isNewEstimatedEventsActive: true,
  });

  const submit = ({ etaEvents, ...submitData }) => {
    if (isCustomerSuccess(currentRole)) {
      updateShipmentEventByQueryParam.mutate(Object.values(etaEvents));
    }

    if (isUpdateAction(exceptionId)) {
      updateShipmentEventIssue(updateShipmentEventMutation, {
        ...submitData,
        shipmentEventId,
      });
    } else {
      createShipmentEventIssue(createShipmentEventMutation, {
        ...submitData,
        shipmentEventId,
      });
    }
  };

  const isSubmitting =
    createShipmentEventMutation.isLoading ||
    updateShipmentEventMutation.isLoading ||
    updateShipmentEventByQueryParam.isLoading;

  return (
    <Formik
      initialValues={{ ...getInitValues(shipmentEventIssue, t, etaEvents) }}
      validationSchema={getValidationSchema(t, currentRole, etaEventsArray)}
      validateOnChange
      onSubmit={submit}>
      {({ values, errors, setFieldValue }) => {
        return (
          <Form className="mt-4 flex flex-col items-start gap-8">
            {isTrackAndTrace(currentRole) ? (
              <>
                <Dropdown
                  name="reason"
                  options={exceptionReasons(t)}
                  placeholder={t('choose-a-reason')}
                  label={t('exception-reason')}
                  styles={{ root: { width: '100%' } }}
                  disabled={isSubmitting}
                />
                <div className="w-full">
                  <Textarea
                    name="internalNote"
                    placeholder={t('add-internal-note')}
                    label={t('additional-notes-internal')}
                    styles={{ root: { width: '100%' } }}
                    maxLength={MAX_LENGTH}
                    disabled={isSubmitting}
                  />
                  <div className="mt-4">
                    <Label color="steel">
                      {t('character-counter', {
                        remainingCharacters:
                          MAX_LENGTH - values.internalNote.length,
                        charactersLength: MAX_LENGTH,
                      })}
                    </Label>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="w-full">
                  <Textarea
                    name="noteEnglish"
                    placeholder={t('add-english-note')}
                    label={t('additional-notes-english')}
                    styles={{ root: { width: '100%' } }}
                    maxLength={MAX_LENGTH}
                    disabled={isSubmitting}
                  />
                  <div className="mt-4">
                    <Label color="steel">
                      {t('character-counter', {
                        remainingCharacters:
                          MAX_LENGTH - values.noteEnglish.length,
                        charactersLength: MAX_LENGTH,
                      })}
                    </Label>
                  </div>
                </div>
                <div className="w-full">
                  <Textarea
                    name="noteSpanish"
                    placeholder={t('add-spanish-note')}
                    label={t('additional-notes-spanish')}
                    styles={{ root: { width: '100%' } }}
                    maxLength={MAX_LENGTH}
                    disabled={isSubmitting}
                  />
                  <div className="mt-4">
                    <Label color="steel">
                      {t('character-counter', {
                        remainingCharacters:
                          MAX_LENGTH - values.noteSpanish.length,
                        charactersLength: MAX_LENGTH,
                      })}
                    </Label>
                  </div>
                </div>
              </>
            )}
            {isCustomerSuccess(currentRole) && (
              <div className="w-full">
                <Text color="green" size="large">
                  {t('update_eta_schedule')}
                </Text>
                <div className="mt-8 flex w-full flex-col  divide-y divide-nuvo-gray/10   ">
                  <div className="flex py-1 [&>div]:flex-1">
                    <div>{t('event_name')}</div>
                    <div>{t('estimated_date')}</div>
                  </div>
                  {Object.keys(values.etaEvents).map(eventKey => {
                    const event = values.etaEvents[eventKey];

                    return (
                      <div key={event.id} className="flex py-4 [&>div]:flex-1">
                        <div className="flex flex-col">
                          {t(event.type)}
                          {event.actualDatetime && (
                            <Text color="steel" size="small">
                              {t('completed')}
                            </Text>
                          )}
                          {errors.etaEvents?.[eventKey]?.estimatedDatetime && (
                            <ErrorMessage
                              error={
                                errors.etaEvents?.[eventKey]?.estimatedDatetime
                              }
                            />
                          )}
                        </div>
                        <div>
                          <Calendar
                            dateFormat="EEEE d MMM - HH:mm"
                            showTimeSelect
                            timeFormat="HH:mm"
                            timeIntervals={30}
                            placeholder={t('select_a_date')}
                            timeCaption={t(
                              'shipment-configuration-event-time-select'
                            )}
                            error={
                              !!errors.etaEvents?.[eventKey]?.estimatedDatetime
                            }
                            disabled={!!event.actualDatetime || isSubmitting}
                            minDate={getMinDate(etaEventsArray, event)}
                            name={`etaEvents.${eventKey}.estimatedDatetime`}
                            onChange={(_, value) => {
                              setFieldValue(
                                `etaEvents.${eventKey}.estimatedDatetime`,
                                value
                              );
                            }}
                            value={
                              new Date(
                                values.etaEvents[eventKey].estimatedDatetime
                              )
                            }
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
            <div className="flex w-full flex-row items-center justify-between">
              <div>
                {isCustomerSuccess(currentRole) && (
                  <Checkbox
                    name="notifyCustomer"
                    size="medium"
                    label={t('notify-customer-of-exception')}
                  />
                )}
              </div>

              <CTAButton
                id="exception-form-save-button"
                style={{ marginLeft: '12px' }}
                onClick={() => trackClick('Add Exception Form Submitted')}
                type="submit">
                {isSubmitting ? (
                  <Spinner color="white" />
                ) : (
                  t('general-save-text-button')
                )}
              </CTAButton>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default IssueForm;
