import React from 'react';
import { TextField, UnitField } from '@nuvocargo/nuvo-styleguide/forms/native';
import { useTranslation } from 'react-i18next';

const General = ({ commodity }) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col gap-6">
      <div className="flex w-full flex-col gap-8">
        <TextField
          label={t('commodity-form-category')}
          name="category"
          value={commodity?.category || ''}
          placeholder={t('commodity-form-category-placeholder')}
          disabled
        />
        <TextField
          label={t('commodity-form-product-description')}
          name="productDescription"
          placeholder={t('commodity-form-product-description-placeholder')}
          value={commodity?.name || ''}
          disabled
        />
        <UnitField
          label={t('commodity-form-weight')}
          name="weight"
          placeholder={t('commodity-form-weight-placeholder')}
          variant="weight"
          inputValue={commodity?.rawWeight || 0}
          unitValue={{
            label: commodity?.weight?.indexOf('kg') ? 'kg' : 'lb',
            value: 'kg',
          }}
          disabled
        />
      </div>
    </div>
  );
};

export default General;
