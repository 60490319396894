const sizes = {
  mobileS: 320,
  mobileM: 375,
  mobileL: 425,
  tablet: 768,
  sm: 968,
  laptop: 1024,
  laptopL: 1440,
  laptopXL: 1536,
  desktop: 2560,
};

const device = Object.keys(sizes).reduce((acc, label) => {
  acc[label] = `
    (max-width: ${sizes[label]}px)
  `;
  return acc;
}, {});

const mediaSizes = Object.keys(sizes).reduce((acc, label) => {
  acc[label] = `${sizes[label]}px`;
  return acc;
}, {});

export const outlines = {
  default: {
    border: 'solid 1px #e4e2df',
  },
  active: {
    border: 'solid 1px #307460',
  },
  error: {
    border: 'solid 1px #fe6b62',
  },
};

const theme = {
  colors: {
    blue: '#3e60b7',
    cream: '#faf8f6',
    darkCream: '#f3f1ef',
    darkGreen: '#143c35',
    darkGrey: '#404040',
    forest: '#307460',
    green: '#1b473f',
    lightBlue: '#DDF2FF',
    red: '#fe6b62',
    salmon: '#f8d7cb',
    silver: '#c4c4c4',
    softGreen: '#8da29d',
    softGrey: '#e9e9e9',
    steel: '#888884',
    white: 'white',
    yellow: '#ffd232',
  },
  device,
  mediaSizes,
  outlines,
};

export default theme;
