import { useRoutesRequest, useCompanies } from 'core/api';
import {
  useQueryParamsState,
  useQueryParams,
  usePageQueryParam,
} from 'core/hooks';
import { createFilters } from 'core/utils';
import createStateOptions from './createStateOptions';

export const useRoutes = ({ t }) => {
  const qp = useQueryParams();
  const [companyId = 'all', setCompanyId] = useQueryParamsState('companyId');
  const [state = 'inactive', setState] = useQueryParamsState('state');
  const [page, setPage] = usePageQueryParam([state, companyId]);
  const {
    data: routesResponse,
    isLoading: routesIsLoading,
    error,
  } = useRoutesRequest(
    {
      page,
      state,
      companyId: companyId === 'all' ? undefined : companyId,
    },
    {
      select: response => ({
        ...response,
        records: response.records.map(r => ({
          ...r,
          commodity: r.commodities?.[0] ?? null,
        })),
      }),
    }
  );
  const { data: companies, isLoading: companiesIsLoading } = useCompanies(
    {
      businessType: 'CUSTOMER',
    },
    {
      select: res => [
        {
          value: 'all',
          label: t('routes-all-filter'),
        },
        ...res.map(({ id, legalName }) => ({ value: id, label: legalName })),
      ],
    }
  );
  const stateOptions = createStateOptions(t);

  return {
    companies,
    error,
    isLoading: companiesIsLoading || routesIsLoading,
    page,
    setPage,
    qp,
    routes: routesResponse?.records ?? [],
    totalPages: routesResponse?.metadata?.totalPages ?? 1,
    ...createFilters([
      {
        field: 'companyId',
        items: companies,
        set: setCompanyId,
        value: companyId,
      },
      {
        field: 'state',
        items: stateOptions,
        set: setState,
        value: state,
      },
    ]),
  };
};
