import Calendar from '@nuvocargo/nuvo-styleguide/Calendar';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getUsedLanguage } from 'core/utils';

const EstimatedDatetimeCalendar = ({
  id,
  eventsValues,
  updateEvent,
  error,
  isSubmitting,
}) => {
  const { t, i18n } = useTranslation();

  const currentEvent = eventsValues[id];

  /**
   * Will look for the nearest previous estimated datetime to use it
   * as minDate for the current Calendar component
   */
  const minDate = useMemo(() => {
    let prevEvent = eventsValues[currentEvent?.prevEventId];

    do {
      if (prevEvent?.estimatedDatetime) {
        return prevEvent.estimatedDatetime;
      }

      prevEvent = eventsValues[prevEvent?.prevEventId];
    } while (prevEvent);

    // If there isn't a nearest estimated datetime, use current date as fallback
    return new Date();
  }, [eventsValues]);

  const [updatedTime, setUpdatedTime] = useState(
    currentEvent.estimatedDatetime
  );

  return (
    <Calendar
      error={error}
      dateFormat="EEEE d MMM - HH:mm"
      placeholder={t('select_a_date')}
      disabled={isSubmitting || !!currentEvent.actualDatetime}
      locale={getUsedLanguage(i18n?.language)}
      minDate={minDate}
      showTimeSelect
      timeCaption={t('shipment-configuration-event-time-select')}
      timeFormat="HH:mm"
      timeIntervals={30}
      value={currentEvent.estimatedDatetime}
      onChange={(_, newTime) => {
        setUpdatedTime(newTime);
      }}
      onClose={() => updateEvent(id, updatedTime, 'estimatedDatetime')}
      isReadOnly={false}
    />
  );
};

EstimatedDatetimeCalendar.displayName = 'EstimatedDatetimeCalendar';
export default EstimatedDatetimeCalendar;
