export const isCustomerSuccess = role => role === 'customer_success';

export const isPricingRepresentative = role =>
  role === 'pricing_representative';

export const isTrackAndTrace = role => role === 'tracking_and_operations';

export const isSales = role => role === 'sales';

export const isCarrierRepresentative = role =>
  role === 'carrier_representative';

export const isFinance = role => role === 'finance';

export const hasMultipleRoles = roles => roles.length > 1;

export const canSeeRoutes = role =>
  isCustomerSuccess(role) ||
  isPricingRepresentative(role) ||
  isTrackAndTrace(role) ||
  isSales(role) ||
  isCarrierRepresentative(role) ||
  isFinance(role);

export const canSeeTrackAndTrace = role =>
  isCustomerSuccess(role) ||
  isCarrierRepresentative(role) ||
  isFinance(role) ||
  isSales(role) ||
  isTrackAndTrace(role);

export const canSeePricingRequests = role => isPricingRepresentative(role);

export const canSeeShipmentDetailsException = role =>
  isCustomerSuccess(role) || isTrackAndTrace(role);
