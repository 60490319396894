import React from 'react';
import styled from 'styled-components';
import { Icon, Text } from '@nuvocargo/nuvo-styleguide';
import PropTypes from 'prop-types';

const getIcon = ({ name, color, alt, size, background }) => {
  return (
    <Icon
      name={name}
      color={color}
      alt={alt}
      size={size}
      background={background}
    />
  );
};

export default function TextAndIcon({ icons, text, onClick, ...props }) {
  return (
    <Container style={{ cursor: 'pointer' }} onClick={onClick} {...props}>
      {icons?.left && getIcon(icons.left)}
      <Text
        size={text.size}
        color={text.color}
        styles={{
          regular: {
            marginLeft: '10px',
            display: 'none',
            [`@media(min-width: 968px)`]: {
              display: 'block',
            },
          },
        }}>
        {text.t}
      </Text>
      {icons?.right && getIcon(icons.right)}
    </Container>
  );
}

export const Container = styled.div`
  display: flex;
  align-items: center;

  > img {
    margin-right: 10px;
  }
`;

TextAndIcon.protoTypes = {
  /**
   * Icon could be on the left or right of the text, or both
   */
  icons: PropTypes.shape({
    left: PropTypes.shape({
      name: PropTypes.string,
      color: PropTypes.string,
      alt: PropTypes.string,
      size: PropTypes.string,
      // An optional parameter to add a colored background square behind the icon.
      background: PropTypes.string,
    }),
    right: PropTypes.shape({
      name: PropTypes.string,
      color: PropTypes.string,
      alt: PropTypes.string,
      size: PropTypes.string,
      background: PropTypes.string,
    }).isRequired,
  }),
  /**
   * Text to display
   */
  text: PropTypes.shape({
    t: PropTypes.string,
    color: PropTypes.string,
    size: PropTypes.string,
  }).isRequired,
  /**
   * In cases where a click needs to go somewhere or do something.
   */
  onClick: PropTypes.func,
};

TextAndIcon.defaultProps = {
  icons: {},
  text: {
    t: '',
    color: '',
    size: '',
  },
};
