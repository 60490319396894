/**
 * Builds a unique 'hash' key (not really a hash) fof shipment events and issues,
 * which is used for the CopyAction template
 * @param { Object } params
 * @param { Object } params.event
 * @param { Object } params.issue
 * @param { 'en' | 'es' } params.lang
 * @param { 'completed' | 'event' | 'issue' } params.type
 */
export function buildCopyEventTemplateKey({ event, issue, lang, type }) {
  let key = `${lang}/${type}`;

  if (type === 'event' || type === 'issue') {
    key += `/${event.id}`;
  }

  if (type === 'issue') {
    key += `/${issue.id}`;
  }

  return key;
}
