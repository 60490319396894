// React & Friends
import React, { useState } from 'react';
import { Form, Formik } from 'formik';

// Utils
import { useTranslation } from 'react-i18next';

// UI & UX
import {
  CTAButton,
  Eyebrow,
  Grid,
  GridColumn,
  Heading,
} from '@nuvocargo/nuvo-styleguide';
import { UnauthenticatedLayout as Layout } from 'components/common/layout';
import { ExpiryPage, Stack } from 'components/generic/kit';

// Feature & local components
import {
  AdditionalServicesToggles,
  Commodities,
  RequestTypeAndCurrencyFields as RateAndCurrency,
  FormError,
  FormHeading,
  NotesField as Notes,
  RouteFields,
  SelectEquipment,
  SelectFuelPricing,
} from 'components/generic/form';

// Data & validations
import { getInitialValues } from './data';
import { getValidationSchema } from './validations';
import Verification from './components/QuoteVerification';

// Hooks
import { useAccessorials } from 'core/api/accessorials';
import { useQuoteRequest, useCreateQuoteRequestMutation } from './hooks';

export default function QuoteRequest() {
  const { t } = useTranslation();
  const [results, setResults] = useState({});
  const {
    companyId,
    company,
    companyName,
    dealName,
    error,
    isQuoteRequestLoading,
    validationErrors,
    verificationStepValues,
  } = useQuoteRequest();

  const { data: accessorials } = useAccessorials();

  const {
    mutate: createQuoteRequest,
    isError: isCreateQuoteRequestError,
    isLoading: isCreateQuoteRequestLoading,
    error: createQuoteRequestError,
  } = useCreateQuoteRequestMutation();
  const [activeStep, setActiveStep] = useState(1);

  const removeFromResults = route => {
    setResults({ ...results, [route.id]: null });
  };

  React.useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [activeStep]);

  if (error) {
    return <ExpiryPage title="The quote request link required is invalid" />;
  }

  if (activeStep === 1) {
    return (
      <Layout>
        <Verification
          initialValues={verificationStepValues}
          isQuoteRequestLoading={isQuoteRequestLoading}
          onSubmit={() => setActiveStep(2)}
          validationErrors={validationErrors}
        />
      </Layout>
    );
  }

  return (
    <Layout>
      <Grid
        styles={{
          root: {
            marginTop: '97px',
            paddingBottom: '100px',
          },
        }}>
        <GridColumn startMd={0} md={3}>
          <Stack>
            <Eyebrow text={t('general-company-text-label')} color="grey" />
            <Heading variant="h6">{companyName}</Heading>
          </Stack>
        </GridColumn>

        <GridColumn
          startMd={4}
          md={6}
          styles={{ root: { width: '656px', margin: '0 auto' } }}>
          <Formik
            initialValues={{ ...getInitialValues(t), companyId, company }}
            validationSchema={getValidationSchema({ t })}
            onSubmit={values => {
              createQuoteRequest(
                { values, prevResults: results },
                {
                  onError: data => {
                    const routeResults = data.response?.data?.records.reduce(
                      (acc, result) => {
                        acc[result?.callbackParams?.callbackId] = result;
                        return acc;
                      },
                      results
                    );
                    setResults(routeResults);
                  },
                }
              );
            }}>
            <Form>
              <FormHeading title="Quote Details" sub={dealName} />

              <RateAndCurrency />

              <SelectFuelPricing />

              <SelectEquipment accessorials={accessorials} />

              <Commodities companyId={companyId} accessorials={accessorials} />

              <RouteFields
                results={results}
                removeFromResults={removeFromResults}
              />

              <AdditionalServicesToggles />

              <Notes />

              <FormError
                isError={isCreateQuoteRequestError}
                error={createQuoteRequestError}
              />

              <CTAButton
                styles={{
                  primary: {
                    marginTop: '48px',
                    width: '230px',
                    height: '64px',
                  },
                  disabledStyle: { marginTop: '48px' },
                }}
                type="submit"
                disabled={isCreateQuoteRequestLoading}>
                Submit
              </CTAButton>
            </Form>
          </Formik>
        </GridColumn>
      </Grid>
    </Layout>
  );
}
