import React from 'react';
import { Stack } from '../kit';
import { Dropdown } from '@nuvocargo/nuvo-styleguide/forms/formik';
import { Text } from '@nuvocargo/nuvo-styleguide';
import { useTranslation } from 'react-i18next';

const SelectFuelPricing = () => {
  const { t } = useTranslation();

  return (
    <Stack space="8px" styles={{ root: { marginTop: '48px' } }}>
      <Dropdown
        name="fuel_pricing"
        label={t('general-fuel-pricing-select-label')}
        styles={{ root: { width: '100%' } }}
        options={[
          {
            value: 'dynamic',
            label: t('general-dynamic-label'),
          },
          {
            value: 'fixed',
            label: t('general-fixed-label'),
          },
        ]}
      />
      <Text
        color="steel"
        size="small"
        styles={{
          marginTop: '8px',
        }}>
        {t('quote-request-fuel-pricing-description')}
      </Text>
    </Stack>
  );
};

export default SelectFuelPricing;
