import Configured from './Configured';
import Unconfigured from './Unconfigured';

export const Facility = ({ place, testId, label, buttonOnClick }) => {
  const isFacilityConfigured = place.configured;

  return (
    <div data-testid={testId} className="flex gap-6 py-10 px-8">
      {isFacilityConfigured ? (
        <Configured label={label} place={place} buttonOnClick={buttonOnClick} />
      ) : (
        <Unconfigured
          label={label}
          place={place}
          buttonOnClick={buttonOnClick}
        />
      )}
    </div>
  );
};
