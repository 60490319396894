import { useEffect, useState } from 'react';
import { useQueryParam, NumberParam, withDefault } from 'use-query-params';

const usePageQueryParam = (pageResetEffect = []) => {
  const [page, setPage] = useQueryParam('page', withDefault(NumberParam, 1));
  const [isFirstRender, setIsFirstRender] = useState(true);

  useEffect(() => {
    /**
     * This is a hack which prevents the first render from resetting our page,
     * that way any kind of reload/remount resets our page progress, which
     * applied to fresh loads.
     */
    if (!isFirstRender) {
      setPage(1);
    } else {
      setIsFirstRender(false);
    }
  }, pageResetEffect);

  return [page, setPage];
};

export default usePageQueryParam;
