// React & friends
import React from 'react';
import { Link } from 'react-router-dom';
import { FieldArray, useFormikContext } from 'formik';

// Utils
import { v4 as uuidv4 } from 'uuid';
import { map as _map } from 'lodash';
import { useTranslation } from 'react-i18next';
import { getGeolocations } from 'core/api/geolocations';
import { debouncePromise } from 'core/utils/lib';

// UI & UX
import { Flex, Stack } from 'components/generic/kit';
import { Heading, Icon } from '@nuvocargo/nuvo-styleguide';
import { RouteAutoComplete } from '@nuvocargo/nuvo-styleguide/forms/formik';
import { RouteLoadsPerMonth } from 'components/generic/form/formik';
import Label from '@nuvocargo/nuvo-styleguide/Label';
import SGErrorMessage from '@nuvocargo/nuvo-styleguide/forms/ErrorMessage';
import UploadCSVButton from './UploadRoutesCSVButton';
import { countryShortNameToFlag } from '@nuvocargo/nuvo-styleguide/lib/utils';
import CSVModal from '../../common/app/RoutesCSVModal';

// CSS and Styled
import styled, { css } from 'styled-components';

const getAutocompleteGeolocations = debouncePromise(
  zipCode =>
    getGeolocations({
      filterName: zipCode,
      filterType: 'zipCode',
    }).then(res =>
      res.records.map(
        ({ name: zipCode, parents: { city, state, country }, id }) => ({
          id,
          icon: {
            name: countryShortNameToFlag(country),
          },
          label: `${zipCode}, ${city}, ${state}`,
        })
      )
    ),
  350
);

const ROUTE_AUTOCOMPLETE_CONTAINER_STYLE = {
  root: { width: '100%', minWidth: '0px' },
};

const StatusIcon = ({
  route,
  index,
  results = {},
  isLastRoute,
  isSuccess,
  isRejected,
  onClick,
}) => {
  if (isLastRoute) return null;

  const className = index === 0 ? 'first-route' : '';

  if (!results?.[route.id]) {
    return (
      <IconContainer className={className} onClick={onClick}>
        <CloseIcon isFirst={false} isHidden={false}>
          <Icon name="close" color="forest" />
        </CloseIcon>
      </IconContainer>
    );
  }

  return (
    <IconContainer
      isSuccess={isSuccess}
      isRejected={isRejected}
      className={className}
      onClick={isSuccess ? null : onClick}>
      <CloseIcon isFirst={false} isHidden={false}>
        <Icon name={isSuccess ? 'check' : 'close'} color="white" />
      </CloseIcon>
    </IconContainer>
  );
};

export default function RouteFields({ results, removeFromResults }) {
  const { t } = useTranslation();
  const { values, errors, touched } = useFormikContext();
  const CSVModalRef = React.useRef();

  const placeError = (type, index) => {
    const fieldError = errors?.routes?.[index]?.[type];
    const isTouched = touched?.routes?.[index]?.[type];

    if (!fieldError || !isTouched) return null;

    return fieldError;
  };

  const isLastRoute = index => values.routes.length === index + 1;
  const isSuccess = route => results?.[route.id]?.requestStatus === 'fulfilled';
  const isRejected = route => results?.[route.id]?.requestStatus === 'rejected';
  const resultMember = route => results?.[route.id]?.existingRoute;
  const resultErrors = route => {
    const result = results?.[route.id];

    if (!result) return;

    return _map(result.errors, value => `${value.join(', ')}`)?.join('\n');
  };

  return (
    <Stack space="32px" styles={{ root: { marginTop: '82px' } }}>
      <CSVModal ref={CSVModalRef} />

      <Flex
        styles={{
          root: {
            alignItems: 'center',
            justifyContent: 'space-between',
          },
        }}>
        <Heading variant="h5b">Routes</Heading>
        <UploadCSVButton modalRef={CSVModalRef} />
      </Flex>
      <FieldArray
        name="routes"
        render={arrayHelpers =>
          values.routes.map((route, index) => (
            <Row key={route.id}>
              <Stack
                axis="horizontal"
                space="24px"
                styles={{ root: { width: '100%' } }}>
                <Stack space={8} styles={ROUTE_AUTOCOMPLETE_CONTAINER_STYLE}>
                  <RouteAutoComplete
                    id={`routes-${index}.origin`}
                    name={`routes[${index}].origin`}
                    placeholder={t('placeholder-origin-postal-code')}
                    label={index === 0 ? 'Origin' : ''}
                    getOptionValue={option => option.id}
                    loadOptions={getAutocompleteGeolocations}
                    loadingMessage={() => t('white-glove-geolocations-loading')}
                    countryCode={option => option.country}
                    error={placeError('origin', index)}
                    openMenuOnFocus={false}
                    openMenuOnClick={false}
                    isClearable={false}
                    disabled={isSuccess(route)}
                  />
                </Stack>
                <Stack space={8} styles={ROUTE_AUTOCOMPLETE_CONTAINER_STYLE}>
                  <RouteAutoComplete
                    id={`routes-${index}.destination`}
                    placeholder={t('placeholder-destination-postal-code')}
                    name={`routes[${index}].destination`}
                    onChange={() => {
                      if (!isLastRoute(index)) return;

                      arrayHelpers.push({
                        origin: {},
                        destination: {},
                        loads_per_month: '',
                        id: uuidv4(),
                      });
                    }}
                    label={index === 0 ? 'Destination' : ''}
                    getOptionValue={option => option.id}
                    loadOptions={getAutocompleteGeolocations}
                    loadingMessage={() => t('white-glove-geolocations-loading')}
                    countryCode={option => option.country}
                    error={placeError('destination', index)}
                    openMenuOnFocus={false}
                    openMenuOnClick={false}
                    isClearable={false}
                    disabled={isSuccess(route)}
                  />
                </Stack>
                <Stack space={8} styles={{ root: { width: '50%' } }}>
                  {index === 0 && (
                    <Label color="steel">{'Loads Per Month'}</Label>
                  )}
                  <RouteLoadsPerMonth
                    id={`loads_per_month-${index}`}
                    name={`routes[${index}].loads_per_month`}
                    placeholder="0"
                    onKeyPress={e => {
                      e.key === 'Enter' && e.preventDefault();
                    }}
                    uploadedValue={route.loads_per_month}
                    disabled={isSuccess(route)}
                  />
                </Stack>
              </Stack>

              <StatusIcon
                {...{ route, index, results, arrayHelpers }}
                isSuccess={isSuccess(route)}
                isRejected={isRejected(route)}
                isLastRoute={isLastRoute(index)}
                onClick={() => {
                  if (isLastRoute(index)) return;

                  arrayHelpers.remove(index);
                  removeFromResults && removeFromResults(route);
                }}
              />

              {isRejected(route) && (
                <ErrorLink
                  to={`/routes/${resultMember(route).id}`}
                  target="_blank">
                  <ErrorMessage error={resultErrors(route)} />
                  <Icon name="keyboardArrowRight" color="red" />
                </ErrorLink>
              )}
              {isSuccess(route) && <SuccessRowOverlay />}
            </Row>
          ))
        }
      />
    </Stack>
  );
}

const ErrorLink = styled(Link)`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
  text-transform: capitalize;
  & > div {
    width: unset;
    span {
      width: unset;
    }
  }
`;

const ErrorMessage = styled(SGErrorMessage)`
  width: 100%;
  padding: 4px 0;
`;

const CloseIcon = styled.div`
  ${({ isFirst }) =>
    isFirst &&
    css`
      opacity: 0;
      pointer-events: none;
    `}
  ${({ isHidden }) =>
    isHidden &&
    css`
      opacity: 0;
      pointer-events: none;
      visibility: hidden;
    `}
  ${({ theme }) => css`
    @media only screen and ${theme.device.sm} {
      position: absolute;
      top: 8px;
      right: 8px;
    }
  `}
`;

const Row = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 8px;
  width: 100%;
`;

const SuccessRowOverlay = styled.div`
  position: absolute;
  display: block;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

const IconContainer = styled.div`
  position: absolute;
  right: calc(-24px - 40px);
  width: 40px;
  height: 40px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.darkCream};

  &.first-route {
    top: 28px;
  }

  svg {
    display: block;
  }

  ${({ isRejected }) =>
    isRejected &&
    css`
      background-color: #fe6b62;
    `}

  ${({ isSuccess }) =>
    isSuccess &&
    css`
      cursor: auto;
      background-color: #307460;
    `}
`;
