import React, { useMemo } from 'react';
import {
  DropdownCell,
  TextCell,
} from '@nuvocargo/nuvo-styleguide/InputTable/components/Cell';
import { formatMoney, buildYesNoDropdownOptions } from 'core/utils';
import {
  COMMODITIES_SPECIAL_REQUIREMENTS,
  getFumigationCost,
  getStrapCost,
  isFumigationAccessorial,
  isStrapsAccessorial,
} from 'core/utils/commodityUtils';
import { buildStrapsOptions } from '../../../../screens/quote-request/data';

function UseAddCommodityModal({ t, accessorials }) {
  const yesNoOptions = useMemo(() => buildYesNoDropdownOptions(t), [t]);
  const extraStrapsOptions = useMemo(() => buildStrapsOptions(t), [t]);

  const getAccessorialColumnValue = row => {
    const fumigationCost = getFumigationCost(accessorials);
    const strapCost = getStrapCost(accessorials);
    const ZERO_COST = formatMoney(0);

    if (isFumigationAccessorial(row)) {
      if (row.required.value) {
        return `${fumigationCost} ${t('quote-request-per-leg')}`;
      } else {
        return ZERO_COST;
      }
    }
    if (isStrapsAccessorial(row)) {
      return `${strapCost} ${t('quote-request-per-strap')}`;
    }

    return ZERO_COST;
  };

  const getRequiredColumnOptions = row => {
    if (isFumigationAccessorial(row)) {
      return yesNoOptions;
    }
    // For now straps is temporary working as a `yes` or `no` selector,
    // in the future we need to replace yesNoOptions by
    // the function called: buildStrapsOptions from `quote-request/data.js`
    if (isStrapsAccessorial(row)) {
      return extraStrapsOptions;
    }

    return [];
  };

  return {
    specialRequirementsColumns: [
      {
        Header: t('quote-request-equipment-option-type-header'),
        accessor: 'type',
        type: 'text',
      },
      {
        Header: t('quote-request-equipment-option-cost-header'),
        accessor: 'cost',
        Cell: ({ row, ...rest }) => {
          const text = getAccessorialColumnValue(row);
          return <TextCell text={text} fontColor="steel" {...rest} />;
        },
      },
      {
        Header: t('quote-request-equipment-option-required-header'),
        accessor: 'required',
        Cell: ({ row, ...rest }) => {
          const options = getRequiredColumnOptions(row);
          return <DropdownCell options={options} {...rest} />;
        },
      },
    ],
    specialRequirementsInitialValue: [
      {
        id: COMMODITIES_SPECIAL_REQUIREMENTS.fumigation,
        type: t('request-quote-type-fumigation'),
        cost: '',
        required: yesNoOptions[0],
      },
      {
        id: COMMODITIES_SPECIAL_REQUIREMENTS.straps,
        type: t('request-quote-type-extra-straps'),
        cost: '',
        required: extraStrapsOptions[0],
      },
    ],
  };
}

export default UseAddCommodityModal;
