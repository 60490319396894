import { useLocation } from 'react-router-dom';
import { useMutation } from 'react-query';
import qs from 'qs';

import * as api from 'core/api/user';

export const useForgotPassword = config => {
  const { search } = useLocation();
  const { invalidToken } = qs.parse(search, { ignoreQueryPrefix: true });
  const { mutate, data, error, isLoading } = useMutation(
    api.forgotPassword,
    config
  );

  return {
    sendEmail: mutate,
    data,
    error,
    isLoading,
    invalidTokenError: invalidToken === 'true' ? '-link-expired' : '',
  };
};
