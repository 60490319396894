/* eslint-disable react/display-name */
import { useMemo } from 'react';
import { Text, Eyebrow, Icon } from '@nuvocargo/nuvo-styleguide';
import { SmallDetails as TableCell } from 'components/generic/kit';
import { LinkCell } from 'components/common/app';
import { convertRouteLocationToStandardLocation } from 'core/utils';
import { formatShortDate } from 'core/utils/date-fns';
import { stringify } from 'qs';
import { formatMoneyWithCurrency } from 'core/utils';
import LocationCell from './components/Location';

const capitalizeFirstLetter = s => {
  if (!s) return s;
  return `${s.charAt(0).toUpperCase()}${s.substring(1)}`;
};

const useCreateColumns = ({ qp, t, page, companies }) =>
  useMemo(
    () => [
      {
        Header: () => {
          return (
            <Eyebrow
              text={t('routes-table-id-column')}
              size="small"
              color="darkGrey"
            />
          );
        },
        accessor: 'id',
        Cell: row => (
          <Text
            styles={{
              regular: {
                fontWeight: 'bold',
              },
            }}>{`RT-${row.cell.value}`}</Text>
        ),
        shrinkToContent: true,
      },
      {
        Header: () => {
          return (
            <Eyebrow
              text={t('routes-table-company-column')}
              size="small"
              color="darkGrey"
            />
          );
        },
        accessor: 'company.name',
        Cell: row => <Text>{row.cell.value}</Text>,
        shrinkToContent: true,
      },
      {
        Header: () => {
          return (
            <Eyebrow
              text={t('routes-table-origin-column')}
              size="small"
              color="darkGrey"
            />
          );
        },
        accessor: 'origin',
        Cell: row => (
          <LocationCell
            location={convertRouteLocationToStandardLocation(row.cell.value, t)}
          />
        ),
      },
      {
        Header: () => {
          return (
            <Eyebrow
              text={t('routes-table-destination-column')}
              size="small"
              color="darkGrey"
            />
          );
        },
        accessor: 'destination',
        Cell: row => (
          <LocationCell
            location={convertRouteLocationToStandardLocation(row.cell.value, t)}
          />
        ),
      },
      {
        Header: () => {
          return (
            <Eyebrow
              text={t('routes-table-details-column')}
              size="small"
              color="darkGrey"
            />
          );
        },
        accessor: 'commodity.name',
        shrinkToContent: true,
        Cell: row => (
          <TableCell
            top={t(
              row.row.original?.truckType ?? 'routes-table-unknown-truck-type'
            )}
            down={t(capitalizeFirstLetter(row.cell.value))}
            downStyles={{
              medium: { whiteSpace: 'break-spaces' },
            }}
          />
        ),
      },
      {
        Header: () => {
          return (
            <Eyebrow
              text={t('routes-table-source-column')}
              size="small"
              color="darkGrey"
            />
          );
        },
        accessor: 'requestedPlatform',
        shrinkToContent: true,
        Cell: row => {
          const createdDate = row.row.original?.createdAt;
          const dateValue = createdDate
            ? formatShortDate(createdDate)
            : undefined;
          return <TableCell top={t(row.cell.value)} down={dateValue} />;
        },
      },
      {
        Header: () => {
          return (
            <Eyebrow
              text={t('routes-table-route-type-column')}
              size="small"
              color="darkGrey"
            />
          );
        },
        accessor: 'serviceType',
        shrinkToContent: true,
        Cell: row => <Text>{t(capitalizeFirstLetter(row.cell.value))}</Text>,
      },
      {
        Header: () => {
          return (
            <Eyebrow
              text={t('routes-table-rate-column')}
              size="small"
              color="darkGrey"
            />
          );
        },
        accessor: 'convertedPriceCents',
        shrinkToContent: true,
        Cell: record => {
          const { currency, convertedPriceCents, priceState } =
            record.row.original;

          switch (priceState) {
            case 'priced':
              return (
                <Text color="blue">
                  {formatMoneyWithCurrency(convertedPriceCents / 100, currency)}
                </Text>
              );

            default:
              return <Text color="steel">{t(priceState)}</Text>;
          }
        },
      },
      {
        id: 'link-to-individual-route',
        accessor: 'id',
        Cell: row => (
          <LinkCell
            className="Routes__link-cell"
            to={{
              pathname: `/routes/${row.cell.value}`,
              search: stringify(qp),
            }}>
            <Icon name="keyboardArrowRight" color="forest" size="medium" />
          </LinkCell>
        ),
        isLink: true,
      },
    ],
    [qp, t, page, companies]
  );

export default useCreateColumns;
