import { QueryCache, QueryClient } from 'react-query';
import { toast } from 'react-toastify';

import { ERROR_JWT_TOKEN_NOT_FOUND } from './axios.config';

import { signOut } from '../api/user';
import i18n from '../integrations/i18n';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
    },
  },
  queryCache: new QueryCache({
    onError: async error => {
      if (error.message === ERROR_JWT_TOKEN_NOT_FOUND) {
        queryClient.cancelQueries();

        toast.info(i18n.t('internalui-session-expired'), {
          toastId: 'sessionExpired',
        });

        await signOut();

        queryClient.invalidateQueries('check-auth');
      }
    },
  }),
});
