import React from 'react';
import { Eyebrow } from '@nuvocargo/nuvo-styleguide';
import { Stack } from 'components/generic/kit';
import InputTable from '@nuvocargo/nuvo-styleguide/InputTable/formik';
import { useTranslation } from 'react-i18next';
import Text from '@nuvocargo/nuvo-styleguide/InputTable/components/Cell/Text';
import {
  formatMoneyWithCurrency,
  buildYesNoDropdownOptions,
  centsToDollar,
} from 'core/utils';

export const TRUCK_TYPE_REQUIREMENTS = ['direct_service', 'team_driver'];

export default function EquipmentOptions({ accessorials }) {
  const { t } = useTranslation();

  const ctpatObject = accessorials.find(
    accessorial => accessorial.slug === 'ctpat'
  );
  const ctpatValues = formatMoneyWithCurrency(
    centsToDollar(ctpatObject.schemaArgs[0]),
    'USD'
  );

  const teamDriverObject = accessorials.find(
    accessorial => accessorial.slug === 'team_driver'
  );
  const teamDriverMeasure = teamDriverObject.schemaArgs[0];
  const teamDriverPrice = formatMoneyWithCurrency(
    centsToDollar(teamDriverObject.schemaArgs[1]),
    'USD'
  );
  const teamDriverValues = `${teamDriverPrice} per ${teamDriverMeasure}`;

  return (
    <Stack styles={{ root: { marginTop: '40px' } }} space="24px">
      <Eyebrow text="Special requirements" color="green" />

      <Stack space="12px">
        <InputTable
          hideActions
          name="equipment_options"
          columns={[
            {
              Header: t('quote-request-equipment-option-type-header'),
              accessor: 'type',
              Cell: ({ value, ...rest }) => (
                <Text
                  text={value.text}
                  {...(value.leftIcon ? { leftIcon: value.leftIcon } : {})}
                  {...rest}
                />
              ),
            },
            {
              Header: t('quote-request-equipment-option-cost-header'),
              accessor: 'cost',
              Cell: ({ row, ...rest }) => {
                const { id } = row;
                return (
                  <Text
                    text={id === 'ctpat' ? ctpatValues : teamDriverValues}
                    fontColor="steel"
                    {...rest}
                  />
                );
              },
            },
            {
              Header: t('quote-request-equipment-option-required-header'),
              accessor: 'required',
              type: 'dropdown',
              options: buildYesNoDropdownOptions(t),
            },
          ]}
        />
      </Stack>
    </Stack>
  );
}
