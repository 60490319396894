import React from 'react';
import styled from 'styled-components';

export default function ErrorMessage({ children, backgroundColor, ...props }) {
  return (
    <StyledNotificationBox backgroundColor={backgroundColor} {...props}>
      <Error>{children}</Error>
    </StyledNotificationBox>
  );
}

const StyledNotificationBox = styled.div`
  background-color: ${({ theme, backgroundColor }) =>
    backgroundColor ? theme.colors[backgroundColor] : theme.colors['taupe']};
  border-radius: 4px;
  margin: 24px 0;
  width: 100%;
`;

const Error = styled.div`
  color: #ce1126;
  font-size: 18px;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  padding: 8px 0;
  & > ul > li {
    padding: 0 24px;
  }
  ::first-letter {
    text-transform: capitalize;
  }
`;
