import { isTrackAndTrace } from 'core/utils/roles';

const createStateOptions = ({ t, currentRole }) => {
  let statusOptions = [
    {
      value: 'ACTIVE',
      label: t('general-active-text-label'),
      id: 'shipment-active-filter',
    },
    {
      value: 'REQUESTED',
      label: t('general-requested-text-label'),
      id: 'shipment-requested-filter',
    },
    {
      value: 'COMPLETED',
      label: t('general-completed-text-label'),
      id: 'shipment-completed-filter',
    },
  ];

  return isTrackAndTrace(currentRole)
    ? (statusOptions = statusOptions.filter(
        state => state.value !== 'REQUESTED'
      ))
    : statusOptions;
};

export default createStateOptions;
