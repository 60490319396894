export const auth = {
  login: '/auth/login',
  forgotPassword: '/password/forgot',
  resetPassword: '/password/reset',
};

const pricingRequestsBasePath = 'pricing-requests';
const pricingRequestsPaths = {
  pricingRequests: `/${pricingRequestsBasePath}`,
  pricingRequest: `/${pricingRequestsBasePath}/:id`,
  pricingRequestsServiceability: `/${pricingRequestsBasePath}/:id/serviceability`,
  pricingRequestsLegCosts: `/${pricingRequestsBasePath}/:id/leg-costs`,
  pricingRequestsDirectServicePremium: `/${pricingRequestsBasePath}/:id/direct-service-premium`,
  pricingRequestsLegCostsDetails: `/${pricingRequestsBasePath}/:id/leg-costs-details`,
  pricingRequestsLegPrices: `/${pricingRequestsBasePath}/:id/leg-prices`,
  pricingRequestsReviewAndPublish: `/${pricingRequestsBasePath}/:id/review-and-publish`,
  pricingRequestsReviewAndPublishDetails: `/${pricingRequestsBasePath}/:id/published-details`,
};

export const app = {
  main: '/',
  quoteRequest: '/quote-request/:uuid',
  success: '/success',
  trackAndTrace: '/track-and-trace',
  route: '/routes/:id',
  routes: '/routes',
  newRoute: '/routes/new',
  lofiCreateShipment: '/routes/:id/new-shipment',
  ...pricingRequestsPaths,
};

export const customs = {
  main: '/customs',
};
