// React & friends
import { useQuery } from 'react-query';

// Utils
// import { reduce } from 'lodash';

// API Requests && success/redirect Paths
import { fetchCompanies } from 'core/api/companies';

export const formatCompany = r => ({
  ...r,
  value: r.id,
  label: r.legalName,
});

export default function useCompanies({ query, enabled } = {}) {
  const queryRes = useQuery(
    ['companies', query],
    () => {
      return fetchCompanies({ query, perPage: 20 });
    },
    {
      enabled,
      select: data => ({
        pagination: data.pagination,
        records: data.records.map(formatCompany),
      }),
    }
  );

  return {
    pagination: queryRes?.data?.pagination,
    companies: queryRes?.data?.records,
    ...queryRes,
  };
}
