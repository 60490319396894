import Sidebar from './Sidebar';
import IssueCard from './issue';
import { canSeeShipmentDetailsException } from 'core/utils';
import { useUser } from 'context';
import { shipmentDetailsViewTypes } from '../utils';

const ShipmentIssuesScreen = ({
  shipmentId,
  shipmentDetailsView,
  closeShipmentDetailsView,
}) => {
  const { currentRole } = useUser();
  if (
    !shipmentId ||
    !shipmentDetailsView ||
    shipmentDetailsView !== shipmentDetailsViewTypes.EXCEPTION ||
    !canSeeShipmentDetailsException(currentRole)
  ) {
    return null;
  }

  return (
    <Sidebar
      isExceptionSidebar
      shipmentId={shipmentId}
      shipmentDetailsView={shipmentDetailsView}
      closeShipmentDetailsView={closeShipmentDetailsView}>
      <IssueCard shipmentId={shipmentId} />
    </Sidebar>
  );
};

export default ShipmentIssuesScreen;
