import * as FullStory from '@fullstory/browser';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { client } from 'core/utils/axios.config';

import { GET_ROUTE_QUERY_BY_ID } from 'core/graphql/queries/routes/getRouteQueryById';
import { GET_ROUTE_DETAILS_QUERY_BY_ID } from 'core/graphql/queries/routes/getRouteDetailsQueryById';
import { placeIdToNumber } from 'screens/route/utils';
import { useParams } from 'react-router-dom';

export const useRouteRequest = ({ id }, queryOptions) =>
  useQuery(
    ['route', id],
    () =>
      getRouteRequest({
        id,
      }),
    queryOptions
  );

export const getRouteRequest = ({ id }) =>
  client
    .gql({
      query: GET_ROUTE_QUERY_BY_ID,
      variables: {
        routeId: id,
      },
    })
    .then(r => r?.data?.data?.routes?.nodes?.[0] ?? null);

export const useRouteDetailsRequest = ({ id }, queryOptions) =>
  useQuery(
    ['route', id],
    () =>
      getRouteDetailsRequest({
        id,
      }),
    queryOptions
  );

export const getRouteDetailsRequest = ({ id }) =>
  client
    .gql({
      query: GET_ROUTE_DETAILS_QUERY_BY_ID,
      variables: {
        routeId: id,
      },
    })
    .then(r => r?.data?.data?.routes?.nodes?.[0] ?? null);

export const useUpdateRoute = ({
  id,
  onSuccessCallback,
  updateProperty,
  onErrorCallback,
}) => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ id: newPlaceId }) =>
      updateRouteLocation({ id, newPlaceId, updateProperty }),
    {
      onSuccess: updatedRoute => {
        onSuccessCallback?.(updatedRoute);

        FullStory.event('Updated route to point to an existing place', {
          updatedRoute,
        });

        return queryClient.invalidateQueries(['route']);
      },
      onError: error => {
        FullStory.event(
          'Error trying to update place to point to an existing place',
          {
            error,
          }
        );
        if (onErrorCallback) {
          onErrorCallback();
        }
      },
    }
  );
};

const updateRouteLocation = ({ id, newPlaceId, updateProperty }) => {
  return client.patch(`/v0/routes/${id}`, {
    [updateProperty]: {
      id: placeIdToNumber(newPlaceId),
    },
  });
};

export const useRequestPrice = ({ onSuccessCallback, onErrorCallback }) => {
  const queryClient = useQueryClient();
  const { id: routeId } = useParams();

  return useMutation(
    ({ id, loadsPerMonth }) =>
      client.post(`/edge/routes/${id}/request_airtable_quote.json`, {
        loadsPerMonth,
      }),
    {
      onSuccess: ({ data }) => {
        onSuccessCallback?.(data);

        FullStory.event('Requested a price for a route', {
          data,
        });

        queryClient.invalidateQueries(['getRouteDetailsRequest', routeId]);
        queryClient.invalidateQueries('places');
      },
      onError: () => {
        onErrorCallback?.();
        FullStory.event('Requested a price for a route had an error');
      },
    }
  );
};
