import * as FullStory from '@fullstory/browser';
import { CTAButton, Heading } from '@nuvocargo/nuvo-styleguide';
import { Wrapper } from './styles';
import { useUpdateShipmentEventByQueryParam } from 'core/api';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

export const EventManager = ({ shipment }) => {
  const { t } = useTranslation();

  const { mutate, isLoading } = useUpdateShipmentEventByQueryParam({
    onSuccess: ({ data }) => {
      toast(`Shipment SH-${shipment.id} has been updated}`, {
        type: 'success',
      });

      FullStory.event('Added Actual Date to Shipment Event', {
        ...data[0],
        shipmentId: shipment.id,
      });
    },
  });

  const handleClick = () => {
    const { id, notifyCustomer } = shipment.currentShipmentEvent;
    const currentDatetime = new Date();
    const actualDatetime = currentDatetime.toISOString();

    mutate([
      {
        id,
        actualDatetime,
        sendNotification: notifyCustomer,
      },
    ]);
  };

  const upcomingEventType = shipment.currentShipmentEvent.type;

  if (
    upcomingEventType !== 'CLEARED_ORIGIN_CUSTOMS' &&
    upcomingEventType !== 'CLEARED_DESTINATION_CUSTOMS'
  ) {
    return null;
  }

  return (
    <Wrapper className="mt-2">
      <Heading
        variant="h6"
        color="green"
        styles={{ root: { marginBottom: '16px' } }}>
        {t('manage-shipment-events')}
      </Heading>
      <div className="flex justify-end">
        {upcomingEventType === 'CLEARED_ORIGIN_CUSTOMS' && (
          <CTAButton
            type="button"
            mode="light"
            colorType="secondary"
            disabled={isLoading}
            onClick={handleClick}>
            Mark as origin customs cleared
          </CTAButton>
        )}
        {upcomingEventType === 'CLEARED_DESTINATION_CUSTOMS' && (
          <CTAButton
            type="button"
            mode="light"
            colorType="secondary"
            disabled={isLoading}
            onClick={handleClick}>
            Mark as destination customs cleared
          </CTAButton>
        )}
      </div>
    </Wrapper>
  );
};
