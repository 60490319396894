import * as yup from 'yup';

export const validationSchema = (t, validateDate) =>
  yup.object().shape({
    actualDatetime: yup
      .string()
      .required(t('validation-datetime-required'))
      .test(
        'shipment-actual-date-time-event-error-previous-date-greater-than-current',
        t(
          'shipment-actual-date-time-event-error-previous-date-greater-than-current'
        ),
        validateDate
      ),
  });
