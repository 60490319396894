import { Text, Label } from '@nuvocargo/nuvo-styleguide';
import * as flag from '@nuvocargo/nuvo-styleguide/Icons/Flags';
import { Stack } from 'components/generic/kit';
import { useTranslation } from 'react-i18next';
import { graphQLCountryToFlagString } from 'screens/track-and-trace/utils';
import { createAddressString } from './utils';

const AdditionalData = ({ label, data }) =>
  data ? (
    <Label color="darkGrey">
      {label} {data}
    </Label>
  ) : null;

const LocationContent = ({
  city,
  country,
  name,
  addressLine1,
  state,
  postalCode,
  hours,
  contact,
  phone,
}) => {
  const { t } = useTranslation();
  const maybeLocationFlag = flag[graphQLCountryToFlagString(country)];

  return (
    <Stack>
      <Stack axis="horizontal" style={{ marginBottom: '4px' }}>
        <Text style={{ marginRight: '8px', lineHeight: '24px' }} size="large">
          {city ?? t('route-city-unknown')}
        </Text>
        {maybeLocationFlag && (
          <img
            style={{ maxWidth: '20px' }}
            src={maybeLocationFlag}
            alt={country}
          />
        )}
      </Stack>

      <Label color="darkGrey" style={{ marginBottom: '8px' }}>
        {name ?? t('route-location-name-unknown')}
      </Label>

      <Label color="darkGrey">
        {createAddressString({
          addressLine1,
          city,
          state,
          postalCode,
          country,
        }) ?? t('route-location-address-unknown')}
      </Label>

      <AdditionalData label={t('route-hours')} data={hours} />
      <AdditionalData label={t('route-contact')} data={contact} />
      <AdditionalData label={t('route-phone')} data={phone} />
    </Stack>
  );
};
export default LocationContent;
