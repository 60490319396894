import { useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  calculateRoutePrice,
  formatMoney,
  isDirectService,
  isTransloadService,
  ROUTE_SERVICEABLE_ENUM,
} from 'core/utils';
import { TextCell } from '@nuvocargo/nuvo-styleguide/InputTable/components/Cell';
import { graphQLCountryToFlagString } from '../../../track-and-trace/utils';
import { generatePath, useParams, useHistory } from 'react-router-dom';
import { useMutation, useQueryClient } from 'react-query';
import { ACTIONS_TYPES } from '../../store/reducer';
import { publishPricedRoutes } from 'core/api';
import { app } from 'routes/paths';

export const createReviewPublishColumns = () => {
  const { t } = useTranslation();
  let columns = useMemo(
    () => [
      {
        Header: t('general-origin-text-label'),
        accessor: 'origin',
        Cell: ({ value, row, ...rest }) => {
          const {
            serviceable: { value: serviceableValue },
          } = row;
          const isServiceable =
            serviceableValue === ROUTE_SERVICEABLE_ENUM.SERVICEABLE;
          return (
            <TextCell
              fontColor={isServiceable ? 'green' : 'steel'}
              text={`${value.zipCode.parents.city}, ${value.zipCode.name}, ${value.zipCode.parents.state}`}
              leftIcon={{
                name: graphQLCountryToFlagString(value.zipCode.parents.country),
              }}
              {...rest}
            />
          );
        },
      },
      {
        Header: t('general-destination-text-label'),
        accessor: 'destination',
        Cell: ({ value, row, ...rest }) => {
          const {
            serviceable: { value: serviceableValue },
          } = row;
          const isServiceable =
            serviceableValue === ROUTE_SERVICEABLE_ENUM.SERVICEABLE;
          return (
            <TextCell
              fontColor={isServiceable ? 'green' : 'steel'}
              text={`${value.zipCode.parents.city}, ${value.zipCode.name}, ${value.zipCode.parents.state}`}
              leftIcon={{
                name: graphQLCountryToFlagString(value.zipCode.parents.country),
              }}
              {...rest}
            />
          );
        },
      },
      {
        Header: t('general-crossing-fees-text-label'),
        accessor: 'crossingFeesCents',
        alignment: 'center',
        width: '180px',
        maxWidth: '180px',
        minWidth: '180px',
        Cell: ({ value, row, ...rest }) => {
          const {
            serviceable: { value: serviceableValue },
          } = row;
          const isServiceable =
            serviceableValue === ROUTE_SERVICEABLE_ENUM.SERVICEABLE;
          return (
            <TextCell
              fontColor="steel"
              text={
                isServiceable
                  ? formatMoney(parseFloat(value / 100), 'usd')
                  : '--'
              }
              alignment="center"
              {...rest}
            />
          );
        },
      },
      {
        Header: t('general-route-price-text-label'),
        accessor: 'priceCents',
        alignment: 'right',
        width: '180px',
        maxWidth: '180px',
        minWidth: '180px',
        Cell: ({ value, row, ...rest }) => {
          const {
            crossingFeesCents,
            serviceable: { value: serviceableValue },
          } = row;
          const isServiceable =
            serviceableValue === ROUTE_SERVICEABLE_ENUM.SERVICEABLE;
          const routePriceCents = value + crossingFeesCents;
          return (
            <TextCell
              fontColor="blue"
              text={
                isServiceable
                  ? formatMoney(parseFloat(routePriceCents / 100), 'usd')
                  : t('pricing-not-serviceable-text-label')
              }
              alignment="right"
              {...rest}
            />
          );
        },
      },
    ],
    [t]
  );
  return columns;
};

export const useReviewPublishTable = ({
  legQuotes,
  routes,
  dispatch,
  serviceType,
}) => {
  const history = useHistory();
  const { t } = useTranslation();
  const queryCache = useQueryClient();
  const { id: requestId } = useParams();

  const getFeesText = (isHandleBorderCrossing, isTransload) => {
    if (isHandleBorderCrossing && isTransload) {
      return t('pricing-all-fees-text');
    }
    if (isHandleBorderCrossing) {
      return t('pricing-crossing-fees-text');
    }
    if (isTransload) {
      return t('pricing-transload-fees-text');
    }
  };

  const setRoutePrices = (legQuotes, routes) => {
    return routes.map(route => {
      return {
        ...route,
        priceCents: calculateRoutePrice(legQuotes, route.id),
      };
    });
  };

  const goTo = () => {
    queryCache.refetchQueries(['pricingRequest', requestId], {
      type: 'active',
    });
    const route = generatePath(app.pricingRequestsReviewAndPublishDetails, {
      id: requestId,
    });
    history.push(route);
  };

  const { mutate } = useMutation(({ input }) => publishPricedRoutes(input), {
    onSuccess: goTo,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });

  const publishRoutes = () => {
    mutate({
      input: {
        attributes: {
          requestIdentifier: requestId,
          routes: routes.map(({ id, serviceable }) => ({
            id,
            serviceable: serviceable.value,
          })),
          legQuotes: legQuotes.map(
            ({ id, baseRateCents, marginCents, directPremiumCostCents }) => ({
              id,
              baseRate: baseRateCents,
              margin: parseInt(marginCents),
              ...(isDirectService(serviceType) && {
                directPremiumCost: directPremiumCostCents,
              }),
            })
          ),
        },
      },
    });
  };

  useEffect(() => {
    dispatch({
      type: ACTIONS_TYPES.SET_ROUTES,
      payload: setRoutePrices(legQuotes, routes),
    });
  }, []);

  const columns = createReviewPublishColumns();

  const isTransload = isTransloadService(routes[0]?.serviceType);
  const isHandleBorderCrossing = routes[0]?.handleBorderCrossing;

  const FeesText = getFeesText(isHandleBorderCrossing, isTransload);

  return {
    mutate,
    columns,
    FeesText,
    publishRoutes,
  };
};
