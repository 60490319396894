import React from 'react';
import { Heading, Text } from '@nuvocargo/nuvo-styleguide';

import Radio from './Radio';

function Option({ title, description, value, name }) {
  return (
    <label className="p flex gap-2 bg-nuvo-gray-dark/[.03] p-8">
      <Radio name={name} value={value} />
      <div className="flex flex flex-col">
        <Heading variant="h6" color="green">
          {title}
        </Heading>
        <Text color="steel" size="small">
          {description}
        </Text>
      </div>
    </label>
  );
}

export default Option;
