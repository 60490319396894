import { TableProvider, TableWrapper } from '@nuvocargo/nuvo-styleguide/Table';
import { Heading } from '@nuvocargo/nuvo-styleguide';
import { useTranslation } from 'react-i18next';

import { PricingRequestsTable } from './components';

export default function PricingRequests() {
  const { t } = useTranslation();

  return (
    <TableWrapper>
      <Heading variant="h3" color="green">
        {t('pricing-requests-title')}
      </Heading>
      <TableProvider>
        <PricingRequestsTable />
      </TableProvider>
    </TableWrapper>
  );
}
