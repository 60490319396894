import { useMemo } from 'react';

import {
  SAFETY_REQUIREMENTS,
  TRAILER_SPECIFICATIONS,
  FASTENING_EQUIPMENTS,
  SAFETY_REQUIREMENTS_TEXT,
  TRAILER_SPECIFICATIONS_TEXT,
  FASTENING_EQUIPMENT_TEXT,
  FUMIGATION,
  FUMIGATION_TEXT,
} from '../constants';

const COMPLETE_TRAILER_SPECIFICATIONS_TEXT = {
  ...TRAILER_SPECIFICATIONS_TEXT,
  [FUMIGATION]: FUMIGATION_TEXT,
};

export default function useParseAccessorials(commodity = { accessorials: [] }) {
  const { fasteningEquipment, safetyRequirements, trailerSpecifications } =
    useMemo(
      () =>
        commodity?.accessorials?.reduce(
          (accessorialsObj, accessorial) => {
            if (SAFETY_REQUIREMENTS.indexOf(accessorial) >= 0) {
              return {
                ...accessorialsObj,
                safetyRequirements: [
                  ...accessorialsObj.safetyRequirements,
                  SAFETY_REQUIREMENTS_TEXT[accessorial],
                ],
              };
            }

            if (TRAILER_SPECIFICATIONS.indexOf(accessorial) >= 0) {
              return {
                ...accessorialsObj,
                trailerSpecifications: [
                  ...accessorialsObj.trailerSpecifications,
                  COMPLETE_TRAILER_SPECIFICATIONS_TEXT[accessorial],
                ],
              };
            }

            if (FASTENING_EQUIPMENTS.indexOf(accessorial) >= 0) {
              return {
                ...accessorialsObj,
                fasteningEquipment: [
                  ...accessorialsObj.fasteningEquipment,
                  FASTENING_EQUIPMENT_TEXT[accessorial],
                ],
              };
            }

            return accessorialsObj;
          },
          {
            fasteningEquipment: [],
            safetyRequirements: [],
            trailerSpecifications: [],
          }
        ),
      [commodity.accessorials]
    );

  return {
    fasteningEquipment,
    safetyRequirements,
    trailerSpecifications,
  };
}
