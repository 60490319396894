import { v4 as uuidv4 } from 'uuid';
import {
  buildSpecialRequirementsOptions,
  buildYesNoDropdownOptions,
} from 'core/utils';
import { createRequestQuoteServiceTypeOptions } from '../quote-request/data';

export const getInitialValues = t => ({
  service_type: createRequestQuoteServiceTypeOptions(t)[0],
  border_crossing: buildYesNoDropdownOptions(t)[0],
  rate_type: 'Contracted',
  requested_platform: 'route_builder',
  truck_type: 'dryvan',
  // Dry Van fields
  special_requirements_dryvan: [],
  special_requirements_reefer: [],
  special_requirements_flatbed: [],
  notes: '',
  routes: [
    {
      origin: {},
      destination: {},
      loads_per_month: '',
      id: uuidv4(),
    },
  ],
  // Reefer fields
  temperature: '',
  // Flatbed requirements
  length: '',
  width: '',
  height: '',
  commodity: '',
  company: '',
  equipment_options: buildSpecialRequirementsOptions(t),
});
