import {
  BackFormButton,
  NextFormButton,
} from 'screens/pricing-request/components';
import InputTable from '@nuvocargo/nuvo-styleguide/InputTable/formik';
import { Heading, Text } from '@nuvocargo/nuvo-styleguide';
import { generatePath, useParams } from 'react-router-dom';
import { useDirectServicePremiumTable } from './hooks';
import { Stack } from 'components/generic/kit';
import { useTranslation } from 'react-i18next';
import { validationSchema } from './data';
import { Formik, Form } from 'formik';
import { app } from 'routes/paths';
import { pricingRequestStyles } from 'screens/pricing-request/pricingRequest.styles';
import { ResizeContainerDiv } from 'screens/pricing-request/pricingRequest.styles';

export const DirectServicePremium = ({ state: { legQuotes }, dispatch }) => {
  const { columns, onSubmit } = useDirectServicePremiumTable({
    dispatch,
    legQuotes,
  });
  const { id: requestId } = useParams();
  const { t } = useTranslation();

  if (!legQuotes) {
    return <></>;
  }

  return (
    <ResizeContainerDiv>
      <Stack style={pricingRequestStyles.title}>
        <Heading variant="h3">{t('leg-costs-title')}</Heading>
      </Stack>
      <Stack style={pricingRequestStyles.subtitle}>
        <Heading variant="h5">{t('direct-service-premium-subtitle')}</Heading>
      </Stack>
      <Stack style={pricingRequestStyles.text}>
        <Text color="steel">{t('direct-service-premium-text-label')}</Text>
      </Stack>
      <Formik
        enableReinitialize
        validationSchema={validationSchema(t)}
        initialValues={{ legQuotes }}
        onSubmit={onSubmit}>
        <Form>
          <InputTable
            hideActions
            name="legQuotes"
            columnIdentifier="id"
            columns={columns}
          />

          <div style={pricingRequestStyles.buttonContainer}>
            <BackFormButton
              route={generatePath(app.pricingRequestsLegCosts, {
                id: requestId,
              })}
            />
            <NextFormButton />
          </div>
        </Form>
      </Formik>
    </ResizeContainerDiv>
  );
};
