import { useQuery } from 'react-query';
import { getEnabledFeatures } from 'core/api';

export function useFlags(actorId) {
  return useQuery(
    ['featureFlags', actorId],
    () => getEnabledFeatures(actorId),
    { enabled: !!actorId, staleTime: 7200000 }
  );
}
