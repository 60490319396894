import React from 'react';
import { useTranslation } from 'react-i18next';
import Header from './Header';
import FooterButtons from './FooterButtons';
import { DataContainer } from './styles';
import LocationInformation from '../LocationInformation';

const ConfirmData = ({
  address,
  confirmDataAndSubmit,
  contactName,
  contactTelephone,
  isOrigin,
  isSubmitting,
  name,
  location,
  handleBackButtonClick,
}) => {
  const { t } = useTranslation();
  return (
    <div style={{ width: '100%' }}>
      <Header isOrigin={isOrigin} city={location?.city} state={location?.state}>
        {t('route-add-location-confirm-this-location')}
      </Header>

      <DataContainer>
        <LocationInformation
          isOrigin={isOrigin}
          name={name}
          address={address}
          contactName={contactName}
          contactTelephone={contactTelephone}
        />
      </DataContainer>

      <FooterButtons
        secondaryButtonText={t('back')}
        handleSecondaryButtonClick={handleBackButtonClick}
        handlePrimaryButtonClick={confirmDataAndSubmit}
        primaryButtonText={t('done')}
        isSecondaryButtonDisabled={isSubmitting}
        isPrimaryButtonDisabled={isSubmitting}
        isSubmit
      />
    </div>
  );
};

export default ConfirmData;
