import ShipmentLeg from './ShipmentLeg';

const ShipmentRoute = ({ route }) => (
  // Error handling is pushed up to the shipment level.
  <>
    {route?.map(
      (
        {
          carrier,
          destination,
          driverName,
          id,
          origin,
          trailerNumber,
          trailerPlateNumber,
          trailerPlateState,
          truckNumber,
          truckPlateNumber,
          truckPlateState,
        },
        index
      ) => (
        <ShipmentLeg
          key={id}
          caat={carrier?.caat}
          carrierName={carrier?.name}
          destination={destination}
          driverName={driverName}
          legNumber={index + 1}
          origin={origin}
          scac={carrier?.scac}
          trailerNumber={trailerNumber}
          trailerPlateNumber={trailerPlateNumber}
          trailerPlateState={trailerPlateState}
          truckNumber={truckNumber}
          truckPlateNumber={truckPlateNumber}
          truckPlateState={truckPlateState}
        />
      )
    )}
  </>
);

export default ShipmentRoute;
