import { Container } from './styles';
import { Documents } from './Documents';
import { EventManager } from './EventManager';

export default function EventDetails({ shipment }) {
  return (
    <Container>
      <Documents
        documents={shipment?.documents}
        documentsRequestedAt={shipment?.documentsRequestedAt}
      />
      <EventManager shipment={shipment} />
    </Container>
  );
}
