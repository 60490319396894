import './styles/tailwind.css';

import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Suspense } from 'react';
import Context from 'context';
import App from './app';
import './styles/font.css';
import 'core/integrations';
import { Spinner } from 'components/generic/kit';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import reportWebVitals from './reportWebVitals';
import { queryClient } from 'core/utils/queryClient.config';
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.min.css';

if (process.env.NODE_ENV === 'development') {
  const { worker } = require('./mocks/browser');
  worker.start();
}

ReactDOM.render(
  <Suspense fallback={<Spinner />}>
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <Context>
          <App />
          <ToastContainer
            position="bottom-right"
            autoClose="5000"
            closeOnClick
            theme="colored"
            style={{ zIndex: '1000000' }}
          />
          <ReactQueryDevtools initialIsOpen={false} />
        </Context>
      </QueryClientProvider>
    </BrowserRouter>
  </Suspense>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
