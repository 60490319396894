import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CTAButton, Heading, Icon, Text } from '@nuvocargo/nuvo-styleguide';

import { formatMoneyWithCurrency } from 'core/utils';

import useParseAccessorials from './hooks/useParseAccessorials';

import CommodityModal from './ComodityModal';

export const Commodity = ({
  companyId,
  commodity,
  serviceType,
  addSuccessToast,
  addErrorToast,
}) => {
  const { t } = useTranslation();

  const { trailerSpecifications, fasteningEquipment, safetyRequirements } =
    useParseAccessorials(commodity);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const onModalClick = () => setModalIsOpen(previousValue => !previousValue);

  return (
    <section className="flex flex-row gap-8 px-6 py-10">
      <div className="flex">
        <Icon name="truck" color="darkGrey" size="medium" />
      </div>

      <div className="flex w-full flex-col">
        <div className="flex justify-between">
          <div>
            <Heading variant="h5" color="darkGrey">
              {commodity.name} ({commodity.category})
            </Heading>
            <Text color="steel">
              {commodity.weight} •{' '}
              {formatMoneyWithCurrency(
                commodity.valuePerLoadCents,
                commodity.currency,
                { minimumFractionDigits: 2 }
              )}
            </Text>
          </div>
          <CTAButton colorType="secondary" onClick={onModalClick}>
            {t('facility-button-edit')}
          </CTAButton>
        </div>
        {commodity.notes && (
          <div className="mt-5">
            <Text>{commodity.notes}</Text>
          </div>
        )}
        <div className="mt-8">
          <div className="grid grid-cols-3 gap-0">
            <div className="border-black/.10 border-0 border-b-[1px] pb-2">
              <Text color="steel" size="small">
                {t('commodity-modal-trailer-specification')}
              </Text>
            </div>
            <div className="border-black/.10 border-0 border-b-[1px] pb-2">
              <Text color="steel" size="small">
                {t('commodity-modal-fastening-equipment')}
              </Text>
            </div>
            <div className="border-black/.10 border-0 border-b-[1px] pb-2">
              <Text color="steel" size="small">
                {t('commodity-modal-safety-requirement')}
              </Text>
            </div>
            <div className="mt-4 flex flex-col">
              {trailerSpecifications.map(trailerSpecification => (
                <Text>{t(trailerSpecification)}</Text>
              ))}
            </div>
            <div className="mt-4 flex flex-col">
              {fasteningEquipment.map(fasteningEquipment => (
                <Text>{t(fasteningEquipment)}</Text>
              ))}
            </div>
            <div className="mt-4 flex flex-col">
              {safetyRequirements.map(safetyRequirement => (
                <Text>{t(safetyRequirement)}</Text>
              ))}
            </div>
          </div>
        </div>
      </div>

      <CommodityModal
        companyId={companyId}
        commodity={commodity}
        serviceType={serviceType}
        isOpen={modalIsOpen}
        onClick={setModalIsOpen}
        addSuccessToast={addSuccessToast}
        addErrorToast={addErrorToast}
      />
    </section>
  );
};
