import React from 'react';

// UI & UX
import { CTAButton } from '@nuvocargo/nuvo-styleguide';

export default function UploadCSVButton({ modalRef }) {
  const handleFile = async e => {
    const file = e.target.files[0];
    if (!file) {
      return;
    }
    const text = await file.text();
    modalRef.current.uploadCsv(text);
  };
  /**
   * Hack documentation:
   *
   * There's a bug where if you close the modal, and you try to open it again
   * with the same file, it won't open. This is because the value of the input
   * gets set to the CSV file you selected, and then onChange → handleFile
   * won't get triggered. Thinking we needed to reset the value of the input
   * after we called handleFile I realized we could hardcode its value to an
   * empty string instad, and that would still make handleFile get triggered
   * every time.
   */
  const inputHardcodedValue = '';

  return (
    <div>
      <input
        type="file"
        id="upload"
        accept=".csv,type/csv"
        onChange={handleFile}
        hidden
        value={inputHardcodedValue}
      />
      <CTAButton
        as="label"
        mode="light"
        colorType="secondary"
        htmlFor="upload"
        styles={{
          secondary: {
            // Center the label text vertically
            display: 'table-cell',
            verticalAlign: 'middle',
            borderRadius: '4px',
            width: '151px',
          },
        }}>
        Upload CSV
      </CTAButton>
    </div>
  );
}
