import * as FullStory from '@fullstory/browser';
import { setUserId } from '@amplitude/analytics-browser';
import qs from 'qs';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useLocalStorage } from '@mantine/hooks';

import * as userApi from 'core/api/user';
import { hasMultipleRoles } from 'core/utils';

export const useAuth = () => {
  const { t } = qs.parse(window.location.search, { ignoreQueryPrefix: true });
  const { i18n } = useTranslation();
  const [currentRole, setCurrentRole] = useLocalStorage({
    key: 'nuvo-current-role',
    defaultValue: 'no_role_set',
  });

  const { isLoading, error, data } = useQuery(
    'check-auth',
    () => userApi.checkAuth(t),
    {
      select: data => {
        // Only admins can see the internal-ui
        if (!data?.isAdmin) {
          return {
            hasValidCredentials: false,
            role: 'no_role_set',
            currentUser: null,
            currentRole: 'no_role_set',
          };
        }

        let currentUser = data?.hasValidCredentials ? data : null;
        let role = 'no_role_set';

        const {
          id,
          email,
          displayName,
          companyId,
          isAdmin,
          internalRoles,
          title,
        } = data;

        if (
          currentRole === 'no_role_set' ||
          // prevent the user from accessing in a role they don't have access to
          !internalRoles.includes(currentRole)
        ) {
          role = internalRoles[0];
          setCurrentRole(role);
        }

        currentUser = {
          id,
          displayName,
          companyName: 'Nuvocargo',
          companyId,
          email,
          isAdmin,
          hasMultipleRoles: hasMultipleRoles(internalRoles),
          roles: internalRoles,
          title,
        };

        return {
          ...data,
          role,
          currentUser,
          currentRole: role,
        };
      },
      onSuccess: async data => {
        const { id, email, displayName, companyName, isAdmin, role } = data;

        FullStory.identify(id, {
          email,
          displayName,
          companyName_str: companyName,
          language: i18n.language,
          role,
          isAdmin,
        });
        setUserId(id);
      },
      onError: () => {
        console.log('an Error has occurred');
      },
    }
  );

  const isAuthenticated = data?.hasValidCredentials;
  const currentUser = data?.currentUser;

  const { pathname } = document.location;

  const shouldRedirect = !isLoading && pathname !== '/' && !!pathname;

  return {
    isLoading,
    isAuthenticated,
    backUrl: !isAuthenticated && shouldRedirect && `/?redirect=${pathname}`,
    currentUser,
    currentRole,
    setCurrentRole,
    error: error,
  };
};
