import { track } from '@amplitude/analytics-browser';

export const trackClick = (eventName, attributes = {}) => {
  track(eventName, {
    app: 'Internal',
    page: window.location.pathname,
    url: window.location.href,
    params: window.location.search,
    ...attributes,
  });
};
