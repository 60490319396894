import React from 'react';
import { CTAButton } from '@nuvocargo/nuvo-styleguide';
import { useTranslation } from 'react-i18next';

function NextFormButton({ goTo = () => {} }) {
  const { t } = useTranslation();

  return (
    <CTAButton
      onClick={goTo}
      icon="keyboardArrowRight"
      iconColor="white"
      type="submit"
      colorType="primary">
      {t('next-text-label')}
    </CTAButton>
  );
}

export default NextFormButton;
