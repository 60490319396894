import { useState } from 'react';
import { setUserId } from '@amplitude/analytics-browser';
import * as FullStory from '@fullstory/browser';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import qs from 'qs';
import { useMutation, useQueryClient } from 'react-query';

import * as userApi from 'core/api/user';

export const useSignIn = () => {
  const [email, setEmail] = useState();
  const { i18n } = useTranslation();
  const { search } = useLocation();
  const history = useHistory();

  const { redirect } = qs.parse(search, { ignoreQueryPrefix: true });
  const queryClient = useQueryClient();

  const { isLoading, mutate, error } = useMutation(userApi.signIn, {
    onSuccess: ({ id, displayName, companyName, impersonatedEmail }) => {
      FullStory.identify(id, {
        email,
        displayName,
        companyName_str: companyName,
        language: i18n.language,
        ...(impersonatedEmail ? { impersonatedEmail } : {}),
      });
      setUserId(id);

      queryClient.invalidateQueries('check-auth').then(() => {
        if (redirect) {
          history.replace(redirect);
        }
      });
    },
  });

  return {
    isSigningIn: isLoading,
    error,
    signIn: data => {
      setEmail(data.email);
      mutate(data);
    },
  };
};
