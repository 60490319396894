import { useCallback, useState } from 'react';
import { useLocalStorageState } from './useLocalStorageState';
import { useHotkey } from './useHotkey';
import { debounce } from 'lodash';

/**
 * Allows you to use a Clipboard that stores to local storage with hotkeys.
 * Every parameter other than clipboardName is optional, but adding handlePaste
 * and valueToCopy are recommended, otherwise hotkeys won't work.
 */
export const useClipboard = (clipboardName, { handlePaste, valueToCopy }) => {
  /**
   * The copied/pasted recently state plugs into our copy/paste actions, and
   * then sets their value to true for 5 seconds. This is used so that we can
   * provide UI feedback shifting buttons from text like "Copy" to "Copying"
   */
  const [hasCopiedRecently, setHasCopiedRecently] = useState(false);
  const [hasPastedRecently, setHasPastedRecently] = useState(false);
  const [clipboardValue, setClipboardValue] = useLocalStorageState(
    `clipboard-${clipboardName}`
  );

  const setPasted = useCallback(() => {
    if (!hasPastedRecently) {
      setHasPastedRecently(true);
      debounce(() => setHasPastedRecently(false), 5000)();
    }
  }, [hasPastedRecently, setHasPastedRecently]);
  const setCopied = useCallback(() => {
    if (!hasCopiedRecently) {
      setHasCopiedRecently(true);
      debounce(() => setHasCopiedRecently(false), 5000)();
    }
  }, [hasCopiedRecently, setHasCopiedRecently]);
  const handleCopy = useCallback(() => {
    if (valueToCopy) {
      setClipboardValue(valueToCopy);
    }

    setCopied();
  }, [valueToCopy, setClipboardValue]);
  const pasteCallback = useCallback(
    e => {
      handlePaste?.(e);
      setPasted();
    },
    [handlePaste, setPasted]
  );
  const setClipboardValueExport = useCallback(nextValue => {
    setClipboardValue(nextValue);
    setCopied();
  }, []);

  useHotkey('KeyC', handleCopy, { withAlt: true });
  useHotkey('KeyV', pasteCallback, { withAlt: true });

  return {
    clipboardValue,
    hasCopiedRecently,
    hasPastedRecently,
    setClipboardValue: setClipboardValueExport,
    setCopied,
    setHasCopiedRecently,
    setHasPastedRecently,
    setPasted,
  };
};
