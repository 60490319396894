import React from 'react';

// UI & UX
import { Stack } from 'components/generic/kit';
import { Heading } from '@nuvocargo/nuvo-styleguide';
import { Textarea } from '@nuvocargo/nuvo-styleguide/forms/formik';

// Styles and Styled Components
import { theme } from 'styles';

export default function QuoteRequestNotes() {
  return (
    <Stack space="22px" styles={{ root: { marginTop: '80px' } }}>
      <Heading variant="h5b">Notes</Heading>
      <Textarea
        name="notes"
        placeholder="Type here"
        styles={{
          input: {
            backgroundColor: theme.colors.darkCream,
            minHeight: '224px',
          },
        }}
      />
    </Stack>
  );
}
