import { useTranslation } from 'react-i18next';
import { Card } from 'components/common/app';
import { getEstimatedDateFromShipmentEvent } from './data';
import { formatShortDateTime, formatTime } from 'core/utils/date-fns';
import { shipmentEventTypeEnum } from '../../utils';
import EstimatedRoute from 'components/generic/kit/EstimatedRoute';

const buildAppointmentTypeOptions = t => ({
  WINDOW: t('appointment-type-window'),
  EXACT_TIME: t('appointment-type-exact-time'),
  FIFO: t('appointment-type-fifo'),
});

const buildAppointmentSchemaOptions = t => ({
  LIVE: t('load-unload-live-type'),
  DROP: t('load-unload-drop-type'),
});

export default function Details({ shipment }) {
  const { t } = useTranslation();
  const appointmentTypeOptions = buildAppointmentTypeOptions(t);
  const appointmentSchemaOptions = buildAppointmentSchemaOptions(t);

  const estimatedPickupDate = getEstimatedDateFromShipmentEvent(
    shipment.shipmentEvents,
    shipmentEventTypeEnum.ARRIVED_TO_ORIGIN
  );

  const estimatedDeliveryDate = getEstimatedDateFromShipmentEvent(
    shipment.shipmentEvents,
    shipmentEventTypeEnum.ARRIVED_TO_DESTINATION
  );

  const appointmentTypeAtOrigin =
    appointmentTypeOptions[shipment.appointmentTypeAtOrigin];
  const appointmentTypeAtDestination =
    appointmentTypeOptions[shipment.appointmentTypeAtDestination];

  const appointmentSchemaAtOrigin =
    appointmentSchemaOptions[shipment.typeOfLoadUnloadAtOrigin];
  const appointmentSchemaAtDestination =
    appointmentSchemaOptions[shipment.typeOfLoadUnloadAtDestination];

  let expectedPickupDate = shipment.lowerBoundForPickupWindowAt
    ? formatShortDateTime(shipment.lowerBoundForPickupWindowAt)
    : t('unspecified');

  if (shipment.upperBoundForPickupWindowAt) {
    expectedPickupDate += ` - ${formatTime(
      new Date(shipment.upperBoundForPickupWindowAt)
    )}`;
  }

  let expectedDeliveryDate = shipment.lowerBoundForDropoffWindowAt
    ? formatShortDateTime(shipment.lowerBoundForDropoffWindowAt)
    : t('unspecified');

  if (shipment.upperBoundForDropoffWindowAt) {
    expectedDeliveryDate += ` - ${formatTime(
      new Date(shipment.upperBoundForDropoffWindowAt)
    )}`;
  }

  const getEstimatedDate = date =>
    date
      ? t('estimated-at', {
          estimatedDate: formatShortDateTime(date),
        })
      : t('estimated-pending');

  return (
    <div className="mb-8">
      <Card noBorder>
        <div className="grid grid-cols-2 gap-x-4 p-8">
          <EstimatedRoute
            appointmentType={appointmentTypeAtOrigin}
            estimatedDatetime={getEstimatedDate(estimatedPickupDate)}
            loadingSchema={appointmentSchemaAtOrigin}
            location={shipment.origin}
            expectedDatetime={expectedPickupDate}
            type="pickup"
          />

          <EstimatedRoute
            appointmentType={appointmentTypeAtDestination}
            estimatedDatetime={getEstimatedDate(estimatedDeliveryDate)}
            loadingSchema={appointmentSchemaAtDestination}
            location={shipment.destination}
            expectedDatetime={expectedDeliveryDate}
            type="delivery"
          />
        </div>
      </Card>
    </div>
  );
}
