import { clone } from 'ramda';

const shipmentEventTypeEnum = {
  ARRIVED_TO_ORIGIN: 'ARRIVED_TO_ORIGIN',
  LOADING: 'LOADING',
  ARRIVED_TO_BORDER: 'ARRIVED_TO_BORDER',
  RECEIVED_PAPERWORK: 'RECEIVED_PAPERWORK',
  CLEARED_ORIGIN_CUSTOMS: 'CLEARED_ORIGIN_CUSTOMS',
  CROSSED_BORDER: 'CROSSED_BORDER',
  CLEARED_DESTINATION_CUSTOMS: 'CLEARED_DESTINATION_CUSTOMS',
  PICKED_UP_FROM_BORDER: 'PICKED_UP_FROM_BORDER',
  ARRIVED_TO_DESTINATION: 'ARRIVED_TO_DESTINATION',
  UNLOADING: 'UNLOADING',
  DELIVERED_TO_DESTINATION: 'DELIVERED_TO_DESTINATION',
  MOVING: 'MOVING',
  OTHER: 'OTHER',
};

export const shipmentEventsRequired = [
  shipmentEventTypeEnum.ARRIVED_TO_ORIGIN,
  shipmentEventTypeEnum.ARRIVED_TO_BORDER,
  shipmentEventTypeEnum.ARRIVED_TO_DESTINATION,
];

export const shipmentStatus = {
  AT_RISK: 'at-risk',
  CLOSE: 'close',
  ON_TIME: 'on-time',
};

const stringToEstimatedDate = (
  estimatedDatetimeString,
  fallback = new Date()
) => (estimatedDatetimeString ? new Date(estimatedDatetimeString) : fallback);

const existEventTime = events =>
  events.some(event => event.estimatedDatetime || event.actualDatetime);

export const initialValuesFromEvents = (
  newEstimatedEventsActive,
  isNotificationsConfigHidden,
  events
) => {
  let result = {};
  const existSomeEventTime =
    newEstimatedEventsActive && existEventTime(events) ? true : false;

  events.forEach(
    ({ id, estimatedDatetime, actualDatetime, notifyCustomer, ...rest }, i) => {
      result[id] = {
        actualDatetime,
        estimatedDatetime: stringToEstimatedDate(estimatedDatetime, null),
        id,
        ...(isNotificationsConfigHidden
          ? {}
          : {
              notifyCustomer:
                estimatedDatetime || actualDatetime || existSomeEventTime
                  ? notifyCustomer
                  : true,
            }),
        prevEventId: events[i - 1]?.id ?? null,
        sequence: i,
        ...rest,
      };
    }
  );

  return result;
};

/**
 * Clipboard comes in the shape of:
 * {
 *   "type": "dateString"
 * }
 *
 * We wanna find every event type match in the eventsArray, and replace their
 * estimated dates with the ones coming from our clipboard. But only if they
 * don't have an actualDatetime already set.
 */
export const pasteDatesFromClipboardToEvents = (clipboard = {}, events) => {
  const currentEventTypeMappingHt = Object.values(events).reduce(
    (acc, curr) => ({ ...acc, [curr.type]: curr.id }),
    {}
  );
  const eventsCopy = clone(events);

  Object.keys(clipboard).forEach(type => {
    const maybeCurrentId = currentEventTypeMappingHt[type];

    if (maybeCurrentId && !eventsCopy[maybeCurrentId]?.actualDatetime) {
      eventsCopy[maybeCurrentId].estimatedDatetime = stringToEstimatedDate(
        clipboard[type]
      );
    }
  });

  return eventsCopy;
};

export const isEstimatedDatetimeRequired = type =>
  shipmentEventsRequired.includes(type);

export const notNextEventTypeWithEstimatedDatetime = type =>
  ['UNLOADING', 'DELIVERED_TO_DESTINATION', 'MOVING', 'OTHER'].includes(type);

const nextEventTypeWithEstimatedDatetime = (type, hasBorderCrossingEvent) => {
  return {
    ARRIVED_TO_ORIGIN: 'ARRIVED_TO_ORIGIN',
    PICKED_UP_FROM_ORIGIN: hasBorderCrossingEvent
      ? 'ARRIVED_TO_BORDER'
      : 'ARRIVED_TO_DESTINATION',
    LOADING: hasBorderCrossingEvent
      ? 'ARRIVED_TO_BORDER'
      : 'ARRIVED_TO_DESTINATION',
    ARRIVED_TO_BORDER: hasBorderCrossingEvent
      ? 'ARRIVED_TO_BORDER'
      : 'ARRIVED_TO_DESTINATION',
    RECEIVED_PAPERWORK: 'ARRIVED_TO_DESTINATION',
    CLEARED_ORIGIN_CUSTOMS: 'ARRIVED_TO_DESTINATION',
    CROSSED_BORDER: 'ARRIVED_TO_DESTINATION',
    CLEARED_DESTINATION_CUSTOMS: 'ARRIVED_TO_DESTINATION',
    PICKED_UP_FROM_BORDER: 'ARRIVED_TO_DESTINATION',
    ARRIVED_TO_DESTINATION: 'ARRIVED_TO_DESTINATION',
    UNLOADING: 'ARRIVED_TO_DESTINATION',
    DELIVERED_TO_DESTINATION: 'ARRIVED_TO_DESTINATION',
    MOVING: 'ARRIVED_TO_DESTINATION',
    OTHER: 'ARRIVED_TO_DESTINATION',
  }[type];
};

export const hasBorderCrossingEvent = events =>
  !!events.find(event => {
    const { node } = event;
    return !!node
      ? event.node.type === 'ARRIVED_TO_BORDER'
      : event.type === 'ARRIVED_TO_BORDER';
  });

export const findNextEventWithEstimatedDatetime = (events, type) => {
  const nextEventTypeWithEstimatedTime = nextEventTypeWithEstimatedDatetime(
    type,
    hasBorderCrossingEvent(events)
  );

  const nextEvent = events.find(event => {
    const { node } = event;
    return !!node
      ? event.node.type === nextEventTypeWithEstimatedTime
      : event.type === nextEventTypeWithEstimatedTime;
  });

  return !!nextEvent?.node ? nextEvent.node : nextEvent;
};
