export const UPDATE_PLACE_MUTATION = `
  mutation updatePlaceMutation($input: UpdatePlaceInput!) {
    updatePlace(input: $input) {
      errors
      place {
        id
        name
        addressLine1
        addressLine2
        contactName
        contactTelephone
      }
    }
  }
`.replace(/\s+/g, ' ');

export const CREATE_PLACE_MUTATION = `
  mutation createPlaceMutation($input: CreatePlaceInput!) {
    createPlace(input: $input) {
      place {
        id
        name
        addressLine1
        addressLine2
        contactName
        contactTelephone
        companyId
        zipCode {
          id
          name
          latitude
          longitude
        }
        city {
          name
        }
        state {
          name
        }
        country {
          name
        }
      }
    }
  }
`.replace(/\s+/g, ' ');
