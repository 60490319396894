import React from 'react';
import { Formik, Form } from 'formik';
import {
  Grid,
  GridColumn,
  CTAButton,
  Heading,
  Icon,
  Text,
} from '@nuvocargo/nuvo-styleguide';
import { UnauthenticatedLayout as Layout } from 'components/common/layout';
import { useTranslation } from 'react-i18next';
import { Password } from '@nuvocargo/nuvo-styleguide/forms/formik';
import {
  MIN_LENGTH,
  SPECIAL_CHARACTERS,
  NUMBERS,
  validatePassword as initValidatePassword,
} from '@nuvocargo/nuvo-styleguide/forms/native/Password/validations';

import { ErrorMessage } from 'components/generic/form';

import styled, { css } from 'styled-components';
import { validationSchema, initialValues } from './data';
import { useResetPassword } from './hooks';
import { useHistory } from 'react-router-dom';

import { openBanner } from 'core/hooks/useBanner';
import { Spinner } from 'components/generic/kit';

const validatePassword = initValidatePassword({ minLength: 8 });

export default function ResetPassword() {
  const { t } = useTranslation();
  const history = useHistory();

  const { resetPassword, error, isLoading, token, verifyTokenApi } =
    useResetPassword({
      onSuccessCallback: () =>
        openBanner({
          key: 'reset-success',
          children: (
            <div style={{ display: 'flex' }}>
              <Icon name="check" color="white" />
              <Heading
                variant="h6"
                styles={{ root: { marginLeft: '10px' } }}
                color="white">
                {t('password-reset-successful')}
              </Heading>
            </div>
          ),
          timeout: 3000,
          onTimeoutEnd: () => history.push('/'),
        }),
    });

  const resetPasswordRequest = ({ password }) => {
    resetPassword({ token, password });
  };

  return verifyTokenApi?.isLoading ? (
    <Spinner />
  ) : (
    <Layout>
      <Grid
        styles={{
          root: {
            alignItems: 'center',
            gridTemplateRows: '100vh',
          },
        }}>
        <GridColumn startMd={5} md={4} sm={4}>
          <Header>
            <Heading variant="h3" color="green">
              {t('reset-password-title')}
            </Heading>
            <Text color="steel">{t('reset-password-subtitle')}</Text>
          </Header>
          <Formik
            validationSchema={validationSchema({ t, validatePassword })}
            initialValues={initialValues}
            onSubmit={resetPasswordRequest}>
            <Form>
              <Row>
                <Password
                  label={t('password')}
                  name="password"
                  placeholder={t('placeholder-password')}
                  useInternalValidation={true}
                  overrideValidationMessages={{
                    [MIN_LENGTH]: t('password-validation-min-length'),
                    [SPECIAL_CHARACTERS]: t(
                      'password-validation-special-characters'
                    ),
                    [NUMBERS]: t('password-validation-number'),
                  }}
                />
              </Row>
              <Row>
                <Password
                  label={t('confirm-password')}
                  name="confirmedPassword"
                  placeholder={t('placeholder-confirm-password')}
                />
              </Row>
              {error?.data?.error && (
                <Row>
                  <ErrorMessage backgroundColor="taupe">
                    {error.data.error}
                  </ErrorMessage>
                </Row>
              )}
              <Row alignCenter>
                <CTAButton type="submit" disabled={isLoading}>
                  {t('reset-password-btn')}
                </CTAButton>
              </Row>
            </Form>
          </Formik>
        </GridColumn>
      </Grid>
    </Layout>
  );
}

const Header = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-bottom: 20px;
  & > * + * {
    margin-top: 16px;
  }
`;

const Row = styled.div`
  margin: ${({ verticalSpacing }) =>
      verticalSpacing ? verticalSpacing : '40px'}
    0;
  ${({ alignCenter }) =>
    alignCenter &&
    css`
      display: flex;
      justify-content: center;
    `}
`;
