/* eslint-disable react/display-name */
import { Text, Eyebrow } from '@nuvocargo/nuvo-styleguide';
import {
  SmallDetails as TableCell,
  SmallRoute as RouteCell,
} from 'components/generic/kit';
import { LinkCell } from 'components/common/app';
import React from 'react';
import { isNil } from 'ramda';
import { Icon } from '@nuvocargo/nuvo-styleguide';
import { theme } from 'styles';
import {
  CarrierColumnContent,
  CarrierColumnHeader,
} from './components/CarrierRepColumn';
import UpcomingEventCol from './components/UpcomingEventCol';
import EtaToBorderCol from './components/EtaToBorderCol';
import PaperworkCol from './components/PaperworkCol';
import { graphQLCountryToFlagString, buildShipmentDetailsLink } from './utils';
import { useFeatures } from 'context';

const useCreateColumns = ({ t, currentRole, state, qp }) => {
  const { customsDocumentsEnabled } = useFeatures();

  let columns = React.useMemo(
    () => [
      {
        Header: () => {
          return (
            <Eyebrow
              text={t('general-shipment-id-text-label')}
              size="small"
              color="darkGrey"
              style={{
                whiteSpace: 'nowrap',
              }}
            />
          );
        },
        accessor: 'id',
        Cell: row => (
          <Text
            styles={{
              regular: {
                fontWeight: 'bold',
              },
            }}>{`SH-${row.cell.value}`}</Text>
        ),
        shrinkToContent: true,
      },
      {
        Header: () => {
          return (
            <Eyebrow
              text={t('general-company-text-label')}
              size="small"
              color="grey"
            />
          );
        },
        accessor: 'company',
        Cell: ({ row, cell }) => (
          <TableCell
            top={cell.value?.name}
            topStyles={{
              regular: {
                width: '100%',
                [`@media ${theme.device.laptopL}`]: {
                  width: '200px',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                },
              },
            }}
            down={row.original?.customerReference ?? ''}
          />
        ),
      },
      {
        Header: () => {
          return (
            <Eyebrow
              text={t('general-route-text-label')}
              size="small"
              color="grey"
            />
          );
        },
        accessor: 'origin',
        Cell: ({ row }) => {
          return isNil(row.original.origin) ||
            isNil(row.original.destination) ? (
            <Text color="red">{t('invalid-route')}</Text>
          ) : (
            <RouteCell
              origin={{
                country: graphQLCountryToFlagString(
                  row.original?.origin?.zipCode?.parents?.country
                ),
                city: row.original?.origin?.zipCode?.parents?.city,
                name: row.original?.origin?.zipCode?.name,
              }}
              destination={{
                city: row.original?.destination?.zipCode?.parents?.city,
              }}
            />
          );
        },
      },
      {
        Header: () => {
          return (
            <Eyebrow text={'Northbound/Southbound'} size="small" color="grey" />
          );
        },
        accessor: 'destination',
        Cell: ({ row }) => {
          const originCountry = row.original?.origin?.zipCode?.parents?.country;
          const destinationCountry =
            row.original?.destination?.zipCode?.parents?.country;
          const northbound =
            originCountry === 'mx' && destinationCountry === 'us';
          const southbound =
            originCountry === 'us' && destinationCountry === 'mx';
          return isNil(row.original.origin) ||
            isNil(row.original.destination) ? (
            <Text color="red">{t('invalid-route')}</Text>
          ) : (
            <Eyebrow
              text={northbound ? 'Northbound' : southbound ? 'Southbound' : ''}
              size="small"
              color="grey"
            />
          );
        },
      },
      {
        Header: () => {
          return <Eyebrow text={'Commodity'} size="small" color="grey" />;
        },
        accessor: 'commodity',
        Cell: ({ cell }) => {
          return (
            <TableCell
              top={<Text>{cell.value?.[0]?.name || 'not_specified'}</Text>}
              down={cell.value?.[0]?.category || 'not_specified'}
            />
          );
        },
      },
      {
        Header: () => <CarrierColumnHeader />,
        accessor: 'currentLeg',
        Cell: ({ cell }) => <CarrierColumnContent cell={cell} />,
        shrinkToContent: true,
      },
      {
        Header: () => {
          return <Eyebrow text={'Paperwork'} size="small" color="grey" />;
        },
        accessor: 'paperworkComplete',
        Cell: ({ cell }) => {
          return <PaperworkCol cell={cell} />;
        },
        shrinkToContent: true,
      },
      {
        Header: () => {
          return <Eyebrow text={'ETA To Border'} size="small" color="grey" />;
        },
        accessor: 'shipmentEvents.edges',
        Cell: ({ cell }) => {
          return <EtaToBorderCol cell={cell} />;
        },
        shrinkToContent: true,
      },
      {
        Header: () => {
          return (
            <Eyebrow text={t('upcoming-event')} size="small" color="grey" />
          );
        },
        accessor: 'currentShipmentEvent',
        Cell: ({ cell }) => {
          return <UpcomingEventCol cell={cell} />;
        },
        shrinkToContent: true,
      },
    ],
    [t]
  );

  if (customsDocumentsEnabled) {
    const sidebarColumn = {
      id: 'link-to-individual-shipment',
      accessor: 'id',
      Cell: ({ cell: { value: id } }) => (
        <LinkCell
          className="Customs__link-cell"
          to={buildShipmentDetailsLink(
            {
              ...qp,
              shipmentId: id,
            },
            'overview'
          )}>
          <Icon name="keyboardArrowRight" color="forest" size="medium" />
        </LinkCell>
      ),
      isLink: true,
    };
    return [...columns, sidebarColumn];
  }

  return columns;
};

export default useCreateColumns;
