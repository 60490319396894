export const ACCESSORIALS_QUERY = `
query accessorials { 
  accessorials {
    id
    slug
    name
    schemaCost
    schemaArgs
  }
}
`;
