import { client } from 'core/utils/axios.config';
import { useQuery } from 'react-query';

export const useRoutesRequest = (
  {
    page = 1,
    perPage = 20,
    locationState,
    companyId,
    temporaryInfinitePageSizeHack,
    state,
  },
  options
) =>
  useQuery(
    [
      'routes',
      temporaryInfinitePageSizeHack,
      page,
      perPage,
      locationState,
      state,
      companyId,
    ],
    () =>
      getRoutesRequest({
        companyId,
        page,
        perPage,
        state,
        locationState,
        temporaryInfinitePageSizeHack,
      }),
    options
  );

export const getRoutesRequest = ({
  companyId,
  page = 1,
  perPage = 20,
  state,
  locationState,
  temporaryInfinitePageSizeHack,
}) => {
  const params = {
    // Active by default, gets overwritten by URL if specified
    'filter[locationState]': locationState,
    'filter[state]': state,
    'filter[companyId]': companyId,
    include: 'company,origin,destination,commodities',
    'page[number]': page,
    'page[size]': perPage,
    temporaryInfinitePageSizeHack: temporaryInfinitePageSizeHack,
  };

  return client.get('/v0/routes', { params }).then(r => r.data);
};
