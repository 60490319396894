export const requestDetailsFields = [
  { field: 'requestId', translation: 'request-number-text-label' },
  { field: 'createdAt', translation: 'request-requested-at-text-label' },
  { field: 'requestedEmail', translation: 'request-email-text-label' },
  { field: 'company', translation: 'general-company-text-label' },
  { field: 'businessType', translation: 'request-customer-type-text-label' },
  { field: 'serviceType', translation: 'request-service-text-label' },
  {
    field: 'handleBorderCrossing',
    translation: 'request-handle-border-crossing-text-label',
  },
  { field: 'category', translation: 'request-commodity-text-label' },
  { field: 'name', translation: 'request-product-text-label' },
  { field: 'weight', translation: 'request-weight-text-label' },
  { field: 'value', translation: 'request-value-text-label' },
  { field: 'currency', translation: 'request-currency-text-label' },
  {
    field: 'conversionRate',
    translation: 'request-exchange-rate-text-label',
  },
];
