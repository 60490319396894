// React & friends
import { useMutation } from 'react-query';

// Utils
import * as FullStory from '@fullstory/browser';
import {
  serviceType,
  isInvalidRoute,
  isPrevSuccess,
  shipmentRequirements,
  handleBorderCrossing,
} from 'core/utils/routeUtils';

// API
import { createRoute } from 'core/api/routes';
import { optionToValue } from 'core/utils';

export default function useCreateRouteMutation() {
  const mutation = useMutation(
    async ({ values, prevResults = {} }) => {
      const nextRoutes = values.routes.reduce((acc, r) => {
        if (isInvalidRoute(r) || isPrevSuccess(r, prevResults)) {
          return acc;
        }

        return acc.concat({
          route: {
            callback_id: r.id,
            commodities: [values.commodity.id],
            company_id: values.company.id,
            origin_geolocation_id: r.origin.id,
            destination_geolocation_id: r.destination.id,
            truck_type: values.truck_type,
            currency: optionToValue(values.currency),
            request_type: values.request_type.value,
            requested_platform: 'route_builder',
            service_type: serviceType(values),
            notes: [values.notes],
            length: values.length,
            width: values.width,
            height: values.height,
            temperature: values.temperature,
            shipment_requirements: shipmentRequirements(values),
            additional_services: values.additional_services || [],
            loads_per_month: r.loads_per_month,
            handle_border_crossing: handleBorderCrossing(values),
          },
          extra: { callbackId: r.id },
        });
      }, []);

      const responses = await Promise.allSettled(
        nextRoutes.map(r => createRoute(r.route, r.extra))
      );

      const results = responses.reduce((acc, r) => {
        if (r.status === 'fulfilled') {
          acc[r.value.callbackId] = r.value;
        } else if (r.status === 'rejected') {
          const data = r.reason?.response?.data;
          acc[data.callbackParams?.callbackId] = data;
        }

        return acc;
      }, prevResults || {});

      return results;
    },
    {
      onSettled: (data, error) => {
        if (error) {
          return FullStory.event(
            'Failed to created a new route using route-builder',
            {
              data,
            }
          );
        }

        FullStory.event('Created new route using route-builder', {
          data,
        });
      },
    }
  );

  return mutation;
}
