import React from 'react';
import { CTAButton, Text } from '@nuvocargo/nuvo-styleguide';
import { theme } from 'styles';

import { Stack } from 'components/generic/kit';
import { CommodityRadio } from 'components/generic/form';
import { useFormikContext } from 'formik';

export default function CommodityRadioGroup({ commodities, openModal }) {
  const { setFieldValue, errors } = useFormikContext();

  if (!commodities?.length) {
    return (
      <>
        <Stack
          space="20px"
          center
          styles={{
            root: {
              backgroundColor: theme.colors.darkCream,
              borderRadius: '8px',
              padding: '74px 16px',
              textAlign: 'center',
            },
          }}>
          <Text
            color="darkGrey"
            styles={{ regular: { maxWidth: '431px', textAlign: 'center' } }}>
            Please add atleast one commodity for this company. This allows
            carriers to accurately quote lanes.
          </Text>
          <CTAButton type="button" onClick={openModal}>
            Add commodity
          </CTAButton>
        </Stack>
        {errors?.commodity && <Text color="red">{errors.commodity}</Text>}
      </>
    );
  }

  return (
    <>
      <Stack space="8px">
        {commodities.map(commodity => {
          const { id, name: label, requirement_tags } = commodity;
          return (
            <CommodityRadio
              key={id || label}
              name="commodity"
              value={id}
              label={label}
              tags={requirement_tags}
              onChange={() => {
                setFieldValue('commodity', commodity);
              }}
            />
          );
        })}
      </Stack>

      {errors?.commodity && <Text color="red">{errors.commodity}</Text>}
    </>
  );
}
