import { useQuery } from 'react-query';

import { client } from 'core/utils/axios.config';
import { ACCESSORIALS_QUERY } from 'core/graphql/queries/accessorials';

export const useAccessorials = (_args, queryOptions) =>
  useQuery('accessorials', () => getAccessorials(), queryOptions);

const getAccessorials = () =>
  client
    .gql({
      query: ACCESSORIALS_QUERY,
    })
    .then(r => r?.data?.data?.accessorials ?? []);
