import { placeToConfirmation } from '../utils';
import { STEPS } from './hook';

export const createConfirmationData = (
  wizardStep,
  fillFormData,
  existingPlace
) => {
  if (
    wizardStep === STEPS.CONFIRM_PLACE_UPDATE ||
    wizardStep === STEPS.CONFIRM_FORCED_PLACE_UPDATE
  ) {
    return placeToConfirmation(fillFormData);
  }

  if (wizardStep === STEPS.CONFIRM_ROUTE_UPDATE) {
    return placeToConfirmation(existingPlace);
  }

  return {};
};
