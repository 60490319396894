import React from 'react';
import { Text } from '@nuvocargo/nuvo-styleguide';
import {
  TextField,
  PhoneNumberField,
} from '@nuvocargo/nuvo-styleguide/forms/formik';
import { useTranslation } from 'react-i18next';

const Contact = () => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col gap-6">
      <div className="flex w-full flex-row gap-8 border-b border-solid border-nuvo-gray-dark/10 pb-1 ">
        <Text fontWeight="bold" color="green" size="large">
          {t('facility-form-contact-title')}
        </Text>
      </div>

      <div className="flex w-full flex-row gap-8">
        <div className="flex-1">
          <PhoneNumberField
            label={t('facility-form-phone-number-label')}
            name="contactTelephone"
            placeholder={t('facility-form-phone-number-placeholder')}
          />
        </div>
        <div className="flex-1">
          <TextField
            label={t('facility-form-contact-name-label')}
            name="contactName"
            placeholder={t('facility-form-contact-name-placeholder')}
          />
        </div>
      </div>
    </div>
  );
};

export default Contact;
