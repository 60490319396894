import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import useQueryParams from 'core/hooks/useQueryParams';
import { buildShipmentDetailsLink } from '../utils';
import { trackClick } from 'core/utils/trackClick';

export const TrackAndTraceTab = ({ name, viewName, isActive }) => {
  const qp = useQueryParams();
  return (
    <TabLink
      exact
      onClick={() => trackClick(`${name} Tab Clicked`)}
      activeStyle={{
        opacity: 1,
      }}
      to={buildShipmentDetailsLink(qp, viewName)}
      isActive={() => isActive}>
      {name}
    </TabLink>
  );
};

export const TabsContainer = styled.div`
  display: flex;
  align-items: center;
  border-bottom: ${({ theme }) => theme.outlines.default.border};
  min-height: 72px;
`;

const TabLink = styled(NavLink)`
  align-items: center;
  display: flex;
  margin-left: 20px;
  opacity: 0.5;
  padding: 17px 5px 15px;
  transition: opacity 0.3s ease 0s;
  color: ${({ theme }) => theme.colors.forest};

  &:hover {
    opacity: 1;
  }

  &:first-child {
    margin-left: 30px;
  }
`;
