import { useCallback, useEffect, useReducer } from 'react';
import axios from 'axios';

const states = {
  loading: 'isLoading',
  failure: 'failure',
  success: 'success',
};

function reducer(state, action) {
  switch (action.type) {
    case states.loading:
      return {
        ...state,
        isLoading: true,
      };
    case states.success:
      return {
        ...state,
        isLoading: false,
        data: action.data,
      };
    case states.failure:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export default function usePromise(
  promiseFn,
  {
    onSuccessCallback,
    onFailureCallback,
    isEffect = false,
    effectCondition = [],
  } = {}
) {
  const initialState = {
    isLoading: false,
    data: undefined,
    error: undefined,
  };

  const [{ error, data, isLoading }, dispatch] = useReducer(
    reducer,
    initialState
  );

  const request = useCallback(
    (...args) => {
      dispatch({ type: states.loading });
      return promiseFn(...args)
        .then(response => {
          dispatch({ type: states.success, data: response.data });
          onSuccessCallback?.(response, args);
          return response;
        })
        .catch(error => {
          if (axios.isCancel(error)) {
            // Want to cancel request from axios without dispatching an actual error.
            return;
          }
          dispatch({ type: states.failure, error: error.response });
          onFailureCallback?.(error.response, args);
        });
    },
    [promiseFn, onFailureCallback, onSuccessCallback]
  );

  useEffect(isEffect ? request : () => {}, effectCondition);

  return {
    request,
    data,
    error,
    isLoading,
  };
}
