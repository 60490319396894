import { gql } from 'graphql-request';

export const GET_USERS_QUERY = gql`
  query getUsers($after: String, $first: Int, $id: ID, $internalRole: String) {
    users(after: $after, first: $first, id: $id, internalRole: $internalRole) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      nodes {
        id
        displayName
        email
      }
    }
  }
`;
