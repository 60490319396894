// React & friends
import { useMutation, useQueryClient } from 'react-query';

// Utils
import { v4 as uuid } from 'uuid';
import * as FullStory from '@fullstory/browser';

// API Request hooks && success/redirect Paths
import { createCommodity } from 'core/api/commodities';
import { isStrapsAccessorial } from '../utils/commodityUtils';

const specialRequirementsToArray = requirements => {
  const specialRequirements = [];

  requirements.forEach(requirement => {
    if (requirement.required.value) {
      specialRequirements.push(requirement.id);
    }
  });

  return specialRequirements;
};

export default function useCreateCommodityMutation({ companyId } = {}) {
  const queryClient = useQueryClient();
  const mutation = useMutation(
    values => {
      const nextRequirements =
        values.requirements ||
        specialRequirementsToArray(values.specialRequirements);

      return createCommodity({
        name: values.name,
        category: values.category.value,
        companyId: values.companyId || companyId,
        requirements: nextRequirements,
        extraStrapsCount: values.specialRequirements.find(requirement =>
          isStrapsAccessorial(requirement)
        ).required.value,
        valuePerLoadCents:
          +(values.valuePerLoad || values.shipmentValue || 0) * 100,
        weight: +(values.weight || 0),
      });
    },
    {
      onMutate: async values => {
        await queryClient.cancelQueries(['commodities', companyId]);
        const newCommodity = { ...values, id: uuid() };

        const previousCommodities = queryClient.getQueryData([
          'commodities',
          companyId,
        ]);

        queryClient.setQueryData(['commodities', companyId], old => {
          return {
            ...old,
            records: [newCommodity, ...old.records],
          };
        });

        return { previousCommodities };
      },
      onSuccess: (data, _values, context) => {
        queryClient.setQueryData(['commodities', companyId], () => {
          const { pagination, records } = context.previousCommodities;
          return {
            ...pagination,
            records: [data, ...records],
          };
        });

        FullStory.event('Created new commodity for route in route-builder', {
          data,
        });
      },
      onError: (_err, _values, context) => {
        queryClient.setQueryData(
          ['commodities', companyId],
          context.previousCommodities
        );
      },
    }
  );

  return mutation;
}
