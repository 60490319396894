import InputTable from '@nuvocargo/nuvo-styleguide/InputTable/formik';

const LegCostDetailsTable = ({ columns, name = 'legQuotes' }) => {
  return (
    <InputTable
      hideActions
      name={name}
      columnIdentifier="id"
      columns={columns}
    />
  );
};

export default LegCostDetailsTable;
