import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { AutoComplete as StyleguideAutoComplete } from '@nuvocargo/nuvo-styleguide/forms/native';

export const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 64px;
`;

export const StyledTable = styled.div`
  padding: 30px 0;

  overflow-x: scroll;

  table {
    border-spacing: 0;
    padding: 20px 30px;
    width: 100%;
    height: 100%;
    thead tr th {
      border-bottom: 0;
      height: 100%;
    }

    th,
    td {
      border-bottom: 1px solid #ece9e7;
      margin: 0;
      padding: 20px;
      text-align: left;
      height: 100%;

      + .clickable-cell {
        // Link defines its own padding
        padding: 0px;
        width: 100px;
      }

      :first-child {
        border-top: 0;
      }

      :last-child {
        border-right: 0;
      }
    }

    td.shrink-to-content {
      width: 1%;
      white-space: nowrap;
    }

    @media (min-width: ${({ theme }) => theme.mediaSizes.mobileL}) {
      overflow-x: auto;
    }
  }
`;

export const LinkCell = styled(Link)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 16px;
  height: 100%;
`;

export const AutoComplete = styled(StyleguideAutoComplete)`
  min-width: 230px;

  .TableFilters__control {
    border-radius: 30px;
    height: 50px;

    &--is-focused,
    &:hover {
      border-color: ${({ theme }) => theme.colors.green};
      box-shadow: 0 0 0 1px ${({ theme }) => theme.colors.green};
    }

    .TableFilters__single-value {
      color: ${({ theme }) => theme.colors.forest};
    }
  }

  .TableFilters__menu {
    top: 110%;

    .TableFilters__menu-list {
      border-radius: 14px;

      .TableFilters__option--is-selected,
      .TableFilters__option:hover {
        color: ${({ theme }) => theme.colors.forest};
      }

      .TableFilters__option:active {
        background-color: inherit;
      }
    }
  }
`;
