import { Label } from '@nuvocargo/nuvo-styleguide';
import Text from '@nuvocargo/nuvo-styleguide/Text';
import styled from 'styled-components';

const displayTooltip = potentialTooltip =>
  typeof potentialTooltip === 'string' ? potentialTooltip : undefined;

export default function SmallDetails({
  down,
  downStyles = {},
  top,
  topStyles = {},
}) {
  return (
    <LocationWrapper>
      <Text
        title={displayTooltip(top)}
        color="darkGrey"
        styles={{
          ...topStyles,
        }}>
        {top}
      </Text>
      <Label
        title={displayTooltip(down)}
        color="steel"
        styles={{
          ...downStyles,
        }}>
        {down}
      </Label>
    </LocationWrapper>
  );
}

const LocationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
`;
