import React from 'react';
import { useTranslation } from 'react-i18next';
import { formatShortDate } from 'core/utils/date-fns';
import { Text, Heading } from '@nuvocargo/nuvo-styleguide';

const Notes = ({ notes, requester, createdAt }) => {
  const { t } = useTranslation();

  const transformedNotes = notes
    .filter(n => !!n)
    .map((message, i) => ({
      message,
      id: i,
      name: requester?.displayName ?? t('unknown-user'),
      createdAt: createdAt ?? t('unknown-note-created-date'),
    }));

  return (
    <div
      className="col-span-12 mt-6 nuvo-sm:col-span-9 nuvo-sm:col-end-9 nuvo-sm:mr-6"
      data-testid="route-notes">
      <Heading color="green">{t('route-notes-header')}</Heading>

      <div className="mt-6">
        {transformedNotes.map(({ id, message, createdAt, name }) => (
          <div key={id} className="mb-6 border-b border-nuvo-gray-dark/10 pb-2">
            <div className="mb-2 flex items-center">
              <FakeAvatar name={name} />
              <Text fontWeight="bold" className="mx-2" size="small">
                {name}
              </Text>
              <Text size="small" color="steel">
                {formatShortDate(createdAt, 'DD')}
              </Text>
            </div>

            <Text>{message}</Text>
          </div>
        ))}
      </div>
    </div>
  );
};

const FakeAvatar = ({ name }) => (
  <div className="flex h-6 w-6 items-center justify-center rounded-full bg-nuvo-forest text-center">
    <Text size="extraSmall" color="white" className="pt-px text-white">
      {name[0]}
    </Text>
  </div>
);

export default Notes;
