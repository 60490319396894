import { gql } from 'graphql-request';

export const UPDATE_USER_MUTATION = gql`
  mutation internalui_updateUser($id: ID!, $userInput: UserInput!) {
    updateUser(input: { id: $id, attributes: $userInput }) {
      user {
        id
      }
    }
  }
`;
