import { client } from 'core/utils/axios.config';

export const fetchCompanies = ({ query, perPage }) =>
  client
    .get('edge/companies.json', {
      params: { query, perPage, businessType: 'customer' },
    })
    .then(response => response.data);

export const createCompany = data =>
  client.post('edge/companies.json', data).then(response => response.data);
