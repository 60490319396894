import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { TextCell } from '@nuvocargo/nuvo-styleguide/InputTable/components/Cell';
import { graphQLCountryToFlagString } from '../../../track-and-trace/utils';
import PriceText from '../../components/Cells/PriceText';
import AccesorialPriceText from '../../components/Cells/AccesorialPriceText';

const createLegCostsColumns = () => {
  const { t } = useTranslation();
  const columns = useMemo(
    () => [
      {
        Header: t('general-origin-text-label'),
        accessor: 'origin',
        Cell: ({ value, ...rest }) => {
          return (
            <TextCell
              fontColor="green"
              text={`${value.parents.city}, ${value.name}, ${value.parents.state}`}
              leftIcon={{
                name: graphQLCountryToFlagString(value.parents.country),
              }}
              {...rest}
            />
          );
        },
      },
      {
        Header: t('general-destination-text-label'),
        accessor: 'destination',
        Cell: ({ value, ...rest }) => {
          return (
            <TextCell
              fontColor="green"
              text={`${value.parents.city}, ${value.name}, ${value.parents.state}`}
              leftIcon={{
                name: graphQLCountryToFlagString(value.parents.country),
              }}
              {...rest}
            />
          );
        },
      },
      {
        Header: t('general-base-rate-text-label'),
        accessor: 'baseRateCents',
        alignment: 'right',
        width: '140px',
        Cell: props => <PriceText fontColor="steel" {...props} />,
      },
      {
        Header: t('general-fuel-cost-text-label'),
        accessor: 'fuelCostCents',
        alignment: 'right',
        width: '140px',
        Cell: props => <PriceText fontColor="green" {...props} />,
      },
      {
        Header: t('general-accesorials-text-label'),
        accessor: 'accessorialsTotalCostCents',
        alignment: 'right',
        width: '140px',
        Cell: props => (
          <AccesorialPriceText fontColor="green" rows={props.row} {...props} />
        ),
      },
    ],
    []
  );
  return columns;
};

const UseLegCostsDetails = () => {
  const columns = createLegCostsColumns();
  return {
    columns,
  };
};

export default UseLegCostsDetails;
