import React from 'react';
import { Text } from '@nuvocargo/nuvo-styleguide';
import { Dropdown, Textarea } from '@nuvocargo/nuvo-styleguide/forms/formik';
import { useTranslation } from 'react-i18next';
import { useField } from 'formik';

import {
  buildAppointmentTypeOptions,
  buildSchemaOptions,
  PROCEDURES_APPOINTMENT_TYPES_MAP,
} from 'core/utils/route-facility';
import { TimeSelector } from 'components/generic/form/formik/TimeSelector';
import { createIsFifoOptions } from '../../../../../lofi-create-shipment/data';
import LoadingDuration from './LoadingDuration';

const Procedures = ({ facilityType }) => {
  const { t } = useTranslation();
  const [appointmentTypeField = {}] = useField({ name: 'appointmentType' });

  const schemaOptions = buildSchemaOptions({ t });
  const appointmentTypeOptions = buildAppointmentTypeOptions({ t });
  const isFifoOptions = createIsFifoOptions(t);

  const isFifoVisible =
    appointmentTypeField?.value?.value ===
      PROCEDURES_APPOINTMENT_TYPES_MAP.FIFO ||
    appointmentTypeField?.value?.value ===
      PROCEDURES_APPOINTMENT_TYPES_MAP.WINDOW;

  return (
    <div className="flex flex-col gap-6">
      <div className="border-b border-solid border-nuvo-gray-dark/10 pb-1">
        <Text fontWeight="bold" color="green" size="large">
          {t(`facility-form-${facilityType}-procedures-title`)}
        </Text>
      </div>

      <div className="flex w-full gap-8">
        <div className="flex-1" data-testid="loading-schema">
          <Dropdown
            label={t(`facility-form-${facilityType}-loading-schema-label`)}
            options={schemaOptions}
            name="loadingScheme"
          />
        </div>
        <div className="flex-1" data-testid="appointment-type">
          <Dropdown
            label={t(`facility-form-${facilityType}-appointment-type-label`)}
            name="appointmentType"
            placeholder={t(
              `facility-form-${facilityType}-appointment-type-placeholder`
            )}
            options={appointmentTypeOptions}
          />
        </div>
      </div>

      {/* fifo + loading time required*/}
      <div className="flex w-full gap-8">
        {isFifoVisible && (
          <div className="flex-1" data-testid="is-fifo-wrapper">
            <Dropdown
              label={t(`facility-form-${facilityType}-is-fifo-label`)}
              placeholder={t(
                `facility-form-${facilityType}-is-fifo-placeholder`
              )}
              name="isFifo"
              options={isFifoOptions}
            />
          </div>
        )}
        <LoadingDuration type={facilityType} />
      </div>
      {isFifoVisible && (
        <div className="flex w-full gap-8">
          <div className="flex-1">
            <TimeSelector
              label={t(`facility-form-${facilityType}-loading-starts-label`)}
              placeholder={t(
                `facility-form-${facilityType}-loading-starts-placeholder`
              )}
              buttonPlaceholder={t(
                `facility-form-loading-${facilityType}-starts-button-placeholder`
              )}
              name="startTime"
            />
          </div>
          <div className="flex-1">
            <TimeSelector
              label={t(`facility-form-${facilityType}-loading-ends-label`)}
              placeholder={t(
                `facility-form-${facilityType}-loading-ends-placeholder`
              )}
              buttonPlaceholder={t(
                `facility-form-${facilityType}-loading-ends-button-placeholder`
              )}
              name="endTime"
            />
          </div>
        </div>
      )}
      <Textarea
        name="facilityInstructions"
        placeholder={t(
          `facility-form-${facilityType}-instructions-label-placeholder`
        )}
        label={t(`facility-form-${facilityType}-instructions-label-label`)}
      />
    </div>
  );
};

export default Procedures;
