import { Text } from '@nuvocargo/nuvo-styleguide';

export const Detail = ({ label, value }) => (
  <div data-testid={label} className="flex flex-col gap-1">
    <Text as="p" size="small" color="steel" fontWeight="bold">
      {label}
    </Text>

    <Text as="p" color="darkGrey">
      {value}
    </Text>
  </div>
);
