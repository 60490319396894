import * as yup from 'yup';

export const initialValues = { password: '', confirmedPassword: '' };

export const validationSchema = ({ t, validatePassword }) =>
  yup.object().shape({
    password: yup
      .string()
      .required(t('valdiation-password-required'))
      .test('password-valdiation', true, validatePassword),
    confirmedPassword: yup
      .string()
      .oneOf([yup.ref('password'), null], t('validation-password-match'))
      .required(t('valdiation-confirm-password-required')),
  });
