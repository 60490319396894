import QueryString from 'qs';
import { useLocation } from 'react-router-dom';
import { stringify } from 'qs';

/**
 * @deprecated use `pbeshai/use-query-params` instead
 * @see https://github.com/pbeshai/use-query-params/tree/master/packages/use-query-params
 */
const useQueryParams = () => {
  const { search } = useLocation();

  const query = QueryString.parse(search, { ignoreQueryPrefix: true });
  return { ...query };
};

export const useQueryParamsString = () => {
  const qp = useQueryParams();

  return stringify(qp);
};

export default useQueryParams;
