export const createAddressString = ({
  addressLine1,
  city,
  state,
  postalCode,
  country,
} = {}) => {
  const maybeJoinString = (originalString, maybeString, finishWith = '') =>
    maybeString
      ? `${originalString}${maybeString}${finishWith}`
      : originalString;

  let result = '';

  result = maybeJoinString(result, addressLine1, '. ');

  if (city && state && postalCode) {
    result = `${result}${city}, ${state} ${postalCode}. `;
  } else if (city && postalCode) {
    result = `${result}${city}, ${postalCode}. `;
  } else if (city && state) {
    result = `${result}${city}, ${state}. `;
  } else if (state && postalCode) {
    result = `${result}${state} ${postalCode}. `;
  } else {
    result = maybeJoinString(result, city, '. ');
    result = maybeJoinString(result, state, '. ');
    result = maybeJoinString(result, postalCode, '. ');
  }

  result = maybeJoinString(result, country);

  return result;
};
