import styled from 'styled-components';
import { Heading, MdIcon, Text } from '@nuvocargo/nuvo-styleguide';
import { formatShortDateTime } from 'core/utils/date-fns';
import { useTranslation } from 'react-i18next';
import { trackClick } from 'core/utils/trackClick';

export function Documents({ shipment }) {
  const { t } = useTranslation();

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <Heading
        color="green"
        styles={{ root: { marginBottom: '16px' } }}
        variant="h6">
        {t('documents')}
      </Heading>
      {shipment.documents?.length > 0 ? (
        shipment.documents.map(doc => {
          return (
            <DocumentRow key={doc.id || doc.document.fileName || doc.name}>
              <MdIcon
                name="MdOutlineInsertDriveFile"
                size="24px"
                color="steel"
              />
              <div className="document-row-text">
                <span>{t(`doc_${doc.category || 'uncategorized'}`)}</span>
                {(doc.document.fileName || doc.name) && (
                  <span>{doc.document.fileName || doc.name}</span>
                )}
              </div>
              <div className="document-row-date">
                {doc.document.createdAt
                  ? formatShortDateTime(doc.document.createdAt)
                  : ''}
              </div>
              <button
                className="document-row-download"
                onClick={() => {
                  window.open(doc.document.url);
                  trackClick(`Download File Link Clicked`, {
                    file_category: doc.category,
                  });
                }}>
                {t('download')}
              </button>
            </DocumentRow>
          );
        })
      ) : (
        <Text size="regular" color="steel">
          {t('no-documents')}
        </Text>
      )}
    </div>
  );
}

const DocumentRow = styled.div`
  display: flex;
  align-items: center;
  padding: 16px 0;
  gap: 16px;
  min-height: 85px;
  box-sizing: border-box;

  :not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.steel};
  }

  &:hover {
    background-color: rgba(64, 64, 64, 0.05);
  }

  > svg {
    color: ${({ theme }) => theme.colors.steel};
    margin-left: 8px;
  }

  .document-row-text {
    font-family: 'value-sans-regular';
    font-weight: 400;
    font-size: 16px;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-items: center;
    gap: 4px;
    line-height: 150%;

    > :first-child {
      font-weight: 500;
      font-family: 'value-sans-medium';
    }
  }

  .document-row-date {
    font-family: 'value-sans-regular';
    font-weight: 400;
    font-size: 14px;
    color: ${({ theme }) => theme.colors.steel};
  }

  .document-row-download {
    font-family: 'value-sans-medium';
    font-weight: 500;
    font-size: 16px;
    color: ${({ theme }) => theme.colors.forest};
    cursor: pointer;
  }
`;
