import * as yup from 'yup';

export const initialValues = { email: '', password: '' };

export const validationSchema = t =>
  yup.object().shape({
    email: yup
      .string()
      .email(t('validation-invalid-email'))
      .required(t('validation-email-required')),
    password: yup.string().required(t('valdiation-password-required')),
  });
