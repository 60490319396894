import React from 'react';
import styled from 'styled-components';

const Radio = ({
  checked,
  name,
  onChange,
  value,
  width = '36px',
  height = '36px',
  ...rest
}) => (
  <StyledRadio
    checked={checked}
    name={name}
    onChange={onChange}
    type="radio"
    value={value}
    width={width}
    height={height}
    {...rest}
  />
);

export default Radio;

const StyledRadio = styled.input`
  appearance: none;
  background: auto;
  border-radius: 50%;
  border: 1px solid ${({ theme }) => theme.colors.darkGrey}40;
  color: currentColor;
  cursor: pointer;
  display: grid;
  font: inherit;
  height: ${({ height }) => height};
  margin: 0;
  outline: none;
  place-content: center;
  width: ${({ width }) => width};

  &:before {
    background-color: ${({ theme }) => theme.colors.forest};
    border-radius: 50%;
    content: '';
    height: calc(${({ height }) => height} - 6px);
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    width: calc(${({ width }) => width} - 6px);
  }

  &:checked:before {
    transform: scale(1);
  }

  &:disabled:checked:before {
    background-color: ${({ theme }) => theme.colors.darkGrey}26;
  }
`;
