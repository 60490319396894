import { gql } from 'graphql-request';

import { client } from 'core/utils/axios.config';

const PLACE_FIELDS_FRAGMENT = gql`
  fragment placeFields on Place {
    zipCode {
      name
      parents {
        country
        city
        state
      }
    }
  }
`;

const GET_SHIPMENTS_QUERY = gql`
  query getShipments(
    $first: Int
    $after: String
    $state: [ShipmentStateEnum!]
    $companyId: String
    $byRepId: Int
    $id: ID
  ) {
    shipments(
      first: $first
      after: $after
      state: $state
      companyId: $companyId
      byRepId: $byRepId
      id: $id
    ) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      nodes {
        id
        company {
          legalName
        }
        state
        status
        progressStatus
        createdAt
        customerReference
        closeToBorder
        origin {
          ...placeFields
        }
        destination {
          ...placeFields
        }
        commodity {
          name
          category
        }
        currentShipmentEvent {
          id
          actualDatetime
          estimatedDatetime
          type
        }
        shipmentEvents {
          edges {
            node {
              id
              actualDatetime
              estimatedDatetime
              type
            }
          }
        }
        currentLeg {
          id
          trailerNumber
          carrier {
            name
          }
          assignee {
            id
            internalRoles
            firstName
            lastName
            email
          }
        }
      }
    }
  }

  ${PLACE_FIELDS_FRAGMENT}
`;

export const getShipmentsRequest = ({
  after,
  byRepId,
  companyId,
  first,
  id,
  signal,
  state,
  ...rest
}) =>
  client
    .gql({
      query: GET_SHIPMENTS_QUERY,
      variables: {
        after,
        byRepId,
        companyId,
        first,
        id,
        state,
        ...rest,
      },
    })
    .then(r => r?.data?.data ?? {});

const GET_CUSTOMS_SHIPMENTS_QUERY = gql`
  query getShipments(
    $first: Int
    $after: String
    $state: [ShipmentStateEnum!]
    $id: ID
    $withCustoms: Boolean
  ) {
    shipments(
      first: $first
      after: $after
      state: $state
      id: $id
      withCustoms: $withCustoms
    ) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      nodes {
        id
        company {
          legalName
        }
        state
        status
        progressStatus
        createdAt
        customerReference
        closeToBorder
        origin {
          ...placeFields
        }
        destination {
          ...placeFields
        }
        commodity {
          name
          category
        }
        currentShipmentEvent {
          id
          actualDatetime
          estimatedDatetime
          type
        }
        shipmentEvents {
          edges {
            node {
              id
              actualDatetime
              estimatedDatetime
              type
            }
          }
        }
        currentLeg {
          id
          trailerNumber
          carrier {
            name
          }
          assignee {
            id
            internalRoles
            firstName
            lastName
            email
          }
        }
        attachments {
          id
          category
          name
          createdAt
        }
      }
    }
  }

  ${PLACE_FIELDS_FRAGMENT}
`;

export const getCustomsShipmentsRequest = ({
  after,
  first,
  id,
  signal,
  state,
  ...rest
}) =>
  client
    .gql({
      query: GET_CUSTOMS_SHIPMENTS_QUERY,
      variables: {
        after,
        first,
        id,
        state,
        withCustoms: true,
      },
    })
    .then(r => r?.data?.data ?? {});
