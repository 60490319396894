import { useQuery, useMutation, useQueryClient } from 'react-query';
import { gql } from 'graphql-request';

import { getCustomsShipmentsRequest } from 'core/graphql/queries/shipments';
import { client } from 'core/utils/axios.config';

export const useGetCustomsShipments = (
  { after, first, id, state = 'active' },
  queryOptions
) =>
  useQuery(
    ['shipments', state, id, first, after],
    () =>
      getCustomsShipmentsRequest({
        after,
        first,
        id,
        state,
      }),
    queryOptions
  );

export const useRequestCustomsDocuments = onSuccessCallback => {
  const queryClient = useQueryClient();

  return useMutation(body => requestCustomsDocuments(body), {
    onSuccess: shipment => {
      onSuccessCallback(shipment);
      return queryClient.invalidateQueries(['documents']);
    },
  });
};

const REQUEST_CUSTOMS_DOCUMENTS_MUTATION = gql`
  mutation ($id: ID!, $requestedDocuments: [DocumentEnum!]!) {
    requestCustomsDocuments(
      input: { id: $id, requestedDocuments: $requestedDocuments }
    ) {
      shipment {
        id
        documentsRequestedAt
      }
    }
  }
`;

const requestCustomsDocuments = ({ id, requestedDocuments }) => {
  return client
    .gql({
      query: REQUEST_CUSTOMS_DOCUMENTS_MUTATION,
      variables: {
        id,
        requestedDocuments,
      },
    })
    .then(r => r.data?.data?.requestCustomsDocuments?.shipment);
};
