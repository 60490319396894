import { Stack } from 'components/generic/kit';
import { useTranslation } from 'react-i18next';
import InputTable from '@nuvocargo/nuvo-styleguide/InputTable';
import { CTAButton, Heading, Icon, Text } from '@nuvocargo/nuvo-styleguide';
import BackFormButton from '../../components/BackFormButton';
import { useReviewPublishTable } from './hooks';
import { pricingRequestStyles } from 'screens/pricing-request/pricingRequest.styles';
import { ResizeContainerDiv } from 'screens/pricing-request/pricingRequest.styles';

export const ReviewPublish = ({
  state: { legQuotes, routes, details },
  dispatch,
}) => {
  const { serviceType } = details;
  const { t } = useTranslation();
  const { columns, FeesText, publishRoutes } = useReviewPublishTable({
    legQuotes,
    routes,
    dispatch,
    serviceType,
  });

  if (!columns || !legQuotes || !routes) {
    return <></>;
  }

  return (
    <ResizeContainerDiv>
      <Stack style={pricingRequestStyles.title}>
        <Heading variant="h3">
          {t('pricing-request-review-and-publish-title')}
        </Heading>
      </Stack>
      <Stack style={pricingRequestStyles.text}>
        <Heading variant="h5">
          {t('pricing-request-review-and-publish-subtitle')}
        </Heading>
      </Stack>
      <InputTable columns={columns} columnIdentifier="id" data={routes || []} />
      {FeesText && (
        <div
          style={{
            display: 'flex',
            marginLeft: 10,
            marginTop: 24,
            gap: 8,
          }}>
          <Icon size="small" name="error" color="darkGrey" />
          <div>
            <Text size="small">{t('pricing-crossing-fees-title')} </Text>
            <Text data-testid="crossing-fee-text" size="small">
              {FeesText}
            </Text>
          </div>
        </div>
      )}
      <div style={pricingRequestStyles.buttonContainer}>
        <BackFormButton goBack />
        <CTAButton
          onClick={publishRoutes}
          icon="keyboardArrowRight"
          iconColor="white"
          colorType="primary">
          {t('pricing-request-review-and-publish-next-button')}
        </CTAButton>
      </div>
    </ResizeContainerDiv>
  );
};
