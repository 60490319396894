import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import {
  Login,
  MagicLinkLogin,
  LinkSent,
  ForgotPassword,
  ResetPassword,
} from 'screens/';
import { auth } from './paths';

export default function UnauthenticatedRoutes({ backUrl }) {
  return (
    <Switch>
      <Route path={auth.resetPassword}>
        <ResetPassword />
      </Route>
      <Route path={auth.forgotPassword}>
        <ForgotPassword />
      </Route>
      <Route path={auth.login}>
        <Login />
      </Route>

      <Route path="/auth/link">
        <MagicLinkLogin />
      </Route>
      <Route path="/auth/link-sent">
        <LinkSent />
      </Route>
      <Route path="*">
        <Redirect to={`${auth.login}${backUrl ? backUrl : ''}`} />
      </Route>
    </Switch>
  );
}
