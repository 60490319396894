import { useQuery } from 'react-query';

import { gql } from 'graphql-request';
import { client } from 'core/utils/axios.config';

const GET_COMPANIES_QUERY = gql`
  query GetCompanies(
    $businessType: CompanyBusinessTypeEnum
    $childrenCompanies: Int
    $withRequestIdentifiers: Boolean
  ) {
    companies(
      businessType: $businessType
      childrenCompanies: $childrenCompanies
      withRequestIdentifiers: $withRequestIdentifiers
    ) {
      nodes {
        id
        legalName
      }
    }
  }
`;

export const getCompanies = variables =>
  client
    .gql({
      query: GET_COMPANIES_QUERY,
      variables,
    })
    .then(r => r?.data?.data?.companies.nodes ?? []);

export const useCompanies = (
  { businessType, childrenCompanies, withRequestIdentifiers },
  queryOptions
) =>
  useQuery(
    'companies',
    () =>
      getCompanies({
        businessType,
        childrenCompanies,
        withRequestIdentifiers,
      }),
    queryOptions
  );
