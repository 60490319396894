import { CTAButton } from '@nuvocargo/nuvo-styleguide';
import { useFlags } from 'core/hooks';
import { useTranslation } from 'react-i18next';
import { useQuoteRequest } from 'screens/quote-request/hooks';

const COP_FEATURE_FLAG_NAME = 'cop_10_05_2023_enable_customer_module';

export default function CopSuccessButtons() {
  const { t } = useTranslation();
  const { isLoading, data } = useFlags(COP_FEATURE_FLAG_NAME);
  const {
    companyId,
    hubspot: { companyId: hubspotCompanyId },
  } = useQuoteRequest();

  const cop_url = companyId
    ? `${process.env.REACT_APP_API_URL}/customers/${companyId}/overview`
    : '';
  const hubspotUrl = hubspotCompanyId
    ? `https://app.hubspot.com/contacts/${process.env.REACT_APP_HUBSPOT_CONTACT_ID}/company/${hubspotCompanyId}`
    : '';

  if (isLoading) return null;
  if (!companyId || !hubspotCompanyId) return null;

  const { enabled: isCopEnabled } =
    data.features.cop_10_05_2023_enable_customer_module;
  return (
    <>
      {isCopEnabled && (
        <div className="flex gap-8" data-testid="copBackButtons">
          <CTAButton
            isLink
            href={hubspotUrl}
            styles={{
              primary: {
                marginTop: '32px',
                width: '200px',
                height: '64px',
              },
            }}>
            {t('back-to-hubspot')}
          </CTAButton>
          <CTAButton
            isLink
            href={cop_url}
            styles={{
              primary: {
                marginTop: '32px',
                width: '200px',
                height: '64px',
              },
            }}>
            {t('view-cop-url')}
          </CTAButton>
        </div>
      )}
    </>
  );
}
