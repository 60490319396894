import * as yup from 'yup';

export const initialValues = { loadsPerMonth: '' };

export const validationSchema = ({ t }) =>
  yup.object().shape({
    loadsPerMonth: yup
      .number()
      .positive(t('validation-loads-per-month-number'))
      .min(1, t('validation-loads-per-month-number'))
      .required(t('validation-loads-per-month-number')),
  });
