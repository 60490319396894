import { Provider as FeaturesProvider, useFeatures } from './features';
import { Provider as UserProvider, useUser } from './user';

export default function Context({ children, featureValues = {} }) {
  return (
    <UserProvider>
      <FeaturesProvider featureValues={featureValues}>
        {children}
      </FeaturesProvider>
    </UserProvider>
  );
}

export { useFeatures, useUser };
