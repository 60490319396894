import { Text } from '@nuvocargo/nuvo-styleguide';
import { SmallDetails } from 'components/generic/kit';
import { formatShortDateTime } from 'core/utils/date-fns';
import { isNil } from 'ramda';
import { useTranslation } from 'react-i18next';
import { theme } from 'styles';

export default function UpcomingEventCol({ cell }) {
  const { t } = useTranslation();
  if (isNil(cell.value?.estimatedDatetime)) {
    return <Text color={theme.colors.silver}>{t('general-pending-text')}</Text>;
  } else {
    return (
      <SmallDetails
        top={t(cell?.value.type ?? 'missing-event-type')}
        down={`${formatShortDateTime(cell.value?.estimatedDatetime)}`}
      />
    );
  }
}
