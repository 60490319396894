import React from 'react';
import styled, { css } from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import { useBannerEvents } from 'core/hooks/useBanner';

export default function Banner() {
  const { bannersState } = useBannerEvents();

  return (
    <AnimatePresence>
      {bannersState.map(({ children, key }) => (
        <ImpersonationBanner
          key={key}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0, height: 0 }}
          transition={{
            duration: 0.6,
            ease: 'easeOut',
          }}>
          {typeof children === 'function' ? children() : children}
        </ImpersonationBanner>
      ))}
    </AnimatePresence>
  );
}

const ImpersonationBanner = styled(motion.div)`
  position: relative;
  z-index: 2;
  height: 64px;
  background-color: #1b473f;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 50px 0 50px;
  .side {
    display: flex;
    align-items: center;
  }
  ${({ theme }) =>
    css`
      @media only screen and (max-width: ${theme.mediaSizes.sm}) {
        padding: 0 20px 0 20px;
      }
    `}
`;
