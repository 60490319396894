/* eslint-disable react/display-name */
import { TableWithFilters } from './components/Table';
import { useUser } from 'context';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useShipments, useShipmentsSidebar } from './hooks';
import ShipmentDetailsScreen from './shipment-details';
import useCreateColumns from './useCreateColumns';
import createStateOptions from './createStateOptions';

export default function Customs() {
  const { t } = useTranslation();
  const {
    fileName,
    filters,
    isLoading,
    onFilterChange,
    page,
    selected,
    setFileName,
    setState,
    shipments,
    state,
    totalPages,
  } = useShipments();
  const { currentRole } = useUser();
  const { shipmentId, updateShipmentsSidebar } = useShipmentsSidebar();
  const columns = useCreateColumns({ t, currentRole, state });
  const statusOptions = createStateOptions({ t, currentRole });
  return (
    <>
      <TableWithFilters
        columns={columns}
        data={shipments}
        filters={filters}
        isLoading={isLoading}
        onFilterChange={onFilterChange}
        page={page}
        searchId="track-and-trace-search"
        searchOnChange={setFileName}
        searchPlaceholder={t('track-and-trace-search')}
        searchValue={fileName}
        selected={selected}
        selectedStatus={state}
        statusOnChange={setState}
        statusOptions={statusOptions}
        tableTitle={'Customs'}
        totalPages={totalPages}
      />

      <ShipmentDetailsScreen
        shipmentId={shipmentId}
        closeShipmentDetailsView={() =>
          updateShipmentsSidebar({ remove: true })
        }
      />
    </>
  );
}
