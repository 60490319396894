/* eslint-disable react/display-name */
import { Text, Eyebrow } from '@nuvocargo/nuvo-styleguide';
import {
  SmallDetails as TableCell,
  SmallRoute as RouteCell,
  Status,
} from 'components/generic/kit';
import { LinkCell } from 'components/common/app';
import { isCustomerSuccess } from 'core/utils/roles';
import React from 'react';
import { isNil } from 'ramda';
import { Icon } from '@nuvocargo/nuvo-styleguide';
import { theme } from 'styles';
import {
  CarrierColumnContent,
  CarrierColumnHeader,
} from './components/CarrierColumn';
import UpcomingEventCol from './components/UpcomingEventCol';
import { buildShipmentDetailsLink, graphQLCountryToFlagString } from './utils';

const useCreateColumns = ({ t, currentRole, state, qp }) => {
  let columns = React.useMemo(
    () => [
      {
        Header: () => {
          return (
            <Eyebrow
              text={t('general-shipment-id-text-label')}
              size="small"
              color="darkGrey"
              style={{
                whiteSpace: 'nowrap',
              }}
            />
          );
        },
        accessor: 'id',
        Cell: row => (
          <Text
            styles={{
              regular: {
                fontWeight: 'bold',
              },
            }}>{`SH-${row.cell.value}`}</Text>
        ),
        shrinkToContent: true,
      },
      {
        Header: () => {
          return (
            <Eyebrow
              text={t('general-company-text-label')}
              size="small"
              color="grey"
            />
          );
        },
        accessor: 'company',
        Cell: ({ row, cell }) => (
          <TableCell
            top={cell.value?.name}
            topStyles={{
              regular: {
                width: '100%',
                [`@media ${theme.device.laptopL}`]: {
                  width: '200px',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                },
              },
            }}
            down={row.original?.customerReference ?? ''}
          />
        ),
      },
      {
        Header: () => {
          return (
            <Eyebrow
              text={t('general-route-text-label')}
              size="small"
              color="grey"
            />
          );
        },
        accessor: 'origin',
        Cell: ({ row }) => {
          return isNil(row.original.origin) ||
            isNil(row.original.destination) ? (
            <Text color="red">{t('invalid-route')}</Text>
          ) : (
            <RouteCell
              origin={{
                country: graphQLCountryToFlagString(
                  row.original?.origin?.zipCode?.parents?.country
                ),
                city: row.original?.origin?.zipCode?.parents?.city,
                name: row.original?.origin?.zipCode?.name,
              }}
              destination={{
                city: row.original?.destination?.zipCode?.parents?.city,
              }}
            />
          );
        },
      },
      {
        Header: () => <CarrierColumnHeader />,
        accessor: 'currentLeg',
        Cell: ({ cell }) => <CarrierColumnContent cell={cell} />,
        shrinkToContent: true,
      },
      {
        Header: () => {
          return (
            <Eyebrow
              text={t('general-progress-text-label')}
              size="small"
              color="grey"
            />
          );
        },
        accessor: 'status',
        Cell: row => <Status size="large" slug={row.cell.value} />,
        shrinkToContent: true,
      },
      {
        Header: () => {
          return (
            <Eyebrow text={t('upcoming-event')} size="small" color="grey" />
          );
        },
        accessor: 'currentShipmentEvent',
        Cell: ({ cell }) => {
          return <UpcomingEventCol cell={cell} />;
        },
        shrinkToContent: true,
      },
      {
        id: 'link-to-individual-shipment',
        accessor: 'id',
        Cell: ({ cell: { value: id } }) => (
          <LinkCell
            className="TrackAndTrace__link-cell"
            to={buildShipmentDetailsLink(
              {
                ...qp,
                shipmentId: id,
              },
              isCustomerSuccess(currentRole) && state === 'requested'
                ? 'configuration'
                : 'overview'
            )}>
            <Icon name="keyboardArrowRight" color="forest" size="medium" />
          </LinkCell>
        ),
        isLink: true,
      },
    ],
    [t, currentRole, state, qp]
  );

  columns =
    state === 'COMPLETED'
      ? columns.filter(col => col.accessor !== 'currentShipmentEvent')
      : columns;

  return columns;
};

export default useCreateColumns;
