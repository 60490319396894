import { useEffect } from 'react';
import { Stack } from 'components/generic/kit';
import { useTranslation } from 'react-i18next';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import { Heading, Text } from '@nuvocargo/nuvo-styleguide';
import NextFormButton from '../../components/NextFormButton';
import { Formik, Form } from 'formik';
import { app } from 'routes/paths';
import useLegCosts from './useLegCosts';
import { ACTIONS_TYPES } from '../../store/reducer';
import { updateLegQuoteDataFromUserBaseRate } from '../../store/utils';
import { validationSchema } from './data';
import LegQuotesTable from './legQuotesTable';
import BackFormButton from '../../components/BackFormButton';
import { isDirectService } from 'core/utils';
import {
  pricingRequestStyles,
  ResizeContainerDiv,
} from 'screens/pricing-request/pricingRequest.styles';
import { useQueryClient } from 'react-query';

const LegCosts = ({ state, dispatch }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { id: requestId } = useParams();
  const queryClient = useQueryClient();
  const {
    legQuotes,
    details: { serviceType },
  } = state;
  const { columns } = useLegCosts();

  useEffect(() => {
    queryClient.invalidateQueries(['legQuotesRequests', requestId]);
  }, []);

  const onSubmit = ({ legQuotes }) => {
    dispatch({
      type: ACTIONS_TYPES.SET_LEG_QUOTES_FROM_USER,
      payload: updateLegQuoteDataFromUserBaseRate(legQuotes),
    });
    const path = isDirectService(serviceType)
      ? app.pricingRequestsDirectServicePremium
      : app.pricingRequestsLegCostsDetails;
    history.push(
      generatePath(path, {
        id: requestId,
      })
    );
  };

  if (!columns || !legQuotes) {
    return <></>;
  }

  return (
    <ResizeContainerDiv>
      <Stack style={pricingRequestStyles.title}>
        <Heading variant="h3">{t('leg-costs-title')}</Heading>
      </Stack>
      <Stack style={pricingRequestStyles.subtitle}>
        <Heading variant="h5">{t('leg-costs-subtitle')}</Heading>
      </Stack>
      <Stack style={pricingRequestStyles.text}>
        <Text color="steel">{t('leg-costs-text-label')}</Text>
      </Stack>
      <Formik
        enableReinitialize
        validationSchema={validationSchema(t)}
        initialValues={{
          legQuotes,
        }}
        onSubmit={onSubmit}>
        <Form>
          <LegQuotesTable columns={columns} />
          <div style={pricingRequestStyles.buttonContainer}>
            <BackFormButton
              route={generatePath(app.pricingRequestsServiceability, {
                id: requestId,
              })}
            />
            <NextFormButton />
          </div>
        </Form>
      </Formik>
    </ResizeContainerDiv>
  );
};

export default LegCosts;
