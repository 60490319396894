// React & Friends (Formik, React Router Dom, etc...)
import { useState, useCallback } from 'react';
import { useFormikContext } from 'formik';

// Utils
import { debounce, values } from 'lodash';
import { useTranslation } from 'react-i18next';

// UI & UX
import Input from '@nuvocargo/nuvo-styleguide/forms/native/Input';
import { Stagger } from 'components/generic/kit';

// Hooks
import { useCompanies } from '../../../core/hooks';

function CompaniesSelectField({ disabled, onChange }) {
  const { setFieldValue, errors } = useFormikContext();
  const [companiesQuery, setCompaniesQuery, isError] = useState('');
  const { t } = useTranslation();
  const { companies, isLoading } = useCompanies({
    query: companiesQuery,
    enabled: !!companiesQuery,
  });

  const setQuery = text => {
    setCompaniesQuery(text);
  };

  const handleQuery = useCallback(debounce(setQuery, 350), []);

  const error = () => {
    if (isError) return 'shipment-form-company-api-error';

    return errors.company || '';
  };

  return (
    <Row>
      <Input
        autocomplete
        asyncMode={false}
        isClearable={true}
        isLoading={isLoading}
        disabled={disabled}
        noOptionsMessage={() => t('no-companies-found', 'No companies found')}
        placeholder={t(
          'start-typing-search-company',
          'Start typing to search for a company'
        )}
        label={t('shipment-form-company-label')}
        name="company"
        error={error()}
        options={companies}
        value={values.company}
        onChange={data => {
          onChange && onChange(data);
          setFieldValue('company', data || '');
        }}
        onInputChange={text => {
          handleQuery(text);
        }}
      />
    </Row>
  );
}

export default CompaniesSelectField;

export const Row = ({ children, style, ...props }) => (
  <Stagger.Child
    style={{
      width: '100%',
      marginBottom: '36px',
      ...style,
    }}
    {...props}>
    {children}
  </Stagger.Child>
);
