import { centsToDollar, formatMoney } from './lib';

export const REQUIREMENTS_MAP = {
  fumigation: 'Fumigation',
  straps: 'Straps',
  hazmat: 'Hazmat',
  // team_driver: 'Team Driver',
};

export const COMMODITIES_SPECIAL_REQUIREMENTS = {
  fumigation: 'fumigation',
  straps: 'straps',
};

export const isFumigationAccessorial = (object, property = 'id') => {
  return object[property] === COMMODITIES_SPECIAL_REQUIREMENTS.fumigation;
};

export const isStrapsAccessorial = (object, property = 'id') => {
  return object[property] === COMMODITIES_SPECIAL_REQUIREMENTS.straps;
};

export const getAccessorialByName = (accessorials, name) =>
  accessorials.find(accessorial => accessorial.slug === name);

export const getFumigationCost = accessorials => {
  const fumigationObject = getAccessorialByName(
    accessorials,
    COMMODITIES_SPECIAL_REQUIREMENTS.fumigation
  );
  return formatMoney(centsToDollar(fumigationObject.schemaArgs[0]));
};

export const getStrapCost = accessorials => {
  const strapObject = getAccessorialByName(
    accessorials,
    COMMODITIES_SPECIAL_REQUIREMENTS.straps
  );
  return formatMoney(centsToDollar(strapObject.schemaArgs[1]));
};

export const commodityCategoryOptions = t => [
  { label: t('commodity-food-perishable'), value: 'food_perishable' },
  { label: t('commodity-food-non-perishable'), value: 'food_non_perishable' },
  {
    label: t('commodity-industrial-material'),
    value: 'industrial_material',
  },
  {
    label: t('commodity-construction-materials'),
    value: 'construction_materials',
  },
  { label: t('commodity-chemicals-hazmat'), value: 'chemicals_hazmat' },
  {
    label: t('commodity-chemicals-non-hazmat'),
    value: 'chemicals_non_hazmat',
  },
  { label: t('commodity-alcohol-and-spirits'), value: 'alcohol' },
  { label: t('commodity-electronics'), value: 'electronics' },
  { label: t('commodity-automotive'), value: 'automotive' },
  { label: t('commodity-other'), value: 'other' },
];
