// @Todo distance change to miles directly
export const LEG_QUOTES_QUERY = `
  query legQuotes(
    $requestIdentifier: String
  ) { 
  legQuotes(requestIdentifier: $requestIdentifier) {
    edges {
      node {
        id
        volume
        routeIds
        distance
        marginCents
        baseRateCents
        expirationDate
        directPremiumCostCents
        fuelCostCents
        origin {
          name
          parents {
            city
            country
            state
          }
        }
        destination {
          name
          parents {
            city
            country
            state
          }
        }
        accessorials {
          slug
          totalCostCents
        }
      }
    }
  }
}
`;
