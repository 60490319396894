import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { formatShortDateTime } from 'core/utils/date-fns';
import { useTranslation } from 'react-i18next';
import { Stack } from 'components/generic/kit';
import { usePublishedDetailsTable } from './hooks';
import InputTable from '@nuvocargo/nuvo-styleguide/InputTable';
import { CTAButton, Heading, Text } from '@nuvocargo/nuvo-styleguide';
import {
  pricingRequestStyles,
  ResizeContainerDiv,
} from 'screens/pricing-request/pricingRequest.styles';
import { usePageHeader } from '@nuvocargo/nuvo-styleguide/Navigation';
import { app } from 'routes';

export const PublishedDetails = ({ state: { routes }, dispatch }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { columns, goTo } = usePublishedDetailsTable({
    routes,
    dispatch,
  });
  const { showSubnav } = usePageHeader();

  if (!columns || !routes) {
    return <></>;
  }

  useEffect(() => {
    showSubnav({
      text: t('back-to-pricing-requests'),
      onGoBackClick: () => history.push(app.pricingRequests),
    });
  }, []);

  return (
    <ResizeContainerDiv>
      <Stack axis="horizontal" style={pricingRequestStyles.titleButton}>
        <Heading variant="h3">{t('published-details-title')}</Heading>
        <CTAButton onClick={goTo} colorType="secondary">
          {t('published-edit-text-label')}
        </CTAButton>
      </Stack>
      <Stack style={pricingRequestStyles.text}>
        <Text color="steel">
          {t('published-details-update-text-label')}{' '}
          {routes[0]?.updatedAt && formatShortDateTime(routes[0]?.updatedAt)}{' '}
          {t('published-details-by-text-label')}{' '}
          {routes[0]?.pricingAssignee?.name}
        </Text>
      </Stack>
      <InputTable columns={columns} data={routes || []} columnIdentifier="id" />
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: 52,
        }}></div>
    </ResizeContainerDiv>
  );
};
