import { useUser } from 'context';
import { stringify } from 'qs';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import {
  useQueryParam,
  NumberParam,
  StringParam,
  withDefault,
} from 'use-query-params';
import { useDebouncedValue } from '@mantine/hooks';
import { useGetShipments, useCompanies, useGetUsers } from 'core/api';
import { useQueryParams } from 'core/hooks';
import { isCustomerSuccess, createFilters } from 'core/utils';
import {
  calculateShipmentStatusColumnValue,
  createGraphQLCursorParams,
  calculateTotalPagesFromGraphQL,
} from './utils';

const PER_PAGE_COUNT = 20;

export const useShipments = () => {
  const { t } = useTranslation();
  const { currentRole } = useUser();
  const roleSlug = isCustomerSuccess(currentRole)
    ? 'customer_success'
    : 'carrier_representative';

  const [assigneeId, setAssigneeId] = useQueryParam(
    'assigneeId',
    withDefault(StringParam, 'all')
  );
  const [companyId, setCompanyId] = useQueryParam(
    'companyId',
    withDefault(StringParam, 'all')
  );
  const [fileName, setFileName] = useQueryParam(
    'fileName',
    withDefault(StringParam, '')
  );
  const [page, setPage] = useQueryParam('page', withDefault(NumberParam, 1));
  const [state, setState] = useQueryParam(
    'state',
    withDefault(StringParam, 'ACTIVE')
  );

  // delayedly trigger getShipments query if fileName changes
  const [delayedFileName] = useDebouncedValue(fileName, 500);

  const { data, isLoading } = useGetShipments(
    {
      ...createGraphQLCursorParams(page - 1, PER_PAGE_COUNT),
      byRepId: assigneeId === 'all' ? undefined : Number(assigneeId),
      companyId: companyId === 'all' ? undefined : companyId,
      id: !delayedFileName ? undefined : delayedFileName,
      state,
    },
    {
      select: data => ({
        shipments: data.shipments.nodes.map(shipment => ({
          ...shipment,
          company: {
            ...shipment?.company,
            name: shipment.company?.legalName,
          },
          status: calculateShipmentStatusColumnValue({
            state: shipment.state,
            progressStatus: shipment.progressStatus,
          }),
        })),
        totalPages: calculateTotalPagesFromGraphQL(
          data.shipments.totalCount,
          PER_PAGE_COUNT
        ),
      }),
    }
  );

  const { data: companies } = useCompanies(
    {
      businessType: 'CUSTOMER',
    },
    {
      select: data =>
        data.map(({ id, legalName }) => ({
          value: String(id),
          label: legalName,
        })),
    }
  );

  const { data: users } = useGetUsers(
    {
      internalRole: roleSlug,
    },
    {
      select: data =>
        data.users.nodes.map(({ id, displayName, email }) => ({
          value: String(id),
          label: displayName || email,
        })),
    }
  );

  return {
    fileName,
    isLoading,
    page,
    setPage,
    setFileName,
    setState,
    shipments: data?.shipments ?? [],
    state,
    totalPages: data?.totalPages ?? 1,
    ...createFilters([
      {
        field: 'assigneeId',
        items: [
          {
            label: t('filter-label-assignees'),
            value: 'all',
          },
          ...(users ?? []),
        ],
        set: setAssigneeId,
        value: assigneeId,
      },
      {
        field: 'companyId',
        items: [
          {
            label: t('filter-label-companies'),
            value: 'all',
          },
          ...(companies ?? []),
        ],
        set: setCompanyId,
        value: companyId,
      },
    ]),
  };
};

export const useShipmentsSidebar = () => {
  const {
    shipmentId: urlShipmentId,
    shipmentDetailsView: urlShipmentDetailsView,
    ...qp
  } = useQueryParams();
  const history = useHistory();
  const updateShipmentsSidebar = ({
    shipmentId = urlShipmentId,
    shipmentDetailsView = urlShipmentDetailsView,
    remove = false,
  }) => {
    let update = {};

    if (!remove) {
      update = {
        shipmentId,
        shipmentDetailsView,
      };
    }

    history.push({
      search: stringify(
        {
          ...qp,
          ...update,
        },
        { encode: false }
      ),
    });
  };

  return {
    shipmentId: urlShipmentId,
    shipmentDetailsView: urlShipmentDetailsView,
    updateShipmentsSidebar,
    qp,
  };
};
