import React from 'react';
import { Box } from 'components/generic/kit';
import { Icon } from '@nuvocargo/nuvo-styleguide';
import { AnimatePresence, motion } from 'framer-motion';

export default function Modal({
  isOpen,
  onClose,
  containerStyle = {},
  children,
}) {
  return (
    <AnimatePresence>
      {isOpen ? (
        <Box
          as={motion.div}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          styles={{
            root: {
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              height: '100vh',
              position: 'fixed',
              top: '0%',
              left: '0%',
              zIndex: 99999,
              backgroundColor: 'rgba(0,0,0,0.1)',
            },
          }}>
          <Box
            as={motion.div}
            initial={{ y: -50 }}
            animate={{ y: 0 }}
            transition={{ duration: 0.3, ease: 'easeOut' }}
            styles={{
              root: {
                boxSizing: 'border-box',
                position: 'relative',
                backgroundColor: '#fff',
                borderRadius: '8px',
                maxHeight: '90vh',
                overflowY: 'scroll',
                boxShadow: '0px 2px 15px 0px rgba(0, 0, 0, 0.07)',
                padding: '88px 113px 66px',
                ...containerStyle,
              },
            }}>
            <Box
              styles={{
                root: {
                  position: 'absolute',
                  top: '24px',
                  right: '24px',
                  '&:hover': {
                    cursor: 'pointer',
                  },
                },
              }}
              onClick={onClose}>
              <Icon name="close" color="forest" />
            </Box>
            {children}
          </Box>
        </Box>
      ) : null}
    </AnimatePresence>
  );
}
