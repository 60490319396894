import {
  CTAButton,
  Eyebrow,
  Heading,
  Icon,
  Tag,
  Text,
} from '@nuvocargo/nuvo-styleguide';
import { countryShortNameToFlag } from '@nuvocargo/nuvo-styleguide/lib/utils';
import { useTranslation } from 'react-i18next';

import { formatAddressOneLine } from 'core/utils/routeUtils';
import {
  PROCEDURES_RECEIVING_DAYS_MAP,
  PROCEDURES_APPOINTMENT_TYPES_MAP,
  PROCEDURES_LOADING_SCHEMA_MAP,
} from 'core/utils/route-facility';
import { twentyFourStringToTwelve } from 'components/generic/form/formik/TimeSelector/TimeSelector';

const Configured = ({ place, label, buttonOnClick }) => {
  const { t } = useTranslation();
  const {
    name,
    facilityProcedure,
    zipCode: {
      parents: { country },
    },
  } = place;

  const facilityName = name;
  const facilityAddress = formatAddressOneLine(place);

  const receivingDays = PROCEDURES_RECEIVING_DAYS_MAP[
    facilityProcedure?.receivingDays
  ]
    ? t(`procedures-receiving-days-${facilityProcedure.receivingDays}`)
    : t('procedures-not-available');

  const isValidLoadingScheme =
    !!PROCEDURES_LOADING_SCHEMA_MAP[facilityProcedure?.loadingScheme];

  const isValidAppointmentType =
    !!PROCEDURES_APPOINTMENT_TYPES_MAP[facilityProcedure?.appointmentType];

  return (
    <>
      <div>
        <Icon name={countryShortNameToFlag(country)} size="small" />
      </div>
      <div className="relative flex w-full flex-col">
        <div className="flex w-full justify-between">
          <div>
            <Eyebrow size="small" color="steel" text={label} />

            <Heading variant="h6" color="green">
              {facilityName}
            </Heading>
          </div>

          <CTAButton colorType="secondary" onClick={buttonOnClick}>
            {t('facility-button-edit')}
          </CTAButton>
        </div>
        <Text color="steel">{facilityAddress}</Text>
        <div
          className="mt-6 bg-nuvo-gray/[.03] p-6"
          data-testid="procedures-information">
          <div className="flex flex-row">
            {/*procedues*/}
            <div className="flex flex-1 flex-col">
              <Eyebrow
                size="extraSmall"
                color="steel"
                text={t('procedures-section-title')}
              />
              {facilityProcedure ? (
                <>
                  <div className="mt-2 flex flex-row gap-2">
                    {/*@todo: add this icon to the styleguide*/}
                    <Icon name="MdOutlineWatchLater" color="darkGrey" />
                    <div className="flex flex-1 flex-col">
                      <Text>
                        {twentyFourStringToTwelve(facilityProcedure.startTime)}{' '}
                        - {twentyFourStringToTwelve(facilityProcedure.endTime)}
                      </Text>
                      <Text size="small" color="steel">
                        {receivingDays}
                      </Text>
                    </div>
                  </div>

                  <div className="mt-2 flex gap-2">
                    {isValidLoadingScheme && (
                      <Tag>
                        {t(
                          `procedures-schema-${facilityProcedure.loadingScheme}-option`
                        )}
                      </Tag>
                    )}
                    {isValidAppointmentType && (
                      <Tag>
                        {t(
                          `procedures-appointment-type-${facilityProcedure.appointmentType}-option`
                        )}
                      </Tag>
                    )}
                  </div>
                </>
              ) : (
                <Text>{t('procedures-not-available')}</Text>
              )}
            </div>
            {/*contact*/}
            <div className="flex flex-1 flex-col">
              <Eyebrow
                size="extraSmall"
                color="steel"
                text={t('facility-contact-section-title')}
              />
              <Text className="mt-2">{place.contactName}</Text>
              <Text size="small" color="steel">
                {place.contactTelephone}
              </Text>
            </div>
          </div>
          {/*protocol*/}
          {facilityProcedure && (
            <div className="mt-6 flex flex-col gap-2">
              <Eyebrow
                size="extraSmall"
                color="steel"
                text={t('procedures-instructions-section-title')}
              />
              <Text size="small" color="darkGrey">
                {facilityProcedure.facilityInstructions}
              </Text>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Configured;
