import React from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from '@nuvocargo/nuvo-styleguide';
import { Flex, Spinner } from 'components/generic/kit';
import { Stack } from 'components/generic/kit';
import { useExistingPlace, RADIO_OPTIONS } from './hooks';
import Radio from './Radio';
import { ClickableDataContainer } from './styles';
import Header from '../Header';
import FooterButtons from '../FooterButtons';
import { TextField } from '@nuvocargo/nuvo-styleguide/forms/native';

const RADIO_WIDTH = '36px';
const INPUT_LEFT_MARGIN = '16px';

const SelectExistingPlace = ({
  addNewData,
  companyId,
  isOrigin,
  location,
  selectExistingPlace,
  handleBackButtonClick,
}) => {
  const { t } = useTranslation();
  const {
    places,
    isLoading,
    selectOption,
    setSelectAddNewPlace,
    setSelectExistingPlace,
    selectedPlace,
    setSelectedPlace,
  } = useExistingPlace({
    companyId,
    geolocationId: location?.geolocationId,
    addNewData,
  });
  return isLoading ? (
    <>
      <Header isOrigin={isOrigin} city={location?.city} state={location?.state}>
        {t('route-add-location-places-loading')}
      </Header>
      <Flex
        styles={{
          root: {
            width: '100%',
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            flex: 1,
          },
        }}>
        <Spinner />
      </Flex>
    </>
  ) : (
    <div style={{ width: '100%' }}>
      <Header isOrigin={isOrigin} city={location?.city} state={location?.state}>
        {t('route-add-location-existing-location-found')}
      </Header>

      <ClickableDataContainer onClick={setSelectExistingPlace} tabIndex="0">
        <Stack
          axis="horizontal"
          styles={{
            root: { width: '100%' },
          }}>
          <Radio
            name="select-place-options"
            checked={selectOption === RADIO_OPTIONS.selectExistingPlace}
            value={RADIO_OPTIONS.selectExistingPlace}
            onChange={setSelectExistingPlace}
            width={RADIO_WIDTH}
          />

          <Stack
            styles={{
              root: {
                width: `calc(100% - ${RADIO_WIDTH} - ${INPUT_LEFT_MARGIN})`,
                marginLeft: INPUT_LEFT_MARGIN,
              },
            }}>
            <Text>{t('route-add-location-use-an-existing-address')}</Text>
            <Text color="steel" style={{ marginBottom: '16px' }}>
              {t('route-add-location-please-choose-address-from-list')}
            </Text>
            <TextField
              autocomplete
              id="select-existing-place"
              className="SelectExistingPlaceContainer"
              classNamePrefix="SelectExistingPlace"
              options={places}
              value={selectedPlace}
              onChange={setSelectedPlace}
            />
          </Stack>
        </Stack>
      </ClickableDataContainer>

      <ClickableDataContainer
        onClick={setSelectAddNewPlace}
        tabIndex="0"
        style={{ marginBottom: '88px' }}>
        <Stack axis="horizontal" styles={{ root: { width: '100%' } }}>
          <Radio
            name="select-place-options"
            checked={selectOption === RADIO_OPTIONS.addNewPlaceData}
            value={RADIO_OPTIONS.addNewPlaceData}
            onChange={setSelectAddNewPlace}
            width={RADIO_WIDTH}
          />

          <Stack
            styles={{
              root: {
                width: '100%',
                marginLeft: '16px',
              },
            }}>
            <Text style={{ marginBottom: '4px' }}>
              {t('route-add-location-add-a-new-address-title')}
            </Text>
            <Text color="steel">
              {t('route-add-location-add-a-new-address-subtitle')}
            </Text>
          </Stack>
        </Stack>
      </ClickableDataContainer>

      <FooterButtons
        secondaryButtonText={t('back')}
        handleSecondaryButtonClick={handleBackButtonClick}
        primaryButtonText={t('continue')}
        handlePrimaryButtonClick={() => {
          if (selectOption === RADIO_OPTIONS.selectExistingPlace) {
            return selectExistingPlace(selectedPlace);
          }
          addNewData();
        }}
      />
    </div>
  );
};

export default SelectExistingPlace;
