import { Text, Tag } from '@nuvocargo/nuvo-styleguide';
import { requestDetailsFields } from './createFields';
import { Stack } from 'components/generic/kit';
import { useTranslation } from 'react-i18next';
import { UseRequestDetails } from './useRequestDetails';
import { requestDetailsStyles } from './RequestDetails.styles';

export const RequestDetails = ({ data, requestId }) => {
  const { details, accessorials, additionalNotes } = UseRequestDetails({
    data,
    requestId,
  });
  const { t } = useTranslation();

  const isRequestedEmailField = ({ field }) => field === 'requestedEmail';

  return (
    <Stack
      axis="vertical"
      space="24px"
      styles={{ root: requestDetailsStyles.root }}>
      <Text style={requestDetailsStyles.heading} size="large" color="green">
        {t('serviceability-requests-details-title')}
      </Text>
      <div style={requestDetailsStyles.details}>
        {requestDetailsFields.map(field => (
          <div
            style={{
              ...requestDetailsStyles.tile,
              ...(isRequestedEmailField(field) ? { gridColumn: 'span 2' } : {}),
            }}
            key={`request-details-${field.field}`}
            data-abel={field.field}>
            <div>
              <Text size="small" color="steel">
                {t(field.translation)}
              </Text>
            </div>
            <div style={{ marginTop: 4 }}>
              <Text size="regular">{details[field.field]}</Text>
            </div>
          </div>
        ))}
      </div>
      <div>
        <div>
          <Text size="small" color="steel">
            {t('request-accessorials-label')}
          </Text>
        </div>
        <div className="mt-2 flex gap-2">
          {accessorials.map((acc, index) => (
            <Tag key={`accessorial-${index}`}>{acc}</Tag>
          ))}
        </div>
      </div>
      <div>
        <div>
          <Text size="small" color="steel">
            {t('request-additional-notes')}
          </Text>
        </div>
        <div style={requestDetailsStyles.additionalNotesTile}>
          <Text size="regular">{additionalNotes}</Text>
        </div>
      </div>
    </Stack>
  );
};
