import { Modal } from '@nuvocargo/nuvo-styleguide';
import { STEPS, useSavePlace } from './hook';
import SelectExistingPlace from './SelectExistingPlace';
import AddLocationForm from './AddLocationForm';
import ConfirmData from './ConfirmData';

const SavePlace = ({
  saveModal,
  closeModal,
  location,
  companyId,
  onSaveCallback,
}) => {
  const isOrigin = saveModal?.property === 'origin';
  const {
    addNewData,
    confirmDataAndSubmit,
    confirmationData,
    fillFormData,
    handleAddStepBackButtonClick,
    handleConfirmStepBackButtonClick,
    isSubmitting,
    selectExistingPlace,
    submitFillFormData,
    wizardStep,
    resetAndExit,
  } = useSavePlace({
    closeModal,
    updateProperty: saveModal?.property,
    updateType: saveModal?.type,
    onSaveCallback,
  });

  return (
    <Modal
      isOpen={!!saveModal}
      closeModal={!isSubmitting ? resetAndExit : () => {}}
      styles={{
        root: {
          minHeight: '452px',
          padding: '48px',
          width: '688px',
        },
        close_button: {
          display: 'none',
        },
      }}>
      {(() => {
        switch (wizardStep) {
          case STEPS.SELECT: {
            return (
              <SelectExistingPlace
                addNewData={addNewData}
                companyId={companyId}
                isOrigin={isOrigin}
                location={location}
                selectExistingPlace={selectExistingPlace}
                handleBackButtonClick={resetAndExit}
              />
            );
          }

          case STEPS.FILL:
          case STEPS.FORCED_FILL: {
            return (
              <AddLocationForm
                handleSubmit={submitFillFormData}
                initialValues={fillFormData}
                isOrigin={isOrigin}
                location={location}
                handleBackButtonClick={handleAddStepBackButtonClick}
              />
            );
          }

          case STEPS.CONFIRM_FORCED_PLACE_UPDATE:
          case STEPS.CONFIRM_PLACE_UPDATE:
          case STEPS.CONFIRM_ROUTE_UPDATE: {
            return (
              <ConfirmData
                confirmDataAndSubmit={confirmDataAndSubmit}
                isOrigin={isOrigin}
                isSubmitting={isSubmitting}
                location={location}
                handleBackButtonClick={handleConfirmStepBackButtonClick}
                {...confirmationData}
              />
            );
          }
        }
      })()}
    </Modal>
  );
};

export default SavePlace;
