import * as yup from 'yup';

export const validationSchema = t =>
  yup.object().shape({
    name: yup.string().required(t('validation-locationName-required')),
    addressLine1: yup.string().required(t('validation-addressLine1-required')),
    contactTelephone: yup
      .string()
      .required(t('validation-contactPhone-required')),
  });
