import {
  addBaseRateFromBaseRateCents,
  filterServiceableLegQuotes,
} from './utils';

export const ACTIONS_TYPES = {
  SET_ROUTES: 'SET_ROUTES',
  SET_REQUEST_DETAILS: 'SET_REQUEST_DETAILS',
  SET_LEG_QUOTES_FROM_USER: 'SET_LEG_QUOTES_FROM_USER',
  SET_LEG_QUOTES_FROM_SERVER: 'SET_LEG_QUOTES_FROM_SERVER',
};

export const initialState = {
  routes: [],
  legQuotes: [],
  details: {},
};

export const reducer = (state, action) => {
  switch (action.type) {
    case ACTIONS_TYPES.SET_ROUTES:
      return {
        ...state,
        routes: action.payload,
      };
    case ACTIONS_TYPES.SET_REQUEST_DETAILS:
      return {
        ...state,
        details: action.payload,
      };
    case ACTIONS_TYPES.SET_LEG_QUOTES_FROM_USER:
      return {
        ...state,
        legQuotes: filterServiceableLegQuotes(state, action.payload),
      };
    case ACTIONS_TYPES.SET_LEG_QUOTES_FROM_SERVER:
      return {
        ...state,
        legQuotes: filterServiceableLegQuotes(
          state,
          addBaseRateFromBaseRateCents(state, action.payload)
        ),
      };
    default:
      throw new Error();
  }
};
