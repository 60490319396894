export const API_MOCK_URL = `${process.env.REACT_APP_API_MOCK_URL}/v0`;
export * from './companiesRequest';
export * from './geolocations';
export * from './placesRequest';
export * from './quoteRequest';
export * from './routeRequest';
export * from './routesRequest';
export * from './shipmentsRequest';
export * from './user';
export * from './usersRequest';
export * from './pricingRequests';
export * from './usersRequest';
export * from './flags';
