import { useMutation, useQueryClient } from 'react-query';
import * as FullStory from '@fullstory/browser';
import { useParams } from 'react-router-dom';

import { updatePlace } from 'core/api/places';
import {
  PROCEDURES_APPOINTMENT_TYPES_MAP,
  PROCEDURES_LOADING_SCHEMA_MAP,
} from '../../utils/route-facility';

/**
 * Executes the mutation with a minimum delay of 800ms
 *
 * @param {*} data
 * @returns
 */
const updatePlaceWithDelay = async data => {
  const isWindow =
    data.appointmentType.value === PROCEDURES_APPOINTMENT_TYPES_MAP.WINDOW;

  const isFifo = isWindow && data.isFifo.value;

  const isLiveLoadingScheme =
    data.loadingScheme.value === PROCEDURES_LOADING_SCHEMA_MAP.LIVE;

  const currentLoadingDuration = isLiveLoadingScheme
    ? data.loadingDurationLive.value
    : data.loadingDurationDrop.value;

  const [result] = await Promise.allSettled([
    updatePlace({
      body: {
        name: data.name,
        addressLine1: data.addressLine1,
        addressLine2: data.addressLine2,
        contactName: data.contactName,
        contactTelephone: data.contactTelephone,
        id: data.id,
        facilityProcedure: {
          appointmentType: isFifo
            ? PROCEDURES_APPOINTMENT_TYPES_MAP.FIFO
            : data.appointmentType.value,
          loadingScheme: data.loadingScheme.value,
          startTime: isWindow ? data.startTime : null,
          endTime: isWindow ? data.endTime : null,
          opensAt: data.opensAt,
          closesAt: data.closesAt,
          receivingDays: data.receivingDays.value,
          loadingDuration: currentLoadingDuration,
          facilityInstructions: data.facilityInstructions,
        },
      },
    }),
    new Promise(resolve => setTimeout(resolve, 800)),
  ]);

  if (result.status === 'rejected') {
    throw result.reason;
  }

  return result?.value;
};

export const useUpdatePlace = queryConfig => {
  const queryClient = useQueryClient();
  const { id: routeId } = useParams();

  return useMutation(updatePlaceWithDelay, {
    onSuccess: updatedPlace => {
      FullStory.event('Updated place information for route', {
        updatedPlace,
      });

      queryClient.invalidateQueries(['getRouteDetailsRequest', routeId]);
      // @todo: remove this one when _configured_ props from _route_ is working
      queryClient.invalidateQueries('places');
      queryConfig?.onSuccess(updatedPlace);
    },
    onError: error => {
      FullStory.event('Error trying to update place information for route', {
        error,
      });
      queryConfig?.onError();
    },
  });
};
