import { useTranslation } from 'react-i18next';

const { Heading, Eyebrow } = require('@nuvocargo/nuvo-styleguide');

const Event = ({ shipmentEventType }) => {
  const { t } = useTranslation();
  return (
    <>
      <Eyebrow
        size="small"
        color="steel"
        text={t('shipment-event')}
        style={{ paddingBottom: '12px' }}
      />
      <Heading
        variant="h6"
        color="green"
        styles={{ root: { paddingBottom: '24px' } }}>
        {t(`shipment-configuration-event-name-${shipmentEventType}`)}
      </Heading>
    </>
  );
};

export default Event;
