import { Stack } from 'components/generic/kit';
import { Text, CTAButton } from '@nuvocargo/nuvo-styleguide';
import { useTranslation } from 'react-i18next';

const commonStyles = {
  '> span': {
    fontSize: '16px',
    lineHeight: '24px',

    '> svg': {
      width: '24px',
      height: '24px',
    },
  },
};

const Header = ({
  handleCopy,
  handlePaste,
  isSubmitting,
  hasCopiedEstimatedDatesRecently,
  hasPastedEstimatedDatesRecently,
}) => {
  const { t } = useTranslation();

  return (
    <Stack
      axis="horizontal"
      style={{ justifyContent: 'space-between', padding: '32px 32px 0 32px' }}
      center>
      <Text
        color="green"
        size="large"
        styles={{ large: { fontSize: '20px', lineHeight: '28px' } }}>
        {t('shipment-configuration-notifications-label')}
      </Text>
      <Stack axis="horizontal">
        <CTAButton
          id="configuration-view-copy-button"
          title={t('you-can-paste-in-other-shipments')}
          size="small"
          styles={{
            primary: {
              ...commonStyles,
              marginRight: '16px',
            },
          }}
          icon={hasCopiedEstimatedDatesRecently ? undefined : 'copy'}
          onClick={handleCopy}
          disabled={isSubmitting}>
          {hasCopiedEstimatedDatesRecently
            ? t('configuration-view-recently-copied')
            : t('configuration-view-copy')}
        </CTAButton>

        <CTAButton
          id="configuration-view-paste-button"
          title={t('configuration-view-paste-hotkey')}
          mode="light"
          colorType="secondary"
          size="small"
          styles={{
            secondary: {
              ...commonStyles,
            },
          }}
          icon={hasPastedEstimatedDatesRecently ? undefined : 'clipboard'}
          onClick={handlePaste}
          disabled={isSubmitting}>
          {hasPastedEstimatedDatesRecently
            ? t('configuration-view-recently-pasted')
            : t('configuration-view-paste')}
        </CTAButton>
      </Stack>
    </Stack>
  );
};

export default Header;
