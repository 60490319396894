import React from 'react';
import styled, { css } from 'styled-components';

// UI & UX
import { Stack } from 'components/generic/kit';
import { Heading, Text } from '@nuvocargo/nuvo-styleguide';
import Iconography from '@nuvocargo/nuvo-styleguide/Icons/Iconography';
import { EquipmentOptions } from './';

export default function SelectEquipment({ accessorials }) {
  return (
    <Stack space="27px" styles={{ root: { marginTop: '56px' } }}>
      <Heading variant="h5b">Equipment</Heading>

      <Text size="small" color="steel">
        Please note that we only offer full truck load services using 53’ Dry
        Vans
      </Text>

      <SvgWrapper>
        <Iconography name="truck" styles={{ marginRight: '16px' }} />
        <Text color="green">53’ Dry Van</Text>
      </SvgWrapper>

      <EquipmentOptions accessorials={accessorials} />
    </Stack>
  );
}

const SvgWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 24px;
  border-radius: 4px;
  margin-top: 16px;

  svg {
    width: 80px;
    height: 80px;
  }

  ${({ theme }) =>
    css`
      border: 1px solid ${theme.colors.forest};
    `}
`;
