import React from 'react';
import { TextCell } from '@nuvocargo/nuvo-styleguide/InputTable/components/Cell';
import { formatMoney } from 'core/utils';

const AccesorialPriceText = ({ value = 0, row, ...rest }) => {
  const { directPremiumCostCents = 0 } = row;
  return (
    <TextCell
      text={formatMoney((value + directPremiumCostCents) / 100, 'usd')}
      {...rest}
    />
  );
};

export default AccesorialPriceText;
