import { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { useParams, useHistory, Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  CTAButton,
  Heading,
  Map,
  Text,
  Toast,
  ToastContainer,
  usePageHeader,
} from '@nuvocargo/nuvo-styleguide';

import { useFeatures } from 'context';
import { getRouteDetailsRequest } from 'core/api';
import { useFlags, useQueryParamsString } from 'core/hooks';
import { useGetPlacesByCompanyAndZipCode } from 'core/hooks/queries/useGetPlacesByCompanyAndZipCode';
import {
  buildMapLegs,
  formatDetails,
  ROUTE_STATE,
} from 'core/utils/routeUtils';
import { app } from 'routes/paths';

import { Detail } from './Detail';
import { PriceSection } from './PriceSection';
import { Facilities } from './Facilities';
import { Commodity } from './Commodity';
import Notes from './Notes';

import './print.css';
import { CustomerStatusEnum } from './utils';

const COP_ENABLE_SHIPMENT_REQUEST_ACTIVE_CLIENT =
  'cop_17_05_2023_enable_shipment_request_active_clients';

export const RouteDetails = () => {
  const { showSubnav, hideSubNav, showBanner, hideBanner } = usePageHeader();

  const history = useHistory();
  const { id } = useParams();
  const { t } = useTranslation();
  const [toasts, setToasts] = useState([]);
  const { lowResolutionShipmentBuilderIsEnabled } = useFeatures();

  const search = useQueryParamsString();

  const { isLoading, data, error } = useQuery(
    ['getRouteDetailsRequest', id],
    () => getRouteDetailsRequest({ id })
  );

  const { isLoading: isLoadingFeatureFlagData, data: featureFlagData } =
    useFlags(COP_ENABLE_SHIPMENT_REQUEST_ACTIVE_CLIENT);

  const isOnFinancialHold =
    lowResolutionShipmentBuilderIsEnabled && data?.company?.financeHold;

  useEffect(() => {
    if (isOnFinancialHold) {
      showBanner({
        children: t('company-financial-hold-cannot-ship'),
        type: 'error',
        dismissible: false,
      });
    }

    return () => {
      hideBanner();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOnFinancialHold]);

  const addSuccessToast = (
    text = 'facility-confirmation-modal-toast-success'
  ) => {
    const newToast = (
      <Toast type="success">
        <p>{t(text)}</p>
      </Toast>
    );
    setToasts(prevState => [...prevState, newToast]);
  };

  const addErrorToast = (text = 'something-went-wrong-error') => {
    const newToast = (
      <Toast type="error">
        <p>{t(text)}</p>
      </Toast>
    );
    setToasts(prevState => [...prevState, newToast]);
  };

  const originPlaces = useGetPlacesByCompanyAndZipCode({
    geolocationId: data?.origin?.zipCode?.id,
    companyId: data?.company?.id,
  });
  const destinationPlaces = useGetPlacesByCompanyAndZipCode({
    geolocationId: data?.destination?.zipCode?.id,
    companyId: data?.company?.id,
  });

  useEffect(() => {
    showSubnav({
      text: t('route-breadcrumb-text'),
      onGoBackClick: () => {
        history.push(app.routes);
        hideSubNav();
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (
    isLoading ||
    originPlaces.isLoading ||
    destinationPlaces.isLoading ||
    isLoadingFeatureFlagData
  ) {
    return <div>{t('loading')}</div>;
  }

  if (error || !data || !originPlaces.data || !destinationPlaces.data) {
    return <div>{t('error')}</div>;
  }

  const { origin, destination, timeInTransit } = data;

  const details = formatDetails(data, t);

  const routeCanCreateShipment =
    lowResolutionShipmentBuilderIsEnabled && data.state === ROUTE_STATE.ACTIVE;

  const isCustomerActive =
    data?.company?.customerState === CustomerStatusEnum.ACTIVE;

  const { enabled: isCopShipmentRequestFlagEnabled } =
    featureFlagData.features
      .cop_17_05_2023_enable_shipment_request_active_clients;

  const showCreateShipmentButton = !!isCopShipmentRequestFlagEnabled
    ? true
    : !isOnFinancialHold;

  const canRequestShipment = isCustomerActive && !isOnFinancialHold;

  const buttonProps =
    !!isCopShipmentRequestFlagEnabled && !canRequestShipment
      ? {
          disabled: true,
          iconPosition: 'left',
          icon: 'errorOutline',
          title: isOnFinancialHold
            ? t('company-financial-hold-cannot-ship')
            : t('active-customer-can-request-shipments'),
        }
      : {
          to: {
            pathname: `/routes/${id}/new-shipment`,
            search: search,
          },
          as: RouterLink,
          isLink: true,
        };

  return (
    <>
      <div className="section-to-print col-start-1 col-end-13 mx-auto grid max-w-screen-2xl grid-cols-12 px-6 pt-12 2xl:px-0">
        <div className="col-span-12 mb-8 nuvo-sm:col-span-12 nuvo-sm:col-end-13">
          <div className="flex flex-row justify-between">
            <Heading variant="h3" color="green">
              {t('route-heading', {
                originCity: origin.zipCode.parents.city,
                destinationCity: destination.zipCode.parents.city,
              })}
            </Heading>

            {routeCanCreateShipment && showCreateShipmentButton && (
              <CTAButton {...buttonProps}>
                {t('create-new-shipment-button')}
              </CTAButton>
            )}
          </div>

          <Text as="p" color="steel">
            {`${details.routeId} · ${details.companyName}`}
          </Text>
        </div>

        <div className="col-span-12 mb-6 min-h-[150px] bg-nuvo-green nuvo-sm:col-span-9 nuvo-sm:mr-6">
          <Map
            destinationLabel={destination.zipCode.parents.city}
            originLabel={origin.zipCode.parents.city}
            fullRoute={{
              legs: buildMapLegs(origin, destination),
            }}
            mapToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}
            showEstimatedTravelTimeInDays={{
              lower: timeInTransit?.lower,
              upper: timeInTransit?.upper,
            }}
          />
        </div>
        {details?.commodity && (
          <div className="col-span-12 mt-6 bg-white nuvo-sm:col-span-9 nuvo-sm:col-end-9 nuvo-sm:mr-6">
            <Commodity
              companyId={data?.company?.id}
              commodity={details?.commodity}
              addSuccessToast={addSuccessToast}
              addErrorToast={addErrorToast}
            />
          </div>
        )}
        <div className="col-span-12 mt-6 bg-white nuvo-sm:col-span-9 nuvo-sm:col-end-9 nuvo-sm:mr-6">
          <Facilities
            origin={origin}
            destination={destination}
            originPlaces={originPlaces.data}
            destinationPlaces={destinationPlaces.data}
            addSuccessToast={addSuccessToast}
            addErrorToast={addErrorToast}
          />
        </div>

        <div className="col-span-4 row-start-3 nuvo-sm:col-end-13 nuvo-sm:row-start-2 nuvo-sm:row-end-4 nuvo-sm:ml-6">
          <PriceSection
            data={data}
            lastUpdatedAt={details.lastUpdatedAt}
            addErrorToast={addErrorToast}
            setToasts={setToasts}
          />

          <div data-testId="details">
            <div className="mb-4 flex flex-row items-center justify-between border-b border-nuvo-gray-dark/10 pb-2">
              <Text as="p" color="green" size="large" fontWeight="bold">
                {t('route-details')}
              </Text>
            </div>

            <div className="mb-8 grid grid-cols-2 gap-y-8">
              {details.routeId && (
                <Detail label={t('route-details-id')} value={details.routeId} />
              )}

              {details.equipment && (
                <Detail
                  label={t('route-details-equipment')}
                  value={details.equipment}
                />
              )}

              {details.serviceType && (
                <Detail
                  label={t('route-details-serviceType')}
                  value={details.serviceType}
                />
              )}

              {details.includeBorderCrossing && (
                <Detail
                  label={t('route-details-includeBorderCrossing')}
                  value={details.includeBorderCrossing}
                />
              )}
            </div>

            <div className="mb-8 grid grid-cols-2 gap-y-8">
              {details.commodity && (
                <>
                  {details.commodity.category && (
                    <Detail
                      label={t('route-details-commodityType')}
                      value={details.commodity.category}
                    />
                  )}

                  {details.commodity.name && (
                    <Detail
                      label={t('route-details-commodityName')}
                      value={details.commodity.name}
                    />
                  )}

                  {details.commodity.weight && (
                    <Detail
                      label={t('route-details-commodityWeight')}
                      value={details.commodity.weight}
                    />
                  )}

                  {details.commodity.value && (
                    <Detail
                      label={t('route-details-commodityValue')}
                      value={details.commodity.value}
                    />
                  )}
                </>
              )}
            </div>

            {details.specialHandling && (
              <div
                data-testid="special-handling"
                className="flex flex-col gap-1">
                <Text as="p" size="small" color="steel" fontWeight="bold">
                  {t('route-details-specialHandling')}
                </Text>

                <div className="flex flex-row flex-wrap gap-y-1">
                  {details.specialHandling.map(value => (
                    <div
                      className="mr-2 rounded border px-2 py-1 text-nuvo-green"
                      key={`route-requirement-${value}`}>
                      {value}
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>

        {data.notes.length > 0 && (
          <Notes
            notes={data.notes}
            requester={data.requester}
            createdAt={data.createdAt}
          />
        )}
      </div>

      <ToastContainer>{toasts}</ToastContainer>
    </>
  );
};
