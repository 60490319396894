import React from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import { CTAButton, Heading } from '@nuvocargo/nuvo-styleguide';
import { Textarea } from '@nuvocargo/nuvo-styleguide/forms/formik';
import Modal from '@nuvocargo/nuvo-styleguide/Modals/Modal';

import { useAccessorials } from 'core/api/accessorials';

import Confirm from './components/Confirm';
import FasteningEquipment from './components/FasteningEquipment';
import General from './components/General';
import SafetyRequirements from './components/SafetyRequirepments';
import TrailerSpecification from './components/TrailerSpecification';

// Hooks
import useUpdateCommodity from './hooks/useUpdateCommodity';
import useParseAccessorialValues from './hooks/useParseAccessorialValues';

const CommodityModal = ({
  commodity,
  onClick,
  isOpen,
  addSuccessToast,
  addErrorToast,
}) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const queryClient = useQueryClient();

  const { data: accessorials } = useAccessorials();

  const {
    fasteningEquipmentOptions,
    safetyRequirementOptions,
    trailerSpecificationInitialValues,
  } = useParseAccessorialValues(commodity, accessorials);
  const handleCloseModal = () => onClick(false);

  const { updateCommodityQuery } = useUpdateCommodity(
    { commodityId: commodity?.id },
    {
      onSuccess: () => {
        onClick(false);
        queryClient.invalidateQueries(['getRouteDetailsRequest', id]);
        addSuccessToast('commodity-update-modal-toast-success');
      },
      onError: addErrorToast,
    }
  );

  return (
    <Modal onOpenChange={onClick} isOpen={isOpen}>
      <Formik
        validationSchema={yup.object().shape({
          confirm: yup
            .string()
            .required(t('commodity-modal-confirmation-required'))
            .matches('COMMODITY', t('commodity-modal-confirmation-required')),
        })}
        initialValues={{
          fasteningEquipments: fasteningEquipmentOptions,
          safetyRequirements: safetyRequirementOptions,
          trailerSpecifications: trailerSpecificationInitialValues,
          notes: commodity?.notes || '',
          confirm: '',
        }}
        onSubmit={updateCommodityQuery.mutate}
        validateOnMount>
        {({ isValid }) => (
          <Form className="mt-10 flex w-full flex-col gap-8">
            <div className="flex">
              <Heading variant="h5" color="green">
                {t('commodity-modal-title')}
              </Heading>
            </div>

            <General commodity={commodity} />
            <TrailerSpecification />
            <FasteningEquipment
              fasteningEquipments={fasteningEquipmentOptions}
            />
            <SafetyRequirements safetyRequirements={safetyRequirementOptions} />
            <Textarea
              name="notes"
              placeholder={t('commodity-modal-additional-notes-placeholder')}
              label={t('commodity-modal-additional-notes-label')}
            />
            <Confirm />

            <Modal.Footer alignment="spread">
              <CTAButton
                type="button"
                colorType="secondary"
                variant=""
                onClick={handleCloseModal}>
                {t('commodity-form-button-cancel')}
              </CTAButton>
              {isValid}
              <CTAButton type="submit" disabled={!isValid}>
                {t('commodity-form-button-continue')}
              </CTAButton>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default CommodityModal;
