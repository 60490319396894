import usePromiseHook from 'core/utils/use-promise-hook';
import * as api from 'core/api/user';
import { useLocation, useHistory } from 'react-router-dom';
import qs from 'qs';

export const useResetPassword = config => {
  const { request, data, error, isLoading } = usePromiseHook(
    api.resetPassword,
    config
  );

  const history = useHistory();

  const { search } = useLocation();
  const { token } = qs.parse(search, { ignoreQueryPrefix: true });
  const rejectPromise = () => Promise.reject({ response: 'no token provided' });
  const verifyTokenApiFn = () => api.verifyToken({ token });
  const verifyTokenApi = usePromiseHook(
    !!token ? verifyTokenApiFn : rejectPromise,
    {
      isEffect: true,
      onFailureCallback: () => {
        history.push('/password/forgot?invalidToken=true');
      },
      effectCondition: [token],
    }
  );

  return {
    resetPassword: body => {
      request(body);
    },
    data,
    error,
    token,
    isLoading,
    verifyTokenApi,
  };
};
