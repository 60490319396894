import { gql } from 'graphql-request';
import { useMutation, useQueryClient } from 'react-query';

import { useQueryParams } from 'core/hooks';
import { client } from 'core/utils/axios.config';

export const CREATE_SHIPMENT_EVENT_ISSUE_MUTATION = gql`
  mutation createShipmentEventIssue(
    $shipmentEventId: String!
    $reason: String!
    $noteSpanish: String
    $noteEnglish: String
    $internalNote: String!
  ) {
    createShipmentEventIssue(
      input: {
        shipmentEventId: $shipmentEventId
        reason: $reason
        noteSpanish: $noteSpanish
        noteEnglish: $noteEnglish
        internalNote: $internalNote
      }
    ) {
      shipmentEventIssue {
        id
        reason
        noteEnglish
        noteSpanish
      }
    }
  }
`;

const createShipmentRequestGQL = ({ attributes }) => {
  const { shipmentEventId, reason, noteEnglish, noteSpanish, internalNote } =
    attributes;

  return client
    .gql({
      query: CREATE_SHIPMENT_EVENT_ISSUE_MUTATION,
      variables: {
        shipmentEventId,
        reason,
        noteEnglish,
        noteSpanish,
        internalNote,
      },
    })
    .then(r => r?.data?.data?.createShipmentEventIssue ?? null);
};

export const useCreateShipmentEventIssue = (queryConfig = {}) => {
  const queryClient = useQueryClient();
  const { shipmentId } = useQueryParams();

  return useMutation(data => createShipmentRequestGQL(data), {
    ...queryConfig,
    onSuccess: (...args) => {
      queryClient.invalidateQueries(['shipment', shipmentId]);
      queryConfig?.onSuccess?.(...args);
    },
  });
};

export const UPDATE_SHIPMENT_EVENT_ISSUE_MUTATION = gql`
  mutation updateShipmentEventIssue(
    $id: ID!
    $reason: String!
    $noteEnglish: String
    $noteSpanish: String
    $internalNote: String
  ) {
    updateShipmentEventIssue(
      input: {
        id: $id
        reason: $reason
        noteEnglish: $noteEnglish
        noteSpanish: $noteSpanish
        internalNote: $internalNote
      }
    ) {
      shipmentEventIssue {
        id
        reason
        noteEnglish
        noteSpanish
        internalNote
      }
    }
  }
`;

const updateShipmentRequestQL = ({ id, attributes }) => {
  return client
    .gql({
      query: UPDATE_SHIPMENT_EVENT_ISSUE_MUTATION,
      variables: { id, ...attributes },
    })
    .then(r => r?.data?.data?.updateShipmentEventIssue ?? null);
};

export const useUpdateShipmentEventIssueById = (queryConfig = {}) => {
  const queryClient = useQueryClient();
  const { shipmentId } = useQueryParams();

  return useMutation(data => updateShipmentRequestQL(data), {
    ...queryConfig,
    onSuccess: (...args) => {
      queryClient.invalidateQueries(['shipment', shipmentId]);
      queryConfig?.onSuccess?.(...args);
    },
  });
};
