import React from 'react';
import { CTAButton } from '@nuvocargo/nuvo-styleguide';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

function BackFormButton({ route, goBack }) {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <div>
      <CTAButton
        type="button"
        onClick={() => {
          if (goBack) {
            history.goBack();
          }
          history.push(route);
        }}
        colorType="secondary">
        {t('back-text-label')}
      </CTAButton>
    </div>
  );
}

export default BackFormButton;
