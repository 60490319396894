import React from 'react';
import { motion } from 'framer-motion';
import { Box } from 'components/generic/kit';
import styled from 'styled-components';
import { theme } from 'styles';
import { useField } from 'formik';

export default function Switch(props) {
  const [field] = useField({ ...props, type: 'checkbox' });
  const isChecked = field.value || false;
  const backgroundColor = isChecked
    ? theme.colors.forest
    : theme.colors.darkCream;

  return (
    <Box styles={{ root: { position: 'relative' } }}>
      <Box
        as={motion.div}
        initial={{ backgroundColor }}
        animate={{
          backgroundColor,
        }}
        styles={{
          root: {
            padding: '2px',
            borderRadius: '100px',
            width: '56px',
            display: 'flex',
            cursor: 'pointer',
          },
        }}>
        <motion.div
          animate={{ x: isChecked ? 28 : 0 }}
          style={{
            height: '28px',
            width: '28px',
            backgroundColor: '#fff',
            borderRadius: '50%',
            boxShadow: '0px 0px 3px rgba(0,0,0,0.2)',
          }}
        />
      </Box>
      <StyledCheckbox {...field} {...props} type="checkbox" />
    </Box>
  );
}

const StyledCheckbox = styled.input`
  position: absolute;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  opacity: 0;
  border-radius: '100px';
  cursor: pointer;
`;
