import { Table } from '@nuvocargo/nuvo-styleguide/Table';
import { usePricingRequestsTable } from './hooks';
import { useTranslation } from 'react-i18next';

export const PricingRequestsTable = () => {
  const { t } = useTranslation();
  const { data, columns, isLoading, totalItems, onRowClickHandler, isError } =
    usePricingRequestsTable();

  if (isError) {
    return t('something-went-wrong-error');
  }

  return (
    <>
      <Table
        autoFilters={{ enabled: true, clearFilterText: t('clear-filters') }}
        columns={columns}
        data={data}
        empty={{}}
        isLoading={isLoading}
        onRowClick={onRowClickHandler}
        pagination={{
          totalItems,
        }}
      />
    </>
  );
};
