import { Documents, EventDetails } from './components';

export function Overview({ shipment }) {
  return (
    <section className="flex flex-col gap-8 ">
      <EventDetails shipment={shipment} />
      <Documents shipment={shipment} />
    </section>
  );
}
