import * as yup from 'yup';
import {
  getIssueKeyFromReason,
  ShipmentEventIssuesTypeEnum,
} from 'screens/track-and-trace/utils';
import { isTrackAndTrace } from 'core/utils';
import { isAfter } from 'date-fns';
import { hasBorderCrossingEvent } from 'screens/track-and-trace/shipment-details/configuration/utils';

const initValues = {
  reason: '',
  noteEnglish: '',
  noteSpanish: '',
  internalNote: '',
};

export const MAX_LENGTH = 150;

export const isUpdateAction = exceptionId =>
  !!exceptionId && Number.isInteger(Number.parseInt(exceptionId));

const parseInitValuesFromShipmentEventIssue = (shipmentEventIssue, t) => ({
  id: shipmentEventIssue.id,
  reason: {
    label: getIssueKeyFromReason(shipmentEventIssue.reason, t),
    value: shipmentEventIssue.reason,
  },
  noteEnglish: shipmentEventIssue.noteEnglish,
  noteSpanish: shipmentEventIssue.noteSpanish,
  internalNote: shipmentEventIssue.internalNote,
});

export const getInitValues = (shipmentEventIssue, t, etaEvents) =>
  !!shipmentEventIssue
    ? {
        ...parseInitValuesFromShipmentEventIssue(shipmentEventIssue, t),
        etaEvents,
        notifyCustomer: !shipmentEventIssue?.resolver?.name,
      }
    : initValues;

const trackAndTraceSchema = t =>
  yup.object().shape({
    reason: yup.object().shape({
      value: yup
        .string()
        .oneOf(
          Object.keys(ShipmentEventIssuesTypeEnum),
          t('exception-validation')
        )
        .required(t('required-field')),
    }),
    internalNote: yup.string().required(t('required-field')),
  });

const customerSuccessWithBorderSchema = t =>
  yup.object().shape({
    reason: yup.object().shape({
      value: yup
        .string()
        .oneOf(
          Object.keys(ShipmentEventIssuesTypeEnum),
          t('exception-validation')
        )
        .required(t('required-field')),
    }),
    noteEnglish: yup.string().required(t('required-field')),
    noteSpanish: yup.string().required(t('required-field')),
    etaEvents: yup.object().shape({
      ARRIVED_TO_ORIGIN: yup.object().shape({
        estimatedDatetime: yup.date().required(t('required-field')),
      }),
      ARRIVED_TO_BORDER: yup.object().shape({
        estimatedDatetime: yup
          .date()
          .required(t('required-field'))
          .test(
            'is_after_arrived_to_origin',
            t('must_be_after_arrived_to_origin'),
            (value, testContext) => {
              const dateToCompare =
                testContext.from[1].value.ARRIVED_TO_ORIGIN.estimatedDatetime;

              return isAfter(value, new Date(dateToCompare));
            }
          ),
      }),
      ARRIVED_TO_DESTINATION: yup.object().shape({
        estimatedDatetime: yup
          .date()
          .required(t('required-field'))
          .test(
            'is_after_arrived_to_border',
            t('must_be_after_arrived_to_border'),
            (value, testContext) => {
              const dateToCompare =
                testContext.from[1].value.ARRIVED_TO_BORDER.estimatedDatetime;

              return isAfter(value, new Date(dateToCompare));
            }
          ),
      }),
    }),
  });

const customerSuccessWithoutBorderSchema = t =>
  yup.object().shape({
    reason: yup.object().shape({
      value: yup
        .string()
        .oneOf(
          Object.keys(ShipmentEventIssuesTypeEnum),
          t('exception-validation')
        )
        .required(t('required-field')),
    }),
    noteEnglish: yup.string().required(t('required-field')),
    noteSpanish: yup.string().required(t('required-field')),
    etaEvents: yup.object().shape({
      ARRIVED_TO_ORIGIN: yup.object().shape({
        estimatedDatetime: yup.date().required(t('required-field')),
      }),
      ARRIVED_TO_DESTINATION: yup.object().shape({
        estimatedDatetime: yup
          .date()
          .required(t('required-field'))
          .test(
            'is_after_arrived_to_origin',
            t('must_be_after_arrived_to_origin'),
            (value, testContext) => {
              const dateToCompare =
                testContext.from[1].value.ARRIVED_TO_ORIGIN.estimatedDatetime;

              return isAfter(value, new Date(dateToCompare));
            }
          ),
      }),
    }),
  });

export const getValidationSchema = (t, currentRole, events = []) => {
  if (isTrackAndTrace(currentRole)) {
    return trackAndTraceSchema(t);
  }

  if (hasBorderCrossingEvent(events)) {
    return customerSuccessWithBorderSchema(t);
  }

  return customerSuccessWithoutBorderSchema(t);
};

export const exceptionReasons = t => {
  const reasons = [
    { label: t('car-accident'), value: ShipmentEventIssuesTypeEnum.accident },
    {
      label: t('carrier-bounced-service'),
      value: ShipmentEventIssuesTypeEnum.carrier_refused,
    },
    {
      label: t('closed-per-holiday'),
      value: ShipmentEventIssuesTypeEnum.holiday_closure,
    },
    {
      label: t('customs-system-down'),
      value: ShipmentEventIssuesTypeEnum.customs_down,
    },
    {
      label: t('customs-traffic'),
      value: ShipmentEventIssuesTypeEnum.customs_traffic,
    },
    {
      label: t('mechanical-breakdown'),
      value: ShipmentEventIssuesTypeEnum.mechanical_breakdown,
    },
    {
      label: t('production-delay'),
      value: ShipmentEventIssuesTypeEnum.production_delay,
    },
    { label: t('road-closed'), value: ShipmentEventIssuesTypeEnum.road_closed },
    {
      label: t('unable-to-find-location'),
      value: ShipmentEventIssuesTypeEnum.location_not_found,
    },
  ].sort((a, b) => a.label.localeCompare(b.label));

  reasons.push({ label: t('other'), value: ShipmentEventIssuesTypeEnum.other });

  return reasons;
};

export const createShipmentEventIssue = (
  createShipmentEventIssueMutation,
  {
    reason: { value: reason = '' } = {},
    noteEnglish,
    noteSpanish,
    shipmentEventId,
    internalNote,
  }
) => {
  createShipmentEventIssueMutation.mutate({
    attributes: {
      shipmentEventId,
      reason,
      noteEnglish,
      noteSpanish,
      internalNote,
    },
  });
};

export const updateShipmentEventIssue = (
  updateShipmentEventIssueMutation,
  {
    id,
    reason: { value: reason = '' } = {},
    noteEnglish,
    noteSpanish,
    internalNote,
    notifyCustomer,
  }
) => {
  updateShipmentEventIssueMutation.mutate({
    id,
    attributes: {
      reason,
      noteEnglish,
      noteSpanish,
      internalNote,
      notifyCustomer,
    },
  });
};
