import * as yup from 'yup';

export const validationSchema = t =>
  yup.object().shape({
    legQuotes: yup.array().of(
      yup.object().shape({
        baseRate: yup
          .number(t('validation-baseRate-number'))
          .positive(t('validation-baseRate-positive'))
          .typeError(t('validation-baseRate-number'))
          .required(t('validation-baseRate-required')),
      })
    ),
  });
