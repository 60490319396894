import {
  CREATE_PLACE_MUTATION,
  UPDATE_PLACE_MUTATION,
} from 'core/graphql/mutations/places';
import { client } from 'core/utils/axios.config';

import { GET_PLACES } from '../graphql/queries/places';

export const updatePlace = ({ body }) =>
  client
    .gql({
      query: UPDATE_PLACE_MUTATION,
      variables: {
        input: body,
      },
    })
    .then(r => r.data?.data?.updatePlace?.place);

export const createPlace = ({ body }) =>
  client
    .gql({
      query: CREATE_PLACE_MUTATION,
      variables: {
        input: {
          attributes: body,
        },
      },
    })
    .then(r => r.data?.data?.createPlace?.place);

export const getPlaces = ({ companyId, geolocationId }) => {
  return client
    .gql({
      query: GET_PLACES,
      variables: {
        companyId: companyId.toString(),
        geolocationId,
      },
    })
    .then(r => r?.data?.data?.places ?? []);
};
