import { Text } from '@nuvocargo/nuvo-styleguide';
import { Stack } from 'components/generic/kit';

export default function StatusesSection({
  selectedStatus,
  statusOnChange,
  statusOptions,
}) {
  return (
    <Stack
      axis="horizontal"
      space="24px"
      styles={{
        root: {
          marginLeft: '60px',
          marginTop: '24px',
        },
      }}>
      {statusOptions.map(({ value, label, id }) => (
        <div
          id={id}
          key={id}
          onClick={() => {
            statusOnChange(value);
          }}>
          <Text
            color={value === selectedStatus ? 'forest' : 'softGreen'}
            styles={{ regular: { cursor: 'pointer' } }}>
            {label}
          </Text>
        </div>
      ))}
    </Stack>
  );
}
