import React from 'react';
import styled, { css } from 'styled-components';

import { useField } from 'formik';
import { Text, Icon, Label } from '@nuvocargo/nuvo-styleguide';
import { Box, Stack } from 'components/generic/kit';

const Tag = ({ children, ...props }) => (
  <Box
    styles={{
      root: ({ theme }) => ({
        backgroundColor: theme.colors.darkCream,
        padding: '4px 16px',
        borderRadius: '16px',
      }),
    }}
    {...props}>
    <Label color="#202020">{children}</Label>
  </Box>
);

export default function CommodityRadio({ label, tags, ...props }) {
  const [field] = useField(props);
  const isChecked = field.value && `${field.value.id}` === `${props.value}`;

  return (
    <Wrapper className={props.value ? '' : 'no-id'}>
      <Placeholder isSelected={field.value === props.value}>
        <Stack axis="horizontal" space="16px" center>
          <Circle isChecked={isChecked}>
            {isChecked && (
              <Icon name="check" color="white" width="10px" height="10px" />
            )}
          </Circle>
          <Text size="large">{label + (props.value ? '' : ' (no id)')}</Text>
        </Stack>
        <Stack axis="horizontal" space="8px">
          {tags.map(tag => (
            <Tag key={tag}>{tag}</Tag>
          ))}
        </Stack>
      </Placeholder>
      <Radio type="radio" {...field} {...props} />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: relative;
  &.no-id {
    opacity: 0.35;
  }
`;

const Radio = styled.input`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0%;
  left: 0%;
  opacity: 0;
  &:hover {
    cursor: pointer;
  }
`;

const Placeholder = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  ${({ theme }) => theme.outlines.default}
  border-radius: 4px;
  padding: 20px;
`;

const STYLES_CIRCLE_CHECKED = css`
  background-color: ${({ theme }) => theme.colors.forest};
`;
const STYLES_CIRCLE_UNCHECKED = css`
  ${({ theme }) => theme.outlines.default}
`;
const Circle = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  ${({ isChecked }) =>
    isChecked ? STYLES_CIRCLE_CHECKED : STYLES_CIRCLE_UNCHECKED};
  svg {
    width: 12px;
    height: 12px;
  }
`;
