import { Eyebrow, Icon, Text } from '@nuvocargo/nuvo-styleguide';
import { SmallDetails, Stack } from 'components/generic/kit';
import { useTranslation } from 'react-i18next';
import { graphQLCountryToFlagString } from 'screens/track-and-trace/utils';

const buildLoadingSchemaLabels = t => ({
  pickup: t('shipment-form-type-of-load-unload-at-origin-label'),
  delivery: t('shipment-form-type-of-load-unload-at-destination-label'),
});

const buildAppointmentTypeLabels = t => ({
  pickup: t('shipment-form-appointment-type-at-origin-label'),
  delivery: t('shipment-form-appointment-type-at-destination-label'),
});

const EstimatedRoute = props => {
  const { t } = useTranslation();

  const {
    loadingSchema,
    appointmentType,
    estimatedDatetime,
    location,
    expectedDatetime,
    styles,
    type,
  } = props;

  const appointmentTypeLabel = buildAppointmentTypeLabels(t)[type];
  const loadingSchemaLabel = buildLoadingSchemaLabels(t)[type];

  return (
    <div className="flex flex-col gap-2" style={{ ...styles }}>
      <Eyebrow color="steel" size="small" text={t(type)} />

      <SmallDetails
        top={
          <Stack axis="horizontal">
            <Icon
              name={graphQLCountryToFlagString(location?.country)}
              styles={{ marginRight: '10px' }}
            />
            {location?.city}
          </Stack>
        }
        down={estimatedDatetime}
        topStyles={{ regular: { marginBottom: '4px' } }}
      />

      <div className="mt-2 flex flex-col">
        <Eyebrow color="steel" size="small" text={t('appointment-details')} />

        <div class="mt-1 flex flex-col">
          <Text color="steel" size="small">
            {loadingSchemaLabel}: {loadingSchema}
          </Text>
          <Text color="steel" size="small">
            {appointmentTypeLabel}: {appointmentType}
          </Text>
          <Text color="steel" size="small">
            {t('date')}: {expectedDatetime}
          </Text>
        </div>
      </div>
    </div>
  );
};

export default EstimatedRoute;
