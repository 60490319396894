import { useQuery } from 'react-query';

import { client } from 'core/utils/axios.config';

export const useGeolocationsRequest = ({ filterName, filterType }) =>
  useQuery(['geolocations', filterType, filterName], () =>
    getGeolocations({
      filterName,
      filterType,
    })
  );

export const getGeolocations = ({ filterName, filterType }) => {
  const params = {
    'filter[name]': filterName,
    'filter[type]': filterType,
    fields: 'id,name,country,parents',
  };

  return client
    .get('/v0/geolocations', { params })
    .then(response => response.data);
};
