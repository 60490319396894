import { LEG_QUOTES_QUERY } from '../graphql';
import { PRICING_REP_FILTER_UNASSIGNED } from 'core/utils/routeUtils';
import { gql } from 'graphql-request';
import { client } from 'core/utils/axios.config';

const GET_ROUTES_BY_REQUEST_IDENTIFIER = gql`
  query routes($requestIdentifier: String) {
    routes(requestIdentifier: $requestIdentifier) {
      edges {
        node {
          id
          requester {
            email
          }
          currency
          conversionRate
          crossingFeesCents
          handleBorderCrossing
          timeInTransit
          priceCents
          updatedAt
          pricingAssignee {
            name
          }
          origin {
            zipCode {
              name
              parents {
                city
                state
                country
              }
            }
          }
          destination {
            name
            zipCode {
              name
              parents {
                city
                state
                country
              }
            }
          }
          createdAt
          truckType
          serviceType
          shipmentRequirements
          notes
          serviceable
          company {
            name
            businessType
          }
          commodities {
            nodes {
              extraStrapsCount
              category
              name
              weight
              valuePerLoadCents
              accessorials {
                slug
                name
              }
            }
          }
        }
      }
    }
  }
`;

const GET_PRICING_ROUTE_REQUESTS = gql`
  query routeRequestListQuery(
    $pageSize: Int
    $after: String
    $priceState: String
    $companyId: String
    $pricingAssigneeId: String
    $requestIdentifier: String
  ) {
    routeRequests(
      first: $pageSize
      after: $after
      priceState: $priceState
      companyId: $companyId
      pricingAssigneeId: $pricingAssigneeId
      requestIdentifier: $requestIdentifier
    ) {
      edges {
        node {
          id
          requestIdentifier
          priceState
          updatedAt
          numberOfRoutes
          company {
            name
          }
          pricingAssignee {
            name
          }
        }
      }
      totalCount
      pageInfo {
        endCursor
        startCursor
        hasNextPage
      }
    }
  }
`;

const ASSIGNED_REP_TO_PRICING_REQUEST_MUTATION = gql`
  mutation assignRepToPricingRequest($input: AssignRepToPricingRequestInput!) {
    assignRepToPricingRequest(input: $input) {
      success
    }
  }
`;

const PUBLISH_PRICED_ROUTES_MUTATION = gql`
  mutation publishRouteRequest($input: PublishRouteRequestInput!) {
    publishRouteRequest(input: $input) {
      routes {
        id
        requestIdentifier
        serviceable
        priceState
        timeInTransit
        legQuotes {
          id
          baseRateCents
          marginCents
        }
      }
    }
  }
`;

export const getPricingRouteRequests = ({
  after = '',
  pageSize = 50,
  filters = {},
}) => {
  return client
    .gql({
      query: GET_PRICING_ROUTE_REQUESTS,
      variables: {
        after,
        pageSize,
        priceState: filters.priceState,
        companyId: filters['company.name'],
        pricingAssigneeId:
          filters['pricingAssignee.name'] === PRICING_REP_FILTER_UNASSIGNED
            ? 'false' // This graphql option only accepts strings and will convert to use in the query
            : filters['pricingAssignee.name'],
        requestIdentifier: filters.requestIdentifier?.replace('RQ-', ''),
      },
    })
    .then(r => ({
      totalCount: r?.data?.data?.routeRequests?.totalCount,
      data: r?.data?.data?.routeRequests?.edges?.map(d => d?.node) ?? [],
    }));
};

export const getRoutesByRequestIdentifier = ({ requestIdentifier }) =>
  client
    .gql({
      query: GET_ROUTES_BY_REQUEST_IDENTIFIER,
      variables: {
        requestIdentifier,
      },
    })
    .then(r => r.data)
    .catch(e => console.log('e =>', e));

export const assignRepToPricingRequestMutation = ({ requestIdentifier }) => {
  return client
    .gql({
      query: ASSIGNED_REP_TO_PRICING_REQUEST_MUTATION,
      variables: {
        input: { requestIdentifier },
      },
    })
    .then(r => {
      return r;
    })
    .catch(e => console.log('e =>', e));
};

export const getLegQuotesByIdentifier = ({ requestIdentifier }) => {
  return client
    .gql({
      query: LEG_QUOTES_QUERY,
      variables: {
        requestIdentifier,
      },
    })
    .then(r => {
      return r?.data?.data?.legQuotes?.edges?.map(d => d?.node) ?? [];
    })
    .catch(e => console.log('e =>', e));
};

export const publishPricedRoutes = input => {
  return client
    .gql({
      query: PUBLISH_PRICED_ROUTES_MUTATION,
      variables: {
        input,
      },
    })
    .then(r => r?.data?.data?.routeRequests?.edges?.map(d => d?.node) ?? [])
    .catch(e => console.log('e =>', e));
};
