import { useMutation } from 'react-query';

import { updateCommodity } from 'core/api/commodities';

import {
  OLD_REQUIREMENTS,
  TRAILER_SPECIFICATION_SNAKED_KEYS,
  FASTENING_EQUIPMENT_QUANTITY,
} from '../constants';

const TRAILER_SPECIFICATIONS = Object.keys(TRAILER_SPECIFICATION_SNAKED_KEYS);

export default function useUpdateCommodity({ commodityId }, queryConfig) {
  const updateCommodityQuery = useMutation(values => {
    const {
      requirements,
      additionalRequirements,
      extraStrapsCount,
      extraLoadLocksCount,
      extraLogisticBarsCount,
    } = values.fasteningEquipments.reduce(
      (acc, requirement) => {
        if (requirement.id && OLD_REQUIREMENTS.indexOf(requirement.id) >= 0) {
          return {
            ...acc,
            additionalRequirements: acc.additionalRequirements,
            requirements: [...acc.requirements, requirement.id],
            [FASTENING_EQUIPMENT_QUANTITY[requirement.id]]:
              requirement?.required?.value || 0,
          };
        }

        const additionalRequirements = [...acc.additionalRequirements];

        if (requirement?.required?.value) {
          additionalRequirements.push(requirement.id);
        }

        return {
          ...acc,
          requirements: acc.requirements,
          additionalRequirements,
          [FASTENING_EQUIPMENT_QUANTITY[requirement.id]]:
            requirement?.required?.value || 0,
        };
      },
      {
        requirements: [],
        additionalRequirements: [],
        extraStrapsCount: 0,
        extraLoadLocksCount: 0,
        extraLogisticBarsCount: 0,
      }
    );
    const oldTrailerSpecificationRequirements = values.trailerSpecifications
      .fumigation
      ? ['fumigation']
      : [];
    const filteredSafetyRequirements = values.safetyRequirements
      .filter(safetyRequirement => safetyRequirement?.required?.value)
      .map(safetyRequirement => safetyRequirement.id);
    const filteredTrailerSpecifications = Object.keys(
      values.trailerSpecifications
    )
      .filter(trailerSpecification => {
        return (
          TRAILER_SPECIFICATIONS.indexOf(trailerSpecification) >= 0 &&
          values.trailerSpecifications[trailerSpecification]
        );
      })
      .map(
        trailerSpecification =>
          TRAILER_SPECIFICATION_SNAKED_KEYS[trailerSpecification]
      );

    return updateCommodity(commodityId, {
      commodity: {
        notes: values.notes,
        requirements: [...requirements, ...oldTrailerSpecificationRequirements],
        additionalRequirements: [
          ...additionalRequirements,
          ...filteredSafetyRequirements,
          ...filteredTrailerSpecifications,
        ],
        extraStrapsCount,
        extraLoadLocksCount,
        extraLogisticBarsCount,
      },
    });
  }, queryConfig);

  return { updateCommodityQuery };
}
