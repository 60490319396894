import React from 'react';
import styled, { keyframes, css } from 'styled-components';
import { times } from 'ramda';

const animation = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

const Wrap = styled.div`
  position: relative;
  ${({ size, speed, elements, color }) => css`
    width: ${size * 8}px;
    height: ${size * 8}px;
    > div {
      left: ${size * 3.75}px;
      top: ${size}px;
      position: absolute;
      animation: ${animation} linear ${speed}s infinite;
      background-color: ${color};
      width: ${size * 0.5}px;
      height: ${size * 1.5}px;
      border-radius: 20%;
      transform-origin: ${size * 0.25}px ${size * 3}px;
    }
    ${times(
      n => `
      > div: nth-child(${n + 1}) {
        transform: rotate(${n * (360 / elements)}deg);
        animation-delay: -${(1 - (n + 1) / elements) * speed}s;
      }
    `,
      elements
    ).join('')}
  `};
`;

export default function Spinner({
  size = 3,
  speed = 0.5,
  elements = 9,
  color = 'black',
  ...rest
}) {
  return (
    <Container data-testid="loader-spinner">
      <Wrap {...{ size, speed, elements, color }} {...rest}>
        {times(
          n => (
            <div key={n} />
          ),
          elements
        )}
      </Wrap>
    </Container>
  );
}

const Container = styled.div`
  height: 5vh;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin: auto;
`;
