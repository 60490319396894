import { Label } from '@nuvocargo/nuvo-styleguide';
import { Row } from './styles';

const FacilityGroup = props => {
  const { title, children } = props;

  return (
    <Row className="relative mb-6 rounded-md border border-gray-400 py-6 px-4">
      <div className="absolute left-6 top-0 translate-y-[-50%] bg-nuvo-cream py-1 px-2">
        <Label>{title}</Label>
      </div>

      {children}
    </Row>
  );
};

export default FacilityGroup;
