import React from 'react';
import { useField } from 'formik';

import { Dropdown } from '@nuvocargo/nuvo-styleguide/forms/native';
import {
  createAppointmentTypeOptions,
  createIsFifoOptions,
  createTypeOfLoadUnloadOptions,
  getAppointmentInfoAtLocation,
} from './data';
import { useTranslation } from 'react-i18next';

const buildErrors = errors => {
  if (errors?.value) {
    return errors.value;
  }

  return errors;
};

export default function CustomPlaceDropdown({
  onChange: customOnChange,
  setFieldValue,
  type,
  ...props
}) {
  const { t } = useTranslation();

  const [field, meta, helpers] = useField(props);
  const error = meta.touched && buildErrors(meta.error);
  const appointmentTypeOptions = createAppointmentTypeOptions(t);
  const isFifoOptions = createIsFifoOptions(t);
  const typeOfLoadUnloadOptions = createTypeOfLoadUnloadOptions(t);

  const onChange = value => {
    if (customOnChange) {
      customOnChange(value, helpers.setValue);
    }
    if (value.value === 'add') {
      return;
    }
    helpers.setTouched(true);
    helpers.setValue(value);

    // Change everything data from the origin|destination
    const currentPlace = getAppointmentInfoAtLocation(value);
    if (type === 'destination') {
      setFieldValue(
        'lowerBoundForDropoffWindowAt',
        currentPlace.lowerBoundWindow
      );
      setFieldValue(
        'upperBoundForDropoffWindowAt',
        currentPlace.upperBoundWindow
      );
      setFieldValue(
        'appointmentTypeAtDestination',
        appointmentTypeOptions.find(
          option => option.value === currentPlace.appointmentType
        ) || appointmentTypeOptions[0]
      );

      setFieldValue(
        'isAppointmentTypeAtDestinationFifo',
        isFifoOptions.find(option => option.value === currentPlace.fifo) ||
          isFifoOptions[0]
      );

      setFieldValue(
        'typeOfLoadUnloadAtDestination',
        typeOfLoadUnloadOptions.find(
          option => option.value === currentPlace.loadingScheme
        ) || typeOfLoadUnloadOptions[0]
      );
    }

    // Change everything data from the origin|destination
    if (type === 'origin') {
      setFieldValue('readyForPickup', currentPlace.lowerBoundWindow);
      setFieldValue(
        'upperBoundForPickupWindowAt',
        currentPlace.upperBoundWindow
      );
      setFieldValue(
        'appointmentTypeAtOrigin',
        appointmentTypeOptions.find(
          option => option.value === currentPlace.appointmentType
        ) || appointmentTypeOptions[0]
      );

      setFieldValue(
        'isAppointmentTypeAtOriginFifo',
        isFifoOptions.find(option => option.value === currentPlace.fifo) ||
          isFifoOptions[0]
      );

      setFieldValue(
        'typeOfLoadUnloadAtOrigin',
        typeOfLoadUnloadOptions.find(
          option => option.value === currentPlace.loadingScheme
        ) || typeOfLoadUnloadOptions[0]
      );
    }
  };

  return <Dropdown {...field} error={error} {...props} onChange={onChange} />;
}

CustomPlaceDropdown.displayName = 'CustomPlaceDropdown';
