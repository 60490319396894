import React from 'react';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import { Text } from '@nuvocargo/nuvo-styleguide';
import {
  TextField,
  PhoneNumberField,
} from '@nuvocargo/nuvo-styleguide/forms/formik';
import { validationSchema } from './data';
import {
  FormWrapper,
  FlexGroup,
  Subsection,
  INPUT_STYLE,
  NARROW_INPUT_STYLE,
} from './styles';
import Header from '../Header';
import FooterButtons from '../FooterButtons';

const AddLocationForm = ({
  handleBackButtonClick,
  handleSubmit,
  isOrigin,
  location,
  initialValues,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <Header isOrigin={isOrigin} city={location?.city} state={location?.state}>
        {t('route-add-location-complete-this-location')}
      </Header>

      <Formik
        initialValues={{
          ...location,
          ...initialValues,
        }}
        validationSchema={validationSchema(t)}
        onSubmit={handleSubmit}>
        {({ isValid }) => (
          <FormWrapper>
            <Subsection>
              <Text size="large">
                {t('route-add-location-address-information')}
              </Text>
            </Subsection>

            <FlexGroup>
              <TextField
                label={t('routes-form-locationName-label')}
                name="name"
                styles={INPUT_STYLE}
                placeholder={t('routes-form-locationName-placeholder')}
              />
            </FlexGroup>

            <FlexGroup>
              <TextField
                label={t('routes-form-addressLine1-label')}
                name="addressLine1"
                styles={INPUT_STYLE}
                placeholder={t('routes-form-addressLine1-placeholder')}
              />
            </FlexGroup>

            <FlexGroup>
              <TextField
                label={t('routes-form-addressLine2-label')}
                name="addressLine2"
                styles={INPUT_STYLE}
                placeholder={t('routes-form-addressLine2-placeholder')}
              />
            </FlexGroup>

            <FlexGroup>
              <TextField
                label={t('routes-form-city-label')}
                name="city"
                disabled
                styles={NARROW_INPUT_STYLE}
              />

              <TextField
                label={t('routes-form-state-label')}
                name="state"
                disabled
                styles={NARROW_INPUT_STYLE}
              />
            </FlexGroup>

            <FlexGroup style={{ marginBottom: '40px' }}>
              <TextField
                label={t('routes-form-postalCode-label')}
                name="zipCode"
                disabled
                styles={NARROW_INPUT_STYLE}
              />
              <TextField
                label={t('routes-form-country-label')}
                name="country"
                disabled
                styles={NARROW_INPUT_STYLE}
              />
            </FlexGroup>

            <Subsection>
              <Text size="large">
                {t('route-add-location-contact-information')}
              </Text>
            </Subsection>

            <FlexGroup style={{ marginBottom: '50px' }}>
              <PhoneNumberField
                label={t('routes-form-contactPhone-label')}
                name="contactTelephone"
                styles={NARROW_INPUT_STYLE}
                buttonPlaceholder={t('phone-number-country-code-placeholder')}
              />

              <TextField
                label={t('routes-form-contactName-label')}
                name="contactName"
                styles={NARROW_INPUT_STYLE}
              />
            </FlexGroup>

            <FooterButtons
              secondaryButtonText={t('back')}
              handleSecondaryButtonClick={handleBackButtonClick}
              primaryButtonText={t('continue')}
              isPrimaryButtonDisabled={!isValid}
              isSubmit
            />
          </FormWrapper>
        )}
      </Formik>
    </>
  );
};

export default AddLocationForm;
