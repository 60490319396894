import * as yup from 'yup';

export const initialValues = { email: '' };

export const validationSchema = t =>
  yup.object().shape({
    email: yup
      .string()
      .email(t('validation-invalid-email'))
      .required(t('validation-email-required')),
  });
