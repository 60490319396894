import { useQueryParamsState } from 'core/hooks';

const usePagination = totalPages => {
  const [urlPage, gotoPage] = useQueryParamsState('page', 1);
  const currentPage = parseInt(urlPage, 10);
  const nextPage = () => {
    if (isNaN(currentPage) || currentPage === totalPages) return;
    gotoPage(currentPage + 1);
  };
  const previousPage = () => {
    const pageIsFirstOrLess = !(currentPage > 1);
    if (isNaN(currentPage) || pageIsFirstOrLess) return;
    gotoPage(currentPage - 1);
  };

  return {
    gotoPage,
    nextPage,
    previousPage,
  };
};

export default usePagination;
