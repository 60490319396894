import * as yup from 'yup';

export const validationSchema = t =>
  yup.object().shape({
    legQuotes: yup.array().of(
      yup.object().shape({
        legCostCents: yup.number(),
        margin: yup
          .number(t('validation-marginCents-number'))
          .typeError(t('validation-marginCents-number'))
          .required(t('validation-marginCents-required')),
      })
    ),
  });
