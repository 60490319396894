import { isEmpty } from 'ramda';
import { Spinner } from 'components/generic/kit';

export const shipmentEventTypeEnum = {
  ARRIVED_TO_ORIGIN: 'ARRIVED_TO_ORIGIN',
  DELIVERED_TO_DESTINATION: 'DELIVERED_TO_DESTINATION',
  ARRIVED_TO_DESTINATION: 'ARRIVED_TO_DESTINATION',
  ARRIVED_TO_BORDER: 'ARRIVED_TO_BORDER',
};

export const isNewEstimatedPreviousDateGreater = (
  previousEvent,
  currentEvent
) => {
  if (
    isEmpty(previousEvent) &&
    currentEvent.type === shipmentEventTypeEnum.ARRIVED_TO_ORIGIN
  ) {
    return false;
  }

  return (
    previousEvent?.estimatedDatetime?.getTime() >
    currentEvent?.estimatedDatetime?.getTime()
  );
};

export const isPreviousDateGreater = (previousDate, currentDate) => {
  if (!previousDate || !currentDate) return false;
  return previousDate.getTime() > currentDate.getTime();
};

export const isSubmitAvailable = (
  newEstimatedEventsActive,
  canSubmit,
  estimatedDatetime,
  isEstimatedDateRequired
) => {
  if (!newEstimatedEventsActive) {
    return canSubmit && estimatedDatetime;
  }

  return isEstimatedDateRequired && !estimatedDatetime ? false : canSubmit;
};

export const getErrors = errorData => {
  const { id, existErrors, errors, t } = errorData;

  return existErrors
    ? {
        ...errors,
        [id]: t(
          'shipment-configuration-event-error-previous-date-greater-than-current'
        ),
      }
    : { ...errors };
};

const oldValueToCopy = (valueToCopy, type, estimatedDatetime) => {
  return {
    ...valueToCopy,
    [type]: estimatedDatetime
      ? estimatedDatetime.toString()
      : estimatedDatetime,
  };
};

export const getValueToCopy = (
  newEstimatedEventsActive,
  canBeCopied,
  valueToCopy,
  currentEvent
) => {
  const { estimatedDatetime, type } = currentEvent;

  if (!newEstimatedEventsActive) {
    return oldValueToCopy(valueToCopy, type, estimatedDatetime);
  }

  return canBeCopied
    ? {
        ...valueToCopy,
        [type]: estimatedDatetime.toString(),
      }
    : { ...valueToCopy };
};

export const saveButtonMessage = (saveButtonStatus, isSubmitting, t) => {
  if (isSubmitting) {
    return <Spinner color="white" />;
  }

  return {
    default: t('configuration-save-changes-button'),
    saved: t('configuration-saved-changes-button'),
  }[saveButtonStatus];
};

export const findShipmentEventById = (shipmentEvents = [], shipmentEventId) =>
  shipmentEvents.find(event => event.id === shipmentEventId);
