import styled from 'styled-components';

export const DataContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.cream};
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  margin-bottom: 48px;
  padding: 24px 24px 40px;
`;

export const FooterWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;
