import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Card } from 'components/common/app';
import { formatShortDateTime } from 'core/utils/date-fns';
import EstimatedRoute from 'components/generic/kit/EstimatedRoute';
import { shipmentEventTypeEnum } from '../../../utils';

const RouteWrapper = styled.div`
  display: flex;
  padding: 32px;
`;

export default function Details({ shipment }) {
  const { t } = useTranslation();

  const getEstimatedDateFromShipmentEvent = (
    shipmentEvents = [],
    shipmentEventType
  ) =>
    shipmentEvents.find(event => event.type === shipmentEventType)
      ?.estimatedDatetime;

  const pickupDate = getEstimatedDateFromShipmentEvent(
    shipment.shipmentEvents,
    shipmentEventTypeEnum.ARRIVED_TO_ORIGIN
  );

  const deliveryDate = getEstimatedDateFromShipmentEvent(
    shipment.shipmentEvents,
    shipmentEventTypeEnum.DELIVERED_TO_DESTINATION
  );

  return (
    <CardWrapper>
      <Card>
        <RouteWrapper>
          <EstimatedRoute
            title={t('pickup')}
            location={shipment.origin}
            estimatedDatetime={t('estimated-at', {
              estimatedDate: formatShortDateTime(pickupDate),
            })}
          />
          <EstimatedRoute
            title={t('delivery')}
            location={shipment.destination}
            estimatedDatetime={t('estimated-at', {
              estimatedDate: formatShortDateTime(deliveryDate),
            })}
            styles={{ marginLeft: '110px' }}
          />
        </RouteWrapper>
      </Card>
    </CardWrapper>
  );
}

const CardWrapper = styled.div`
  margin-bottom: 32px;
`;
CardWrapper.displayName = 'CardWrapper';
