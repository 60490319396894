import { formatShortDateTime } from 'core/utils/date-fns';
import { useTranslation } from 'react-i18next';
import { shipmentEventTypeEnum } from 'screens/track-and-trace/shipment-details/utils';
import { getEstimatedDateFromShipmentEvent } from 'screens/track-and-trace/shipment-details/_components/details/data';
import {
  buildCustomerShipmentDetailsLink,
  EN,
  ES,
  getIssueKeyFromReason,
} from 'screens/track-and-trace/utils';
import { buildCopyEventTemplateKey } from '../../data';

function formatLocation(location) {
  return `${location.city}, ${location.state} ${location.postalCode}`;
}

/**
 * Build a hash map (plain object) with events/issues hashes as key and
 * message templates as values
 * @param { Object } params
 * @param { import("i18next").i18n } params.i18n
 * @see #buildCopyEventTemplateKey for template 'hash' key format
 */
function buildTemplateContentOptions({ i18n, shipment, upcomingEvent }) {
  const languages = [EN, ES];
  const templates = {};

  const deliveryDate = getEstimatedDateFromShipmentEvent(
    shipment.shipmentEvents,
    shipmentEventTypeEnum.DELIVERED_TO_DESTINATION
  );

  languages.forEach(lang => {
    const expectedAtDestination = Boolean(deliveryDate)
      ? formatShortDateTime(deliveryDate, lang)
      : i18n.t('estimated-pending', { lng: lang });

    // All events
    shipment.shipmentEvents.forEach(event => {
      const templateKey = buildCopyEventTemplateKey({
        event,
        lang,
        type: 'event',
      });
      const translationKey = `shipment-event-clipboard-template-event`;
      const eventName = i18n.t(
        `shipment-configuration-event-name-${event.type}`,
        { lng: lang }
      );
      const options = {
        replace: {
          expectedAtDestination,
          destination: formatLocation(shipment.destination),
          event: eventName,
          origin: formatLocation(shipment.origin),
          shipment: shipment.folioNumber,
          url: buildCustomerShipmentDetailsLink(shipment.id),
        },
        lng: lang,
      };

      templates[templateKey] = i18n.t(translationKey, options);
    });

    // Issues for upcoming event
    upcomingEvent?.shipmentEventIssues?.forEach(issue => {
      const templateKey = buildCopyEventTemplateKey({
        issue,
        lang,
        event: upcomingEvent,
        type: 'issue',
      });
      const translationKey = `shipment-event-clipboard-template-issue`;
      const eventName = i18n.t(
        `shipment-configuration-event-name-${upcomingEvent.type}`,
        { lng: lang }
      );
      const options = {
        replace: {
          expectedAtDestination,
          destination: formatLocation(shipment.destination),
          event: eventName,
          notes: lang === EN ? issue.noteEnglish : issue.noteSpanish,
          origin: formatLocation(shipment.origin),
          reason: getIssueKeyFromReason(issue.reason, i18n.t.bind(i18n), {
            lng: lang,
          }),
          reportedAt: formatShortDateTime(issue.updatedAt, lang),
          shipment: shipment.folioNumber,
          url: buildCustomerShipmentDetailsLink(shipment.id),
        },
        lng: lang,
      };

      templates[templateKey] = i18n.t(translationKey, options);
    });

    // Completed event
    const templateKey = buildCopyEventTemplateKey({ lang, type: 'completed' });
    const completedTranslationKey = `shipment-event-clipboard-template-completed`;
    const completedOptions = {
      replace: {
        destination: formatLocation(shipment.destination),
        origin: formatLocation(shipment.origin),
        shipment: shipment.folioNumber,
        url: buildCustomerShipmentDetailsLink(shipment.id),
      },
      lng: lang,
    };

    templates[templateKey] = i18n.t(completedTranslationKey, completedOptions);
  });

  return templates;
}

export function useCopyEventTemplateOptions(data) {
  const { shipment, upcomingEvent } = data;
  const { t, i18n } = useTranslation();

  const templates = buildTemplateContentOptions({
    i18n,
    shipment,
    t,
    upcomingEvent,
  });

  return {
    templates,
  };
}
