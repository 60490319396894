import { Eyebrow } from '@nuvocargo/nuvo-styleguide';
import { LegRowWrapper } from './styles';

const LegRow = ({
  hasSeparator,
  marginBottom,
  marginTop,
  name,
  paddingBottom,
  rightComponent,
}) => (
  <LegRowWrapper
    hasSeparator={hasSeparator}
    marginBottom={marginBottom}
    marginTop={marginTop}
    paddingBottom={paddingBottom}>
    <Eyebrow text={name} size="small" color="steel" />

    {rightComponent}
  </LegRowWrapper>
);

export default LegRow;
