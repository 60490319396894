import { useEffect, useState } from 'react';
import {
  usePlacesRequest,
  formatPlace,
  placeHasData,
} from 'core/api/placesRequest';

export const RADIO_OPTIONS = {
  selectExistingPlace:
    'We will choose a place that comes from our BE, and select it in our dropdown',
  addNewPlaceData: 'We will manually fill out the place data for this entry',
};

export const useExistingPlace = ({ companyId, geolocationId, addNewData }) => {
  const [selectOption, setSelectOption] = useState(
    RADIO_OPTIONS.selectExistingPlace
  );
  const [selectedPlace, setSelectedPlace] = useState();
  const {
    data: places,
    status,
    isLoading,
    error,
  } = usePlacesRequest(
    {
      geolocationId: geolocationId ? `geolocation-${geolocationId}` : undefined,
      companyId,
    },
    {
      select: data => data.map(formatPlace).filter(placeHasData),
    }
  );

  useEffect(() => {
    // If we're done fetching, and we didn't find any places for this
    // geolocation/company combination, we want to move directly to the "Add"
    // step of the Wizard
    if (status === 'success' && places?.length === 0) {
      addNewData(true);
    } else if (places?.length) {
      // By default, select the first place
      setSelectedPlace(places[0]);
    }
  }, [places, companyId, geolocationId]);

  return {
    error,
    isLoading,
    places,
    selectOption,
    selectedPlace,
    setSelectedPlace,
    setSelectExistingPlace: () => {
      setSelectOption(RADIO_OPTIONS.selectExistingPlace);
    },
    setSelectAddNewPlace: () => {
      setSelectOption(RADIO_OPTIONS.addNewPlaceData);
    },
  };
};
