import styled from 'styled-components';
import { Form } from 'formik';
import { Stagger } from 'components/generic/kit';

export const FormWrapper = styled(Form)`
  display: flex;
  flex-direction: column;
`;

export const Row = ({ children, style, ...props }) => (
  <Stagger.Child
    style={{
      width: '100%',
      marginBottom: '24px',
      ...style,
    }}
    {...props}>
    {children}
  </Stagger.Child>
);
