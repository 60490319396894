import {
  TextCell,
  InputCell,
} from '@nuvocargo/nuvo-styleguide/InputTable/components/Cell';
import { generatePath, useParams, useHistory } from 'react-router-dom';
import { graphQLCountryToFlagString } from '../../../track-and-trace/utils';
import { updateLegQuoteDataFromUserMargin } from '../../store/utils';
import { formatMoney, toCurrency } from 'core/utils';
import { ACTIONS_TYPES } from '../../store/reducer';
import { Icon } from '@nuvocargo/nuvo-styleguide';
import { useTranslation } from 'react-i18next';
import * as paths from 'routes/paths';
import { useMemo } from 'react';

export const createLegPricesColumns = () => {
  const { t } = useTranslation();
  let columns = useMemo(
    () => [
      {
        Header: t('general-origin-text-label'),
        accessor: 'origin',
        Cell: ({ value, ...rest }) => (
          <TextCell
            text={`${value.parents.city}, ${value.name}, ${value.parents.state}`}
            leftIcon={{
              name: graphQLCountryToFlagString(value.parents.country),
            }}
            {...rest}
          />
        ),
      },
      {
        Header: t('general-destination-text-label'),
        accessor: 'destination',
        Cell: ({ value, ...rest }) => (
          <TextCell
            text={`${value.parents.city}, ${value.name}, ${value.parents.state}`}
            leftIcon={{
              name: graphQLCountryToFlagString(value.parents.country),
            }}
            {...rest}
          />
        ),
      },
      {
        Header: t('general-leg-cost-text-label'),
        accessor: 'legCostCents',
        alignment: 'right',
        Cell: ({ value, ...rest }) => {
          return (
            <TextCell
              fontColor="steel"
              text={formatMoney(parseFloat(value / 100), 'usd')}
              {...rest}
            />
          );
        },
      },
      {
        Header: t('general-margin-text-label'),
        accessor: 'margin',
        Cell: ({ onChange, value, ...rest }) => {
          return (
            <InputCell
              value={value}
              onChange={e => onChange(toCurrency(e.target.value))}
              leftComponent={<Icon name="attachMoney" />}
              {...rest}
            />
          );
        },
      },
      {
        Header: t('general-leg-price-text-label'),
        accessor: 'legPriceCents',
        alignment: 'right',
        Cell: ({ row, ...rest }) => {
          const margin = row.margin * 100;
          const value = (row.legCostCents + margin) / 100;
          const legPrice = Math.max(0, value);
          return (
            <TextCell
              fontColor="green"
              text={formatMoney(parseFloat(legPrice), 'usd')}
              {...rest}
            />
          );
        },
      },
    ],
    [t]
  );
  return columns;
};

export const useLegPricesTable = ({ dispatch }) => {
  const history = useHistory();
  const { id: requestId } = useParams();
  const columns = createLegPricesColumns();

  const onSubmit = ({ legQuotes }) => {
    dispatch({
      type: ACTIONS_TYPES.SET_LEG_QUOTES_FROM_USER,
      payload: updateLegQuoteDataFromUserMargin(legQuotes),
    });
    history.push(
      generatePath(paths.app.pricingRequestsReviewAndPublish, {
        id: requestId,
      })
    );
  };

  return {
    columns,
    onSubmit,
  };
};
