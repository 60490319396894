// React & friends
import { useQuery } from 'react-query';

// Utils
import { reduce } from 'lodash';
import { REQUIREMENTS_MAP } from '../utils/commodityUtils';

// API Requests && success/redirect Paths
import { fetchCommodities } from 'core/api/commodities';

export const formatCommodity = r => ({
  ...r,
  selected: true,
  requirement_tags: reduce(
    r.requirements,
    (acc, v, k) => {
      const rMap = REQUIREMENTS_MAP[k];
      return v && rMap ? acc.concat(rMap) : acc;
    },
    []
  ),
});

export default function useCommodities({ companyId } = {}) {
  const { data, isLoading, isFetching, isError } = useQuery(
    ['commodities', companyId],
    () => fetchCommodities(companyId, { perPage: 100 }),
    {
      enabled: !!companyId, // not enabled or items were passed
      select: data => data.records.map(formatCommodity),
    }
  );

  return {
    commodities: data,
    isLoading,
    isFetching,
    isError,
  };
}
