import React from 'react';
import * as Sentry from '@sentry/browser';
import { ErrorPage } from 'components/common/app';
export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, errorInfo) {
    if (process.env.NODE_ENV === 'production') {
      Sentry.captureException(error, { extra: errorInfo });
    }
  }
  static getDerivedStateFromError() {
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      return (
        <ErrorPage
          onClick={() =>
            Sentry.lastEventId() && Sentry.showReportDialog()
          }></ErrorPage>
      );
    } else {
      return this.props.children;
    }
  }
}
