import { Card } from 'components/common/app';
import styled from 'styled-components';

export const ShipmentCard = styled(Card)`
  margin-bottom: 48px;
`;
ShipmentCard.displayName = 'ShipmentCard';

export const LegRowWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 6fr;
  padding: 0px 24px;
  ${({ paddingBottom }) =>
    paddingBottom ? `padding-bottom: ${paddingBottom}` : ''};
  ${({ marginBottom }) =>
    marginBottom ? `margin-bottom: ${marginBottom}` : ''};
  ${({ marginTop }) => (marginTop ? `margin-top: ${marginTop}` : '')};
  ${({ hasSeparator, theme }) =>
    hasSeparator ? `border-bottom: ${theme.outlines.default.border}` : ''};
`;
LegRowWrapper.displayName = 'LegRowWrapper';
