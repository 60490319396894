import {
  CTAButton,
  Grid,
  GridColumn,
  Heading,
  Text,
} from '@nuvocargo/nuvo-styleguide';
import {
  PhoneNumberField,
  Dropdown,
  TextField,
} from '@nuvocargo/nuvo-styleguide/forms/formik';
import { Box, Stack } from 'components/generic/kit';
import { FieldArray, Form, Formik } from 'formik';
import { isEmpty } from 'ramda';
import { useTranslation } from 'react-i18next';
import { theme } from 'styles';

const hubSpotInstructions = t => [
  t('hubspot-error-instruction-1'),
  t('hubspot-error-instruction-2'),
  t('hubspot-error-instruction-3'),
];

const ValidationErrors = ({ errors }) => {
  const { t } = useTranslation();

  return !isEmpty(errors) ? (
    <Box
      styles={{
        root: ({ theme }) => ({
          backgroundColor: theme.colors.red,
          borderRadius: '8px',
          padding: '24px 16px',
          marginTop: '16px',
        }),
      }}>
      <Heading variant="h3" color="white">
        {t('hubspot-error-title')}
      </Heading>
      <Stack as="div" styles={{ root: { margin: '20px 0' } }}>
        {errors.map((error, index) => {
          return (
            <div key={index}>
              <Heading variant="h6b" color="white">
                {error}
              </Heading>
            </div>
          );
        })}
      </Stack>
      <Stack>
        <Heading variant="h6b" color="white">
          {t('hubspot-error-instruction-steps')}
        </Heading>
      </Stack>
      <Stack
        as="ul"
        styles={{ root: { 'list-style-type': 'decimal', color: 'white' } }}>
        {hubSpotInstructions(t).map((error, index) => {
          return (
            <li key={index}>
              <Heading variant="h6b" color="white">
                {error}
              </Heading>
            </li>
          );
        })}
      </Stack>
    </Box>
  ) : null;
};

export default function QuoteVerification({
  initialValues,
  isQuoteRequestLoading,
  onSubmit,
  validationErrors,
}) {
  const { t } = useTranslation();
  return (
    <>
      <Grid styles={{ root: { height: '80px' } }} />
      <Formik
        initialValues={{
          ...initialValues,
          country: {
            label: initialValues.country,
            value: initialValues.country,
          },
          industry: {
            label: initialValues.industry,
            value: initialValues.industry,
          },
        }}
        enableReinitialize
        onSubmit={values => {
          if (!isEmpty(validationErrors)) return;

          onSubmit({
            ...values,
            country: values.country.value,
            industry: values.industry.value,
          });
        }}>
        {({ values }) => (
          <Form>
            <Grid>
              <GridColumn startMd={4} md={6}>
                <Stack space="16px">
                  <Heading variant="h3">
                    {t('general-new-quote-request-title')}
                  </Heading>
                  <Text color="steel">
                    {t('general-new-quote-request-subtitle')}
                  </Text>
                </Stack>
                <ValidationErrors errors={Object.values(validationErrors)} />
                <Stack space="24px" styles={{ root: { marginTop: '48px' } }}>
                  <TextField
                    label={t('general-company-text-label')}
                    name="company"
                    disabled
                  />
                  <Stack axis="horizontal" space="22px">
                    <Dropdown
                      name="country"
                      label={t('general-country-select-label')}
                      styles={{ root: { width: '100%' } }}
                      disabled
                      options={[
                        {
                          value: initialValues?.country,
                          label: initialValues?.country,
                        },
                      ]}
                    />
                    <Dropdown
                      name="industry"
                      label={t('general-industry-select-label')}
                      disabled
                      styles={{ root: { width: '100%' } }}
                      options={[
                        {
                          label: initialValues?.industry,
                          value: initialValues?.industry,
                        },
                      ]}
                    />
                  </Stack>
                </Stack>
                <Stack space="25px" styles={{ root: { marginTop: '65px' } }}>
                  <Heading variant="h6">{t('general-contacts-title')}</Heading>

                  <FieldArray
                    name="contacts"
                    render={() =>
                      values.contacts.map((contact, index) => (
                        <Stack
                          key={index}
                          space="24px"
                          styles={{
                            root: {
                              width: 'calc(100% + 88px)',
                              marginLeft: '-44px',
                              padding: '48px 44px',
                              borderRadius: '8px',
                              border: theme.outlines.default.border,
                            },
                          }}>
                          <Stack axis="horizontal" space="22px">
                            <TextField
                              label={t('general-first-name-label')}
                              name={`contacts[${index}].first_name`}
                              disabled
                              styles={{ root: { width: '100%' } }}
                            />
                            <TextField
                              label={t('general-last-name-label')}
                              name={`contacts[${index}].last_name`}
                              disabled
                              styles={{ root: { width: '100%' } }}
                            />
                          </Stack>
                          <Stack axis="horizontal" space="22px">
                            <TextField
                              label={t('general-email-label')}
                              name={`contacts[${index}].email`}
                              disabled
                              styles={{ root: { width: '100%' } }}
                            />
                            <PhoneNumberField
                              label={t('general-phone-number-optional-label')}
                              name={`contacts[${index}].phone_number`}
                              disabled
                              styles={{ root: { width: '100%' } }}
                              buttonPlaceholder={t(
                                'phone-number-country-code-placeholder'
                              )}
                            />
                          </Stack>
                        </Stack>
                      ))
                    }
                  />
                </Stack>
                <Box
                  styles={{
                    root: { marginTop: '58px', marginBottom: '58px' },
                  }}>
                  <CTAButton
                    type="submit"
                    disabled={
                      !isEmpty(validationErrors) || isQuoteRequestLoading
                    }
                    styles={{
                      primary: {
                        width: '230px',
                        height: '64px',
                      },
                    }}>
                    {t('general-next-step-btn-label')}
                  </CTAButton>
                </Box>
              </GridColumn>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
}
