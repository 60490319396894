/* eslint-disable react/display-name */
import * as FullStory from '@fullstory/browser';
import { useState, useMemo } from 'react';
import { useClipboard } from 'core/hooks/useClipboard';
import { openBanner } from 'core/hooks/useBanner';
import { useUpdateShipmentEventByQueryParam } from 'core/api';
import { useTranslation } from 'react-i18next';
import Banner from './Banner';
import {
  isEstimatedDatetimeRequired,
  initialValuesFromEvents,
  pasteDatesFromClipboardToEvents,
} from './utils';
import {
  getErrors,
  getValueToCopy,
  isNewEstimatedPreviousDateGreater,
  isPreviousDateGreater,
  isSubmitAvailable,
} from '../utils';
import { debounce } from 'lodash';

export const useEventsForm = (
  events,
  shipmentId,
  closeShipmentDetailsView,
  newEstimatedEventsActive,
  isNotificationsConfigHidden
) => {
  const { t } = useTranslation();
  const [eventsValues, setEventsValues] = useState(
    initialValuesFromEvents(
      newEstimatedEventsActive,
      isNotificationsConfigHidden,
      events
    )
  );
  const updateEvent = (eventId, newValue, field) => {
    setEventsValues(values => {
      return {
        ...values,
        [eventId]: {
          ...values[eventId],
          [field]: newValue,
        },
      };
    });
  };
  const updateAllCheckboxes = newValue => {
    setEventsValues(values => {
      let update = {};

      Object.keys(values).forEach(k => {
        const notifyCustomer = values[k].actualDatetime
          ? values[k].notifyCustomer
          : newValue;

        update[k] = {
          ...values[k],
          notifyCustomer,
        };
      });

      return update;
    });
  };

  const shipmentEvents = Object.values(eventsValues).sort(
    (a, b) => a.sequence - b.sequence
  );

  const {
    canSubmit,
    errors,
    globalCheckboxIsDisabled,
    globalCheckboxState,
    valueToCopy,
  } = useMemo(
    () =>
      shipmentEvents.reduce(
        (
          {
            canSubmit,
            errors,
            globalCheckboxIsDisabled,
            globalCheckboxState,
            valueToCopy,
            previousEvent,
          },
          currentEvent,
          index
        ) => {
          const {
            id,
            estimatedDatetime,
            notifyCustomer,
            actualDatetime,
            type,
          } = currentEvent;

          const previousDate =
            eventsValues[events?.[index - 1]?.id]?.estimatedDatetime;

          const globalCheckboxStateUpdate = actualDatetime
            ? globalCheckboxState
            : notifyCustomer;

          const isEstimatedDateRequired = newEstimatedEventsActive
            ? isEstimatedDatetimeRequired(type)
            : true;

          const isPrevDateGreater = newEstimatedEventsActive
            ? isNewEstimatedPreviousDateGreater(previousEvent, currentEvent)
            : isPreviousDateGreater(previousDate, estimatedDatetime);

          const errorData = {
            errors,
            id,
            existErrors: isEstimatedDateRequired && isPrevDateGreater,
            t,
          };

          return {
            canSubmit: isSubmitAvailable(
              newEstimatedEventsActive,
              canSubmit,
              estimatedDatetime,
              isEstimatedDateRequired
            ),
            errors: getErrors(errorData),
            globalCheckboxIsDisabled:
              globalCheckboxIsDisabled && actualDatetime,
            globalCheckboxState:
              globalCheckboxState || globalCheckboxStateUpdate,
            valueToCopy: getValueToCopy(
              newEstimatedEventsActive,
              isEstimatedDateRequired && estimatedDatetime,
              valueToCopy,
              currentEvent
            ),
            previousEvent: isEstimatedDateRequired
              ? currentEvent
              : previousEvent,
          };
        },
        {
          canSubmit: true,
          errors: {},
          globalCheckboxIsDisabled: true,
          globalCheckboxState: false,
          valueToCopy: {},
          previousEvent: {},
        }
      ),
    [eventsValues]
  );
  const updateShipmentEventMutation = useUpdateShipmentEventByQueryParam({
    isNewEstimatedEventsActive: newEstimatedEventsActive,
  });

  const submitEvents = (newEstimatedEventsActive, setSaveButtonStatus) => {
    if (!canSubmit || !shipmentId || globalCheckboxIsDisabled) return;
    const updates = Object.values(eventsValues).reduce(
      (
        acc,
        { id, estimatedDatetime, notifyCustomer, actualDatetime, type }
      ) => {
        if (actualDatetime) return acc;
        return [
          ...acc,
          {
            id,
            estimatedDatetime,
            ...(isNotificationsConfigHidden ? {} : { notifyCustomer }),
            type,
          },
        ];
      },
      []
    );

    return updateShipmentEventMutation.mutate(updates, {
      onSuccess: () => {
        FullStory.event('Added Estimated Dates to Shipment Event', {
          ...updates,
          shipmentId,
        });

        if (newEstimatedEventsActive) {
          setSaveButtonStatus('saved');
          debounce(() => setSaveButtonStatus('default'), 5000)();
        } else {
          openBanner({
            key: 'configuration-submit-form-success',
            timeout: 5000,
            children: () => <Banner />,
          });
          closeShipmentDetailsView();
        }
      },
    });
  };

  const handlePaste = () => {
    if (updateShipmentEventMutation.isLoading || !eventsValues) return;

    setEventsValues(
      pasteDatesFromClipboardToEvents(estimatedDatesClipboard, eventsValues)
    );

    FullStory.event('Pasted Estimated Dates into Another Shipment', {
      estimatedDatesClipboard,
    });
  };
  const {
    clipboardValue: estimatedDatesClipboard,
    hasCopiedRecently: hasCopiedEstimatedDatesRecently,
    hasPastedRecently: hasPastedEstimatedDatesRecently,
    setClipboardValue: setEstimatedDatesClipboard,
    setPasted: setEstimatedDatesPasted,
  } = useClipboard('configuration-view-estimated-dates', {
    handlePaste,
    valueToCopy,
  });
  const pasteAndSetPasted = () => {
    handlePaste();
    setEstimatedDatesPasted();
  };

  return {
    canSubmit,
    errors: Object.keys(errors).length ? errors : null,
    eventsValues,
    handleCopy: () => {
      setEstimatedDatesClipboard(valueToCopy);
    },
    handlePaste: pasteAndSetPasted,
    hasCopiedEstimatedDatesRecently,
    hasPastedEstimatedDatesRecently,
    isSubmitting: updateShipmentEventMutation.isLoading,
    setEstimatedDatesPasted,
    submitEvents,
    updateEvent,
    ...(isNotificationsConfigHidden
      ? {}
      : {
          globalCheckboxIsDisabled,
          globalCheckboxState,
          updateAllCheckboxes,
        }),
  };
};
