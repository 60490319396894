import React from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox } from '@nuvocargo/nuvo-styleguide/forms/formik';
import { Heading } from '@nuvocargo/nuvo-styleguide';
import { Stack } from 'components/generic/kit';
import { ADDITIONAL_SERVICES } from 'core/utils/routeUtils';

const AdditionalServicesToggles = () => {
  const { t } = useTranslation();

  const additionalServices = ADDITIONAL_SERVICES(t);

  return (
    <Stack space="22px" styles={{ root: { marginTop: '80px' } }}>
      <Heading variant="h5b">{t('additional-services')}</Heading>

      <Stack space="12px">
        {additionalServices.map(as => (
          <Checkbox
            styles={{
              root: { width: 23, height: 23 },
              label: { fontSize: '16px', marginLeft: '12px' },
            }}
            key={as.value}
            id={as.value}
            name="additional_services"
            label={as.label}
            value={as.value}
          />
        ))}
      </Stack>
    </Stack>
  );
};

export default AdditionalServicesToggles;
