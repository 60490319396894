import React from 'react';
import { Checkbox as StyleCheckbox } from '@nuvocargo/nuvo-styleguide/forms/formik';
import { useTranslation } from 'react-i18next';
import { Text } from '@nuvocargo/nuvo-styleguide';

const Checkbox = ({ labelText, descriptionText, ...props }) => {
  return (
    <label className="flex w-full">
      <div className="order-last ml-4 flex flex-col">
        <Text>{labelText}</Text>
        <Text size="small" color="steel">
          {descriptionText}
        </Text>
      </div>
      <StyleCheckbox {...props} size="medium" />
    </label>
  );
};

const TrailerSpecification = () => {
  const { t } = useTranslation();

  return (
    <div>
      <Text color="green" size="large" fontWeight="bold">
        {t('commodity-modal-trailer-specification')}
      </Text>
      <div className="mt-6 grid grid-cols-2 gap-6">
        <Checkbox
          id="food-grade"
          name="trailerSpecifications.foodGrade"
          labelText={t('trailer-specification-food-grade')}
          descriptionText={t('trailer-specification-food-grade-description')}
        />
        <Checkbox
          id="modern-units"
          name="trailerSpecifications.modernUnits"
          labelText={t('trailer-specification-modern-units')}
          descriptionText={t('trailer-specification-modern-units-description')}
        />
        <Checkbox
          id="odorless"
          name="trailerSpecifications.odorless"
          labelText={t('trailer-specification-odorless')}
          descriptionText={t('trailer-specification-odorless-description')}
        />
        <Checkbox
          id="fumigation"
          name="trailerSpecifications.fumigation"
          labelText={t('trailer-specification-fumigation')}
          descriptionText={t('trailer-specification-fumigation-description')}
        />
        <Checkbox
          id="humidity-controlled"
          name="trailerSpecifications.humidityControlled"
          labelText={t('trailer-specification-humidity-controlled')}
          descriptionText={t(
            'trailer-specification-humidity-controlled-description'
          )}
        />
        <Checkbox
          id="lightproof"
          name="trailerSpecifications.lightproof"
          labelText={t('trailer-specification-lightproof')}
          descriptionText={t('trailer-specification-lightproof-description')}
        />
      </div>
    </div>
  );
};

export default TrailerSpecification;
