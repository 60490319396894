import { useQuery } from 'react-query';

import { getPlaces } from '../../api/places';

export const useGetPlacesByCompanyAndZipCode = (
  { geolocationId, companyId },
  queryOptions
) =>
  useQuery(
    ['places', companyId, geolocationId],
    ({ signal }) =>
      getPlaces({
        geolocationId,
        companyId,
        signal,
      }),
    queryOptions
  );
