import Header from '../header';
import Details from '../details';

export default function HeadContent({ shipment }) {
  return (
    <>
      <Header
        name={shipment.fileName}
        reference={shipment.customerReference}
        trialPeriod={shipment.trialPeriod}
      />
      <Details shipment={shipment} />
    </>
  );
}
