/* eslint-disable react/jsx-key */
import { Pagination } from '@nuvocargo/nuvo-styleguide';
import { Flex, MovingTruck } from 'components/generic/kit';
import { isEmpty } from 'ramda';
import React from 'react';
import { useTable } from 'react-table';
import clsx from 'clsx';
import NoResults from './NoResults';
import { PaginationWrapper, StyledTable } from './styles';
import { usePagination } from './usePagination';

export default function Table({
  columns,
  data,
  dataTestId,
  paginationDataTestId,
  isLoading,
  page,
  setPage,
  totalPages,
}) {
  const { gotoPage, nextPage, previousPage } = usePagination({
    page,
    setPage,
    totalPages,
  });
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data,
    });

  return !isLoading && isEmpty(data) ? (
    <NoResults />
  ) : isLoading ? (
    <Flex
      styles={{
        root: {
          width: '100%',
          height: '50vh',
          justifyContent: 'center',
          alignItems: 'center',
        },
      }}>
      <MovingTruck />
    </Flex>
  ) : (
    <StyledTable>
      <table data-testid={dataTestId} {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps()}>{column.render('Header')}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => (
                  <td
                    className={clsx({
                      'clickable-cell': cell.column.isLink,
                      'shrink-to-content': cell.column.shrinkToContent,
                    })}
                    {...cell.getCellProps()}>
                    {cell.render('Cell')}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
      <PaginationWrapper>
        {totalPages && (
          <Pagination
            data-testid={paginationDataTestId}
            pageCount={totalPages}
            pageIndex={page - 1}
            nextPage={nextPage}
            previousPage={previousPage}
            gotoPage={page => {
              // The Pagination component is 0 indexed, our API is indexed at 1,
              // so we need to do an offset.
              gotoPage(page + 1);
            }}
          />
        )}
      </PaginationWrapper>
    </StyledTable>
  );
}
