import React from 'react';
import { useTranslation } from 'react-i18next';
import { Formik, Form } from 'formik';
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import {
  Grid,
  GridColumn,
  CTAButton,
  Link as StyledLink,
  Text,
  Heading,
} from '@nuvocargo/nuvo-styleguide';
import { UnauthenticatedLayout as Layout } from 'components/common/layout';
import { TextField, Password } from '@nuvocargo/nuvo-styleguide/forms/formik';
import { ErrorMessage } from 'components/generic/form';
import { Stack } from 'components/generic/kit';
import { initialValues, validationSchema } from './data';
import { useSignIn } from './hooks';

export default function Login() {
  const { t } = useTranslation();
  const { error, isSigningIn, signIn } = useSignIn();
  return (
    <Layout>
      <Grid
        styles={{
          root: {
            alignItems: 'center',
            gridTemplateRows: '100vh',
          },
        }}>
        <GridColumn startMd={5} md={4} sm={4}>
          <Formik
            onSubmit={signIn}
            validationSchema={validationSchema(t)}
            initialValues={initialValues}>
            <Form>
              <Stack space={48}>
                <Header>
                  <Heading variant="h3" color="green">
                    {t('sign-in-title')}
                  </Heading>
                  <Text color="steel">{t('sign-in-subtitle')}</Text>
                </Header>

                <Stack space={24}>
                  <TextField
                    label={t('email')}
                    type="email"
                    name="email"
                    placeholder={t('placeholder-email')}
                  />
                  <Password
                    label={t('password')}
                    name="password"
                    placeholder={t('placeholder-password')}
                  />
                  <StyledLink to="/password/forgot" as={Link}>
                    {t('link-forgot-password')}
                  </StyledLink>
                  {error && error.data && (
                    <Row>
                      <ErrorMessage backgroundColor="taupe">
                        {t(`internal-login-error-${error.data.key}`)}
                      </ErrorMessage>
                    </Row>
                  )}
                </Stack>

                <Stack space={24} center>
                  <CTAButton disabled={isSigningIn} type="submit">
                    {t('btn-login')}
                  </CTAButton>
                  <StyledLink to="/welcome/user-information" as={Link}>
                    {t('link-dont-have-account')}
                  </StyledLink>
                </Stack>
              </Stack>
            </Form>
          </Formik>
        </GridColumn>
      </Grid>
    </Layout>
  );
}

const Row = styled.div`
  margin: ${({ verticalSpacing }) =>
      verticalSpacing ? verticalSpacing : '40px'}
    0;
  ${({ alignCenter }) =>
    alignCenter &&
    css`
      display: flex;
      justify-content: center;
    `}
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  & > * + * {
    margin-top: 16px;
  }
`;
