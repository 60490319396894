import React from 'react';
import {
  PageHeaderProvider,
  UnauthenticatedHeader,
} from '@nuvocargo/nuvo-styleguide';
import { useTranslation } from 'react-i18next';

export default function UnauthenticatedLayout({ children }) {
  const { i18n } = useTranslation();

  const onLangChange = (_event, lang) => {
    i18n.changeLanguage(lang.value);
  };

  return (
    <PageHeaderProvider>
      <UnauthenticatedHeader
        selectedLang={i18n.language}
        onLangChange={onLangChange}
      />
      {children}
    </PageHeaderProvider>
  );
}
