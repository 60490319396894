import { useState } from 'react';
import { app } from 'routes';
import { useParams, useHistory } from 'react-router-dom';
import {
  useRouteRequest,
  useCreateShipment as useCreateShipmentMutation,
  usePlacesRequest,
  formatPlace,
  placeHasData,
  useCompanies,
} from 'core/api';
import { useLocalStorageState } from 'core/hooks';
import * as FullStory from '@fullstory/browser';

export const useCreateShipment = t => {
  const [locationModalState, setLocationModalState] = useState();
  const [origin, setOrigin] = useState();
  const [destination, setDestination] = useState();
  const [customerOperationsProfile, setCustomerOperationsProfile] =
    useLocalStorageState('customerOperationsProfile', {});
  const { push } = useHistory();
  const { id } = useParams();

  const {
    data: route,
    isLoading: isRouteLoading,
    error: isRouteError,
  } = useRouteRequest(
    {
      id,
    },
    {
      select: route => {
        // Turn the route's company ID into a string to match GraphQL endpoints
        if (typeof route?.company?.id !== 'undefined') {
          return {
            ...route,
            company: {
              ...route.company,
              id: `${route.company.id}`,
            },
          };
        }

        return route;
      },
    }
  );

  const {
    isLoading: isCompaniesLoading,
    data: companies,
    isError: isCompaniesError,
  } = useCompanies(
    { businessType: 'CUSTOMER', childrenCompanies: +route?.company?.id },
    {
      enabled: !!route,
      select: data =>
        data.length <= 0
          ? []
          : [
              {
                label: t('no-company-selected'),
                value: null,
              },
              ...data.map(({ id, legalName }) => ({
                value: id,
                label: legalName,
              })),
            ],
    }
  );

  const { data: origins, isLoading: isOriginLoading } = usePlacesRequest(
    {
      geolocationId: `geolocation-${route?.origin?.geolocationId}`,
      companyId: route?.company?.id,
    },
    {
      // Only fetch once we have a route
      enabled: !!route?.id,
      select: createPlacesSelect(t, 'origin'),
      onSuccess: maybeSetLocation(origin, setOrigin, route?.origin?.id),
    }
  );
  const { data: destinations, isLoading: isDestinationLoading } =
    usePlacesRequest(
      {
        geolocationId: `geolocation-${route?.destination?.geolocationId}`,
        companyId: route?.company?.id,
      },
      {
        // Only fetch once we have a route
        enabled: !!route?.id,
        select: createPlacesSelect(t, 'destination'),
        onSuccess: maybeSetLocation(
          destination,
          setDestination,
          route?.destination?.id
        ),
      }
    );

  const {
    isLoading: isSubmitting,
    mutate: createShipment,
    error: shipmentCreateError,
    isError: isShipmentError,
  } = useCreateShipmentMutation(({ data: newShipment }) => {
    const company = route.company.id;

    setCustomerOperationsProfile({
      ...customerOperationsProfile,
      [company]: {
        ...customerOperationsProfile[company],
        loadInstructions: newShipment.loadInstructions,
      },
    });

    FullStory.event('Created a Shipment', {
      newShipment,
    });

    push({
      pathname: app.trackAndTrace,
    });
  });

  const onPlaceSave = place => {
    if (locationModalState.property === 'origin') {
      return setOrigin(formatPlace(place));
    }
    setDestination(formatPlace(place));
  };

  return {
    companies,
    createShipment,
    destination,
    destinations,
    isCompaniesError,
    isLoading:
      isRouteLoading ||
      isCompaniesLoading ||
      isOriginLoading ||
      isDestinationLoading,
    isRouteError,
    isShipmentError,
    isSubmitting,
    legsCrossMexicanBorder: checkIfLegsCrossMexicanBorder(
      route?.origin?.zipCode?.parents?.country,
      route?.destination?.zipCode?.parents?.country
    ),
    loadInstructions:
      customerOperationsProfile[route?.company?.id]?.loadInstructions ?? '',
    locationModalState,
    onPlaceSave,
    origin,
    origins,
    route,
    setDestination,
    setLocationModalState,
    setOrigin,
    shipmentCreateError,
  };
};

export const MEXICO_COUNTRY = 'mx';

export const checkIfLegsCrossMexicanBorder = (firstCountry, secondCountry) => {
  const bothCountriesHaveValues = !!firstCountry && !!secondCountry;
  const oneCountryIsMexicoAndOtherIsNot =
    (firstCountry === MEXICO_COUNTRY && secondCountry !== MEXICO_COUNTRY) ||
    (firstCountry !== MEXICO_COUNTRY && secondCountry === MEXICO_COUNTRY);

  return bothCountriesHaveValues && oneCountryIsMexicoAndOtherIsNot;
};

const createPlacesSelect =
  (t, type = 'origin') =>
  data => {
    const result = data.map(formatPlace).filter(placeHasData);

    result.push({
      label: t(`add-new-${type}-option`),
      value: 'add',
    });

    return result;
  };

const maybeSetLocation =
  (currentLocationValue, setCurrentLocationValue, routeLocationId) => data => {
    // We only set a location's value if we don't have one already
    if (!currentLocationValue) {
      setCurrentLocationValue(
        data.find(({ id }) => id === `place-${routeLocationId}`)
      );
    }
  };
