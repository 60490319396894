import * as Sentry from '@sentry/browser';
import SentryFullStory from '@sentry/fullstory';
import { Integrations } from '@sentry/tracing';

export default () => {
  Sentry.init({
    environment: process.env.NODE_ENV,
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [
      new SentryFullStory('nuvocargo'),
      new Integrations.BrowserTracing(),
    ],
    tracesSampleRate: 0.5,
  });
};
