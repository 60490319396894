import React from 'react';

export default function useModal() {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const closeModal = () => setIsModalOpen(false);
  const openModal = () => setIsModalOpen(true);

  return {
    isModalOpen,
    closeModal,
    openModal,
  };
}
