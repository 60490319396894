import { curry } from 'ramda';

export const hasUpperCase = val => new RegExp(/[A-Z]/).test(val);

export const hasNumber = val => new RegExp(/[0-9]/).test(val);

export const hasMinLength = curry((min, val) => val?.length >= min);

// List taken from https://owasp.org/www-community/password-special-characters
export const hasSpecialChars = val =>
  new RegExp(/[ !"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/).test(val);
