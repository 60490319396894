import { canada, mexico, usa } from '@nuvocargo/nuvo-styleguide/Icons/Flags';
import Papa from 'papaparse';

export function capitalizeString(string) {
  let capsArray = string
    ?.toLowerCase()
    ?.split(' ')
    ?.map(word => {
      return word.replace(word[0], word[0].toUpperCase());
    });

  return capsArray?.join(' ');
}

export const optionToValue = option => option.value;

const formatUSD = amount => {
  return amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
};

export const formatMoney = (amount, formatOptions = {}) =>
  new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    ...formatOptions,
  }).format(amount);

export const formatMoneyWithCurrency = (
  amount,
  currency,
  formatOptions = {}
) => {
  currency = currency ?? 'USD';
  return `${formatMoney(amount, formatOptions)} ${currency.toUpperCase()}`;
};

const priceToDecimal = amount => {
  return amount.toFixed(2);
};

const trimFileExtension = fileName => {
  return fileName.replace(/\.[^/.]+$/, '');
};

const pluralize = (count, t) => {
  return `${count} ${+count !== 1 ? t('trucks') : t('truck')}`;
};

const thousandCommaSeparator = amount => {
  return Number(amount).toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

export {
  formatUSD,
  priceToDecimal,
  pluralize,
  trimFileExtension,
  thousandCommaSeparator,
};

export const differenceInDaysFromNow = date => {
  const today = new Date();
  const dateA = new Date(date);
  var diff = today.getTime() - dateA.getTime();
  return Math.floor(diff / (1000 * 3600 * 24));
};

export function toTitleCase(str) {
  return str
    ? str.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      })
    : '';
}

// Returns a function, that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// N milliseconds. If `immediate` is passed, trigger the function on the
// leading edge, instead of the trailing.
export const debounce = (func, wait, immediate) => {
  var timeout;
  return function () {
    var context = this,
      args = arguments;
    var later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
};

/**
 * Debounces promises. Immediately returns a promise, and allows itself to keep
 * getting called. Once the timeout of the debounce clears, it resolves the
 * last promise to all the previous promise calls. Which makes it so that you
 * only call your async resource once.
 *
 * @param { Number } wait Time to wait in milliseconds.
 */
export const debouncePromise = (func, wait) => {
  let timeout;
  let calls = [];

  return function (...args) {
    return new Promise(resolve => {
      clearTimeout(timeout);

      timeout = setTimeout(() => {
        timeout = null;

        const result = func.apply(this, args);

        calls.forEach(resolve => {
          resolve(result);
        });

        calls = [];
      }, wait);

      calls.push(resolve);
    });
  };
};

//Returns mexico or USA depending on the country name coming from the backend
export const getCountryName = countryName => {
  switch (countryName) {
    case 'USA':
      return 'usa';
    case 'Mexico':
      return 'mexico';
    case 'Canada':
      return 'canada';
    default:
      return undefined;
  }
};

export const isDevelopment = process.env.NODE_ENV === 'development';

export const countryShortNameToFlag = country =>
  ({
    US: usa,
    MX: mexico,
    CA: canada,
  }[country.toUpperCase()]);

export const arrayToObject = (arr, keys) =>
  arr.reduce((acc, item, i) => ({ ...acc, [keys[i]]: item }), {});

/**
 * @typedef ParsedCsvRoutes
 * @property {string} originCode
 * @property {string} originFlag
 * @property {string} destinationCode
 * @property {string} destinationFlag
 */

/**
 *
 * @param {String} csvString, example: 'originCode, originFlag, destinationCode, destinationFlag\n1213, US, 12312, MX'
 * @returns {Promise<Array.<ParsedCsvRoutes>>}
 */
export const parseCsvToRoutes = async csvString => {
  const headers = [
    'originCode',
    'originFlag',
    'destinationCode',
    'destinationFlag',
    'loadsPerMonth',
  ];

  const parseHeaders = record =>
    headers.reduce(
      (acc, header, i) => ({
        ...acc,
        [header]: record[i],
      }),
      {}
    );

  const parsedCsv = await new Promise((resolve, reject) =>
    Papa.parse(csvString, {
      header: false,
      complete: results =>
        resolve(results.data?.slice(1)?.map(parseHeaders) || []),
      error: err => reject(err),
      skipEmptyLines: true,
    })
  );

  return parsedCsv;
};

export const getFile = fileList => Array.from(fileList)[0];

export const isFileCsv = file =>
  file?.type?.toLowerCase() === 'type/csv' ||
  file?.name?.toLowerCase().endsWith('.csv');

export const convertRouteCountryToStandardCountry = country => {
  switch (country) {
    case 'mx': {
      return 'mexico';
    }

    case 'us': {
      return 'usa';
    }

    default: {
      return country;
    }
  }
};

export const convertRouteLocationToStandardLocation = (routeLocation, t) => ({
  country: convertRouteCountryToStandardCountry(
    routeLocation?.zipCode?.country
  ),
  name:
    routeLocation?.name && routeLocation?.addressLine1
      ? routeLocation?.name
      : t('location-missing'),
  city: routeLocation?.zipCode?.parents?.city,
});

export const getUsedLanguage = language =>
  language?.startsWith('es') ? 'es' : 'en';

export const formatAddress = ({
  addressLine1,
  addressLine2,
  zipCode,
  city,
  state,
  country,
}) =>
  [
    [addressLine1, addressLine2].filter(s => !!s).join(' '),
    zipCode,
    city,
    state,
    country,
  ]
    .filter(s => !!s)
    .join(', ');

const throwForUnhandledFilter = (filtersHt, field) => {
  if (!filtersHt[field]) {
    throw new Error(
      `Unhandled filter called with value ${field}, all cases must be handled in createFilterFunctions.`
    );
  }
};

/**
 * Creates filters for our Table component
 *
 * @param {Array<{ field: String, items: Array, set: (value: String) => void, value: any }>} filters
 */
export const createFilters = filters => {
  const filtersHt = filters.reduce(
    (acc, curr) => ({
      ...acc,
      [curr.field]: curr,
    }),
    {}
  );

  return {
    filters,
    onFilterChange: ({ field, value }) => {
      throwForUnhandledFilter(filtersHt, field);

      // Set the next value of the current filter
      filtersHt[field].set(value);
    },
    // Figures out whether the currently selected item if given a full array of options
    selected: ({ field, items }) =>
      items?.find(({ value }) => {
        throwForUnhandledFilter(filtersHt, field);

        // Set the next value of the current filter
        return filtersHt[field].value === value;
      }),
  };
};

export const buildYesNoDropdownOptions = t => [
  { label: t('quote-request-yes-label'), value: true },
  { label: t('quote-request-no-label'), value: false },
];

export const centsToDollar = value => value / 100;

/**
 * Converts USD cents to dollars
 * @param { number } amount
 */
export const centsToDollars = amount => {
  if (isNaN(amount)) {
    return 0;
  }

  return amount / 100;
};

/**
 * Format `weight`
 * @param { number } weight
 * @param { Intl.NumberFormatOptions } formatOptions
 */
export const formatWeight = (weight, formatOptions = {}) =>
  new Intl.NumberFormat('en-US', {
    style: 'unit',
    unit: 'kilogram',
    ...formatOptions,
  }).format(weight);
