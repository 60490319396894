import {
  useEffect,
  useImperativeHandle,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';
import { getFile, isFileCsv, parseCsvToRoutes } from 'core/utils/lib';
import { getGeolocations } from 'core/api/geolocations';
import * as R from 'ramda';

const useModal = () => {
  const [modalState, setModalState] = useState('hide');
  const closeModal = () => setModalState('hide');
  const showCSVModal = () => setModalState('show');
  const showAffordance = () => setModalState('hint');
  return {
    closeModal,
    showCSVModal,
    showAffordance,
    modalState,
  };
};

export const useHandleFileDrop = ({ ref, onFileDrop }) => {
  const { closeModal, showCSVModal, modalState, showAffordance } = useModal();
  // Handle DnD Fns
  const handleDragOver = e => e.preventDefault();
  const handleDragEnter = e => (e.preventDefault(), showAffordance);
  const handleDragLeave = e => {
    e.preventDefault();
    if (!e.relatedTarget) closeModal();
  };

  const handleDrop = async e => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    const file = getFile(files);
    if (!isFileCsv(file)) {
      alert('Could not generate routes successfully. Invalid CSV Format');
      if (!e.relatedTarget) closeModal();
      return;
    }
    const text = await file.text();
    const isFileDropped = await onFileDrop(text);

    if (isFileDropped) {
      showCSVModal();
    } else {
      alert('Could not generate routes successfully. Invalid CSV Format');
    }
  };

  const handleImperativeUpload = async text => {
    const isFileDropped = await onFileDrop(text);
    if (isFileDropped) {
      showCSVModal();
    } else {
      alert('Could not generate routes successfully. Invalid CSV Format');
    }
  };

  useEffect(() => {
    window.addEventListener('drop', handleDrop);
    window.addEventListener('dragover', handleDragOver);
    window.addEventListener('dragenter', handleDragEnter);
    window.addEventListener('dragleave', handleDragLeave);
    return () => {
      window.removeEventListener('drop', handleDrop);
      window.removeEventListener('dragover', handleDragOver);
      window.removeEventListener('dragenter', handleDragEnter);
      window.removeEventListener('dragleave', handleDragLeave);
    };
  }, []);

  useImperativeHandle(ref, () => ({
    uploadCsv: handleImperativeUpload,
  }));

  return {
    modalState,
    closeModal,
  };
};

const getCityFromPostalCode = async ({ postCode, country }) => {
  const lowerCaseCountry = country.toLowerCase();
  const res = await getGeolocations({
    filterName: postCode,
    filterType: 'zipCode',
  });
  return res?.records?.find(
    ({ name, parents: { country } }) =>
      name === postCode && country === lowerCaseCountry
  );
};

export const useRoutes = () => {
  const [routesKeys, setRoutesKeys] = useState([]);
  const [places, setPlaces] = useState({});

  const getRoute = ({ origin, destination, loadsPerMonth }) => ({
    origin: places[origin],
    destination: places[destination],
    loads_per_month: loadsPerMonth,
  });

  const parseFile = file => {
    const extractUniqPlaces = async parsedRoutes => {
      const uniqPlaces = R.uniq(
        parsedRoutes.flatMap(route => [
          {
            postCode: route.originCode,
            country: route.originFlag,
            key: `${route.originCode}-${route.originFlag}`,
          },
          {
            postCode: route.destinationCode,
            country: route.destinationFlag,
            key: `${route.destinationCode}-${route.destinationFlag}`,
          },
          {
            loadsPerMonth: route.loadsPerMonth,
          },
        ]),
        'key'
      );
      // Initiate places
      const initialPlaces = uniqPlaces.reduce(
        (acc, { key, postCode, country }) => ({
          ...acc,
          [key]: {
            preview: {
              postCode: postCode,
              country: country,
              isLoading: true,
              error: false,
              city: undefined,
            },
            value: {},
          },
        }),
        {}
      );
      setPlaces(initialPlaces);
      return parsedRoutes;
    };

    const buildRoutesKeys = parsedRoutes => {
      // Create Route Keys (eg: 10013-US, 11560-MX)
      const routesKeys = parsedRoutes.map(route => ({
        origin: `${route.originCode}-${route.originFlag}`,
        destination: `${route.destinationCode}-${route.destinationFlag}`,
        loadsPerMonth: route.loadsPerMonth,
      }));
      setRoutesKeys(routesKeys);
      return true;
    };

    return parseCsvToRoutes(file)
      .then(extractUniqPlaces)
      .then(buildRoutesKeys)
      .catch(() => {
        return false;
      });
  };

  useLayoutEffect(() => {
    const handleError = key => {
      setPlaces(prev => ({
        ...prev,
        [key]: {
          ...prev[key],
          preview: {
            ...prev[key].preview,
            error: true,
            isLoading: false,
          },
        },
      }));
    };
    if (!R.isEmpty(places)) {
      Promise.all(
        Object.keys(places).map(async key => {
          const {
            preview: { postCode, country },
          } = places[key];
          try {
            if (!postCode || !country) handleError(key);
            const route = await getCityFromPostalCode({ postCode, country });
            if (!route) return handleError(key);
            setPlaces(prev => {
              // If we cleared the state while loading, we do not update.
              // Probably someone closed the modal before we finished loading.
              if (!prev[key]) return prev;
              return {
                ...prev,
                [key]: {
                  ...prev[key],
                  preview: {
                    ...prev[key].preview,
                    city: route.parents.city,
                    isLoading: false,
                  },
                  value: route,
                },
              };
            });
          } catch (e) {
            handleError(key);
          }
        })
      );
    }
  }, [routesKeys]);

  const totalCreated = useMemo(() => {
    if (R.isEmpty(places)) return;
    const totalCreated = routesKeys.filter(key => {
      const route = getRoute(key);
      return (
        !route.origin.preview.isLoading &&
        !route.destination.preview.isLoading &&
        !R.isEmpty(route.origin.value) &&
        !R.isEmpty(route.destination.value)
      );
    }).length;
    return totalCreated;
  }, [places]);

  const isAnyRowLoading = useMemo(() =>
    Object.values(places).some(place => !!place?.preview?.isLoading)
  );

  return {
    parseFile,
    getRoute,
    places,
    routesKeys,
    totalCreated,
    isAnyRowLoading,
    resetState: () => {
      setRoutesKeys([]);
      setPlaces({});
    },
  };
};
