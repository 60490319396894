import { useState, useEffect } from 'react';
import { useUpdatePlace, useUpdateRoute, useCreatePlace } from 'core/api';
import { useParams } from 'react-router-dom';
import { createConfirmationData } from './utils';
import { placeIdToNumber } from '../utils';

export const STEPS = {
  SELECT: 'Select route or add new information toggles',
  FILL: 'Fill data in form',
  FORCED_FILL:
    'No place data, editing, or creating, so we forcefully redirected to FILL',
  CONFIRM_FORCED_PLACE_UPDATE:
    'Cannot edit route, but have filled out the form and get to confirm data is correct',
  CONFIRM_PLACE_UPDATE:
    'Confirm data is correct, and update the places endpoints',
  CONFIRM_ROUTE_UPDATE:
    'Confirm data is correct, and change the place for our route',
};

export const useSavePlace = ({
  closeModal,
  onSaveCallback,
  updateProperty,
  updateType,
}) => {
  const [existingPlace, setExistingPlace] = useState();
  const [fillFormData, setFillFormData] = useState({});
  const [wizardStep, setWizardStep] = useState();
  const params = useParams();

  const resetAndExit = () => {
    setWizardStep();
    setFillFormData({});
    setExistingPlace();
    closeModal();
  };
  const onSuccessCallback = (...params) => {
    onSaveCallback?.(...params);
    resetAndExit();
  };
  const goBackToSelect = () => {
    setWizardStep(STEPS.SELECT);
  };

  const { isLoading: placeIsUpdating, mutate: updatePlace } =
    useUpdatePlace(onSuccessCallback);
  const { isLoading: routeIsUpdating, mutate: updateRoute } = useUpdateRoute({
    onSuccessCallback,
    id: params.id,
    updateProperty,
  });
  const { isLoading: placeIsCreating, mutate: createPlace } =
    useCreatePlace(onSuccessCallback);

  useEffect(() => {
    switch (updateType) {
      // Add will automatically redirect to FILL if we don't have matching places
      case 'add': {
        return setWizardStep(STEPS.SELECT);
      }
      case 'create':
      case 'edit': {
        return setWizardStep(STEPS.FORCED_FILL);
      }
      default: {
        return setWizardStep();
      }
    }
  }, [updateType]);

  return {
    fillFormData,
    isSubmitting: placeIsUpdating || routeIsUpdating || placeIsCreating,
    resetAndExit,
    wizardStep,
    goBackToSelect,
    confirmationData: createConfirmationData(
      wizardStep,
      fillFormData,
      existingPlace
    ),
    addNewData: isForced => {
      setWizardStep(isForced ? STEPS.FORCED_FILL : STEPS.FILL);
    },
    selectExistingPlace: place => {
      setExistingPlace(place);
      setWizardStep(STEPS.CONFIRM_ROUTE_UPDATE);
    },
    submitFillFormData: data => {
      // CreatePlaceInput! expects geolocationId of int, not string
      setFillFormData({
        ...data,
        geolocationId: Number(data.geolocationId),
      });
      setWizardStep(
        wizardStep === STEPS.FORCED_FILL
          ? STEPS.CONFIRM_FORCED_PLACE_UPDATE
          : STEPS.CONFIRM_PLACE_UPDATE
      );
    },
    handleAddStepBackButtonClick: () => {
      wizardStep === STEPS.FORCED_FILL ? resetAndExit() : goBackToSelect();
    },
    handleConfirmStepBackButtonClick: () => {
      if (wizardStep === STEPS.CONFIRM_FORCED_PLACE_UPDATE) {
        setWizardStep(STEPS.FORCED_FILL);
      } else if (wizardStep === STEPS.CONFIRM_PLACE_UPDATE) {
        setWizardStep(STEPS.FILL);
      } else if (wizardStep === STEPS.CONFIRM_ROUTE_UPDATE) {
        setWizardStep(STEPS.SELECT);
      }
    },
    confirmDataAndSubmit: () => {
      if (wizardStep === STEPS.CONFIRM_ROUTE_UPDATE) {
        const updateId = placeIdToNumber(existingPlace.id);

        if (typeof updateId === 'number') {
          updateRoute({
            id: updateId,
          });
        }
      } else if (
        wizardStep === STEPS.CONFIRM_PLACE_UPDATE ||
        wizardStep === STEPS.CONFIRM_FORCED_PLACE_UPDATE
      ) {
        if (updateType === 'create') {
          return createPlace(fillFormData);
        }
        updatePlace(fillFormData);
      }
    },
  };
};
