import React from 'react';
import {
  buildLoadingDurationDropOptions,
  buildLoadingDurationLiveOptions,
  PROCEDURES_LOADING_SCHEMA_MAP,
} from 'core/utils/route-facility';
import { Dropdown } from '@nuvocargo/nuvo-styleguide/forms/formik';
import { useField } from 'formik';
import { useTranslation } from 'react-i18next';

const LoadingDuration = ({ type }) => {
  const { t } = useTranslation();
  const [loadingSchemeField = {}] = useField({ name: 'loadingScheme' });

  return (
    <>
      {loadingSchemeField.value.value ===
        PROCEDURES_LOADING_SCHEMA_MAP.LIVE && (
        <div className="flex-1" data-testid="loading-duration">
          <Dropdown
            label={t(`facility-form-${type}-loading-duration-label`)}
            placeholder={t(
              `facility-form-${type}-loading-duration-placeholder`
            )}
            name="loadingDurationLive"
            options={buildLoadingDurationLiveOptions({
              t,
            })}
          />
        </div>
      )}
      {loadingSchemeField.value.value ===
        PROCEDURES_LOADING_SCHEMA_MAP.DROP && (
        <div className="flex-1" data-testid="loading-duration">
          <Dropdown
            label={t(`facility-form-${type}-loading-duration-label`)}
            placeholder={t(
              `facility-form-${type}-loading-duration-placeholder`
            )}
            name="loadingDurationDrop"
            options={buildLoadingDurationDropOptions({
              t,
            })}
          />
        </div>
      )}
    </>
  );
};

export default LoadingDuration;
