import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CTAButton, Toast } from '@nuvocargo/nuvo-styleguide';
import { useRequestPrice as useRequestPriceQuery } from 'core/api';

import { Form, Formik } from 'formik';
import Input from '@nuvocargo/nuvo-styleguide/forms/formik/Input';
import { useParams } from 'react-router-dom';
import { validationSchema, initialValues } from './data';

const RequestPrice = ({ addErrorToast, setToasts }) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const [showRequestButton, setShowRequestButton] = useState(true);
  const { isLoading: isSubmitting, mutate } = useRequestPriceQuery({
    onSuccessCallback: () => {
      setShowRequestButton(true);
      const newToast = (
        <Toast type="success">
          <p>{t('general-requested-text-label')}</p>
        </Toast>
      );
      setToasts(prev => [...prev, newToast]);
    },
    onErrorCallback: () => {
      addErrorToast();
    },
  });

  if (showRequestButton) {
    return (
      <CTAButton
        size="small"
        onClick={() => setShowRequestButton(false)}
        disabled={false}>
        {t('request-price-button')}
      </CTAButton>
    );
  }

  const onSubmit = ({ loadsPerMonth }) => {
    mutate({
      id,
      loadsPerMonth,
    });
  };

  return (
    <Formik
      validationSchema={validationSchema({ t })}
      initialValues={initialValues}
      onSubmit={onSubmit}>
      <Form className="flex flex-col gap-2">
        <Input
          label={t('routes-loads-per-month-label')}
          name="loadsPerMonth"
          placeholder={t('routes-loads-per-month-placeholder')}
          size="small"
          type="number"
        />

        <div className="flex flex-row gap-4">
          <CTAButton disabled={isSubmitting} size="small" type="submit">
            {t('confirm-request-price-button')}
          </CTAButton>

          <CTAButton
            colorType="secondary"
            disabled={isSubmitting}
            onClick={() => setShowRequestButton(true)}
            size="small"
            type="button">
            {t('cancel-request-price-button')}
          </CTAButton>
        </div>
      </Form>
    </Formik>
  );
};

export default RequestPrice;
