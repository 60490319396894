import styled from 'styled-components';

export const pricingRequestStyles = {
  rootContainer: { display: 'flex', justifyContent: 'center' },
  maxContainer: { maxWidth: '1536px' },
  root: { padding: '48px 48px 0 0' },
  titleButton: {
    marginBottom: 48,
    display: 'flex',
    justifyContent: 'space-between',
  },
  title: { marginBottom: 48 },
  text: { marginBottom: 24 },
  nextButton: { marginTop: 48, display: 'flex', justifyContent: 'flex-end' },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 48,
  },
};

export const ResizeContainerDiv = styled.div`
  min-width: 100%;
  @media (max-width: ${({ theme }) => theme.mediaSizes.laptopXL}) {
    min-width: calc(66vw - 96px);
  }
`;
