import * as yup from 'yup';
import {
  getAppointmentTypeOptionOrDefault,
  getLoadingDurationDropOptionOrDefault,
  getLoadingDurationLiveOptionOrDefault,
  getLoadingSchemeOptionOrDefault,
  getReceivingDaysOptionOrDefault,
  PROCEDURES_APPOINTMENT_TYPES_MAP,
  PROCEDURES_LOADING_DURATION,
  PROCEDURES_LOADING_SCHEMA_MAP,
  PROCEDURES_RECEIVING_DAYS_MAP,
} from 'core/utils/route-facility';
import { createIsFifoOptions } from '../../../../../lofi-create-shipment/data';

export const validationSchema = t =>
  yup.object().shape({
    name: yup.string().required(t('validation-locationName-required')),
    addressLine1: yup.string().required(t('validation-addressLine1-required')),
    contactTelephone: yup
      .string()
      .matches(/^(\+?1|\+?52).+/, t('validation-contactPhone-required')),
    contactName: yup.string().required(t('validation-contactName-required')),
    startTime: yup
      .string()
      .nullable()
      .when('appointmentType', {
        is: appointmentType => {
          return (
            appointmentType?.value === PROCEDURES_APPOINTMENT_TYPES_MAP.WINDOW
          );
        },
        then: yup
          .string()
          .required(t('validation-facility-form-loading-starts-required')),
      }),
    endTime: yup
      .string()
      .nullable()
      .when('appointmentType', {
        is: appointmentType =>
          appointmentType?.value === PROCEDURES_APPOINTMENT_TYPES_MAP.WINDOW,
        then: yup
          .string()
          .required(t('validation-facility-form-loading-ends-required')),
      }),
  });

export const getInitialValues = ({ placeData, t }) => {
  const {
    name,
    addressLine1,
    contactTelephone,
    contactName,
    addressLine2,
    facilityProcedure,
  } = placeData;

  const {
    loadingScheme = PROCEDURES_LOADING_SCHEMA_MAP.LIVE,
    appointmentType = PROCEDURES_APPOINTMENT_TYPES_MAP.FIFO,
    opensAt = '',
    closesAt = '',
    startTime = '08:00',
    endTime = '18:00',
    receivingDays = PROCEDURES_RECEIVING_DAYS_MAP.MONDAY_FRIDAY,
    loadingDuration = PROCEDURES_LOADING_DURATION.LIVE.LESS_THAN_TWO_HOURS,
    facilityInstructions,
  } = facilityProcedure || {};

  const isFifo = appointmentType === PROCEDURES_APPOINTMENT_TYPES_MAP.FIFO;

  return {
    name: name || '',
    addressLine1: addressLine1 || '',
    addressLine2: addressLine2 || '',
    contactTelephone: contactTelephone || '+1',
    contactName: contactName || '',
    loadingScheme: getLoadingSchemeOptionOrDefault({ value: loadingScheme, t }),
    appointmentType: getAppointmentTypeOptionOrDefault({
      value: appointmentType,
      t,
    }),
    isFifo: createIsFifoOptions(t).find(({ value }) => value === isFifo),
    opensAt: opensAt || '',
    closesAt: closesAt || '',
    startTime: startTime || '',
    endTime: endTime || '',
    receivingDays: getReceivingDaysOptionOrDefault({ value: receivingDays, t }),
    loadingDurationLive: getLoadingDurationLiveOptionOrDefault({
      value: loadingDuration,
      t,
    }),
    loadingDurationDrop: getLoadingDurationDropOptionOrDefault({
      value: loadingDuration,
      t,
    }),
    facilityInstructions,
  };
};
