import { motion } from 'framer-motion';
import * as React from 'react';

function SvgComponent(props) {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ x: [0, 20, 160], opacity: [0, 1, 1] }}
      transition={{
        duration: 2.5,
        times: [0, 0.2, 1],
        type: 'tween',
        repeat: Infinity,
      }}
      {...props}>
      <motion.svg
        className="prefix__css-whxabe-root"
        viewBox="0 0 102 45"
        width="50"
        animate={{ rotateZ: [0.2, 0.5, -0.2] }}
        transition={{
          duration: 1,
          repeat: Infinity,
          repeatType: 'reverse',
        }}
        {...props}>
        <title />
        <g transform="translate(1 1)" fill="none" fillRule="evenodd">
          <path
            stroke="#002C9D"
            strokeWidth={1.265}
            fill="#FFF"
            strokeLinejoin="round"
            d="M0 0v27.463h64.648V0z"
          />
          <path
            stroke="#002C9D"
            strokeWidth={1.265}
            fill="#FFF"
            strokeLinecap="round"
            d="M38.356 37.041H70.71V6.764h-6.063v20.7H3.698h0v9.577h3.01"
          />
          <path stroke="#002C9D" strokeWidth={1.265} d="M64.648 27.463v9.578" />
          <path
            stroke="#002C9D"
            strokeWidth={1.265}
            strokeLinecap="round"
            d="M64.648 34.386h-26.4"
          />
          <motion.circle
            stroke="#002C9D"
            strokeWidth={1.265}
            fill="#FFF"
            cx={15.079}
            cy={36.937}
            r={6.324}
            initial={{ scaleY: 0.96 }}
            animate={{ scaleY: 1 }}
            transition={{
              duration: 0.3,
              repeat: Infinity,
              repeatType: 'reverse',
            }}
          />
          <motion.circle
            stroke="#002C9D"
            strokeWidth={1.265}
            fill="#FFF"
            cx={29.625}
            cy={36.937}
            r={6.324}
            initial={{ scaleY: 0.96 }}
            animate={{ scaleY: 1 }}
            transition={{
              duration: 0.3,
              repeat: Infinity,
              repeatType: 'reverse',
            }}
          />
          <path
            d="M91.713 37.041h5.12V23.992c0-.839-.334-1.643-.927-2.236l-.34-.34a3.162 3.162 0 01-.927-2.237v-8.845h0c0-4.541-3.68-8.222-8.221-8.222H70.71v34.93h2.857"
            stroke="#002C9D"
            strokeWidth={1.265}
            fill="#FFF"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            stroke="#002C9D"
            strokeWidth={1.265}
            strokeLinejoin="round"
            d="M94.566 9.65H83.772v9.927h10.794z"
          />
          <motion.circle
            stroke="#002C9D"
            strokeWidth={1.265}
            cx={15.079}
            cy={36.937}
            r={2.53}
            initial={{ scaleY: 0.96 }}
            animate={{ scaleY: 1 }}
            transition={{
              duration: 0.3,
              repeat: Infinity,
              repeatType: 'reverse',
            }}
          />
          <motion.circle
            stroke="#002C9D"
            strokeWidth={1.265}
            cx={29.625}
            cy={36.937}
            r={2.53}
            initial={{ scaleY: 0.96 }}
            animate={{ scaleY: 1 }}
            transition={{
              duration: 0.3,
              repeat: Infinity,
              repeatType: 'reverse',
            }}
          />
          <motion.circle
            stroke="#002C9D"
            strokeWidth={1.265}
            fill="#FFF"
            cx={82.748}
            cy={36.937}
            r={6.324}
            initial={{ scaleY: 0.96 }}
            animate={{ scaleY: 1 }}
            transition={{
              duration: 0.3,
              repeat: Infinity,
              repeatType: 'reverse',
            }}
          />
          <motion.circle
            stroke="#002C9D"
            strokeWidth={1.265}
            cx={82.748}
            cy={36.937}
            r={2.53}
            initial={{ scaleY: 0.96 }}
            animate={{ scaleY: 1 }}
            transition={{
              duration: 0.3,
              repeat: Infinity,
              repeatType: 'reverse',
            }}
          />
          <path
            d="M91.74 33.91a9.49 9.49 0 00-17.982-.011"
            stroke="#002C9D"
            strokeWidth={1.265}
            strokeLinecap="round"
          />
          <path
            d="M96.832 28.494h1.326c.699 0 1.265.566 1.265 1.265v3.363c0 .698-.566 1.264-1.265 1.264h-1.326 0"
            stroke="#002C9D"
            strokeWidth={1.265}
          />
          <path
            stroke="#F0F2F2"
            strokeWidth={0.632}
            d="M96.832 25.502H70.711M80.816 25.213V6.764M77.764 6.764h15.663"
          />
          <path
            d="M91.713 37.041h5.12V23.992c0-.839-.334-1.643-.927-2.236l-.34-.34a3.162 3.162 0 01-.927-2.237v-8.845h0c0-4.541-3.68-8.222-8.221-8.222H70.71v34.93h2.857"
            stroke="#002C9D"
            strokeWidth={1.265}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </motion.svg>
    </motion.div>
  );
}

export default SvgComponent;
