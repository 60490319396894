import { useEffect } from 'react';

/**
 * You can use https://codepen.io/Zyst/pen/bGrMOoj?editors=0011
 * to find the Keyboard Event code you want.
 *
 * using the withCtrl/withAlt/etc options makes it so that people have to be
 * pressing those keys at the same time as your provided key for the callback
 * to get triggered.
 *
 * customKeyChecker is a function that will override everything else, if that
 * returns a truthy value, your callback will get called no matter what.
 */
export const useHotkey = (
  code,
  callback,
  {
    customKeyChecker,
    withCtrl = false,
    withAlt = false,
    withShift = false,
    withMeta = false,
  } = {}
) => {
  useEffect(() => {
    const handleKeyPress = e => {
      if (callback) {
        const modifiersMatch =
          e.shiftKey === withShift &&
          e.ctrlKey === withCtrl &&
          e.altKey === withAlt &&
          e.metaKey === withMeta;
        const keyMatches = e.code === code;

        if ((modifiersMatch && keyMatches) || customKeyChecker?.(e)) {
          e.stopImmediatePropagation();
          callback(e);
        }
      }
    };
    window.document.addEventListener('keydown', handleKeyPress);

    return () => {
      window.document.removeEventListener('keydown', handleKeyPress);
    };
  }, [code, callback, customKeyChecker]);
};
