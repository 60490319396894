import {
  CTAButton,
  Eyebrow,
  Heading,
  Icon,
  Text,
} from '@nuvocargo/nuvo-styleguide';
import { useTranslation } from 'react-i18next';

const Unconfigured = ({ place, label, buttonOnClick }) => {
  const { t } = useTranslation();
  const {
    zipCode: {
      name: zipCode,
      parents: { city },
    },
  } = place;

  return (
    <>
      <div>
        <Icon name="error" color="darkGrey" size="small" />
      </div>
      <div className="relative flex w-full flex-col">
        <div className="flex w-full justify-between">
          <div>
            <Eyebrow size="small" color="steel" text={label} />

            <Heading variant="h6" color="green">
              {`${city}, ${zipCode}`}
            </Heading>
          </div>

          <CTAButton
            icon="keyboardArrowRight"
            colorType="primary"
            onClick={buttonOnClick}>
            {t('facility-button-add')}
          </CTAButton>
        </div>
        <Text color="steel">{t('facility-not-configured-text')}</Text>
      </div>
    </>
  );
};

export default Unconfigured;
