export const GET_PLACES = `
      query getPlaces($geolocationId: String, $companyId: String) {
        places(geolocationId: $geolocationId, companyId: $companyId) {
          id
          name
          addressLine1
          addressLine2
          contactName
          contactTelephone
          companyId
          configured
          city {
            name
          }
          state {
            name
          }
          zipCode {
            id
            name
            latitude
            longitude
            parents {
              city
              state
              country
            }
          }
          country {
            name
          }
        }
      }`.replace(/\s+/g, ' ');
