import styled from 'styled-components';
import { theme } from 'styles';

export const TableStyles = styled.div`
  margin-top: 24px;
  background-color: ${({ theme }) => theme.colors.white};
  padding: 32px;

  table {
    width: 100%;
    border-collapse: collapse;

    thead {
      border-bottom: ${({ theme }) => theme.outlines.default.border};
    }
  }
`;
TableStyles.displayName = 'TableStyles';

export const TableHeader = styled.div`
  align-items: center;
  display: flex;
  min-height: 20px;
  padding-bottom: 10px;
  justify-content: ${({ alignRight }) =>
    alignRight ? 'flex-end' : 'flex-start'};
`;
TableHeader.displayName = 'TableHeader';

export const TableCell = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${({ alignRight }) =>
    alignRight ? 'flex-end' : 'flex-start'};
  ${({ maxWidth }) => (maxWidth ? `max-width: ${maxWidth}` : undefined)}
  padding-top: ${({ isFirstRow }) => (isFirstRow ? '24px' : '32px')}
`;
TableCell.displayName = 'TableCell';

export const Footer = styled.div`
  align-items: center;
  border-top: 1px solid #ece9e7;
  display: flex;
  justify-content: space-between;
  margin-bottom: 42px;
  padding: 24px 32px 0 32px;
`;
Footer.displayName = 'Footer';

export const EstimatedWrapper = styled.div`
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${theme.colors.white};
`;
EstimatedWrapper.displayName = 'EstimatedWrapper';

export const ShipmentEventTypeDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  justify-content: center;
`;
ShipmentEventTypeDetails.displayName = 'ShipmentEventTypeDetails';
