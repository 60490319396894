import { formatAddress } from 'core/utils';

export const placeToConfirmation = ({
  name,
  addressLine1,
  addressLine2,
  city,
  state,
  zipCode,
  country,
  contactName,
  contactTelephone,
}) => ({
  name,
  address: formatAddress({
    addressLine1,
    addressLine2,
    city,
    state,
    zipCode,
    country,
  }),
  contactName,
  contactTelephone,
});

export const countryShorthandToString = country => {
  switch (country) {
    case 'mx': {
      return 'Mexico';
    }

    case 'us': {
      return 'USA';
    }

    default: {
      return undefined;
    }
  }
};

export const placeIdToNumber = placeId =>
  parseInt(placeId?.replace('place-', ''), 10);

export const CustomerStatusEnum = Object.freeze({
  PROSPECT: 'PROSPECT',
  ACTIVE: 'ACTIVE',
  ON_HOLD: 'ON_HOLD',
});
