import { useState, useEffect } from 'react';
import { TextField } from '@nuvocargo/nuvo-styleguide/forms/formik';
import { useField } from 'formik';

const isInteger = /^\d+$/;

export default function RouteLoadsPerMonth({
  name,
  id,
  placeholder,
  uploadedValue,
  disabled,
}) {
  const [loads, setLoads] = useState(uploadedValue || '');
  const [, , helpers] = useField(name);

  useEffect(() => {
    if (isInteger.test(uploadedValue)) {
      helpers.setTouched(true);
      setLoads(uploadedValue);
    }
  }, [uploadedValue]);

  const handleChange = e => {
    const value = parseInt(e.currentTarget.value);
    helpers.setValue(value);
    setLoads(value);
  };

  return (
    <TextField
      name={name}
      id={id}
      placeholder={placeholder}
      type="number"
      onChange={handleChange}
      value={loads}
      min={1}
      disabled={disabled}
    />
  );
}
