import { Modal } from '@nuvocargo/nuvo-styleguide';
import { theme } from 'styles';
import { theme as colorTheme } from '@nuvocargo/nuvo-styleguide/lib/theme';

const Sidebar = ({
  children,
  closeShipmentDetailsView,
  shipmentId,
  isExceptionSidebar = false,
}) => {
  return (
    <Modal
      isOpen={!!shipmentId}
      closeModal={closeShipmentDetailsView}
      variant="rightSidebar"
      styles={{
        sidebar: {
          width: '764px',
          backgroundColor: `${theme.colors.cream}`,
        },
        close_button: {
          right: '32px',
          top: '14px',
          backgroundColor: `${colorTheme.colorWithAlpha('darkGrey/10')}`,
          width: '48px',
          height: '48px',
          borderRadius: '50%',
          color: `${theme.colors.forest}`,
        },
      }}>
      {children}
    </Modal>
  );
};

export default Sidebar;
