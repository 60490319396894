import { RequestDetails } from './components';
import { Route, Switch, useParams } from 'react-router-dom';
import usePricingRequest from './usePricingRequest';
import { pricingRequestStyles } from './pricingRequest.styles';

import {
  Serviceability,
  LegCosts,
  LegCostsDetails,
  LegPrices,
  ReviewPublish,
  PublishedDetails,
  DirectServicePremium,
} from './steps';

import { Grid, GridColumn } from '@nuvocargo/nuvo-styleguide';

export default function PricingRequest() {
  const { id: requestId } = useParams();

  const { paths, isLoading, state, dispatch } = usePricingRequest({
    requestId,
  });

  // @todo: improve loading state
  if (isLoading) return <></>;
  return (
    <div style={pricingRequestStyles.rootContainer}>
      <div style={pricingRequestStyles.maxContainer}>
        <Grid>
          <GridColumn style={pricingRequestStyles.root} md={8} sm={4}>
            <Switch>
              <Route exact path={paths.pricingRequestsServiceability}>
                <Serviceability state={state} dispatch={dispatch} />
              </Route>
              <Route exact path={paths.pricingRequestsLegCosts}>
                <LegCosts state={state} dispatch={dispatch} />
              </Route>
              <Route exact path={paths.pricingRequestsDirectServicePremium}>
                <DirectServicePremium state={state} dispatch={dispatch} />
              </Route>
              <Route exact path={paths.pricingRequestsLegCostsDetails}>
                <LegCostsDetails state={state} dispatch={dispatch} />
              </Route>
              <Route exact path={paths.pricingRequestsLegPrices}>
                <LegPrices state={state} dispatch={dispatch} />
              </Route>
              <Route exact path={paths.pricingRequestsReviewAndPublish}>
                <ReviewPublish state={state} dispatch={dispatch} />
              </Route>
              <Route exact path={paths.pricingRequestsReviewAndPublishDetails}>
                <PublishedDetails state={state} dispatch={dispatch} />
              </Route>
            </Switch>
          </GridColumn>
          <GridColumn md={4} sm={4}>
            <RequestDetails requestId={requestId} data={state.details} />
          </GridColumn>
        </Grid>
      </div>
    </div>
  );
}
