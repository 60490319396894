import { Heading } from '@nuvocargo/nuvo-styleguide';
import { useTranslation } from 'react-i18next';
import { Stagger } from 'components/generic/kit';
import ShipmentForm from './ShipmentForm';

const CreateShipment = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="mx-auto max-w-2xl py-12 px-8">
        <Stagger>
          <Stagger.Child>
            <Heading
              variant="h3"
              color="forest"
              style={{ marginBottom: '12px' }}>
              {t('create-new-shipment-heading')}
            </Heading>
          </Stagger.Child>

          <ShipmentForm />
        </Stagger>
      </div>
    </>
  );
};

export default CreateShipment;
