import { Heading } from '@nuvocargo/nuvo-styleguide';
import { Stack } from 'components/generic/kit';

export default function FiltersSection({ tableTitle }) {
  return (
    <Stack
      axis="horizontal"
      space="24px"
      styles={{
        root: {
          margin: '60px 60px 0px 60px',
          justifyContent: 'space-between',
        },
      }}>
      <Heading variant="h3" color="forest">
        {tableTitle}
      </Heading>
    </Stack>
  );
}
