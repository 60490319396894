import {
  getCursorFromOffset,
  getOffsetFromPageNumber,
} from 'core/utils/pagination';
import { useNuvoTable } from '@nuvocargo/nuvo-styleguide/Table';
import useCreateColumns from './useCreateColumns';
import { useQuery } from 'react-query';
import { getPricingRouteRequests, useCompanies, useAssignees } from 'core/api';
import {
  REQUEST_PRICE_STATUS_ENUM,
  PRICING_REP_FILTER_UNASSIGNED,
} from 'core/utils/routeUtils';
import { generatePath, useHistory } from 'react-router-dom';
import * as paths from 'routes/paths';
import { useTranslation } from 'react-i18next';

export const usePricingRequests = ({ size, after = '', filters }) => {
  return useQuery(
    ['getPricingRouteRequests', size, after, filters],
    () => getPricingRouteRequests({ pageSize: size, after, filters }),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );
};

export const usePricingRequestsTable = () => {
  const history = useHistory();
  const { t } = useTranslation();

  const {
    contextState: {
      page: { number, size },
    },
    contextState,
  } = useNuvoTable();

  const {
    isLoading: isCompaniesLoading,
    data: companies,
    isError: isCompaniesError,
  } = useCompanies(
    { businessType: 'CUSTOMER', withRequestIdentifiers: true },
    {
      select: data => {
        return data.length <= 0
          ? []
          : [
              ...data.map(({ id, legalName }) => ({
                value: id,
                label: legalName,
              })),
            ];
      },
    }
  );

  const {
    isLoading: isAssigneesLoading,
    data: assignees,
    isError: isAssigneesError,
  } = useAssignees({
    select: data => {
      return data.length <= 0
        ? []
        : [
            {
              value: PRICING_REP_FILTER_UNASSIGNED,
              label: t('general-not-assigned-text-label'),
            },
            ...data.map(({ id, name }) => ({
              value: id,
              label: name,
            })),
          ];
    },
  });

  const columns = useCreateColumns({
    isCompaniesLoading,
    companies,
    isAssigneesLoading,
    assignees,
  });

  const {
    data,
    isLoading,
    isError: isPricingRequestError,
  } = usePricingRequests({
    size: size,
    after: getCursorFromOffset(getOffsetFromPageNumber(size, number)),
    filters: contextState.filter,
  });

  const isPricedStatus = value =>
    value.toUpperCase() === REQUEST_PRICE_STATUS_ENUM.PRICED;

  const onRowClickHandler = ({ values }) => {
    if (isPricedStatus(values.priceState)) {
      history.push(
        generatePath(paths.app.pricingRequestsReviewAndPublishDetails, {
          id: values.requestIdentifier,
        })
      );
      return;
    }

    history.push(
      generatePath(paths.app.pricingRequestsServiceability, {
        id: values.requestIdentifier,
      })
    );
  };

  return {
    columns,
    onRowClickHandler,
    isLoading,
    isError: isPricingRequestError || isAssigneesError || isCompaniesError,
    data: data?.data || [],
    totalItems: data?.totalCount || 0,
  };
};
